import { gql } from '@apollo/client';

export const GET_MARKET_PLANS = gql`
  query GetMarketPlans {
    marketPlans {
      id
      name
      price
      description
      label
      slug
      alias
      renewal
      rights {
        value
        type
        name
        description
      }
      scope {
        apparel
        audience
      }
      color
      markets {
        location {
          name
        }
        audiences {
          ageMin
          ageMax
          gender
        }
        apparelGroups {
          name
          items {
            label
          }
        }
      }
    }
  }
`;

export const GET_AVAILABLE_APPARELS = gql`
  query AvailableApparels {
    availableApparels {
      id
      name
      items {
        label
        value
      }
    }
  }
`;

export const GET_FINALIZE_MARKET_APPARELS = gql`
  query FinalizeMarketApparels($cartId: String) {
    finalizeMarketApparels(cartId: $cartId) {
      marketId
      apparelGroups {
        id
        name
        items {
          label
          value
        }
      }
      location {
        name
      }
      predefinedPlan
    }
  }
`;

export const GET_STUDIO_PLANS = gql`
  query StudioPlans {
    studioPlans {
      slug
      label
      color
      description
      price
      credit
      featureFlags {
        isStudioEnabled
        isStudioExpertModeEnabled
        isPrivateImageEnabled
      }
    }
  }
`;

export const CHECK_PRORATED_PAYMENT = gql`
  query CheckProratedPayment($quantity: Int, $planSlug: String) {
    checkProratedPayment(quantity: $quantity, planSlug: $planSlug) {
      days
      price
    }
  }
`;
