import { gapMap } from '@/components';

export function FlexColumn({
  gap = 'md',
  items,
  justify,
  className = '',
  children,
  as = 'div',
  ...props
}) {
  const Comp = as;

  return (
    <Comp
      className={[
        'flex flex-col',
        items ? `items-${items}` : '',
        justify ? `justify-${justify}` : '',
        gapMap[gap],
        className,
      ].join(' ')}
      {...props}
    >
      {children}
    </Comp>
  );
}
