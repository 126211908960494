import React, { useEffect, useMemo, useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { ChevronRight, Minus, Plus } from 'lucide-react';

import { GET_STUDIO_EXPLORE } from '@/api/studio/queries';
import { Slider } from '@/components/ui/slider';

import { LazyLoadTrigger } from '../../explore/components/utils';

import { SidebarHeader } from './SidebarMenu';
import { StudioExploreTabs } from './StudioExploreTabs';
import { StudioGeneratedImage } from './StudioHistorySidebar';

const generationType = {
  sketches: 'REALIZE_SKETCH',
  patterns: 'GENERATE_PATTERN',
  designs: 'GENERATE_APPAREL',
};

const StudioExplore = ({ activeModal, activeTab, columnCount }) => {
  const [edges, setEdges] = useState([]);
  const [noEdges, setNoEdges] = useState(false);
  const [, { data, error, loading, fetchMore, networkStatus, refetch }] =
    useLazyQuery(GET_STUDIO_EXPLORE, {
      variables: {
        cursor: null,
        generationType: generationType[activeModal],
        entityType: activeTab === 'all' ? null : activeTab,
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data.studioExplore.edges.length === 0) {
          setNoEdges(true);
        } else {
          setEdges(data.studioExplore.edges);
        }
      },
    });

  useEffect(() => {
    if (activeModal) {
      refetch({
        cursor: null,
        generationType: generationType[activeModal],
        entityType: activeTab === 'all' ? null : activeTab,
      });
    }
  }, [activeTab, activeModal]);

  const { pageInfo } = data?.studioExplore || {};
  const { endCursor, hasNextPage } = pageInfo || {};

  const loadingMore = networkStatus === 3;

  if (loading && !loadingMore) {
    return <StudioExploreSideBarLoading activeModal={activeModal} />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="h-full px-2 lg:px-6">
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: columnCount }}>
        <Masonry gutter="0.5rem">
          {edges.length > 0 &&
            edges.map((edge, i) => (
              <StudioGeneratedImage
                key={i}
                image={edge.node.image}
                studioItemId={edge.node.id}
                entityType={edge.entityType}
                activeModal={activeModal}
              />
            ))}
        </Masonry>
      </ResponsiveMasonry>
      {noEdges && (
        <div className="flex h-full w-full items-center justify-center">
          <h3 className="text-center text-sm text-secondary">
            No {activeModal} found
          </h3>
        </div>
      )}
      {hasNextPage &&
        !loadingMore &&
        Array(10)
          .fill()
          .map((_, index) => (
            <div key={index} className="flex h-12 w-full bg-transparent">
              <LazyLoadTrigger
                fetchMore={() => {
                  fetchMore({
                    variables: {
                      cursor: endCursor,
                    },
                  });
                }}
                cursorNext={endCursor}
                loading={loading}
              />
            </div>
          ))}
    </div>
  );
};

export const StudioExploreSideBar = ({ activeModal, setActiveModal }) => {
  const [columnCount, setColumnCount] = useState(3);
  const [activeTab, setActiveTab] = useState('all');

  const handleSliderChange = (value) => {
    setColumnCount(Math.max(1, Math.min(5, 6 - value[0])));
  };

  const sliderValue = useMemo(() => [6 - columnCount], [columnCount]);

  return (
    <div
      style={{
        boxShadow: '-4px 0 8px rgba(0, 0, 0, 0.05)',
        transition: 'right 2s ease',
      }}
      className="absolute top-0 right-[7rem] flex h-full w-[22.5rem] items-start justify-start border-x border-secondary/50"
    >
      <div className="flex h-full w-full flex-col items-start justify-between bg-white py-6">
        <div className="flex h-full w-full flex-col gap-4 overflow-hidden">
          <div className="flex flex-col px-2 lg:px-6">
            <SidebarHeader
              text={activeModal}
              variant="back"
              icon={ChevronRight}
              onClick={() => setActiveModal(null)}
            />

            <StudioExploreTabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
          <div className="flex w-full flex-row items-center justify-between px-2 lg:px-6">
            <h3 className="text-xs font-bold text-secondary">
              {dayjs().format('MMM YYYY')}
            </h3>
            <div className="flex w-1/2 items-center justify-center gap-x-2">
              <div className="flex cursor-pointer items-center justify-center">
                <Minus
                  size={18}
                  strokeWidth={1.5}
                  onClick={() => handleSliderChange([sliderValue[0] - 1])}
                />
              </div>
              <Slider
                value={sliderValue}
                min={1}
                max={5}
                step={1}
                onValueChange={handleSliderChange}
              />
              <div className="flex cursor-pointer items-center justify-center">
                <Plus
                  size={18}
                  strokeWidth={1.5}
                  onClick={() => handleSliderChange([sliderValue[0] + 1])}
                />
              </div>
            </div>
          </div>
          <div className="flex h-full flex-col overflow-auto">
            <StudioExplore
              activeModal={activeModal}
              activeTab={activeTab}
              columnCount={columnCount}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const StudioExploreSideBarLoading = ({ activeModal }) => {
  return (
    <div className="grid grid-cols-3 gap-2 px-2 lg:px-6">
      {Array.from({ length: 16 }).map((_, i) => (
        <div
          key={i}
          className={`${activeModal === 'patterns' ? 'aspect-square' : 'aspect-[3/4]'} animate-pulse rounded-md bg-gray-200`}
        />
      ))}
    </div>
  );
};
