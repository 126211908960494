import React from 'react';

import { useReports } from '@/hooks/report';

export const SelectPlanType = ({ item, checkPlans }) => {
  const { plan } = useReports();

  const planSelected = plan && plan.slug === item.slug;

  return (
    <div
      className="flex h-[47px] w-[200px] items-center justify-center border-x-2 border-t-2 border-charcoal text-[24px] font-medium leading-[28px] text-white group-hover:border-primary"
      style={{
        background: item.color,
        borderColor: planSelected && '#3CA780',
      }}
    >
      {item.label}
    </div>
  );
};
