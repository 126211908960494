import { useState } from 'react';
import { Root as RadixRoot } from '@radix-ui/react-popover';

import { PopoverContext } from './PopoverHook';

export function PopoverRoot({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  function show() {
    setIsOpen(true);
  }

  function hide() {
    setIsOpen(false);
  }

  return (
    <PopoverContext.Provider value={{ hide, show }}>
      <RadixRoot open={isOpen}>{children}</RadixRoot>
    </PopoverContext.Provider>
  );
}
