import React from 'react';

import { useMarketsMutation } from '@/api/market/hook';
import { useReports } from '@/hooks/report';

import { ArrowRightIcon } from '../../icons';

export const ReportButtons = () => {
  const { addNewReport, reports } = useReports();

  const [createSession] = useMarketsMutation('createMarketCheckoutSession');

  const containsPredefinedPlan = reports.find(
    (report) => report.plan.alias !== undefined,
  );

  const predefinedPlanVariables = {
    successUrl: `${window.location.origin}/create-trend-report/finalize`,
    cancelUrl: `${window.location.origin}/create-trend-report/select-a-plan`,
    predefinedPlan: {
      slug: containsPredefinedPlan.plan.slug,
    },
  };

  const handleCheckout = () => {
    createSession({
      variables: containsPredefinedPlan
        ? predefinedPlanVariables
        : {
            marketInputs: reports.map((report) => {
              return {
                plan: {
                  slug: report.plan.slug,
                },
                location: {
                  id: report.location.id,
                  name: report.location.name,
                },
                audience: report.audience.map((audience) => ({
                  ageMin: audience.ageMin,
                  ageMax: audience.ageMax,
                  gender: audience.gender,
                })),
                apparelGroups: report.apparelGroups.map((group) => group.id),
              };
            }),
            successUrl: `${window.location.origin}/create-trend-report/finalize`,
            cancelUrl: `${window.location.origin}/create-trend-report/select-a-plan`,
          },
      onCompleted: (data) => {
        window.open(data.createMarketCheckoutSession.url, '_blank').focus();
      },
    });
  };

  return (
    <div className="mt-[32px] flex w-full gap-x-[27px]">
      {containsPredefinedPlan ? null : (
        <button
          className="flex h-[58px] flex-1 items-center justify-center border-2 border-primary text-[16px] font-semibold leading-[28px] text-primary"
          onClick={addNewReport}
        >
          Add a New Market +
        </button>
      )}
      <button
        className="flex h-[58px] flex-1 items-center justify-center space-x-[1px] bg-[#3CA780] text-[16px] font-semibold leading-[28px] text-white"
        onClick={handleCheckout}
      >
        <span>Complete Checkout</span> <ArrowRightIcon color="#fff" />
      </button>
    </div>
  );
};
