import { FilterDropdown } from '@/components/FilterDropdown';
import { useAppFilters } from '@/contexts/AppFiltersContext';

export const Markets = () => {
  const { filters, handleSourceOnChange } = useAppFilters();
  const { source } = filters;

  return (
    <FilterDropdown
      label={source?.label}
      placeholder={source?.placeholder}
      options={source?.options}
      value={source?.value}
      onChange={handleSourceOnChange}
      isLoading={source?.loading}
      isSearchable={true}
      autoFocus={true}
      colorScheme="primary"
    />
  );
};
