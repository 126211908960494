import styles from '../index.module.css';

const gridSizes = {
  1: [12],
  2: [6, 6],
  3: [4, 4, 4],
  4: [7, 5, 5, 7],
  5: [7, 5, 4, 4, 4],
  6: [4, 4, 4, 4, 4, 4],
};

export function ImageOverlay({ count, url }) {
  return (
    <div className={styles.imageOverlayWrapper}>
      <p className={styles.imageOverlayText}>{`+${count} More`}</p>
    </div>
  );
}

export function Image({ url, spanSize, showOverlay, totalItemCount }) {
  return (
    <div
      style={{
        gridColumn: `span ${spanSize}`,
        aspectRatio: `${spanSize} / 4`,
        position: 'relative',
      }}
    >
      {showOverlay && <ImageOverlay url={url} count={totalItemCount} />}

      <img src={url} className={styles.image} />
    </div>
  );
}

function ImageSkeleton() {
  return <div className="ghost col-span-4 aspect-square w-full" />;
}

export function Images({ images, totalItemCount, skeleton }) {
  if (skeleton) {
    return (
      <div className={styles.imagesWrapper}>
        {Array.from({ length: 6 }).map((_, index) => (
          <ImageSkeleton key={index} />
        ))}
      </div>
    );
  }

  return (
    <div className={styles.imagesWrapper}>
      {images.map((image, index) => (
        <Image
          key={index}
          url={image.url}
          showOverlay={
            index === images.length - 1 &&
            totalItemCount > 6 &&
            totalItemCount > images.length
          }
          spanSize={gridSizes[images.length][index]}
          totalItemCount={totalItemCount}
        />
      ))}
    </div>
  );
}
