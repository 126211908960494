import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const accountFormSchema = Yup.object().shape({
  fullName: Yup.string()
    .required('Fullname is required')
    .min(3, 'Fullname must be at 3 char long'),
  email: Yup.string()
    .email()
    .required('Email is required')
    .min(3, 'Email must be at 3 char long'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at 8 char long'),
  accountType: Yup.string()
    .required('Account type is required')
    .min(3, 'Account type must be at 3 char long'),
});

export const accountFormOptions = {
  resolver: yupResolver(accountFormSchema),
};
