import {
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
  Text,
} from '@/components';
import { hexToHsl } from '@/helpers/hexToHsl';
import { getInitials } from '@/helpers/utils';
import { useToast } from '@/hooks/useToast';
import { Icon } from '@/icons';

export const PantoneCard = ({ item: { hex, code, addedBy, name } }) => {
  const toast = useToast();

  return (
    <div
      className={''}
      style={{
        breakInside: 'avoid',
      }}
    >
      <div
        className="group relative aspect-[16/9] min-h-[6rem] w-full"
        style={{ background: hex }}
      >
        <div className="absolute top-3 left-3 hidden group-hover:flex">
          {addedBy && (
            <PopoverRoot>
              <PopoverTrigger asChild>
                <Text
                  weight="bold"
                  className={hexToHsl(hex) >= 75 ? 'text-black' : 'text-white'}
                >
                  {getInitials(addedBy.fullName)}
                </Text>
              </PopoverTrigger>
              <PopoverContent sideOffset={5} className="z-[999]">
                <div className="flex w-[17.3125rem] flex-col px-[2rem] py-[1.625rem]">
                  <h5 className="text-[1rem] font-semibold leading-[1.155rem]  text-white">
                    Added by {addedBy.fullName}
                  </h5>
                </div>
              </PopoverContent>
            </PopoverRoot>
          )}
        </div>
        <div
          className="absolute top-0 right-0 hidden scale-50 cursor-pointer items-center justify-center group-hover:flex"
          onClick={() => {
            navigator.clipboard.writeText(hex);
            toast.createToast({
              message: 'Copied to clipboard',
              type: 'success',
              showCloseButton: true,
              position: 'top-right',
            });
          }}
        >
          <Icon name="copy" color={hexToHsl(hex) >= 75 ? 'black' : 'white'} />
        </div>
        <div
          className="absolute bottom-[6px] left-[12px] text-[18px] font-semibold leading-[27px]"
          style={{
            color: hexToHsl(hex) >= 75 ? 'black' : 'white',
          }}
        >
          <p>Pantone {code} TCX</p>
          {/* <p></p> */}
          <p className="capitalize">{name.replaceAll('-', ' ')}</p>
        </div>
      </div>
    </div>
  );
};
