import { useReports } from '@/hooks/report';

import {
  AudienceAge,
  AudienceAttention,
  AudienceCaption,
  AudienceCard,
  AudienceCreate,
  AudienceCreateNew,
  AudienceGender,
  AudienceName,
  AudienceSave,
} from './';

export const Audience = () => {
  // const audience =
  //   reports?.[reports?.findIndex((item) => item.id === activeReport)]?.audience;
  // const scope =
  //   reports?.[reports?.findIndex((item) => item.id === activeReport)]?.plan
  //     .scope;

  const { audience, plan } = useReports();

  // useEffect(() => {
  //   activeReport === null && navigate('/create-trend-report');
  // }, [activeReport, navigate]);

  const scope = plan?.scope || {};

  const isEdit = (index) =>
    audience?.some((item) => item.edit === true) &&
    audience[index].edit !== true;

  const checkScroll = () =>
    audience?.some((item) => item.edit === true) || audience?.length > 2;

  return (
    <div className="mt-[29px] flex max-w-[573px] flex-col">
      <AudienceCaption />
      <div
        className={`custom-scrollbar-second flex max-h-[273px] w-full flex-col space-y-[12px] overflow-scroll overflow-x-hidden 2xl:max-h-[433px] ${
          checkScroll() && 'pr-[20px]'
        }`}
      >
        {audience?.map((item, index) => (
          <div
            className={`w-full flex-col ${!isEdit(index) ? 'flex' : 'hidden'}`}
            key={index}
          >
            {item?.edit ? (
              <>
                <AudienceName audience={item} />
                <AudienceGender audience={item} />
                <AudienceAge audience={item} />
                <AudienceSave audience={item} id={index} />
              </>
            ) : (
              <AudienceCard audience={item} />
            )}
          </div>
        ))}
      </div>
      {audience?.length === scope?.audience &&
      !audience?.find((item) => item.edit === true) ? (
        <AudienceAttention />
      ) : audience.length ? (
        <AudienceCreateNew />
      ) : null}

      {!audience?.length && <AudienceCreate />}
    </div>
  );
};
