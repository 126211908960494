import { Outlet } from 'react-router-dom';

import { useAppFilters } from '@/contexts/AppFiltersContext';
import { ExploreFilters } from '@/pages/general/explore/components/ExploreFilters';
import { ExploreTabs } from '@/pages/general/explore/components/ExploreTabs';

import { FashionWeekItems } from './FashionItems';
import { SocialMediaImages } from './SocialMediaImages';

export function ExploreContent() {
  const { mode } = useAppFilters();

  if (!mode) {
    return <SocialMediaImages />;
  }

  if (mode === 'explore-fashion-week') {
    return <FashionWeekItems />;
  }

  if (mode === 'explore-trends') {
    return <SocialMediaImages />;
  }

  return <SocialMediaImages />;
}

export function Explore() {
  return (
    <div className={'flex h-full w-full'}>
      <ExploreFilters />
      <ExploreTabs />
      <Outlet />
    </div>
  );
}
