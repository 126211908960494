import React from 'react';
import { Navigate } from 'react-router-dom';

import { useFeatureFlag } from '@/hooks/useFeatureFlag';

export const Private = ({ children }) => {
  const user = localStorage.getItem('user');
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  if (!user || !accessToken || !refreshToken) {
    localStorage.clear();
    return <Navigate to="/login" />;
  }
  return children;
};

export const BehindFeatureFlag = ({ children, featureFlag }) => {
  const { isEnabled, loading } = useFeatureFlag(featureFlag);
  if (loading) return null;
  if (!isEnabled) return <Navigate to="/" />;
  return children;
};
