import { LazyVideo } from 'react-lazy-media';

import { useStudioContext } from '@/contexts/StudioContext';

import { SidebarButton } from './components/SidebarMenu';
import { ImageView } from './components/StudioContents';
import { ImageSlider } from '@/components/ImageSlider/index';

export const StudioHome = ({ handleChange }) => {
  const { setPositivePrompt, updateCreateItemDetails } = useStudioContext();
  const DiscoverContents = [
    {
      title: '',
      description:
        'An establishing shot capturing the full-body of a dramatic, elegant woman figure embodying the Western Gothic aesthetic. She is dressed in a black suede tassel jacket with silver embellishments, paired with a floor-length deep burgundy tulle skirt. The look features spider-embroidered cowboy boots, a wide-brim hat, and silver skull rings. The backdrop is a moody, rural setting, subtly enhancing the macabre, frontier-inspired look.',
      image: '/assets/studio/studio-home/discover1.jpg',
      hoverImage: '/assets/studio/studio-home/discover1.jpg',
      alt: 'Discover 1',
    },
    {
      title: '',
      description:
        'Elegant white blouse, high-waisted beige trousers, classy pearl earrings, vintage handbag, no model, photorealistic, soft lighting, waist-up view, clean background, ultra-detailed, Ralph Lauren inspired, timeless fashion, sophisticated, high-definition, perfect product, high-quality, premium shot.',
      image: '/assets/studio/studio-home/discover2.jpg',
      hoverImage: '/assets/studio/studio-home/discover2.jpg',
      alt: 'Discover 2',
    },
    {
      title: '',
      description:
        'Create a full look women inspired by the White Noise style: a clean, minimalist long white overcoat with straight, sharp lines, paired with a soft grey turtleneck and tailored white trousers. Complete the look with white leather loafers and a sleek, minimalist silver wristwatch. The overall design should evoke tranquility and refined elegance through simplicity and neutral tones.',
      image: '/assets/studio/studio-home/discover3.jpg',
      hoverImage: '/assets/studio/studio-home/discover3.jpg',
      alt: 'Discover 3',
    },
    {
      title: '',
      description:
        'Oversized black bomber jacket with patches, black cargo pants, chunky black boots, black cap, no model, photorealistic, high-contrast lighting, full product, ultra hd, focused shot, highly detailed.',
      image: '/assets/studio/studio-home/discover4.jpg',
      hoverImage: '/assets/studio/studio-home/discover4.jpg',
      alt: 'Discover 4',
    },
    {
      title: '',
      description:
        'Black leather jacket, plain white t-shirt, dark skinny jeans, black combat boots, Kanye West inspired, no model, photorealistic, moody lighting, front view, full product, realistic, stylish, high-fashion, intricately detailed, gritty.',
      image: '/assets/studio/studio-home/discover5.jpg',
      hoverImage: '/assets/studio/studio-home/discover5.jpg',
      alt: 'Discover 5',
    },
    {
      title: '',
      description:
        'An establishing shot capturing the full-body of a polished, chic woman figure in a classic preppy look. She is dressed in a crisp white button-down shirt tucked into tailored navy Bermuda shorts. The look is completed with brown leather boat shoes and a pearl necklace. The backdrop is a sophisticated, coastal setting, subtly enhancing the timeless East Coast vibe.',
      image: '/assets/studio/studio-home/discover6.jpg',
      hoverImage: '/assets/studio/studio-home/discover6.jpg',
      alt: 'Discover 6',
    },
  ];

  const AIToolsContents = [
    {
      title: 'AI Prompt Helper',
      description:
        'Improve your prompts with word and phrase suggestions for better AI-generated outputs, all without language barriers.',
      image: '/assets/studio/studio-home/1.png',
      hoverImage: '/assets/studio/studio-home-hover/1.png',
      alt: 'AI Prompt Helper',
    },
    {
      title: 'Apply Pattern',
      description:
        'Effortlessly fit previously produced patterns on our platform to your current design.',
      image: '/assets/studio/studio-home/2.png',
      hoverImage: '/assets/studio/studio-home-hover/2.png',
      alt: 'Apply Pattern',
    },
    {
      title: 'Remove Background',
      description:
        'Remove image backgrounds for flexible use, whether created on our platform or uploaded.',
      image: '/assets/studio/studio-home/3.png',
      hoverImage: '/assets/studio/studio-home-hover/3.png',
      alt: 'Remove Background',
    },
    {
      title: 'Background Manipulation',
      description: 'Seamlessly Change and Customize Your Image Backdrops',
      image: '/assets/studio/studio-home/4.png',
      hoverImage: '/assets/studio/studio-home-hover/4.png',
      alt: 'Background Manipulation',
    },
    {
      title: 'Upscale',
      description:
        'Enhance the quality of your images with our AI-powered upscaling tool.',
      image: '/assets/studio/studio-home/5.png',
      hoverImage: '/assets/studio/studio-home-hover/5.png',
      alt: 'Upscale',
    },
    {
      title: 'Inpainting',
      description:
        'Effortlessly modify specific parts of your image based on color tones with our advanced AI tool.',
      image: '/assets/studio/studio-home/6.png',
      hoverImage: '/assets/studio/studio-home-hover/6.png',
      alt: 'Inpainting',
    },
    {
      title: 'Color Tone',
      description:
        'Easily add Pantone color tones to your images and modify specific parts with precise color adjustments.',
      image: '/assets/studio/studio-home-hover/7.png',
      hoverImage: '/assets/studio/studio-home/7.png',
      alt: 'Color Tone',
    },
    {
      title: 'Zoom In',
      description:
        'Generate zoomed versions of your images to capture and enhance intricate details with precision.',
      image: '/assets/studio/studio-home/8.png',
      hoverImage: '/assets/studio/studio-home-hover/8.png',
      alt: 'Zoom In',
    },
    {
      title: 'Zoom Out',
      description:
        'Create zoomed-out versions of your images to capture and emphasize the background effectively.',
      image: '/assets/studio/studio-home/9.png',
      hoverImage: '/assets/studio/studio-home-hover/9.png',
      alt: 'Zoom Out',
    },
    {
      title: 'Variants',
      description:
        'Generate new, similar images based on your uploaded image, offering alternative and diverse options.',
      image: '/assets/studio/studio-home/10.png',
      hoverImage: '/assets/studio/studio-home-hover/10.png',
      alt: 'Variants',
    },
    {
      title: 'Generate Tile',
      description:
        'Create a tiled version of your pattern for seamless continuity and a larger visual display.',
      image: '/assets/studio/studio-home/11.png',
      hoverImage: '/assets/studio/studio-home-hover/11.png',
      alt: 'Generate Tile',
    },
    {
      title: 'Image Manipulation',
      description:
        'View and customize the entire picture, including background and design, with a single prompt.',
      image: '/assets/studio/studio-home/12.png',
      hoverImage: '/assets/studio/studio-home-hover/12.png',
      alt: 'Image Manipulation',
    },
  ];

  return (
    <div className="flex max-w-[77rem] flex-col">
      <div className="flex w-full flex-col gap-y-8">
        {/* Hero */}
        <div className="flex flex-col gap-y-4">
          <h1 className="text-[2rem] font-bold leading-[2.7rem] text-primary">
            Home
          </h1>
          {/* Banner Video */}
          <div className="relative flex flex-col items-center justify-center">
            <LazyVideo
              src={'/assets/studio/studio-banner.mp4'}
              controls={false}
              loop={true}
              muted={true}
              autoplay={true}
              poster={'/assets/studio/hero-poster.png'}
              playsInline={true}
              classes={['aspect-[3.4/1] w-full h-full object-cover rounded']}
            />
            <div className="absolute top-0 left-0 flex flex-col p-6">
              <h1 className="text-xl font-medium text-white sm:text-5xl sm:leading-[5rem]">
                Design Without Limits
              </h1>
              <h2 className="text-base font-normal text-white sm:text-2xl sm:leading-[2rem]">
                AI-Powered Creativity at Your Fingertips
              </h2>
            </div>
          </div>
        </div>
        {/* Design Content */}
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-[2rem] font-bold leading-[2.7rem] text-primary">
              Design with T-Fashion
            </h2>
            <p className="text-[1rem] leading-[1.5rem] text-night">
              Experience limitless creativity with T-Fashion’s AI studio.
              Whether you’re designing apparel, patterns, or working from a
              sketch, our platform empowers you to bring your ideas to life
              effortlessly. Think it, design it, and watch it come to life.
            </p>
          </div>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 lg:gap-12">
            <div className="flex flex-col gap-y-4 py-4">
              <div>
                <h3 className="pb-2 text-2xl font-bold leading-[2rem] text-night/90">
                  Create an Apparel
                </h3>
                <p className="text-[1rem] leading-[1.5rem] text-night/90">
                  Describe your design using AI prompt helper, Let T-Fashion
                  Studio turn your idea into a unique design.
                  <span className="text-transparent" inert="">
                    char optimizer cha
                  </span>
                </p>
              </div>
              <div
                style={{
                  background:
                    'url(/assets/studio/studio-apparel.png) no-repeat center center / cover',
                }}
                className="aspect-[3/4] h-full w-full rounded"
              ></div>
              <SidebarButton
                className="flex w-full items-center justify-center rounded py-2 font-bold hover:bg-primary hover:text-white"
                text="Get Started"
                onClick={() => handleChange('apparel')}
              />
            </div>
            <div className="flex flex-col gap-y-4 py-4">
              <div>
                <h3 className="pb-2 text-2xl font-bold leading-[2rem] text-night/90">
                  Create a Pattern
                </h3>
                <p className="text-[1rem] leading-[1.5rem] text-night/90">
                  Think of your ideal pattern and describe it using our AI
                  prompt helper. You can add a reference pattern for
                  inspiration.
                  <span className="text-transparent" inert="">
                    char optimizer
                  </span>
                </p>
              </div>
              <div
                style={{
                  background:
                    'url(/assets/studio/studio-pattern.png) no-repeat center center / cover',
                }}
                className="aspect-[3/4] h-full w-full rounded"
              ></div>
              <SidebarButton
                className="flex w-full items-center justify-center rounded py-2 font-bold hover:bg-primary hover:text-white"
                text="Get Started"
                onClick={() => handleChange('pattern')}
              />
            </div>
            <div className="flex flex-col gap-y-4 py-4">
              <div>
                <h3 className="pb-2 text-2xl font-bold leading-[2rem] text-night/90">
                  Create from Sketch
                </h3>
                <p className="text-[1rem] leading-[1.5rem] text-night/90">
                  Upload your sketch and describe your vision, Let our AI tool
                  transform your sketch into a fashionable design in seconds.
                </p>
              </div>
              <ImageSlider
                firstImage="/assets/studio/studio-sketch2.png"
                secondImage="/assets/studio/studio-sketch1.png"
              />
              <SidebarButton
                className="flex w-full items-center justify-center rounded py-2 font-bold hover:bg-primary hover:text-white"
                text="Get Started"
                onClick={() =>
                  import.meta.env.VITE_APP_ENV === 'development'
                    ? handleChange('sketch')
                    : null
                }
              />
            </div>
          </div>
        </div>
        {/* AI Tools Content */}
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-[2rem] font-bold leading-[2.7rem] text-primary">
              AI Tools
            </h2>
            <p className="text-[1rem] leading-[1.5rem] text-night">
              Our AI tools empower you to take full control of your creative
              process. Make real-time adjustments, from colors to intricate
              details. Save time while maintaining complete creative control,
              delivering unique, high-quality designs faster than ever.
            </p>
          </div>
          <div className="grid grid-cols-1 gap-12 md:grid-cols-3">
            {AIToolsContents.map((tool, index) => {
              return (
                <ImageView
                  imageUrl={tool.image}
                  hoverImageUrl={tool.hoverImage}
                  title={tool.title}
                  description={tool.description}
                  alt={tool.alt}
                  key={index}
                />
              );
            })}
          </div>
        </div>
        {/* Tutorials */}
        {import.meta.env.VITE_APP_ENV === 'development' && (
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-2">
              <h2 className="text-[2rem] font-bold leading-[2.7rem] text-primary">
                Tutorials
              </h2>
              <p className="text-[1rem] leading-[1.5rem] text-night">
                Unleash your creativity with T-Fashion Studio! Our AI-powered
                platform is here to support your artistic vision every step of
                the way. Whether you&rsquo;re just starting or looking to refine
                your skills, these tutorials will show you how to use the studio
                to its full potential.
              </p>
            </div>
            <div className="grid grid-cols-1 gap-12 md:grid-cols-3">
              {AIToolsContents.slice(6, 9).map((tool, index) => {
                return (
                  <ImageView
                    imageUrl={tool.image}
                    aspect="[4/3]"
                    hoverImageUrl={tool.hoverImage}
                    description={tool.description}
                    alt={tool.alt}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
        )}
        {/* Inspiration */}
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-[2rem] font-bold leading-[2.7rem] text-primary">
              Discover & Inspiration
            </h2>
            <p className="text-[1rem] leading-[1.5rem] text-night">
              Unlock a world of inspiration with T-Fashion Studio. Browse
              through a curated collection of AI-generated designs created by
              other users, and gain insight into their creative process.
              Discover the prompts behind each design and learn how to craft
              your own AI-powered creations.
            </p>
          </div>
          <div className="grid grid-cols-1 gap-12 md:grid-cols-3">
            {DiscoverContents.map((tool, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    updateCreateItemDetails('apparel');
                    setPositivePrompt(tool.description);
                  }}
                >
                  <ImageView
                    imageUrl={tool.image}
                    aspect="[3/4]"
                    hoverImageUrl={tool.hoverImage}
                    description={tool.description}
                    alt={tool.alt}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
