export function RightChevron() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.51726 18.6875L15.616 11.4943L16.507 11.2756L16.507 11.017L-3.92657e-07 11.017L-4.80453e-07 9.00852L16.507 9.00852L16.507 8.75L15.6696 8.53125L8.48746 1.33807L9.8911 -4.32354e-07L19.8806 10.0114L9.89409 20L8.51726 18.6875Z"
        fill="#282928"
      />
    </svg>
  );
}
