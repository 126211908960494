import React from 'react';

export const CheckIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_523_4068)">
      <path
        d="M16.6663 5L7.49967 14.1667L3.33301 10"
        stroke="#3CA780"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </g>
  </svg>
);
