// import { AiOutlineCheck } from 'react-icons/ai'

import demoPopupImage from '@/assets/image/demoPopupImage.png';
import demoPopupImageX2 from '@/assets/image/demoPopupImage-x2.png';

import { Modal } from './Modal';

export function DemoAccountModal({ isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="grid max-w-fit grid-cols-2 border-2 border-black">
        <div className="flex flex-col p-16">
          <div className="flex flex-col gap-4">
            <h1 className="font-planc text-4xl font-semibold">
              Upgrade your account
            </h1>

            <span className="font-planc text-base font-light">
              This is a demo account. Please upgrade your account to see the
              details.
            </span>
          </div>

          {/* <div className="mt-10 flex flex-col gap-4">
            <div className="flex items-center gap-4">
              <AiOutlineCheck className="text-2xl text-green" />
              <span className="font-planc text-base font-medium">
                Unlimited access to all features
              </span>
            </div>

            <div className="flex items-center gap-4">
              <AiOutlineCheck className="text-2xl text-green" />
              <span className="font-planc text-base font-medium">
                Unlimited access to all features
              </span>
            </div>

            <div className="flex items-center gap-4">
              <AiOutlineCheck className="text-2xl text-green" />
              <span className="font-planc text-base font-medium">
                Unlimited access to all features
              </span>
            </div>

            <div className="flex items-center gap-4">
              <AiOutlineCheck className="text-2xl text-green" />
              <span className="font-planc text-base font-medium">
                Unlimited access to all features
              </span>
            </div>

            <div className="flex items-center gap-4">
              <AiOutlineCheck className="text-2xl text-green" />
              <span className="font-planc text-base font-medium">
                Unlimited access to all features
              </span>
            </div>

            <div className="flex items-center gap-4">
              <AiOutlineCheck className="text-2xl text-green" />
              <span className="font-planc text-base font-medium">
                Unlimited access to all features
              </span>
            </div>
          </div> */}

          <div
            dangerouslySetInnerHTML={{
              __html: `
                <script
                  charset="utf-8"
                  type="text/javascript"
                  src="//js.hsforms.net/forms/embed/v2.js"
                ></script>
                <script>
                  hbspt.forms.create({
                    region: 'na1',
                    portalId: '7322935',
                    formId: '7f27c35a-d50c-4605-84ea-3b9c0497794b',
                  });
                </script>
              `,
            }}
          />

          <iframe
            src="https://share.hsforms.com/1fyfDWtUMRgWE6jucBJd5Sw4cyev"
            frameBorder="0"
            className="mt-6 h-full"
          ></iframe>
        </div>

        <img
          src={demoPopupImage}
          srcSet={`${demoPopupImage} 300w, ${demoPopupImageX2} 1920w`}
          alt=""
          className="w-full"
        />
      </div>
    </Modal>
  );
}
