import React, { useEffect, useMemo, useState } from 'react';
import { InView } from 'react-intersection-observer';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { Download } from 'lucide-react';

import { useCollectionsMutations } from '@/api/collections/hooks';
import {
  GET_COLLECTION_ITEMS_MERGED,
  REMOVED_EXPLORE_ITEM_FRAGMENT,
} from '@/api/collections/queries';
import {
  FlexRow,
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
  Text,
} from '@/components';
import { cn, downloadFile, getInitials } from '@/helpers/utils';
import { Card } from '@/pages/general/explore/components';
import { RemoveFromCollection } from '@/pages/general/myCollection/components/ItemCard/Overlay';
import { PantoneCard } from '@/pages/general/myCollection/components/PantoneCard';

// const ImgCard = ({ item }) => (
//   <div
//     className="group relative mb-[30px] flex cursor-pointer transition-all"
//     style={{
//       aspectRatio: Math.random() > 0.5 ? '3/5' : '4/3'
//     }}
//   >
//     <div
//       className="absolute bottom-0 left-0 h-[45%] w-full opacity-0 transition-all group-hover:opacity-100"
//       style={{
//         background:
//           'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.52) 60.88%)',
//         mixBlendMode: 'multiply'
//       }}
//     />
//     <span className="absolute right-4 top-4 opacity-0 transition-all group-hover:opacity-100">
//       <TrashIcon />
//     </span>
//     <img className="w-full object-cover" src={item.image.url} />
//   </div>
// )

const GalleryCard = ({ item, collection }) => {
  const client = useApolloClient();
  const [isRemoved, setIsRemoved] = useState(false);

  useEffect(() => {
    if (client && !isRemoved) {
      const cachedItem = client.readFragment({
        id: client.cache.identify(item),
        fragment: REMOVED_EXPLORE_ITEM_FRAGMENT,
      });

      if (cachedItem?.removed) {
        setIsRemoved(true);
      }
    }
  }, [client, item, isRemoved]);

  const [removeItem, { loading }] = useCollectionsMutations('removeItemById', {
    refetchQueries: [
      {
        query: GET_COLLECTION_ITEMS_MERGED,
        variables: {
          collection,
          cursor: null,
        },
      },
    ],
  });

  const handleDelete = async () => {
    if (loading) return;
    setIsRemoved(true);

    const removeItemResponse = await removeItem({
      variables: {
        collection,
        itemId: item.id,
      },
    });

    if (removeItemResponse) {
      // Cache removed status to avoid refetching & layout shift
      client.writeFragment({
        id: client.cache.identify(item),
        fragment: REMOVED_EXPLORE_ITEM_FRAGMENT,
        data: {
          removed: true,
        },
      });

      setIsRemoved(true);

      // toast.createToast({
      //   message: 'Item removed from collection',
      //   type: 'success',
      //   position: 'top-right'
      // })
    }
  };

  const navigate = useNavigate();

  const typeNameToExploreType = {
    Post: 'socialMediaItem',
    ExploreFashionWeekItem: 'fashionWeekItem',
    StudioItem: 'studioItem',
  };

  if (
    item.__typename === 'Post' ||
    item.__typename === 'ExploreFashionWeekItem' ||
    item.__typename === 'StudioItem'
  ) {
    return (
      <div
        className={cn('w-full', isRemoved && 'opacity-25 pointer-events-none')}
        style={{
          breakInside: 'avoid',
        }}
      >
        <Card.Root data={item} moderationEnabled={false}>
          <Card.Image />

          <Card.HoverContent gradientDegree={'45deg'}>
            <div
              className="z-[999] h-full w-full"
              onClick={() => {
                navigate(
                  `/explore/image-preview/${
                    typeNameToExploreType[item.__typename]
                  }/${item.entityId}`,
                );
              }}
            >
              <FlexRow
                justify="between"
                items="center"
                className="z-[999] pt-2 pl-1"
              >
                {item.addedBy ? (
                  <PopoverRoot>
                    <PopoverTrigger>
                      <Text
                        weight="bold"
                        className="rounded-full px-2.5 py-1 text-white hover:bg-primary"
                      >
                        {getInitials(item.addedBy?.fullName)}
                      </Text>
                    </PopoverTrigger>
                    <PopoverContent sideOffset={5} className="z-[999]">
                      <div className="flex w-[17.3125rem] flex-col px-[2rem] py-[1.625rem]">
                        <h5 className="text-[1rem] font-semibold leading-[1.155rem]  text-white">
                          Added by {item.addedBy?.fullName}
                        </h5>
                      </div>
                    </PopoverContent>
                  </PopoverRoot>
                ) : null}

                <div className="flex flex-col items-center justify-end">
                  <div
                    className="mr-11 flex items-center justify-center rounded-full bg-transparent p-2 text-white hover:bg-primary"
                    onClick={() =>
                      downloadFile(item.image.url, 'collection-image')
                    }
                    aria-label="download"
                  >
                    <Download size={20} />
                  </div>
                  <RemoveFromCollection onClick={handleDelete} show={true} />
                </div>
              </FlexRow>
            </div>
          </Card.HoverContent>
        </Card.Root>
      </div>
    );
  }

  if (item.__typename === 'Tone') {
    return <PantoneCard item={item} />;
  }

  return <></>;
};

const LazyLoadTrigger = ({ loadMore, cursorNext, loading }) => (
  <InView
    onChange={async (inView) => {
      if (inView) {
        loadMore();
      }
    }}
  />
);

export const Gallery = ({ layout, data, collection, loadMore }) => {
  const filteredData = data;

  const columnCounts = useMemo(() => {
    if (layout === 0) {
      return {
        350: 2,
        900: 3,
        1200: 3,
      };
    } else if (layout === 1) {
      return {
        350: 2,
        900: 2,
        1200: 2,
      };
    } else if (layout === 2) {
      return {
        350: 2,
        900: 4,
        1200: 6,
      };
    }

    return {
      350: 2,
      900: 4,
      1200: 6,
    };
  }, [layout]);

  return (
    <div className={'w-full'}>
      <ResponsiveMasonry columnsCountBreakPoints={columnCounts}>
        <Masonry gutter={'1.625rem'}>
          {filteredData.map((item, i) => (
            <GalleryCard
              key={i}
              item={{ ...item.item, addedBy: item.addedBy }}
              collection={collection}
            />
          ))}
          {Array.from({ length: 6 }).map((_, i) => (
            <LazyLoadTrigger
              loadMore={loadMore}
              key={'collection_gallery_lazyload_' + i}
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};
