import { Magnify } from '../../../icons';

export const SearchBox = ({ onClearAll, placeholder, value, onChange }) => {
  return (
    <div className="flex items-center">
      <div className="relative flex w-full">
        <input
          className="font-book h-[42px] w-full border border-[#282828] px-[13px] outline-none placeholder:text-[#A1A1A1]"
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            onChange && onChange(e.target.value);
          }}
        />
        <div className="absolute top-0 right-[10px] flex h-full items-center">
          <Magnify />
        </div>
      </div>
      <div
        className="ml-[1.638rem] flex basis-20 items-center"
        onClick={() => {
          onClearAll && onClearAll();
        }}
      >
        <span className="cursor-pointer border-b border-charcoal text-[1rem] font-semibold leading-[90%]">
          Clear All
        </span>
      </div>
    </div>
  );
};
