import { useQuery } from '@apollo/client';

import {
  GET_EXPLORE_APPAREL_FILTERS,
  GET_EXPLORE_COLOR_FILTERS,
  GET_EXPLORE_PATTERN_FILTERS,
  GET_EXPLORE_TONE_FILTERS,
  GET_MARKETS,
  GET_TREND_APPAREL_FILTERS,
  GET_TREND_COLOR_FILTERS,
  GET_TREND_FABRIC_FILTERS,
  GET_TREND_FILTERS,
  GET_TREND_PATTERN_FILTERS,
} from '@/api/filters/queries';

const queryMap = {
  markets: GET_MARKETS,
  trendFilters: GET_TREND_FILTERS,
  trendColors: GET_TREND_COLOR_FILTERS,
  trendPatterns: GET_TREND_PATTERN_FILTERS,
  trendApparels: GET_TREND_APPAREL_FILTERS,
  trendFabrics: GET_TREND_FABRIC_FILTERS,
  exploreApparels: GET_EXPLORE_APPAREL_FILTERS,
  exploreColors: GET_EXPLORE_COLOR_FILTERS,
  explorePatterns: GET_EXPLORE_PATTERN_FILTERS,
  exploreTones: GET_EXPLORE_TONE_FILTERS,
};

export const useFiltersQuery = (queryName, variables, options = {}) => {
  const query = queryMap[queryName];
  if (!query) throw new Error('Invalid query name');

  return useQuery(query, { variables, ...options });
};
