import styles from './index.module.css';

export function Button({ onClick, children, icon, className }) {
  return (
    <button
      onClick={onClick}
      className={[
        styles.buttonWrapper,
        icon ? styles.buttonWithIcon : '',
        className || '',
      ].join(' ')}
    >
      {children}
      {icon && icon}
    </button>
  );
}
