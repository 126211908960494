import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import { FORGOT_PASSWORD } from '@/api/authentication/mutations.js';
import { TFashion } from '@/assets/svg';
import { useToast } from '@/hooks/useToast';

import { forgotPasswordOptions } from './forgotPasswordFormOptions';

const Navbar = () => (
  <div className="flex min-h-[90px] w-full border-b border-[#A1A1A1] bg-white px-[60px]">
    <div className="flex w-[212.8px] items-center">
      <TFashion />
    </div>
  </div>
);

export const ForgotPassword = () => {
  const toast = useToast();
  const [sendLink, { loading, error }] = useMutation(FORGOT_PASSWORD, {
    onCompleted: () => {
      toast.createToast({
        message: 'Email sent successfully',
        type: 'success',
        showCloseButton: true,
        position: 'top-right',
      });
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(forgotPasswordOptions);

  const onSubmit = (data) => {
    const { email } = data;

    sendLink({ variables: { email } });
  };

  return (
    <div className="flex h-full w-full flex-col">
      <Navbar />
      <div className="flex w-full flex-1 flex-col items-center justify-center">
        <div className="flex flex-col items-center">
          <h3 className="mb-[16px] text-[48px] font-semibold leading-[55px]">
            Forgot Password
          </h3>
          <p className="font-regular mb-[16px] text-[14px] leading-[173%] text-[#626775]">
            Please enter your email address. If you have an account, we will
            send you a link to reset your password.
          </p>
        </div>

        <form
          className="mb-[22px] flex h-auto w-[404px] flex-col"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-[14px]">
            <div className="flex w-full flex-col gap-[4px]">
              <p className="h-[28px] px-[16px] pb-[4px] text-[16px] font-semibold leading-[28px] ">
                E-mail
              </p>
              <input
                className="h-[47px] border border-charcoal px-[16px] outline-0"
                type="email"
                placeholder="Write Your E-mail"
                {...register('email', { required: true })}
              />
              {errors.email?.message && (
                <p className="mt-[4px] text-[16px] font-semibold leading-[28px] text-red-500 ">
                  {errors.email?.message}
                </p>
              )}
            </div>

            {error && (
              <p className="mt-[4px] text-[16px] font-semibold leading-[28px] text-red-500">
                {error.message}
              </p>
            )}

            <button className="btn-primary-without-hover h-[46px]">
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
