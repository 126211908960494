import { cn } from '@/helpers/utils';

import styles from './index.module.css';

export function Input({
  type = 'text',
  placeholder,
  value,
  onChange,
  bordered,
  fluid,
  block = false,
  textArea = false,
  ...props
}) {
  const InputComp = textArea ? 'textarea' : 'input';

  return (
    <InputComp
      className={cn(
        bordered ? styles.inputWrapper : styles.input,
        fluid ? styles.fluid : styles.fixed,
        block && 'w-full max-w-full',
        'font-medium',
      )}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
}
