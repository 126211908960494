import { createContext, useContext, useEffect, useState } from 'react';

import defaultVersion from '../version.json';

export const AppContext = createContext();

export function AppContextProvider({ children }) {
  const [collectionsLayout, setCollectionsLayout] = useState('gallery');
  const [currentCollection, setCurrentCollection] = useState(null);
  const [currentVersion, setCurrentVersion] = useState(() => {
    return localStorage.getItem('appVersion') || defaultVersion.version;
  });
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);

  const UPDATE_CHECK_INTERVAL = 60000;

  const changeCollectionsLayout = (layout) => {
    if (!['gallery', 'two-col', 'three-col'].includes(layout)) {
      throw new Error('Invalid layout');
    }
    setCollectionsLayout(layout);
  };

  const checkVersion = async () => {
    try {
      const response = await fetch('/version.json', {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
        },
      });

      if (!response.ok) {
        throw new Error('Version file not found');
      }

      const { version } = await response.json();

      if (currentVersion && currentVersion !== version) {
        setShowUpdatePopup(true);
      }

      setCurrentVersion(version);
      localStorage.setItem('appVersion', version);
    } catch (error) {
      console.error('Version check failed:', error);
    }
  };

  const handleUpdate = () => {
    const url = new URL(window.location.href);
    url.searchParams.append('update', Math.random().toString(36).substring(7));
    window.location.href = url.toString();
  };

  useEffect(() => {
    checkVersion();
    const interval = setInterval(checkVersion, UPDATE_CHECK_INTERVAL);
    return () => clearInterval(interval);
  }, [currentVersion]);

  const contextValue = {
    collectionsLayout,
    changeCollectionsLayout,
    currentCollection,
    setCurrentCollection,
    showUpdatePopup,
    setShowUpdatePopup,
    handleUpdate,
    currentVersion,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppContextProvider');
  }

  return context;
};
