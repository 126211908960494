import { useEffect, useState } from 'react';
import { Wand } from 'lucide-react';

import { DisplayMode, useStudioContext } from '@/contexts/StudioContext';

import { SidebarButton } from './SidebarMenu';

export const GenerateButton = ({ variant = 'default' }) => {
  const [disabled, setDisabled] = useState(true);

  const {
    createItem,
    setDisplayMode,
    positivePrompt,
    selectedArea,
    referenceImage,
    referenceSketch,
    referencePattern,
    referenceOtherImage,
    handleCreateNewStudioTask,
  } = useStudioContext();

  useEffect(() => {
    const isDisabled = () => {
      switch (createItem.type) {
        case 'GENERATE_APPAREL':
          return !positivePrompt;
        case 'GENERATE_PATTERN':
          return !positivePrompt;
        case 'REALIZE_SKETCH':
          return !referenceSketch;
        case 'IMAGE_BACKGROUND_REMOVER':
          return !referenceImage;
        case 'UPSCALE_IMAGE':
          return !referenceImage;
        case 'ENHANCE_IMAGE':
          return !referenceImage;
        case 'CREATE_VARIANTS':
          return !referenceImage;
        case 'PATTERN_TILE':
          return !referencePattern;
        case 'ZOOM_IN_IMAGE':
          return (
            !referenceImage ||
            !selectedArea ||
            selectedArea?.x === 0 ||
            selectedArea?.y === 0
          );
        case 'IMAGE_CHANGE_BACKGROUND':
          return !referenceOtherImage || !referenceImage;
        default:
          return true;
      }
    };

    setDisabled(isDisabled());
  }, [
    createItem.type,
    positivePrompt,
    referenceImage,
    referenceSketch,
    selectedArea,
    referencePattern,
    referenceOtherImage,
  ]);

  const handleGenerate = () => {
    handleCreateNewStudioTask(createItem.type);
    setDisplayMode(DisplayMode.GENERATING);
  };

  if (variant === 'tool') {
    return (
      <SidebarButton
        variant="generate"
        text="Apply Tool"
        className="text-sm"
        disabled={disabled}
        onClick={() => !disabled && handleGenerate()}
      />
    );
  }

  return (
    <SidebarButton
      variant="generate"
      text="Generate"
      disabled={disabled}
      icon={Wand}
      onClick={() => !disabled && handleGenerate()}
    />
  );
};
