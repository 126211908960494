import styles from '../index.module.css';

export function Title({ children = 'Rising Star', skeleton }) {
  return (
    <span
      className={[
        styles.title,
        skeleton ? 'bg-slate-300 animate-pulse text-transparent' : '',
      ].join(' ')}
    >
      {children}
    </span>
  );
}
