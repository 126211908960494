import { FilterDropdown } from '@/components/FilterDropdown';
import { useAppFilters } from '@/contexts/AppFiltersContext';

export function DesignerFilter() {
  const { filters, handleDesignerOnChange } = useAppFilters();
  const { designer } = filters;

  return (
    <FilterDropdown
      label={designer.label}
      placeholder={designer.placeholder}
      isLoading={designer?.loading}
      options={designer?.options}
      value={designer?.value}
      onChange={handleDesignerOnChange}
      isClearable
      iSearchable
      autoFocus
    />
  );
}
