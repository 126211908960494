import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

export const filterSlice = createSlice({
  name: 'filters',
  initialState: { ...initialState },
  reducers: {
    setFilters: (state, action) => {
      const { location, audience, apparels } = action.payload;
      state.selectedLocations = [location];
      state.selectedAudiences = [audience];
      state.selectedApparels = [...apparels];
    },
    setApparels: (state, action) => {
      state.selectedApparels = [...action.payload];
    },
    toggleLocation: (state, action) => {
      if (state.selectedLocations.includes(action.payload)) {
        state.selectedLocations = [
          ...state.selectedLocations.filter((item) => item !== action.payload),
        ];
      } else {
        state.selectedLocations = [];
        state.selectedLocations = [...state.selectedLocations, action.payload];
      }
    },
    toggleAudience: (state, action) => {
      const { id } = action.payload;
      const audience = state.selectedAudiences.find((item) => item.id === id);
      if (audience) {
        state.selectedAudiences = [
          ...state.selectedAudiences.filter((item) => item.id !== id),
        ];
      } else {
        state.selectedAudiences = [{ ...action.payload }];
      }
    },
    resetLocation: (state) => {
      state.selectedLocations = [];
      state.selectedAudiences = [];
    },
    toggleSeason: (state, action) => {
      if (state.selectedSeasons.includes(action.payload)) {
        state.selectedSeasons = state.selectedSeasons.filter(
          (item) => item !== action.payload,
        );
      } else {
        state.selectedSeasons = [];
        state.selectedSeasons = [...state.selectedSeasons, action.payload];
      }
    },
    toggleCategory: (state, action) => {
      if (
        state.selectedCategories.find((item) => item.name === action.payload)
      ) {
        state.selectedCategories = [
          ...state.selectedCategories.filter(
            (item) => item.name !== action.payload,
          ),
        ];
      } else {
        state.selectedCategories = [
          ...state.selectedCategories,
          { name: action.payload, items: [] },
        ];
      }
    },
    toggleCategoryType: (state, action) => {
      const { title, filters, category } = action.payload;
      const index = state.selectedCategories.findIndex(
        (c) => c.name === category,
      );
      if (
        state.selectedCategories[index]?.items.find(
          (item) => item.title === title,
        )
      ) {
        state.selectedCategories[index].items = [
          ...state.selectedCategories[index]?.items.filter(
            (t) => t.title !== title,
          ),
        ];
      } else {
        state.selectedCategories[index].items = [
          ...state.selectedCategories[index]?.items,
          { title, filters },
        ];
      }
    },
    resetCategory: (state) => {
      state.selectedCategories = [];
    },
    toggleColor: (state, action) => {
      if (state.selectedColors.includes(action.payload)) {
        state.selectedColors = state.selectedColors.filter(
          (item) => item !== action.payload,
        );
      } else {
        state.selectedColors = [...state.selectedColors, action.payload];
      }
    },
    togglePattern: (state, action) => {
      if (state.selectedPatterns.includes(action.payload)) {
        state.selectedPatterns = state.selectedPatterns.filter(
          (item) => item !== action.payload,
        );
      } else {
        state.selectedPatterns = [...state.selectedPatterns, action.payload];
      }
    },
    resetPattern: (state) => {
      state.selectedPatterns = [];
    },
    resetFilters: (state) => {
      state.selectedLocations = initialState.selectedLocations;
      state.selectedSeasons = initialState.selectedSeasons;
      state.selectedCategories = initialState.selectedCategories;
      state.selectedColors = initialState.selectedColors;
      state.selectedPatterns = initialState.selectedPatterns;
      state.selectedAudiences = initialState.selectedAudiences;
    },
  },
});

// this is for dispatch
export const {
  setFilters,
  setApparels,
  toggleLocation,
  toggleAudience,
  resetLocation,
  toggleSeason,
  toggleCategory,
  toggleCategoryType,
  resetCategory,
  toggleColor,
  togglePattern,
  resetPattern,
  resetFilters,
} = filterSlice.actions;

// this is for configureStore
export default filterSlice.reducer;
