import React from 'react';
import { Navigate } from 'react-router-dom';

import * as Pages from '@/pages/general';

import { Private } from './types';

export const report = [
  {
    path: 'create-trend-report',
    element: <Pages.CreateReport />,
    children: [
      {
        index: true,
        element: <Navigate to="select-a-plan" replace />,
      },
      {
        path: 'select-a-plan',
        element: (
          <Private>
            <Pages.SelectPlan />
          </Private>
        ),
      },
      {
        path: 'location',
        element: (
          <Private>
            <Pages.Location />
          </Private>
        ),
      },
      {
        path: 'audience',
        element: (
          <Private>
            <Pages.Audience />
          </Private>
        ),
      },
      {
        path: 'category',
        element: (
          <Private>
            <Pages.Category />
          </Private>
        ),
      },
      {
        path: 'report',
        element: (
          <Private>
            <Pages.Report />
          </Private>
        ),
      },
      {
        path: 'finalize',
        element: (
          <Private>
            <Pages.Finalize />
          </Private>
        ),
      },
    ],
  },
];
