import { Desc } from './components/Desc';
import { Left } from './components/Left';
import { Navigation } from './components/Navigation';
import { Subtitle } from './components/Subtitle';
import { Title } from './components/Title';

function Root({ children, skeleton }) {
  return (
    <div
      className={[
        'mb-20 flex items-center justify-between px-14',
        skeleton ? 'bg-slate-300 animate-pulse !text-transparent' : '',
      ].join(' ')}
    >
      <Left>{children}</Left>
      <Navigation />
    </div>
  );
}

export default {
  Root,
  Title,
  Subtitle,
  Desc,
};
