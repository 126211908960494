import { useReports } from '@/hooks/report';

import { AlertCircleIcon, ArrowDownIcon, CheckIcon } from '../../icons';

export const LocationInput = ({
  value,
  onChange,
  setShowList,
  showList,
  disabled,
}) => {
  const { location, exists: marketExists } = useReports();

  return (
    <div className="relative flex w-full">
      <div className="flex h-[58px] w-full items-center justify-start border-2 border-charcoal px-8">
        <input
          type="text"
          placeholder="Enter Location..."
          onClick={() => setShowList(!showList)}
          onChange={(event) => onChange(event)}
          value={value}
          className="h-full w-full outline-none placeholder:text-[#626775]"
          disabled={disabled}
        />
      </div>
      <span className="absolute top-[19px] right-[19px]">
        {!location ? (
          <ArrowDownIcon />
        ) : marketExists ? (
          <AlertCircleIcon color="#ffae00" />
        ) : (
          <CheckIcon />
        )}
      </span>
    </div>
  );
};
