import React, { useRef } from 'react';

import { getImageUrl } from '@/assets/image';

const TrashIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 5H4.16667H17.5"
      stroke="#D74D4D"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <path
      d="M15.8337 5.0013V16.668C15.8337 17.11 15.6581 17.5339 15.3455 17.8465C15.0329 18.159 14.609 18.3346 14.167 18.3346H5.83366C5.39163 18.3346 4.96771 18.159 4.65515 17.8465C4.34259 17.5339 4.16699 17.11 4.16699 16.668V5.0013M6.66699 5.0013V3.33464C6.66699 2.89261 6.84259 2.46868 7.15515 2.15612C7.46771 1.84356 7.89163 1.66797 8.33366 1.66797H11.667C12.109 1.66797 12.5329 1.84356 12.8455 2.15612C13.1581 2.46868 13.3337 2.89261 13.3337 3.33464V5.0013"
      stroke="#D74D4D"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <path
      d="M8.33301 9.16797V14.168"
      stroke="#D74D4D"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
    <path
      d="M11.667 9.16797V14.168"
      stroke="#D74D4D"
      strokeWidth="1.5"
      strokeLinecap="square"
    />
  </svg>
);

export const Payment = () => {
  const inputFirst = useRef();
  const inputSecond = useRef();

  return (
    <div className="flex w-full flex-col">
      <h5 className="mb-[21px] flex text-[36px] font-semibold   leading-[42px]">
        Payment
      </h5>
      <p className="font-regular w-[80%] text-[16px]   leading-[18px]">
        Easily manage your payment methods within the T-Fashion platform,
        ensuring seamless and uninterrupted access to our industry-leading
        fashion analysis tools. Add, remove, or update your stored payment
        options with just a few clicks, safeguarding your subscription and
        keeping your financial data secure.
      </p>
      <div className="mt-[47px] flex flex-col gap-y-[32px]">
        <div className="flex flex-col">
          <h5 className="mb-[4px] flex max-w-[404px] justify-between pl-[18px] text-[16px] font-semibold leading-[28px]">
            Your Payment Methods
            <span className="cursor-pointer text-[12px] font-medium underline">
              Add
            </span>
          </h5>
          <div className="relative mb-[14px] flex w-full max-w-[404px] flex-col">
            <img
              src={getImageUrl('mastercard')}
              alt=""
              className="absolute left-[12px] top-[18px] h-auto w-[33px]"
            />
            <input
              ref={inputFirst}
              type="password"
              name="password"
              className="h-[58px] max-w-[404px] border border-charcoal pl-[58px] pr-[12px] outline-none"
              placeholder="Card Number"
            />
            <span
              className="absolute right-[14px] top-[19px] cursor-pointer"
              onClick={() => (inputFirst.current.value = '')}
            >
              <TrashIcon />
            </span>
          </div>
          <div className="relative mb-[14px] flex w-full max-w-[404px] flex-col">
            <img
              src={getImageUrl('mastercard')}
              alt=""
              className="absolute left-[12px] top-[18px] h-auto w-[33px]"
            />
            <input
              ref={inputSecond}
              type="password"
              name="password"
              className="h-[58px] max-w-[404px] border border-charcoal pl-[58px] pr-[12px] outline-none"
              placeholder="Card Number"
            />
            <span
              className="absolute right-[14px] top-[19px] cursor-pointer"
              onClick={() => (inputSecond.current.value = '')}
            >
              <TrashIcon />
            </span>
          </div>
        </div>
      </div>
      <button className="mt-[62px] h-[50px] w-[168px] bg-[#3CA780] px-[20px] pt-[2px] text-white">
        Save Changes
      </button>
    </div>
  );
};
