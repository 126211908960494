import { useEffect, useRef } from 'react';

import { FilterDropdown } from '@/components/FilterDropdown';
import { colorMap } from '@/constants';
import { useAppFilters } from '@/contexts/AppFiltersContext';
import { cn } from '@/helpers/utils';
export function TrendsColor() {
  const { filters, handleTrendsColorOnChange, openFilter, closeFilter } =
    useAppFilters();
  const { trendsColor } = filters;
  const ref = useRef(null);

  const handleOpen = () => openFilter('trendsColor');
  const handleClose = () => {
    closeFilter('trendsColor');
  };

  useEffect(() => {
    if (trendsColor.isOpen) {
      ref?.current?.focus();
    } else {
      ref?.current?.onMenuClose();
    }
  }, [trendsColor.isOpen]);

  return (
    <FilterDropdown
      label={trendsColor.label}
      placeholder={trendsColor.placeholder}
      options={trendsColor?.options.sort((a, b) =>
        a.label.localeCompare(b.label),
      )}
      value={trendsColor?.value}
      onChange={handleTrendsColorOnChange}
      isMulti
      isClearable
      isSearchable
      formatOptionLabel={(option) => (
        <div
          className={'flex flex-row items-center gap-3'}
          style={{
            margin: '1px 0',
          }}
        >
          <div
            className={cn(
              'h-4 w-4 border-charcoal rounded-full',
              option.value === 'white' && 'border-2',
            )}
            style={{
              background: colorMap[option.label.toLowerCase()],
            }}
          ></div>
          <span>{option.label}</span>
        </div>
      )}
      onMenuOpen={() => handleOpen()}
      onMenuClose={() => handleClose()}
      onFocus={() => handleOpen()}
      onBlur={() => handleClose()}
      openMenuOnFocus={true}
      selectRef={ref}
    />
  );
}
