import React from 'react';

import { Card } from '@/layouts/common/';

export const Cards = ({ data }) => {
  return (
    <div className={'flex basis-6/12 flex-col gap-[30px]'}>
      {data.map((collectionItem, index) => (
        <Card
          item={{ ...collectionItem.item, addedBy: collectionItem.addedBy }}
          key={index}
        />
      ))}
    </div>
  );
};
