import { useRef, useState } from 'react';
import { BiHelpCircle } from 'react-icons/bi';
import * as Popover from '@radix-ui/react-popover';
import { keyframes, styled } from '@stitches/react';
import _ from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/react';

import { LineChart } from './LineChart';

import styles from './index.module.css';

const colors = ['#35B787', '#E26E2F', '#68AAE3'];

function Card({ type, value, index, percent }) {
  return (
    <div className={styles.wrapper}>
      <span
        className={styles.percent}
        style={{
          color: colors[index],
          borderRight: `3px solid ${colors[index]}`,
        }}
      >
        % {percent}
      </span>

      <div className={styles.desc}>
        <h5 className="shrink-0">{value}</h5>
        <h5 className="shrink-0">{type}</h5>
      </div>
    </div>
  );
}

function Pagination({ count, onChange, activeIndex }) {
  const items = Array(count).fill(0);

  return (
    <div className={styles.paginationWrapper}>
      {items.map((_, index) => (
        <div
          key={index}
          className={[
            styles.paginationDot,
            activeIndex === index ? styles.paginationDotActive : '',
          ].join(' ')}
          onClick={() => onChange(index)}
        />
      ))}
    </div>
  );
}

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const PopoverContent = styled(Popover.Content, {
  outline: 'none',
  backgroundColor: 'rgba(88, 88, 88, 0.81)',
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
  zIndex: 200,
});

const PopoverArrow = styled(Popover.Arrow, {
  fill: 'rgba(88, 88, 88, 0.81)',
  height: '22px',
  width: '28px',
});

export function Root({ data }) {
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [popoverOpen, setPopoverOpen] = useState(false);

  function handleOnChange(index) {
    swiperRef.current.slideTo(index);
  }

  const chunks = _.chunk(data, 3);

  return (
    <div className={styles.rootWrapper}>
      <Popover.Root open={popoverOpen}>
        <Popover.Trigger asChild>
          <div
            className={styles.title}
            onMouseEnter={() => setPopoverOpen(true)}
            onMouseLeave={() => setPopoverOpen(false)}
          >
            <BiHelpCircle className="text-4xl" />
            <h1 className="pt-2 text-4xl">Attributes</h1>
          </div>
        </Popover.Trigger>

        <Popover.Portal>
          <PopoverContent sideOffset={5}>
            <div className="flex w-[29.875rem] flex-col px-[2rem] pt-[1.625rem]">
              <div className="mb-[1.3125rem] flex flex-col">
                <p className="font-semi-light text-[1rem] leading-[1.155rem]  text-white">
                  The percentages indicate the frequency of specific design
                  attributes in the current trend for the selected season. These
                  insights highlight dominant design features in the market for
                  the selected fashion item.
                </p>
              </div>
            </div>
            <PopoverArrow />
          </PopoverContent>
        </Popover.Portal>
      </Popover.Root>

      <LineChart />

      <div className="z-10 flex w-full flex-col gap-12">
        <div className="flex w-full">
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            pagination={true}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            onRealIndexChange={(element) => setActiveIndex(element.activeIndex)}
          >
            {chunks.map((chunk, index) => (
              <SwiperSlide className="flex" key={index}>
                {chunk.map((attribute, idx) => (
                  <Card
                    index={idx}
                    key={attribute.value}
                    percent={attribute.percent}
                    type={attribute.type}
                    value={attribute.value}
                  />
                ))}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <Pagination
          count={chunks.length}
          onChange={handleOnChange}
          activeIndex={activeIndex}
        />
      </div>
    </div>
  );
}
