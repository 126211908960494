import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useReports } from '@/hooks/report';

import {
  ReportButtons,
  ReportCaption,
  ReportDetails,
  ReportHeader,
  ReportTotal,
} from './';

export const Report = () => {
  const navigate = useNavigate();

  const { location, apparelGroups, audience, plan, reports } = useReports();

  useEffect(() => {
    if (
      !plan ||
      (!plan.alias && (!location || !apparelGroups.length || !audience))
    ) {
      navigate('/create-trend-report/select-a-plan');
    }
  }, [location, apparelGroups, audience, plan]);

  return (
    <div className="my-[52px] flex w-full flex-col items-center">
      <ReportCaption />
      {reports?.length >= 1 &&
        reports.map((report, idx) => (
          <div key={idx} className="mt-[28px] flex w-full flex-col">
            <ReportHeader plan={report.plan} />
            <ReportDetails plan={report.plan} />
          </div>
        ))}
      <ReportTotal />
      <ReportButtons />
    </div>
  );
};
