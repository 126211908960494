import { useEffect, useRef } from 'react';

import { FilterDropdown } from '@/components/FilterDropdown';
import { useAppFilters } from '@/contexts/AppFiltersContext';

export function TrendsCategory() {
  const { filters, handleTrendsCategoryOnChange, openFilter, closeFilter } =
    useAppFilters();
  const { trendsCategory } = filters;
  const ref = useRef(null);

  const handleOpen = () => openFilter('trendsCategory');
  const handleClose = () => {
    closeFilter('trendsCategory');
  };

  useEffect(() => {
    if (trendsCategory.isOpen) {
      ref?.current?.focus();
    } else {
      ref?.current?.onMenuClose();
    }
  }, [trendsCategory.isOpen]);

  return (
    <FilterDropdown
      label={trendsCategory.label}
      placeholder={trendsCategory.placeholder}
      options={trendsCategory?.options?.sort((a, b) =>
        a.label.localeCompare(b.label),
      )}
      value={trendsCategory?.value}
      onChange={handleTrendsCategoryOnChange}
      isMulti
      isClearable
      isSearchable
      onMenuOpen={() => handleOpen()}
      onMenuClose={() => handleClose()}
      onFocus={() => handleOpen()}
      onBlur={() => handleClose()}
      openMenuOnFocus={true}
      selectRef={ref}
    />
  );
}
