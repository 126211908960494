import React, { useState } from 'react';

const ArrowRight = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 21L17.5 14L10.5 7"
      stroke="#282928"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

const ArrowDown = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 10.5L14 17.5L21 10.5"
      stroke="#282928"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

export const Activity = () => {
  const [selected, setSelected] = useState(0);

  return (
    <div className="flex w-full flex-col">
      <h5 className="mb-[21px] flex text-[36px] font-semibold   leading-[42px]">
        Activity
      </h5>
      <p className="font-regular w-[80%] text-[16px]   leading-[18px]">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim illo et
        illum, quasi aliquam eius facere corporis! Sed, vero quibusdam,
        similique porro ratione atque corrupti quidem placeat dignissimos
        dolores voluptatibus!
      </p>
      <div className="mt-[47px] flex flex-col gap-y-[32px]">
        <button
          className="flex items-start justify-between border-b border-[#a1a1a1]  pb-[14px]  text-[24px] font-semibold leading-[42px]"
          onClick={() => setSelected((prev) => (prev === 1 ? 0 : 1))}
        >
          June 25 / 2022
          {selected === 1 ? <ArrowDown /> : <ArrowRight />}
        </button>
        <div
          className={`w-full flex-col gap-y-[14px] ${
            selected === 1 ? 'flex' : 'hidden'
          }`}
        >
          {Array(4)
            .fill(0)
            .map((item, index) => (
              <p
                className="font-regular flex w-full items-center justify-between text-[16px] leading-[18px] "
                key={index}
              >
                Lorem ipsum dolor sit amet consectetur.{' '}
                <span className="font-regular text-[16px] leading-[18px] text-[#A1A1A1] ">
                  08.15 PM
                </span>
              </p>
            ))}
        </div>
        <button
          className="flex items-start justify-between border-b border-[#a1a1a1]  pb-[14px]  text-[24px] font-semibold leading-[42px]"
          onClick={() => setSelected((prev) => (prev === 2 ? 0 : 2))}
        >
          June 23 / 2022
          {selected === 2 ? <ArrowDown /> : <ArrowRight />}
        </button>
        <div
          className={`w-full flex-col gap-y-[14px] ${
            selected === 2 ? 'flex' : 'hidden'
          }`}
        >
          {Array(4)
            .fill(0)
            .map((item, index) => (
              <p
                className="font-regular flex w-full items-center justify-between text-[16px] leading-[18px] "
                key={index}
              >
                Lorem ipsum dolor sit amet consectetur.{' '}
                <span className="font-regular text-[16px] leading-[18px] text-[#A1A1A1] ">
                  08.15 PM
                </span>
              </p>
            ))}
        </div>
        <button
          className="flex items-start justify-between border-b border-[#a1a1a1]  pb-[14px]  text-[24px] font-semibold leading-[42px]"
          onClick={() => setSelected((prev) => (prev === 3 ? 0 : 3))}
        >
          June 22 / 2022
          {selected === 3 ? <ArrowDown /> : <ArrowRight />}
        </button>
        <div
          className={`w-full flex-col gap-y-[14px] ${
            selected === 3 ? 'flex' : 'hidden'
          }`}
        >
          {Array(4)
            .fill(0)
            .map((item, index) => (
              <p
                className="font-regular flex w-full items-center justify-between text-[16px] leading-[18px] "
                key={index}
              >
                Lorem ipsum dolor sit amet consectetur.{' '}
                <span className="font-regular text-[16px] leading-[18px] text-[#A1A1A1] ">
                  08.15 PM
                </span>
              </p>
            ))}
        </div>
        <button
          className="flex items-start justify-between border-b border-[#a1a1a1]  pb-[14px]  text-[24px] font-semibold leading-[42px]"
          onClick={() => setSelected((prev) => (prev === 4 ? 0 : 4))}
        >
          June 18 / 2022
          {selected === 4 ? <ArrowDown /> : <ArrowRight />}
        </button>
        <div
          className={`w-full flex-col gap-y-[14px] ${
            selected === 4 ? 'flex' : 'hidden'
          }`}
        >
          {Array(4)
            .fill(0)
            .map((item, index) => (
              <p
                className="font-regular flex w-full items-center justify-between text-[16px] leading-[18px] "
                key={index}
              >
                Lorem ipsum dolor sit amet consectetur.{' '}
                <span className="font-regular text-[16px] leading-[18px] text-[#A1A1A1] ">
                  08.15 PM
                </span>
              </p>
            ))}
        </div>
      </div>
    </div>
  );
};
