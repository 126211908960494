import React from 'react';

export const ArrowRightIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 15L12.5 10L7.5 5"
        stroke={color || '#282928'}
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
};
