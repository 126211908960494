import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  DiamondPlus,
  Eraser,
  Fullscreen,
  ImagePlus,
  NotebookPen,
  Palette,
  Pen,
  Plus,
  Replace,
  ScanSearch,
  SquareAsterisk,
  SquareDashedBottom,
} from 'lucide-react';

import {
  CREATE_STUDIO_TASK,
  FILL_PAYLOAD_STUDIO_TASK,
  PROCESS_STUDIO_TASK,
} from '@/api/studio/mutations';
import { GET_SIMILAR_IMAGES, STUDIO_TASK } from '@/api/studio/queries';
import {
  conceptOptions,
  orientationOptions,
} from '@/pages/general/studio/components/options';

export const StudioContext = createContext();

const defaultConcept = conceptOptions[0];
const defaultOrientation = orientationOptions[0];

export const DisplayMode = {
  PENDING: 'PENDING',
  GENERATING: 'GENERATING',
  PREVIEW: 'PREVIEW',
  RESULT: {
    TILE: 'TILE',
    SOLO: 'SOLO',
  },
};

export function StudioProvider({ children }) {
  const [createItem, setCreateItem] = useState({
    icon: null,
    text: null,
    type: null,
  });
  const [positivePrompt, setPositivePrompt] = useState('');
  const [negativePrompt, setNegativePrompt] = useState('');
  const [referenceImage, setReferenceImage] = useState(null);
  const [referencePattern, setReferencePattern] = useState(null);
  const [referenceSketch, setReferenceSketch] = useState(null);
  const [referenceOtherImage, setReferenceOtherImage] = useState(null);
  const [orientation, setOrientation] = useState('PORTRAIT');
  const [generateVisibility, setGenerateVisibility] = useState('PUBLIC');
  const [generateSize, setGenerateSize] = useState('PORTRAIT');
  const [selectedArea, setSelectedArea] = useState(null);

  const [refSoloItemType, setRefSoloItemType] = useState(null);
  const [openTools, setOpenTools] = useState(false);
  const [selectedTool, setSelectedTool] = useState('');
  const [promptLayoutType, setPromptLayoutType] = useState('templates');
  const [activeModal, setActiveModal] = useState(null);

  const [displayMode, setDisplayMode] = useState(DisplayMode.PENDING);

  const [referenceError, setReferenceError] = useState(false);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [selectedGeneratedImage, setSelectedGeneratedImage] = useState(-1);
  const [studioTask, setStudioTask] = useState({
    id: null,
    status: 'LOADING',
  });

  const [intervalCheckStatus, setIntervalCheckStatus] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    if (studioTask?.payload?.orientation) {
      setOrientation(studioTask.payload.orientation);
    }

    if (studioTask?.status === 'PENDING_GENERATION') {
      if (!intervalCheckStatus) {
        const interval = setInterval(async () => {
          const newParams = new URLSearchParams(window.location.search);

          if (!newParams.has('taskId') || newParams.get('taskId') === null) {
            clearInterval(interval);
            setIntervalCheckStatus(null);
            return;
          }

          const { data } = await refetchStudioTask();
          setIntervalCheckStatus(true);

          if (data?.studioTask?.status === 'COMPLETED') {
            clearInterval(interval);
            setIntervalCheckStatus(null);
          }

          setStudioTask(data?.studioTask);
        }, 5000);
      }
    }
  }, [studioTask]);

  const studioToolsConfig = {
    GENERATE_APPAREL: {
      icon: Plus,
      text: 'Create an Apparel',
      pendingAreaBoxCount: 4,
    },
    GENERATE_PATTERN: {
      icon: SquareAsterisk,
      text: 'Create a Pattern',
      pendingAreaBoxCount: 4,
    },
    REALIZE_SKETCH: {
      icon: NotebookPen,
      text: 'Create from Sketch',
      pendingAreaBoxCount: 1,
    },
    PATTERN_TILE: {
      icon: Fullscreen,
      text: 'Create Tiles',
      buttonText: 'Tiles',
      pendingAreaBoxCount: 1,
    },
    CREATE_VARIANTS: {
      icon: DiamondPlus,
      text: 'Generate Variants',
      buttonText: 'Create Variants',
      pendingAreaBoxCount: 4,
    },
    ZOOM_IN_IMAGE: {
      icon: Fullscreen,
      text: 'Zoom In',
      buttonText: 'Zoom In',
      pendingAreaBoxCount: 1,
    },
    UPSCALE_IMAGE: {
      icon: Replace,
      text: 'Upscale',
      buttonText: 'Upscale',
      pendingAreaBoxCount: 1,
    },
    ENHANCE_IMAGE: {
      icon: SquareDashedBottom,
      text: 'Enhance',
      buttonText: 'Enhance',
      pendingAreaBoxCount: 1,
    },
    IMAGE_BACKGROUND_REMOVER: {
      icon: Eraser,
      text: 'Remove Background',
      buttonText: 'Remove Background',
      pendingAreaBoxCount: 1,
    },
    applyPattern: {
      icon: SquareAsterisk,
      text: 'Apply a Pattern',
      buttonText: 'Patterns',
      pendingAreaBoxCount: 1,
    },
    ZOOM_OUT_IMAGE: {
      icon: ScanSearch,
      text: 'Zoom Out',
      buttonText: 'Zoom Out',
      pendingAreaBoxCount: 1,
    },
    COLOR_TRANSFER: {
      icon: Palette,
      text: 'Color Tone',
      buttonText: 'Color Tone',
      pendingAreaBoxCount: 1,
    },
    INPAINTING: {
      icon: ImagePlus,
      text: 'Inpainting',
      buttonText: 'Inpainting',
      pendingAreaBoxCount: 1,
    },
    imgManipulation: {
      icon: ImagePlus,
      text: 'Image Manipulation',
      buttonText: 'Img Manip.',
      pendingAreaBoxCount: 1,
    },
    IMAGE_CHANGE_BACKGROUND: {
      icon: Pen,
      text: 'Change Background',
      buttonText: 'Change Background',
      pendingAreaBoxCount: 1,
    },
    changeColor: { icon: Palette, text: 'Change Color' },
  };

  const navigate = useNavigate();

  const [payload, setPayload] = useState({
    item: null,
    design: null,
    concept: defaultConcept,
    orientation: defaultOrientation,
    mode: 'GUIDED',
  });

  const [designTask, setDesignTask] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams({
    tab: 'get-inspired',
  });

  const [imagePopup, setImagePopup] = useState({
    isOpen: false,
    designTask: null,
  });

  const updateCreateItemDetails = (type) => {
    if (!type || !studioToolsConfig[type]) {
      setCreateItem({
        icon: null,
        text: null,
        pendingAreaBoxCount: null,
        type: null,
      });
      setSelectedTool('');
    } else {
      const { icon, text, pendingAreaBoxCount } = studioToolsConfig[type];

      if (type === 'CREATE_VARIANTS') {
        setCreateItem({ icon, text: 'Variants', type, pendingAreaBoxCount });
      } else {
        setCreateItem({ icon, text, type, pendingAreaBoxCount });
      }

      setSelectedTool(type);
    }
    if (type === 'GENERATE_APPAREL') {
      setGenerateSize('PORTRAIT');
    } else if (type === 'pattern') {
      setGenerateSize('SQUARE');
    }
  };

  const activeTab = searchParams.get('tab') || 'get-inspired';

  const setActiveTab = (tab) => {
    setSearchParams({ tab });
  };

  const [createNewStudioTask] = useMutation(CREATE_STUDIO_TASK);

  const [fillPayloadStudioTask] = useMutation(FILL_PAYLOAD_STUDIO_TASK);

  const [processStudioTask] = useMutation(PROCESS_STUDIO_TASK);

  const [
    studioTaskQuery,
    {
      data: studioTaskData,
      loading: studioTaskLoading,
      refetch: refetchStudioTask,
    },
  ] = useLazyQuery(STUDIO_TASK, {
    variables: {
      studioTaskId: params.get('taskId'),
    },
  });

  useEffect(() => {
    if (params.get('generationType') === 'apparel') {
      updateCreateItemDetails('apparel');
      setActiveModal(null);
      setOpenTools(true);
    } else if (params.get('generationType') === 'pattern') {
      updateCreateItemDetails('pattern');
      setActiveModal(null);
      setOpenTools(true);
    } else if (params.get('generationType') === 'edit') {
      updateCreateItemDetails(null);
      setActiveModal(null);
      setOpenTools(true);
    }
  }, [params.has('generationType')]);

  useEffect(() => {
    if (studioTaskData?.studioTask) {
      setStudioTask(studioTaskData?.studioTask);
    } else {
      setStudioTask({
        ...studioTask,
        id: null,
        status: studioTask?.status ? 'PENDING' : 'LOADING',
        images: [],
        createdAt: new Date().toISOString(),
      });
    }
  }, [studioTaskData]);

  useEffect(() => {
    if (params.has('taskId')) {
      studioTaskQuery({
        variables: {
          id: params.get('taskId'),
        },
      });

      if (
        studioTask?.status === 'COMPLETED' ||
        studioTask?.status === 'PENDING_GENERATION'
      ) {
        studioSimilarImagesQuery({
          variables: {
            studioSimilarImagesId: params.get('taskId'),
          },
        });
      }
    }
  }, [params.has('taskId'), params.get('taskId')]);

  const [
    studioSimilarImagesQuery,
    {
      data: similarImagesData,
      error: similarImagesError,
      loading: similarImagesLoading,
    },
  ] = useLazyQuery(GET_SIMILAR_IMAGES);

  const clearDesignTask = () => {
    setNegativePrompt('');
    setReferenceError(false);
  };

  const handleCreateNewStudioTask = (type) => {
    setStudioTask({
      id: null,
      status: 'PENDING',
      images: [],
      createdAt: new Date().toISOString(),
    });

    const { pendingAreaBoxCount, icon, text } = studioToolsConfig[type];
    setCreateItem({
      ...createItem,
      icon,
      text,
      pendingAreaBoxCount,
    });

    createNewStudioTask({
      variables: {
        payload: {
          taskType: type,
        },
      },
      onCompleted: (data) => {
        const taskId = data?.createNewStudioTask?.id;
        if (taskId) {
          navigate(`/studio?taskId=${taskId}`);
        }
        setStudioTask(data?.createNewStudioTask);
        handleFillPayloadStudioTask(taskId);
      },
    });
  };

  const handleFillPayloadStudioTask = (studioTaskId) => {
    const payload = {
      positivePrompt,
      negativePrompt,
      visibility: generateVisibility,
      orientation: generateSize,
    };
    if (['PATTERN_TILE'].includes(createItem.type)) {
      payload.image = {
        entityType: referencePattern?.entityType,
        entityId: referencePattern?.entityId,
      };
    }

    if (['REALIZE_SKETCH'].includes(createItem.type)) {
      payload.image = {
        entityType: referenceSketch?.entityType,
        entityId: referenceSketch?.entityId,
      };
    }

    if (['GENERATE_APPAREL'].includes(createItem.type)) {
      payload.referenceImages = referenceImage
        ? [
            {
              entityType: referenceImage?.entityType,
              entityId: referenceImage?.entityId,
            },
          ]
        : [];

      payload.referencePatterns = referencePattern
        ? [
            {
              entityType: referencePattern?.entityType,
              entityId: referencePattern?.entityId,
            },
          ]
        : [];
    }

    if (['GENERATE_PATTERN'].includes(createItem.type)) {
      payload.referencePatterns = referencePattern
        ? [
            {
              entityType: referencePattern?.entityType,
              entityId: referencePattern?.entityId,
            },
          ]
        : [];
    }

    if (
      [
        'IMAGE_BACKGROUND_REMOVER',
        'CREATE_VARIANTS',
        'UPSCALE_IMAGE',
        'ENHANCE_IMAGE',
        'ZOOM_IN_IMAGE',
        'IMAGE_CHANGE_BACKGROUND',
      ].includes(createItem.type)
    ) {
      payload.image = {
        entityType: referenceImage?.entityType,
        entityId: referenceImage?.entityId,
      };
    }

    if (['ZOOM_IN_IMAGE'].includes(createItem.type)) {
      payload.selectedArea = selectedArea;
    }

    if (['IMAGE_CHANGE_BACKGROUND'].includes(createItem.type)) {
      payload.background = {
        entityType: referenceOtherImage?.entityType,
        entityId: referenceOtherImage?.entityId,
      };
    }

    fillPayloadStudioTask({
      variables: { fillPayloadStudioTaskId: studioTaskId, payload },
      onCompleted: async (data) => {
        setStudioTask(data?.fillPayloadStudioTask);
        handleProcessStudioTask(studioTaskId);

        await studioSimilarImagesQuery({
          variables: { studioSimilarImagesId: data?.fillPayloadStudioTask?.id },
        });
      },
    });
  };

  const handleProcessStudioTask = (studioTaskId) => {
    processStudioTask({
      variables: { processStudioTaskId: studioTaskId },
      onCompleted: (data) => {
        setStudioTask(data?.processStudioTask);
      },
    });
  };

  return (
    <StudioContext.Provider
      value={{
        payload,
        setPayload,
        designTask,
        setDesignTask,
        activeTab,
        setActiveTab,
        imagePopup,
        setImagePopup,
        updateCreateItemDetails,
        createItem,
        setCreateItem,

        referenceImage,
        setReferenceImage,
        referencePattern,
        setReferencePattern,
        referenceSketch,
        setReferenceSketch,
        referenceError,
        setReferenceError,
        openTools,
        setOpenTools,
        selectedTool,
        setSelectedTool,
        generateSize,
        setGenerateSize,
        positivePrompt,
        setPositivePrompt,
        negativePrompt,
        setNegativePrompt,
        generateVisibility,
        setGenerateVisibility,

        activeModal,
        setActiveModal,
        orientation,
        setOrientation,
        generatedImages,
        setGeneratedImages,
        displayMode,
        setDisplayMode,
        promptLayoutType,
        setPromptLayoutType,
        selectedArea,
        setSelectedArea,
        studioTaskData,
        studioTaskLoading,
        studioTask,
        setStudioTask,

        selectedGeneratedImage,
        setSelectedGeneratedImage,
        clearDesignTask,
        studioToolsConfig,
        refSoloItemType,
        setRefSoloItemType,
        referenceOtherImage,
        setReferenceOtherImage,
        handleCreateNewStudioTask,
        similarImagesData,
        similarImagesError,
        similarImagesLoading,
      }}
    >
      {children}
    </StudioContext.Provider>
  );
}

export const useStudioContext = () => useContext(StudioContext);
