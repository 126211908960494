import React from 'react';

import { genderMapping } from '@/constants';
import { useReports } from '@/hooks/report';

export const AudienceCard = ({ audience }) => {
  const { updateAudience, deleteAudience, setTempAudience } = useReports();

  return (
    <div className="flex h-[83px] w-full flex-col justify-center border-2 border-charcoal px-[32px]">
      <div className="flex justify-between text-[16px] font-semibold leading-[28px]">
        <div className="flex flex-col">
          <span>{audience?.name}</span>
          <div className="flex text-[#A1A1A1]">
            <span className="mr-[8px] ml-[-10px] flex items-center text-[8px]">
              &#9679;
            </span>{' '}
            <span className="font-regular text-[12px]">
              {genderMapping[audience?.gender].label}, {audience?.ageMin} -{' '}
              {audience?.ageMax}
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <span
            className="semi-light font-regular cursor-pointer text-[12px] underline"
            onClick={() => {
              updateAudience({ id: audience.id, edit: true });
              setTempAudience(audience);
            }}
          >
            Edit List
          </span>
          <span
            className="semi-light font-regular cursor-pointer text-right text-[12px] text-red-500"
            onClick={() => deleteAudience(audience.id)}
          >
            Delete
          </span>
        </div>
      </div>
    </div>
  );
};
