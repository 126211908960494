import React from 'react';
import { Navigate } from 'react-router-dom';

import * as Pages from '@/pages/general';

import { Private } from './types';

export const studioLicence = [
  {
    path: 'studio-licence',
    element: <Pages.PurchaseStudioLicence />,
    children: [
      {
        index: true,
        element: <Navigate to="select-plan" replace />,
      },
      {
        path: 'select-plan',
        element: (
          <Private>
            <Pages.SelectStudioPlan />
          </Private>
        ),
      },
      {
        path: 'assign-members',
        element: (
          <Private>
            <Pages.AssignMembers />
          </Private>
        ),
      },
    ],
  },
];
