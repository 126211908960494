import {
  HoverCard,
  HoverCardContent,
  HoverCardPortal,
  HoverCardTrigger,
} from '@/components/ui/hover-card';

import { Circles } from './Circles';
import { ToneCards } from './ToneCards';

export function Tones() {
  return (
    <HoverCard openDelay={0} closeDelay={0}>
      <HoverCardTrigger>
        <Circles />
      </HoverCardTrigger>
      <HoverCardPortal>
        <HoverCardContent side="left" align="start">
          <ToneCards />
        </HoverCardContent>
      </HoverCardPortal>
    </HoverCard>
  );
}
