import { cn } from '@/helpers/utils';

export function Spacing({ size, className, ...props }) {
  return (
    <div
      className={cn('w-full', className)}
      style={{
        height: size + 'rem',
      }}
      {...props}
    />
  );
}
