import React from 'react';

export const CrossIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 5L5 15"
      stroke="#8A8A8A"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      d="M5 5L15 15"
      stroke="#8A8A8A"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);
