import { gql } from '@apollo/client';

export const TRENDS = gql`
  query Trends {
    trends {
      tabs {
        name
        data {
          id
          tone {
            id
            name
            code
            hex
            main
            weight
            isLiked
            collections
          }
        }
      }
      tones {
        data {
          market {
            id
            location {
              id
              name
            }
            apparels
          }
          isLiked
          collections
          statistics {
            yoyGrowth {
              percent
              chartPoints {
                points
                seasonKey
                year
              }
            }
            trendType
            reach
            optimalLaunch
          }
          image {
            width
            url
            height
            blurhash
          }
          featuredImages {
            width
            url
            height
            blurhash
          }
          item {
            name
            type
            value
            tone {
              code
              hex
              main
              name
            }
          }
        }
        cursor
      }
      trendItems {
        cursor
        data {
          tiId
          isLiked
          collections
          market {
            id
            location {
              id
              name
            }
            apparels
          }
          statistics {
            reach
            trendType
            yoyGrowth {
              percent
              chartPoints {
                year
                seasonKey
                points
              }
            }
            optimalLaunch
          }
          image {
            blurhash
            url
            height
            width
          }
          featuredImages {
            width
            url
            height
            blurhash
          }
          tones {
            name
            hex
            code
          }
        }
      }
      styles {
        cursor
        data {
          market {
            id
            location {
              id
              name
            }
            apparels
          }
          statistics {
            reach
            trendType
            yoyGrowth {
              percent
              chartPoints {
                year
                seasonKey
                points
              }
            }
            optimalLaunch
          }
          image {
            blurhash
            url
            height
            width
          }
          featuredImages {
            width
            url
            height
            blurhash
          }
          tones {
            name
            hex
            code
          }
        }
      }
    }
  }
`;

export const TREND_ITEMS = gql`
  query TrendItems(
    $cursor: String
    $filters: TrendItemFiltersInput
    $audience: [String]
    $season: String
    $sorting: SortingInput
  ) {
    trendItems(
      season: $season
      filters: $filters
      audience: $audience
      cursor: $cursor
      sorting: $sorting
    ) {
      cursor
      data {
        tiId
        id
        isLiked
        collections
        featuredImages {
          url
          width
          height
          blurhash
          exploreItemId
          urlKey
        }
        image {
          url
        }
        apparel
        tiColor
        tiColorTone
        title
        optimalLaunch
        reach
        trendType
        yoyGrowth {
          percent
          data {
            full
            green
          }
        }
      }
    }
  }
`;

export const TRENDS_TONES = gql`
  query TrendTones(
    $cursor: String
    $filters: TrendItemFiltersInput
    $audience: [String]
    $season: String
  ) {
    tones(
      season: $season
      filters: $filters
      audience: $audience
      cursor: $cursor
    ) {
      cursor
      data {
        id
        tiId
        tiColor
        apparel
        apparelName
        collections
        isLiked
        image {
          url
          width
          height
          blurhash
          exploreItemId
        }
        featuredImages {
          url
          width
          height
          blurhash
          exploreItemId
          urlKey
        }
        title
        optimalLaunch
        reach
        trendType
        tone {
          name
          hex
          code
          main
        }
        yoyGrowth {
          percent
          data {
            full
            green
          }
        }
      }
    }
  }
`;

export const TREND_PATTERNS = gql`
  query TrendPatterns(
    $cursor: String
    $filters: TrendItemFiltersInput
    $audience: [String]
    $season: String
  ) {
    patterns(
      season: $season
      filters: $filters
      audience: $audience
      cursor: $cursor
    ) {
      cursor
      data {
        id
        tiId
        image {
          url
        }
        title
        optimalLaunch
        reach
        trendType
        yoyGrowth {
          percent
          data {
            full
            green
          }
        }
      }
    }
  }
`;

export const TREND_FABRICS = gql`
  query TrendFabrics(
    $cursor: String
    $filters: TrendItemFiltersInput
    $audience: [String]
    $season: String
  ) {
    fabrics(
      season: $season
      filters: $filters
      audience: $audience
      cursor: $cursor
    ) {
      cursor
      data {
        id
        tiId
        image {
          url
        }
        title
        optimalLaunch
        reach
        trendType
        yoyGrowth {
          percent
          data {
            full
            green
          }
        }
      }
    }
  }
`;

export const TREND_OVERVIEWS = gql`
  query TrendsOverview {
    trendsOverview {
      id
      location {
        name
        type
        locationId
        geonamesId
        countryCode
      }
      apparels {
        label
        value
      }
      audiences {
        highlight
        audience {
          ageMax
          ageMin
          gender
          id
          audienceId
          platform
          name
          features
        }
        analyzedImages
        yoyGrowth
      }
      featuredItems {
        type
        itemId
        featuredImages {
          url
        }
        yoyGrowth
        title
        tone {
          name
          hex
          code
          main
        }
      }
    }
  }
`;

export const TREND_ITEM = gql`
  query TrendItem($trendItemId: ID!, $season: String!, $market: String!) {
    trendItem(id: $trendItemId, season: $season, market: $market) {
      id
      tiId
      apparel
      isLiked
      tiType
      tiColor
      tiColorTone
      tiFabric
      tiPattern
      trendItemId
      title
      market {
        id
        location {
          name
          locationId
          countryCode
        }
        apparels {
          label
          value
        }
        audiences {
          id
          ageMax
          ageMin
          gender
        }
        plan {
          scope {
            permissions
          }
        }
      }
      audience {
        id
        ageMin
        ageMax
        gender
      }
      featuredImages {
        url
        exploreItemId
        urlKey
        blurhash
        entity {
          ... on Post {
            description
            publishDate
            image {
              url
              width
              height
              credit
              designer
              blurhash
              urlKey
              exploreItemId
              source
              systemPick
              studioItemId
            }
            ttPostId
            isVideo
            likes
            comments
            shortcode
            influencer {
              id
              fullName
              instagramId
              username
              instagramUsername
              tiktokUsername
              followerCount
              followingCount
              postCount
              totalLikeCount
              averageLikesPerPost
              platform
              active
              createdAt
              updatedAt
            }
            id
          }
          ... on ExploreFashionWeekItem {
            id
            apparel
            apparelLabelId
            image {
              url
              width
              height
              credit
              designer
              blurhash
              urlKey
              exploreItemId
              source
              systemPick
              studioItemId
            }
            attributes {
              name
              value
            }
            tones {
              id
              name
              code
              hex
              main
              weight
              isLiked
              collections
            }
            disabled
            score
            designer
            season
            itemType
            category
            city
            originalBox
            box
            fashionWeekSlug
            fashionWeek {
              season
              fwType
              city
              name
              designers
            }
          }
        }
      }
      tone {
        id
        name
        code
        hex
        main
        weight
      }
      optimalLaunch
      reach
      trendType
      yoyGrowth {
        percent
        data {
          full
          green
        }
      }
      attributes {
        percent
        type
        value
      }
      forecast {
        availableYears
        data {
          points {
            date
            value
            yoyGrowth
          }
          year
        }
      }
      tabs {
        name
        data {
          id
          title
          optimalLaunch
          apparel
          tiId
          tiColor
          image {
            url
            exploreItemId
          }
          tone {
            code
            hex
            main
          }
          reach
          yoyGrowth {
            percent
          }
          featuredImages {
            url
          }
        }
      }
      image {
        url
        width
        height
        credit
        designer
        blurhash
        urlKey
        exploreItemId
        source
        systemPick
        studioItemId
      }
    }
  }
`;

export const TREND_ITEM_FORECAST = gql`
  query TrendItemForecast($trendItemId: ID!, $season: String!, $year: Int!) {
    trendItem(id: $trendItemId, season: $season) {
      forecast(year: $year) {
        data {
          points {
            value
            date
            yoyGrowth
          }
          year
        }
        availableYears
      }
    }
  }
`;

export const SIMILAR_TREND_ITEMS = gql`
  query SimilarTrendItems($itemId: String, $season: String) {
    similarTrendItems(itemId: $itemId, season: $season) {
      id
      tiId
      apparel
      tiColor
      trendItemId
      title
      market {
        id
        location {
          name
          locationId
          countryCode
        }
        apparels {
          label
          value
        }
        audiences {
          id
          ageMax
          ageMin
          gender
        }
        plan {
          scope {
            permissions
          }
        }
      }
      featuredImages {
        url
        exploreItemId
        urlKey
      }
      tone {
        id
        name
        code
        hex
        main
        weight
      }
      optimalLaunch
      reach
      trendType
      yoyGrowth {
        percent
        data {
          full
          green
        }
      }
      attributes {
        percent
        type
        value
      }
      forecast {
        availableYears
        data {
          points {
            date
            value
            yoyGrowth
          }
          year
        }
      }
    }
  }
`;

export const SIMILAR_SNAPSHOT_ITEM = gql`
  query SimilarSnapshotItem($similarSnapshotItemId: ID!, $cursor: String) {
    similarSnapshotItem(id: $similarSnapshotItemId, cursor: $cursor) {
      edges {
        node {
          id
          title
          featuredImages {
            url
            exploreItemId
            urlKey
            entity {
              ... on Post {
                description
                publishDate
                image {
                  url
                  width
                  height
                  credit
                  designer
                  blurhash
                  urlKey
                  exploreItemId
                  source
                  systemPick
                  studioItemId
                }
                ttPostId
                isVideo
                likes
                comments
                shortcode
                influencer {
                  id
                  fullName
                  instagramId
                  username
                  instagramUsername
                  tiktokUsername
                  followerCount
                  followingCount
                  postCount
                  totalLikeCount
                  averageLikesPerPost
                  platform
                  active
                  createdAt
                  updatedAt
                }
                id
              }
              ... on ExploreFashionWeekItem {
                id
                apparel
                apparelLabelId
                image {
                  url
                  width
                  height
                  credit
                  designer
                  blurhash
                  urlKey
                  exploreItemId
                  source
                  systemPick
                  studioItemId
                }
                attributes {
                  name
                  value
                }
                tones {
                  id
                  name
                  code
                  hex
                  main
                  weight
                  isLiked
                  collections
                }
                disabled
                score
                designer
                season
                itemType
                category
                city
                originalBox
                box
                fashionWeekSlug
                fashionWeek {
                  season
                  fwType
                  city
                  name
                  designers
                }
              }
            }
          }
          weeklyData {
            growth
            trendType
            value
            visibility
            qualityEngagement
            totalEngagement
            totalPostCount
            date
            apparelCount
          }
          twoWeeklyData {
            growth
            trendType
            value
            visibility
            qualityEngagement
            totalEngagement
            totalPostCount
            date
            apparelCount
          }
          fourWeeklyData {
            growth
            trendType
            value
            visibility
            qualityEngagement
            totalEngagement
            totalPostCount
            date
            apparelCount
          }
        }
      }
    }
  }
`;

export const NOTIFICATIONS = gql`
  query Notifications($cursor: String) {
    notifications(cursor: $cursor) {
      data {
        createdAt
        image {
          url
          height
          width
        }
        id
        notificationType
        seen
        user {
          fullName
        }
        source {
          fullName
        }
        payload {
          ... on CommentReplyNotification {
            entityId
            entityType
            comment {
              id
              comment
              mentions {
                fullName
              }
            }
            reply
          }
          ... on MentionNotification {
            entityId
            entityType
            comment {
              comment
              mentions {
                fullName
              }
            }
          }
        }
      }
      newNotification
      cursor
    }
  }
`;

export const SNAPSHOT_ITEMS = gql`
  query SnapshotItems(
    $cursor: String
    $filters: FiltersInput
    $audience: [String]
    $sortField: SortingType
  ) {
    snapshotItems(
      cursor: $cursor
      filters: $filters
      audience: $audience
      sortField: $sortField
    ) {
      pageInfo {
        endCursor
        hasNextPage
        total
      }
      edges {
        node {
          apparel
          isLiked
          collections
          weeklyData {
            growth
            trendType
            value
            visibility
            qualityEngagement
            totalEngagement
            totalPostCount
            postCount
            date
            apparelCount
          }
          twoWeeklyData {
            growth
            trendType
            value
            visibility
            qualityEngagement
            totalEngagement
            totalPostCount
            postCount
            date
            apparelCount
          }
          fourWeeklyData {
            growth
            trendType
            value
            visibility
            qualityEngagement
            totalEngagement
            totalPostCount
            postCount
            date
            apparelCount
          }
          lastFourWeeksMagnitude
          lastFourWeeksQualityEngagement
          lastTwoWeeksMagnitude
          lastTwoWeeksQualityEngagement
          lastWeekMagnitude
          lastWeekQualityEngagement
          ssiColor
          ssiFabric
          ssiPattern
          ssiType
          attributeKeyA
          attributeValueA
          attributeKeyB
          attributeValueB
          id
          color
          colorTone
          audience {
            id
            ageMin
            ageMax
            gender
            groups {
              audienceId
              platform
            }
          }
          itemId
          title
          tone {
            code
            hex
            main
            name
          }
          featuredImages {
            credit
            blurhash
            designer
            exploreItemId
            height
            source
            studioItemId
            systemPick
            url
            urlKey
            width
          }
        }
      }
    }
  }
`;

export const SNAPSHOT_ITEM = gql`
  query SnapshotItem($snapshotItemId: ID!, $timeframe: String!) {
    snapshotItem(id: $snapshotItemId, timeframe: $timeframe) {
      node {
        apparel
        color
        title
        id
        ssiColor
        ssiFabric
        ssiPattern
        ssiType
        isLiked
        collections
        tone {
          code
          hex
          main
          name
        }
        weeklyData {
          date
          value
          growth
          trendType
          apparelCount
          qualityEngagement
          totalEngagement
          totalPostCount
          postCount
          visibility
        }
        twoWeeklyData {
          date
          value
          growth
          trendType
          apparelCount
          qualityEngagement
          totalEngagement
          totalPostCount
          postCount
          visibility
        }
        fourWeeklyData {
          date
          value
          growth
          trendType
          apparelCount
          qualityEngagement
          totalEngagement
          totalPostCount
          postCount
          visibility
        }
        lastFourWeeksMagnitude
        lastFourWeeksQualityEngagement
        lastTwoWeeksMagnitude
        lastTwoWeeksQualityEngagement
        lastWeekMagnitude
        lastWeekQualityEngagement
        colorTone
        featuredImages {
          url
          width
          height
          credit
          designer
          blurhash
          urlKey
          source
          entity {
            ... on Post {
              description
              publishDate
              image {
                url
                width
                height
                credit
                designer
                blurhash
                urlKey
                exploreItemId
                source
                systemPick
                studioItemId
              }
              isVideo
              likes
              comments
              shortcode
              influencer {
                id
                fullName
                instagramId
                username
                instagramUsername
                tiktokUsername
                followerCount
                followingCount
                postCount
                totalLikeCount
                averageLikesPerPost
                platform
                active
                createdAt
                updatedAt
              }
              id
            }
            ... on ExploreFashionWeekItem {
              id
              apparel
              apparelLabelId
              image {
                url
                width
                height
                credit
                designer
                blurhash
                urlKey
                exploreItemId
                source
                systemPick
                studioItemId
              }
              attributes {
                name
                value
              }
              tones {
                id
                name
                code
                hex
                main
                weight
                isLiked
                collections
              }
              disabled
              score
              designer
              season
              itemType
              category
              city
              originalBox
              box
              fashionWeekSlug
              fashionWeek {
                season
                fwType
                city
                name
                designers
              }
            }
          }
        }
        itemId
        audience {
          ageMax
          ageMin
          groups {
            audienceId
            platform
          }
          gender
          id
        }
        tabs {
          name
          data {
            id
            attributeKeyA
            attributeValueA
            attributeKeyB
            attributeValueB
            percentage
            title
            apparel
            itemId
            ssiColor
            ssiPattern
            ssiFabric
            lastWeekQualityEngagement
            tone {
              code
              hex
              main
              name
            }
            featuredImages {
              url
            }
          }
        }
        attributeKeyA
        attributeValueA
        attributeKeyB
        attributeValueB
        charts {
          id
          name
          stats {
            percent
            value
          }
        }
      }
    }
  }
`;
