export function Copy({ color = '#FFF' }) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 14V8H40V28H34" stroke={color} strokeWidth="3" />
      <rect
        width="20"
        height="20"
        transform="matrix(-1 0 0 1 28 20)"
        stroke={color}
        strokeWidth="3"
      />
    </svg>
  );
}
