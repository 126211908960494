import { createContext, useEffect, useState } from 'react';

import { ToastContainer } from '@/components/Toast';

export const ToastContext = createContext();

/**
 * type Toast = {
 *  id: string;
 *  title: string;
 *  content: string;
 *  type: 'success' | 'error' | 'warning' | 'info';
 *  duration: number;
 *  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
 *  showCloseButton: boolean;
 * }
 * state -> toasts: Toast[]
 * function -> createToast: (toast: Toast) => void
 * function -> removeToast: (id: string) => void
 * function -> removeAllToasts: () => void
 *
 * max toasts to display: 5
 */

// remove toasts after 5 seconds or with the given duration

export function ToastProvider({ children }) {
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    const lastToast = toasts[toasts.length - 1];

    if (!lastToast) return;

    const timeout = setTimeout(() => {
      removeToast(lastToast.id);
    }, lastToast.duration || 5000);

    return () => clearTimeout(timeout);
  }, [toasts]);

  function createToast(toast) {
    if (toasts.length >= 5) {
      return setTimeout(() => {
        removeToast(toasts[0].id);
        setToasts((prevToasts) => [...prevToasts, toast]);
      }, 500);
    }

    setToasts((prevToasts) => [...prevToasts, toast]);
  }

  function removeToast(id) {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  }

  function removeAllToasts() {
    setToasts([]);
  }

  return (
    <ToastContext.Provider
      value={{
        toasts,
        createToast,
        removeToast,
        removeAllToasts,
      }}
    >
      <ToastContainer />
      {children}
    </ToastContext.Provider>
  );
}
