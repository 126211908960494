export function Spinner({ color = '#C29B77' }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1.5C6.27609 1.5 4.62279 2.18482 3.40381 3.40381C2.18482 4.6228 1.5 6.2761 1.5 8C1.5 8.19892 1.42098 8.38968 1.28033 8.53033C1.13968 8.67099 0.948912 8.75 0.75 8.75C0.551088 8.75 0.360322 8.67099 0.21967 8.53033C0.0790175 8.38968 4.19177e-09 8.19892 0 8C0 6.41775 0.469192 4.87103 1.34824 3.55544C2.22729 2.23985 3.47672 1.21447 4.93853 0.608967C6.40034 0.00346625 8.00887 -0.15496 9.56072 0.153721C11.1126 0.462403 12.538 1.22433 13.6569 2.34315C14.7757 3.46197 15.5376 4.88743 15.8463 6.43928C16.155 7.99113 15.9965 9.59966 15.391 11.0615C14.7855 12.5233 13.7602 13.7727 12.4446 14.6518C11.129 15.5308 9.58225 16 8 16C7.80109 16 7.61032 15.921 7.46967 15.7803C7.32902 15.6397 7.25 15.4489 7.25 15.25C7.25 15.0511 7.32902 14.8603 7.46967 14.7197C7.61032 14.579 7.80109 14.5 8 14.5C9.72391 14.5 11.3772 13.8152 12.5962 12.5962C13.8152 11.3772 14.5 9.72391 14.5 8C14.5 6.2761 13.8152 4.6228 12.5962 3.40381C11.3772 2.18482 9.72391 1.5 8 1.5Z"
        fill={color}
      />
    </svg>
  );
}
