import { useEffect, useState } from 'react';
import ReactTimeAgo from 'react-time-ago';
import { useLazyQuery, useMutation } from '@apollo/client';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { RefreshCw, Trash } from 'lucide-react';

import { UPDATE_FILE_STATUS } from '@/api/studio/mutations';
import { GENERATE_FILE_UPLOAD_URL } from '@/api/studio/queries';
import { useStudioContext } from '@/contexts/StudioContext';

import { SidebarTitle } from './SidebarMenu';

TimeAgo.addDefaultLocale(en);

export const GenerationElapsed = () => {
  const { studioTask } = useStudioContext();
  return studioTask && studioTask.createdAt ? (
    <span className={'tabular-nums'} style={{ fontSize: 14, color: '#a1a1a1' }}>
      <ReactTimeAgo
        date={new Date(studioTask.createdAt)}
        timeStyle={{
          steps: [
            {
              formatAs: 'second',
            },
          ],
          labels: 'mini',
        }}
        tooltip={false}
      />
    </span>
  ) : null;
};

export const UploadBox = ({
  title,
  icon: Icon,
  text,
  type,
  disabled = false,
  fileVisibility = 'PRIVATE',
  fileEntityType = 'APPAREL',
}) => {
  const [generateFileUploadUrl] = useLazyQuery(GENERATE_FILE_UPLOAD_URL);
  const [updateFileStatus] = useMutation(UPDATE_FILE_STATUS);
  const [uploading, setUploading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);

  const [isDragging, setIsDragging] = useState(false);
  const {
    setReferenceImage,
    setReferencePattern,
    setReferenceError,
    setReferenceOtherImage,
    referenceError,
    referenceImage,
    referencePattern,
    referenceSketch,
    setReferenceSketch,
    referenceOtherImage,
  } = useStudioContext();

  console.log({
    referenceImage,
    referencePattern,
    referenceSketch,
    referenceOtherImage,
  });

  useEffect(() => {
    if (fileEntityType === 'APPAREL' && referenceImage) {
      setUploadedImage({
        id: referenceImage.entityId,
        name: 'image',
        type: 'image',
        url: referenceImage.url,
      });
    } else if (fileEntityType === 'PATTERN' && referencePattern) {
      setUploadedImage({
        id: referencePattern.entityId,
        name: 'pattern',
        type: 'pattern',
        url: referencePattern.url,
      });
    } else if (fileEntityType === 'SKETCH' && referenceSketch) {
      setUploadedImage({
        id: referenceSketch.entityId,
        name: 'sketch',
        type: 'image',
        url: referenceSketch.url,
      });
    } else if (fileEntityType === 'OTHER' && referenceOtherImage) {
      setUploadedImage({
        id: referenceOtherImage.entityId,
        name: 'other',
        type: 'image',
        url: referenceOtherImage.url,
      });
    } else {
      setUploadedImage(null);
    }
  }, [referenceImage, referencePattern, referenceSketch, referenceOtherImage]);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      handleFileUpload({ target: { files: [file] } });
    }
  };

  useEffect(() => {
    if (referenceError) {
      setTimeout(() => {
        setReferenceError(null);
      }, 4000);
    }
  }, [referenceError]);

  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];

    if (!file) {
      setReferenceError('No file selected');
      return;
    }

    try {
      setUploading(true);
      setReferenceError(null);

      const { data, error: queryError } = await generateFileUploadUrl({
        variables: {
          fileVisibility,
          fileName: file.name,
          mimeType: file.type,
          fileEntityType,
        },
      });

      if (queryError) {
        throw new Error(queryError.message);
      }

      if (!data?.generateFileUploadUrl?.signedUrl) {
        throw new Error('Failed to get signed URL');
      }

      const response = await fetch(data.generateFileUploadUrl.signedUrl, {
        method: 'PUT',
        body: file,
      });

      if (!response.ok) {
        throw new Error(`Failed to upload image: ${response.statusText}`);
      }

      await updateFileStatus({
        variables: {
          fileId: data.generateFileUploadUrl.entityId,
          status: 'DONE',
        },
      });

      const imageUrl = URL.createObjectURL(file);

      setUploadedImage({
        id: data.generateFileUploadUrl.entityId,
        name: file.name,
        type,
        url: imageUrl,
      });
      console.log({ type });
      if (type === 'image') {
        setReferenceImage({
          entityId: data.generateFileUploadUrl.entityId,
          entityType: 'FILE_UPLOAD',
          url: imageUrl,
        });
      } else if (type === 'pattern') {
        setReferencePattern({
          entityId: data.generateFileUploadUrl.entityId,
          entityType: 'FILE_UPLOAD',
          url: imageUrl,
        });
      } else if (type === 'other') {
        setReferenceOtherImage({
          entityId: data.generateFileUploadUrl.entityId,
          entityType: 'FILE_UPLOAD',
          url: imageUrl,
        });
      } else if (type === 'sketch') {
        setReferenceSketch({
          entityId: data.generateFileUploadUrl.entityId,
          entityType: 'FILE_UPLOAD',
          url: imageUrl,
        });
      }
    } catch (error) {
      if (error.message === 'FILE_UPLOAD_IN_PROGRESS') {
        setReferenceError('This file is already being uploaded');
      } else {
        setReferenceError(`Failed to upload image: ${error.message}`);
      }
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteImage = () => {
    if (fileEntityType === 'APPAREL') {
      setReferenceImage(null);
    } else if (fileEntityType === 'PATTERN') {
      setReferencePattern(null);
    } else if (fileEntityType === 'SKETCH') {
      setReferenceSketch(null);
    } else if (fileEntityType === 'OTHER') {
      setReferenceOtherImage(null);
    }
    setUploadedImage(null);
    setReferenceError(false);
  };

  if (disabled) {
    return (
      <div className="flex flex-col gap-2">
        <SidebarTitle text={title} />
        <label className="flex cursor-not-allowed flex-col items-center justify-center gap-y-2 rounded border border-night/10 bg-secondary/20 p-5 text-center text-sm text-night">
          <Icon size={16} />
          {text}
        </label>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      <SidebarTitle text={title} />
      {uploadedImage ? (
        <div className="group relative max-h-[12rem] w-full">
          <img
            src={uploadedImage.url}
            alt={uploadedImage.name}
            className="h-full w-full rounded object-cover"
          />
          <div className="absolute inset-0 bg-night/40 opacity-0 transition-opacity duration-300 group-hover:opacity-100"></div>
          <div className="absolute top-2 right-2 flex gap-2 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
            <label className="cursor-pointer rounded border border-secondary/10 bg-white p-1 text-black hover:border-primary hover:bg-white hover:text-primary">
              <RefreshCw size={20} />
              <input
                type="file"
                className="hidden"
                onChange={handleFileUpload}
                accept="image/*"
              />
            </label>
            <button
              className="rounded border border-secondary/10 bg-white p-1 text-black hover:border-red-600 hover:bg-white hover:text-red-600"
              onClick={handleDeleteImage}
            >
              <Trash size={20} />
            </button>
          </div>
        </div>
      ) : (
        <label
          className={`focus-visible:none flex min-h-[8rem] min-w-[8rem] cursor-pointer flex-col items-center justify-center gap-y-2 rounded border ${
            isDragging
              ? 'border-primary bg-[#FFFDFD]'
              : 'border-night/5 bg-secondary/5'
          } p-5 text-center text-sm text-night hover:border-primary hover:bg-[#FFFDFD] hover:text-primary focus:border-primary focus:bg-[#FFFDFD] focus:ring-0 focus-visible:outline-none`}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <input
            type="file"
            className="hidden"
            onChange={handleFileUpload}
            accept="image/*"
            disabled={uploading}
          />
          <Icon size={16} />
          <p>{uploading ? 'Uploading...' : text}</p>
        </label>
      )}
    </div>
  );
};
