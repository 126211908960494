import { useContext } from 'react';

import { Context } from '@/contexts/TrendsContext';

export function useTrends() {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useTrends must be used within a TrendsProvider');
  }

  return context;
}
