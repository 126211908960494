import { useTrends } from '@/hooks/trends';
import { Icon } from '@/icons';

export function Navigation() {
  const { nextYear, prevYear, pastButtonDisabled, forecastButtonDisabled } =
    useTrends();

  return (
    <div className="flex min-w-fit items-center justify-evenly gap-4">
      <div
        className="flex cursor-pointer items-center gap-4"
        onClick={() => !pastButtonDisabled && prevYear()}
      >
        <Icon name="arrow-left" color={pastButtonDisabled ? '#CCC' : '#000'} />
        <span
          className={[
            'font-planc text-2xl font-semibold',
            pastButtonDisabled && 'text-[#CCC]',
          ].join(' ')}
        >
          Past
        </span>
      </div>

      <div className="h-12 w-[0.1rem] bg-black" />

      <div
        className="flex cursor-pointer items-center gap-4"
        onClick={() => !forecastButtonDisabled && nextYear()}
      >
        <span
          className={[
            'font-planc text-2xl font-semibold',
            forecastButtonDisabled && 'text-[#CCC]',
          ].join(' ')}
        >
          Forecast
        </span>
        <Icon
          name="arrow-right"
          color={forecastButtonDisabled ? '#CCC' : '#000'}
        />
      </div>
    </div>
  );
}
