import React from 'react';

import { Button } from '@/components/Button';
import { FormInput, FormLabel } from '@/components/FormInput';
import { Heading } from '@/components/Heading';
import { SelectDropdown } from '@/components/SelectDropdown';
import { Sidebar } from '@/components/Sidebar';
import { Spacing } from '@/components/Spacing';
import { useAppFilters } from '@/contexts/AppFiltersContext';
import { useTrendsContext } from '@/contexts/TrendsContext';

function FiltersHeading({ clearCategoryFilter }) {
  return (
    <div className="flex items-end justify-between">
      <Heading size={'xl'}>Filter</Heading>
      <Button
        variant={'link'}
        size={'xs'}
        className={'mb-2 p-0'}
        onClick={clearCategoryFilter}
      >
        Clear All
      </Button>
    </div>
  );
}

function SortHeading() {
  return (
    <div className="flex items-end justify-between">
      <Heading size={'xl'}>Sort</Heading>
    </div>
  );
}

export function TrendsFashionWeeksFilters() {
  const {
    filters,
    handleTrendsCategoryOnChange,
    handleSourceOnChange,
    handleDesignerOnChange,
  } = useAppFilters();

  const { source, designer, trendsCategory } = filters;

  const categoryFilterClearAll = () => {
    handleTrendsCategoryOnChange(null);
  };

  return (
    <>
      <FiltersHeading
        category={trendsCategory?.value}
        clearCategoryFilter={categoryFilterClearAll}
      />

      <Spacing size={1.125} />

      <div className="flex flex-col gap-4">
        <FormInput>
          <FormLabel>{source?.label}</FormLabel>
          <SelectDropdown
            placeholder={source?.placeholder}
            isSearchable={true}
            options={source?.options}
            multiselect={false}
            isClearable={false}
            value={source?.value}
            onChange={(e) => handleSourceOnChange(e)}
          />
        </FormInput>

        <FormInput>
          <FormLabel>{designer?.label}</FormLabel>
          <SelectDropdown
            placeholder={designer?.placeholder}
            isSearchable={true}
            options={designer?.options}
            multiselect={false}
            isClearable={false}
            value={designer?.value}
            onChange={(e) => handleDesignerOnChange(e)}
          />
        </FormInput>

        <FormInput>
          <FormLabel>{trendsCategory?.label}</FormLabel>
          <SelectDropdown
            placeholder={trendsCategory?.placeholder}
            isSearchable={true}
            options={trendsCategory?.options}
            isMulti={true}
            isClearable={true}
            value={trendsCategory?.value}
            onChange={(selectedOptions) => {
              if (!Array.isArray(selectedOptions)) {
                selectedOptions = [selectedOptions];
              }

              const selectedValues = selectedOptions.map((option) => ({
                label: option.label,
                value: String(option.value),
              }));

              handleTrendsCategoryOnChange(selectedValues);
            }}
          />
        </FormInput>
      </div>

      <Spacing size={1.125} />

      <SortHeading />

      <Spacing size={1.125} />

      <div className="flex flex-col gap-4">
        <FormInput>
          <FormLabel>Visibility</FormLabel>
          <SelectDropdown
            placeholder={'Default'}
            isSearchable={false}
            options={[]}
            multiselect={false}
            isClearable={false}
            value={null}
          />
        </FormInput>

        <FormInput>
          <FormLabel>Engagement</FormLabel>
          <SelectDropdown
            placeholder={'Descending'}
            isSearchable={false}
            options={[]}
            multiselect={false}
            isClearable={false}
            value={null}
          />
        </FormInput>
      </div>
    </>
  );
}

export function TrendsInsightsFilters() {
  const {
    filters,
    handleTrendsCategoryOnChange,
    handleEngagementOnChange,
    handleTrendTypeOnChange,
    handleSeasonOnChange,
    handleSourceOnChange,
  } = useAppFilters();

  const { source, season, trendsCategory, engagement, trendType } = filters;

  const categoryFilterClearAll = () => {
    handleTrendsCategoryOnChange(null);
  };

  return (
    <>
      <FiltersHeading
        category={trendsCategory?.value}
        clearCategoryFilter={categoryFilterClearAll}
      />

      <Spacing size={1.125} />

      <div className="flex flex-col gap-4">
        <FormInput>
          <FormLabel>{source?.label}</FormLabel>
          <SelectDropdown
            placeholder={source?.placeholder}
            isSearchable={true}
            options={source?.options}
            multiselect={false}
            isClearable={false}
            value={source?.value}
            onChange={(e) => handleSourceOnChange(e)}
          />
        </FormInput>

        <FormInput>
          <FormLabel>{season?.label}</FormLabel>
          <SelectDropdown
            placeholder={season?.placeholder}
            isSearchable={true}
            options={season?.options}
            multiselect={false}
            isClearable={false}
            value={season?.value}
            onChange={(e) => handleSeasonOnChange(e)}
          />
        </FormInput>

        <FormInput>
          <FormLabel>{trendsCategory?.label}</FormLabel>
          <SelectDropdown
            placeholder={trendsCategory?.placeholder}
            isSearchable={true}
            options={trendsCategory?.options}
            isMulti={true}
            isClearable={true}
            value={trendsCategory?.value}
            onChange={(selectedOptions) => {
              if (!Array.isArray(selectedOptions)) {
                selectedOptions = [selectedOptions];
              }

              const selectedValues = selectedOptions.map((option) => ({
                label: option.label,
                value: String(option.value),
              }));

              handleTrendsCategoryOnChange(selectedValues);
            }}
          />
        </FormInput>
      </div>

      <Spacing size={1.125} />

      <SortHeading />

      <Spacing size={1.125} />

      <div className="flex flex-col gap-4">
        <FormInput>
          <FormLabel>{engagement?.label}</FormLabel>
          <SelectDropdown
            placeholder={engagement?.placeholder}
            isSearchable={false}
            options={engagement?.options}
            multiselect={false}
            isClearable={true}
            value={engagement?.value}
            onChange={(e) => handleEngagementOnChange(e)}
          />
        </FormInput>

        <FormInput>
          <FormLabel>{trendType?.label}</FormLabel>
          <SelectDropdown
            placeholder={trendType?.placeholder}
            isSearchable={false}
            options={trendType?.options}
            multiselect={false}
            isClearable={true}
            value={trendType?.value}
            onChange={(e) => handleTrendTypeOnChange(e)}
          />
        </FormInput>
      </div>
    </>
  );
}

export function TrendsSnapshotsFilters() {
  const {
    filters,
    handleSourceOnChange,
    handlePlatformOnChange,
    handleTimeframeOnChange,
    handleTrendsCategoryOnChange,
    handleSnapshotSortOnChange,
    selectedTimeframe,
    selectedSnapshotSort,
  } = useAppFilters();

  const { source, platform, trendsCategory, timeframe, snapshotSort } = filters;

  const categoryFilterClearAll = () => {
    handleTrendsCategoryOnChange(null);
    handleTimeframeOnChange(
      { label: 'Last week', value: 'lastWeek' },
      'timeframe',
    );
  };

  return (
    <>
      <FiltersHeading
        category={trendsCategory?.value}
        clearCategoryFilter={categoryFilterClearAll}
      />

      <Spacing size={1.125} />

      <div className="flex flex-col gap-4">
        <FormInput>
          <FormLabel>{source?.label}</FormLabel>
          <SelectDropdown
            placeholder={source?.placeholder}
            isSearchable={true}
            options={source?.options}
            multiselect={false}
            isClearable={false}
            value={source?.value}
            variant={'primary'}
            onChange={(e) => handleSourceOnChange(e)}
          />
        </FormInput>

        <FormInput>
          <FormLabel>{platform?.label}</FormLabel>
          <SelectDropdown
            placeholder={platform?.placeholder}
            isSearchable={true}
            options={platform?.options}
            multiselect={false}
            isClearable={false}
            value={platform?.value}
            onChange={(e) => handlePlatformOnChange(e)}
          />
        </FormInput>

        <FormInput>
          <FormLabel>{timeframe?.label}</FormLabel>
          <SelectDropdown
            placeholder={timeframe?.placeholder}
            isSearchable={true}
            options={timeframe?.options}
            multiselect={true}
            isClearable={false}
            id={'hasIndicator'}
            value={timeframe?.options.find(
              (option) => option.value === selectedTimeframe,
            )}
            onChange={(e) => handleTimeframeOnChange(e)}
          />
        </FormInput>

        <FormInput>
          <FormLabel>{trendsCategory?.label}</FormLabel>
          <SelectDropdown
            placeholder={trendsCategory?.placeholder}
            isSearchable={true}
            options={trendsCategory?.options}
            isMulti={true}
            isClearable={true}
            value={trendsCategory?.value}
            onChange={(selectedOptions) => {
              if (!Array.isArray(selectedOptions)) {
                selectedOptions = [selectedOptions];
              }

              const selectedValues = selectedOptions.map((option) => ({
                label: option.label,
                value: String(option.value),
              }));

              handleTrendsCategoryOnChange(selectedValues);
            }}
          />
        </FormInput>
      </div>

      <Spacing size={1.125} />

      <SortHeading />

      <Spacing size={0.425} />

      <div className="flex flex-col gap-4">
        <FormInput>
          <SelectDropdown
            placeholder={'Default'}
            isSearchable={false}
            options={snapshotSort.options}
            multiselect={false}
            isClearable={false}
            id={'hasIndicator'}
            value={snapshotSort.options.find(
              (option) => option.value === selectedSnapshotSort,
            )}
            onChange={(e) => handleSnapshotSortOnChange(e, 'snapshotSort')}
          />
        </FormInput>
      </div>
    </>
  );
}

export function TrendsBrandsFilters() {
  return (
    <>
      <FiltersHeading />

      <Spacing size={1.125} />

      <div className="flex flex-col gap-4">
        <FormInput>
          <FormLabel>Country</FormLabel>
          <SelectDropdown
            placeholder={'Select a country'}
            isSearchable={true}
            options={[]}
            multiselect={false}
            isClearable={true}
            value={null}
          />
        </FormInput>

        <FormInput>
          <FormLabel>City</FormLabel>
          <SelectDropdown
            placeholder={'Select a city'}
            isSearchable={true}
            options={[]}
            multiselect={false}
            isClearable={true}
            value={null}
          />
        </FormInput>

        <FormInput>
          <FormLabel>Date</FormLabel>
          <SelectDropdown
            placeholder={'Select a date'}
            isSearchable={true}
            options={[]}
            multiselect={false}
            isClearable={true}
            value={null}
          />
        </FormInput>
      </div>
    </>
  );
}

const FiltersGhostLoading = () => {
  return (
    <Sidebar variant={'primary'} className="overflow-hidden">
      <Spacing size={1.125} />
      <FiltersHeading />
      <Spacing size={1.125} />
      <div className="mb-8 h-10 w-full animate-pulse bg-gray-300"></div>
      <div className="mb-8 h-10 w-full animate-pulse bg-gray-300"></div>
      <div className="mb-8 h-10 w-full animate-pulse bg-gray-300"></div>
      <Spacing size={1.125} />
      <SortHeading />
      <Spacing size={1.125} />
      <div className="mb-8 h-10 w-full animate-pulse bg-gray-300"></div>
      <div className="mb-8 h-10 w-full animate-pulse bg-gray-300"></div>
    </Sidebar>
  );
};

export const TrendsFilters = () => {
  const { activeTab, marketsLoading } = useTrendsContext();
  const filterOption = activeTab;

  const filterOptions = {
    insights: <TrendsInsightsFilters />,
    snapshots: <TrendsSnapshotsFilters />,
    'fashion-weeks': <TrendsFashionWeeksFilters />,
    brands: <TrendsBrandsFilters />,
    'in-progress': <TrendsSnapshotsFilters />,
    buzzwords: <TrendsSnapshotsFilters />,
  };

  if (marketsLoading) {
    return <FiltersGhostLoading />;
  }

  return (
    <Sidebar variant={'primary'} className="overflow-hidden">
      {filterOptions[filterOption]}
    </Sidebar>
  );
};
