export function Modal({ isOpen, onClose, children, fluid = true }) {
  if (isOpen) {
    return (
      <div className="fixed top-0 left-0 z-[990] flex h-full w-[100vw] items-center justify-center">
        <div
          className="fixed inset-0 z-[997] h-screen w-screen bg-black/50 backdrop-blur-sm"
          onClick={onClose}
        />
        <div
          className={`z-[999] max-w-[75vw] rounded-md bg-white ${
            fluid ? 'w-full' : ''
          }`}
        >
          {children}
        </div>
      </div>
    );
  }

  return null;
}
