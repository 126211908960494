import {
  Arrow,
  Content as RadixContent,
  Portal,
} from '@radix-ui/react-popover';
import { styled } from '@stitches/react';

import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from '@/animations';

const StyledContent = styled(RadixContent, {
  outline: 'none',
  backgroundColor: 'rgba(88, 88, 88, 0.81)',
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  zIndex: 999,
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
});

const StyledArrow = styled(Arrow, {
  fill: 'rgba(88, 88, 88, 0.81)',
  height: '22px',
  width: '28px',
});

export function PopoverContent({ children, ...props }) {
  return (
    <Portal>
      <StyledContent {...props}>
        {children}
        <StyledArrow />
      </StyledContent>
    </Portal>
  );
}
