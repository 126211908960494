import React, { useEffect, useRef, useState } from 'react';
import { ImSpinner8 } from 'react-icons/im';
import { useNavigate } from 'react-router-dom';
import { NetworkStatus, useQuery } from '@apollo/client';

import { GET_LOCATIONS } from '@/api/filters';
import { useReports } from '@/hooks/report';
import useOutsideClick from '@/hooks/useOutsideClick';

import {
  LocationAttention,
  LocationCaption,
  LocationInput,
  LocationList,
  LocationListItem,
  MarketExists,
} from './';

export const Location = () => {
  const menuRef = useRef();
  const {
    location,
    reports,
    exists: marketExists,
    marketLoading,
  } = useReports();
  const [searchQuery, setSearchQuery] = useState(location?.name || '');
  const [showList, setShowList] = useState(false);

  const navigate = useNavigate();

  const { data, networkStatus } = useQuery(GET_LOCATIONS, {
    variables: {
      name: searchQuery,
    },
  });

  useOutsideClick(menuRef, () => {
    setShowList(false);
  });

  useEffect(() => {
    if (location?.name) {
      setSearchQuery(location.name);
    }
  }, [location]);

  if (reports.length === 0) {
    navigate('/create-trend-report/select-a-plan');
    return null;
  }

  return (
    <div className="flex max-w-[573px] flex-col">
      <LocationCaption />
      <div className="relative mt-[18px] flex flex-col" ref={menuRef}>
        <LocationInput
          setShowList={setShowList}
          showList={showList}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          disabled={marketLoading}
        />
        {showList && (
          <LocationList>
            {networkStatus === NetworkStatus.setVariables && (
              <div className="ghost h-4 w-full" />
            )}
            {data?.locations?.map((location) => (
              <LocationListItem
                key={location.id}
                {...location}
                onCheck={() => setShowList(false)}
              />
            ))}
          </LocationList>
        )}

        {location?.id && !marketExists && !marketLoading && (
          <LocationAttention />
        )}
        {location?.id && marketExists && !marketLoading && <MarketExists />}
        {marketLoading && (
          <div className="mt-[24px] flex items-center justify-center gap-4">
            <ImSpinner8 className="animate-spin" />
            <span className="text-lg">Checking market location...</span>
          </div>
        )}
      </div>
    </div>
  );
};
