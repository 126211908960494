import { gql } from '@apollo/client';

export const SET_TREND_ITEM_GALLERY = gql`
  mutation SetTrendItemGallery(
    $id: ID!
    $gallery: [TrendItemGalleryInput]!
    $season: Int!
    $tiId: String!
    $itemType: String
  ) {
    setTrendItemGallery(
      id: $id
      gallery: $gallery
      season: $season
      tiId: $tiId
      itemType: $itemType
    ) {
      status
    }
  }
`;

export const SET_TREND_ITEM_COVER = gql`
  mutation SetTrendItemCover(
    $id: ID!
    $cover: TrendItemGalleryInput!
    $season: Int!
    $tiId: String!
    $itemType: String
  ) {
    setTrendItemCover(
      id: $id
      cover: $cover
      season: $season
      tiId: $tiId
      itemType: $itemType
    ) {
      status
    }
  }
`;

export const DISABLE_TREND_ITEM = gql`
  mutation DisableTrendItem($id: ID!) {
    disableTrendItem(id: $id) {
      status
    }
  }
`;

export const EDIT_SNAPSHOT_ITEM = gql`
  mutation EditSnapshotItem($id: ID!, $input: EditSnapshotItemInput!) {
    editSnapshotItem(id: $id, input: $input) {
      id
    }
  }
`;
