import * as Tabs from '@radix-ui/react-tabs';

import { TabHeading } from '@/components/TabHeading';
import { useStudioContext } from '@/contexts/StudioContext';
import { cn } from '@/helpers/utils';
import { StudioTabGenerate } from '@/pages/general/studio/components/StudioTabGenerate';
import { StudioTabGetInspired } from '@/pages/general/studio/components/StudioTabGetInspired';
import { StudioTabHistory } from '@/pages/general/studio/components/StudioTabHistory';

const tabs = [
  {
    label: 'Get Inspired',
    value: 'get-inspired',
    Component: StudioTabGetInspired,
  },
  {
    label: 'History',
    value: 'history',
    Component: StudioTabHistory,
  },
];

export function StudioTabs({ className }) {
  const { designTask, activeTab, setActiveTab } = useStudioContext();

  return (
    <div className={cn('h-full w-full', className)}>
      <Tabs.Root
        value={activeTab}
        onValueChange={(tab) => {
          setActiveTab(tab);
        }}
      >
        <Tabs.List aria-label="tabs" className={'flex gap-16'}>
          {tabs.map((tab, i) => (
            <TabHeading label={tab.label} value={tab.value} key={i} />
          ))}

          {designTask && (
            <TabHeading label={'Design Task'} value={'design-task'} />
          )}
        </Tabs.List>
        {tabs.map((tab, i) => (
          <Tabs.Content key={i} value={tab.value}>
            <tab.Component />
          </Tabs.Content>
        ))}
        {designTask && (
          <Tabs.Content value={'design-task'}>
            <StudioTabGenerate />
          </Tabs.Content>
        )}
      </Tabs.Root>
    </div>
  );
}
