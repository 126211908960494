import React from 'react';

import * as Authentication from '@/pages/authentication';

import { Private, Public } from './types';

export const authentication = [
  {
    path: 'login',
    element: (
      <Public>
        <Authentication.Login />
      </Public>
    ),
  },
  {
    path: 'sign-up',
    element: (
      <Public>
        <Authentication.SignUp />
      </Public>
    ),
  },
  {
    path: 'sign-up-onboarding',
    element: (
      <Public>
        <Authentication.SignUpOnboarding />
      </Public>
    ),
  },
  {
    path: 'forgot-password',
    element: (
      <Public>
        <Authentication.ForgotPassword />
      </Public>
    ),
  },
  {
    path: 'reset-password',
    element: (
      <Public>
        <Authentication.ResetPassword />
      </Public>
    ),
  },
  {
    path: 'logout',
    element: (
      <Private>
        <Authentication.LogOut />
      </Private>
    ),
  },
  {
    path: 'invitation',
    element: (
      <Public>
        <Authentication.AcceptInvitation />
      </Public>
    ),
  },
];
