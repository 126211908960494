import React from 'react';

import { PantoneCard } from '@/pages/general/myCollection/components/PantoneCard';

export const Colors = ({ data }) => {
  const filteredData = data.filter(
    (item) => item.__typename === 'CollectionToneItem',
  );

  return (
    <div className="flex basis-2/12 flex-col gap-[1.5rem]">
      {filteredData.map((item, i) => (
        <PantoneCard item={{ ...item.item, addedBy: item.addedBy }} key={i} />
      ))}
    </div>
  );
};
