import { ArrowDown } from './ArrowDown';
import { ArrowLeft } from './ArrowLeft';
import { ArrowRight } from './ArrowRight';
import { Check } from './Check';
import { CheckIcon } from './CheckIcon';
import { ChevronDown } from './ChevronDown';
import { Close } from './Close';
import { CloseX } from './CloseX';
import { Copy } from './Copy';
import { CrossIcon } from './CrossIcon';
import { EditIcon } from './Edit';
import { HelpIcon } from './HelpIcon';
import { LayoutOne } from './LayoutOne';
import { LayoutThree } from './LayoutThree';
import { LayoutTwo } from './LayoutTwo';
import { Magnify } from './Magnify';
import { PinIcon } from './PinIcon';
import { Plus } from './Plus';
import { RightChevron } from './RightChevron';
import { SmallArrow } from './SmallArrow';
import { Spinner } from './Spinner';
import { TrashIcon } from './TrashIcon';

const iconMap = {
  pin: PinIcon,
  'chevron-right': RightChevron,
  'chevron-down': ChevronDown,
  plus: Plus,
  magnify: Magnify,
  'arrow-down': ArrowDown,
  'layout-one': LayoutOne,
  'layout-two': LayoutTwo,
  'layout-three': LayoutThree,
  trash: TrashIcon,
  'arrow-right': ArrowRight,
  'arrow-left': ArrowLeft,
  help: HelpIcon,
  copy: Copy,
  'small-arrow': SmallArrow,
  close: Close,
  check: Check,
  spinner: Spinner,
  'close-x': CloseX,
  edit: EditIcon,
  cross: CrossIcon,
  'check-2': CheckIcon,
};

export function Icon({ name, ...props }) {
  if (!name) return null;

  if (!iconMap[name]) {
    throw new Error(`Invalid icon name: ${name}`);
  }

  const IconComponent = iconMap[name];

  return <IconComponent {...props} />;
}
