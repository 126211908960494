import { createContext, useContext } from 'react';

export const CardContext = createContext();

export function useCard() {
  const context = useContext(CardContext);

  if (!context) {
    throw new Error('useCard must be used within an CardContextProvider');
  }

  return context;
}
