import React from 'react';
import { Link } from 'react-router-dom';

import { cn } from '../helpers/utils';

// PageHeader (or PageHeading) parent component
export const PageHeader = ({ children }) => {
  return <div className="page-header">{children}</div>;
};

// PageHeaderTitle child component
export const PageHeaderTitle = ({ children, primary = false }) => {
  return (
    <h2
      className={cn(
        'text-[3rem]',
        'font-semibold',
        'leading-[5rem]',
        primary ? 'text-primary' : 'text-black',
      )}
    >
      {children}
    </h2>
  );
};

// PageHeaderLink child component
export const PageHeaderLink = ({ to, children }) => {
  return (
    <Link
      to={to}
      className="select-none text-[2rem] font-semibold leading-[3.5rem] text-primary"
    >
      {children}
    </Link>
  );
};

// PageHeaderDescription child component
export const PageHeaderDescription = ({ children }) => {
  return (
    <div className="flex max-w-[100%]">
      <p className="text-[1.5rem] leading-[1.75rem] text-black">{children}</p>
    </div>
  );
};
