import { createContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { useLazyQuery } from '@apollo/client';

import { CHECK_MARKET_WITH_LOCATION } from '@/api/filters';

export const CreateReportContext = createContext();

const steps = {
  0: {
    name: 'select-a-plan',
    title: 'Select a Plan',
    description: 'Select a plan to get started',
    requirements: ['plan'],
  },
  1: {
    name: 'location',
    title: 'Location',
    description: 'Select a location to proceed with the report',
    requirements: ['plan', 'location', 'exists'],
  },
  2: {
    name: 'audience',
    title: 'Audience',
    description: 'Select an audience to proceed with the report',
    requirements: ['plan', 'location', 'audience'],
  },
  3: {
    name: 'category',
    title: 'Categories',
    description: 'Select categories to proceed with the report',
    requirements: ['plan', 'location', 'audience', 'apparelGroups'],
  },
  4: {
    name: 'report',
    title: 'Report',
    description: 'Select a report to proceed with the report',
    requirements: [],
  },
  5: {
    name: 'finalize',
    title: 'Finalize',
    description: 'Finalize the report',
    requirements: [],
  },
};

const stepToPath = {
  'select-a-plan': 0,
  location: 1,
  audience: 2,
  category: 3,
  report: 4,
  finalize: 5,
};

export const CreateReportProvider = ({ children }) => {
  const [
    checkMarketWithLocation,
    { data: marketData, loading: marketLoading },
  ] = useLazyQuery(CHECK_MARKET_WITH_LOCATION);

  const [reports, setReports] = useState([]);
  const [activeReportId, setActiveReportId] = useState(null);
  const [currStepId, setCurrStepId] = useState(0);
  const [tempAudience, setTempAudience] = useState(null);

  const navigate = useNavigate();

  const location = useLocation();

  const reportData = reports.find((rep) => rep.id === activeReportId) || {
    location: null,
    plan: null,
    audience: [],
    apparelGroups: [],
    edit: false,
    empty: true,
  };

  useEffect(() => {
    const currPath = location.pathname.split('/')[2];

    if (currPath) {
      setCurrStepId(stepToPath[currPath]);
    } else {
      setCurrStepId(0);
    }
  }, [location]);

  function addNewReport() {
    const newReport = {
      id: uuid(),
      location: null,
      plan: null,
      audience: [],
      apparelGroups: [],
      edit: false,
      empty: true,
      exists: true,
    };

    setReports((prev) => [...prev, newReport]);
    setActiveReportId(newReport.id);

    window.location.pathname !== '/create-trend-report/select-a-plan' &&
      navigate('/create-trend-report/select-a-plan');
  }

  function setReportData(data) {
    const newReportData = {
      ...reportData,
      ...data,
    };

    const newReports = reports.map((rep) =>
      rep.id === activeReportId ? newReportData : rep,
    );

    setReports(newReports);
  }

  function checkNextStep(step = currStepId) {
    if (steps[step].requirements.length) {
      const check = steps[step].requirements.every((req) =>
        req === 'exists'
          ? reportData.exists === false
          : Array.isArray(reportData[req])
            ? reportData[req].length
            : reportData[req] !== null,
      );

      return check;
    }

    return true;
  }

  function checkPrevStep() {
    return steps[currStepId - 1];
  }

  function nextStep() {
    const check = checkNextStep();

    if (check && steps[currStepId + 1]) {
      setCurrStepId((prev) => prev + 1);
      navigate(`/create-trend-report/${steps[currStepId + 1].name}`);
    }
  }

  function prevStep() {
    const check = checkPrevStep();

    if (check) {
      setCurrStepId((prev) => prev - 1);
      navigate(`/create-trend-report/${steps[currStepId - 1].name}`);
    }
  }

  function selectLocation({ name, id }) {
    if (reportData.location && reportData.location.id === id) {
      return setReportData({
        location: null,
      });
    }

    if (marketData && marketData.checkMarketWithLocation.location === id) {
      return setReportData({
        location: {
          name,
          id,
        },
        exists: true,
      });
    }

    setReportData({
      location: {
        name,
        id,
      },
    });

    checkMarketWithLocation({
      variables: {
        location: id,
      },
      onCompleted: (data) => {
        if (
          data.checkMarketWithLocation &&
          data.checkMarketWithLocation.exists
        ) {
          setReportData({
            location: {
              name,
              id,
            },
            exists: true,
          });
        } else {
          setReportData({
            location: {
              name,
              id,
            },
            exists: false,
          });
        }
      },
    });
  }

  function selectPlan({ name, color, price, scope, slug, alias, markets }) {
    setReportData({
      plan: {
        name,
        color,
        price,
        scope,
        slug,
        alias,
        markets,
      },
    });

    if (alias) {
      navigate('/create-trend-report/report');
      return;
    }

    navigate('/create-trend-report/location');
  }

  function addEmptyAudience() {
    setReportData({
      audience: [
        ...(reportData?.audience || []),
        {
          name: `Audience ${reportData.audience.length + 1}`,
          id: uuid(),
          gender: '2',
          ageMin: 18,
          ageMax: 65,
          edit: true,
          empty: true,
        },
      ],
    });
  }

  function updateAudience(payload) {
    const { id } = payload;

    setReportData({
      audience: reportData?.audience.map((aud) => {
        if (aud.id === id) {
          return {
            ...aud,
            ...payload,
          };
        }

        return aud;
      }),
    });
  }

  function deleteAudience(id) {
    setReportData({
      audience: reportData.audience.filter((aud) => aud.id !== id),
    });
  }

  function selectApparelGroup(item) {
    if (reportData.apparelGroups.some((group) => group.id === item.id)) {
      return setReportData({
        apparelGroups: reportData.apparelGroups.filter(
          (group) => group.id !== item.id,
        ),
      });
    }

    return setReportData({
      apparelGroups: [...reportData.apparelGroups, item],
    });
  }

  function clearReportData() {
    setReportData({
      plan: null,
      location: null,
      audience: [],
      apparelGroups: [],
      report: null,
    });
  }

  return (
    <CreateReportContext.Provider
      value={{
        reports,
        activeReportId,
        ...reportData,
        setTempAudience,
        tempAudience,
        nextStep,
        prevStep,
        checkNextStep,
        checkPrevStep,
        selectLocation,
        selectPlan,
        addEmptyAudience,
        updateAudience,
        deleteAudience,
        selectApparelGroup,
        clearReportData,
        addNewReport,
        marketLoading,
      }}
    >
      {children}
    </CreateReportContext.Provider>
  );
};
