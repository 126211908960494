import { FilterDropdown } from '@/components/FilterDropdown';
import { useAppFilters } from '@/contexts/AppFiltersContext';
export const Season = () => {
  const { filters, handleSeasonOnChange, mode } = useAppFilters();
  const { season } = filters;

  return (
    <FilterDropdown
      label={season.label}
      placeholder={season.placeholder}
      options={season.options}
      value={season.value}
      isLoading={season.loading}
      onChange={handleSeasonOnChange}
      autoFocus
      isSearchable
      isClearable={mode !== 'trends'}
    />
  );
};
