import React from 'react';

export const ChevronDown = ({ color, ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="group-hover:stroke-white"
        d="M6 9L12 15L18 9"
        stroke={color || '#282928'}
        strokeWidth="2"
      />
    </svg>
  );
};
