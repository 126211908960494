import React from 'react';

export const HelpIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9998 29.3346C23.3636 29.3346 29.3332 23.3651 29.3332 16.0013C29.3332 8.63751 23.3636 2.66797 15.9998 2.66797C8.63604 2.66797 2.6665 8.63751 2.6665 16.0013C2.6665 23.3651 8.63604 29.3346 15.9998 29.3346Z"
      stroke="#282928"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1201 12.0008C12.4336 11.1096 13.0523 10.3582 13.8667 9.8796C14.6811 9.40097 15.6386 9.22601 16.5697 9.38571C17.5007 9.54541 18.3452 10.0295 18.9536 10.7521C19.5619 11.4748 19.8949 12.3895 19.8935 13.3341C19.8935 16.0008 15.8935 17.3341 15.8935 17.3341"
      stroke="#282928"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 22.668H16.0125"
      stroke="#282928"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
