import React, { useRef, useState } from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { keyframes, styled } from '@stitches/react';

import { AlertCircle, Remove, Star } from '@/assets/svg';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StyledContent = styled(DropdownMenuPrimitive.Content, {
  zIndex: 300,
  backgroundColor: 'rgba(255, 255, 255, 0.82)',
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

function Content({ children, ...props }) {
  return (
    <DropdownMenuPrimitive.Portal>
      <StyledContent {...props}>{children}</StyledContent>
    </DropdownMenuPrimitive.Portal>
  );
}

const StyledItem = styled(DropdownMenuPrimitive.Item, {
  fontSize: '1rem !important',
  all: 'unset',
  lineHeight: 1,
  display: 'flex',
  alignItems: 'center',
  height: 28,
  position: 'relative',
  paddingLeft: 25,
  userSelect: 'none',
  '&[data-disabled]': {
    pointerEvents: 'none',
  },
});

// Exports
export const DropdownMenu = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
export const DropdownMenuContent = Content;
export const DropdownMenuItem = StyledItem;

export const More = () => {
  const trigger = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [opinion, setOpinion] = useState(false);

  const closeDropdown = (e) => {
    return !trigger.current.contains(e.target) && setOpen(false);
  };

  return (
    <DropdownMenu modal={false} open={isOpen}>
      <DropdownMenuTrigger asChild>
        <button
          ref={trigger}
          className="inline-flex h-[2.1875rem] w-[2.1875rem] items-center justify-center rounded-full outline-none"
          onClick={() => setOpen((prev) => !prev)}
        >
          {/* <MoreVertical /> */}
        </button>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        sideOffset={5}
        align="end"
        onPointerDownOutside={(e) => closeDropdown(e)}
      >
        <div className="flex h-[7.5rem] w-[12rem] flex-col overflow-hidden">
          {!opinion ? (
            ['Report', 'Remove', 'More like this'].map((item, index) => (
              <p
                className={`color-[#333] flex flex-1 cursor-pointer items-center px-[0.625rem] ${
                  index !== 3 && 'border-[rgba(161, 161, 1)] border-b'
                }`}
                onClick={() => item === 'Report' && setOpinion(true)}
                key={index}
              >
                {item === 'Report' && (
                  <span className="flex w-[1.25rem]">
                    <AlertCircle />
                  </span>
                )}
                {item === 'Remove' && (
                  <span className="flex w-[1.25rem]">
                    <Remove />
                  </span>
                )}
                {item === 'More like this' && (
                  <span className="flex w-[1.25rem]">
                    <Star />
                  </span>
                )}
                {item === 'Sentence 4' && (
                  <span className="flex w-[1.25rem]">
                    <Remove />
                  </span>
                )}
                <span className="ml-[0.5rem]">{item}</span>
              </p>
            ))
          ) : (
            <div className="flex h-full w-full flex-col items-center justify-center gap-[0.75rem]">
              <p className="max-w-[5.9375rem] text-center text-[1rem]  font-semibold leading-[120.5%] ">
                Your opinion matters!
              </p>
              <p className="font-regular w-[8.375rem] text-center text-[0.75rem] leading-[120.5%] text-[#282928e6] ">
                Thanks for helping us improve our report.
              </p>
            </div>
          )}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
