import React from 'react';

export const Search = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2122 15.443H17.068L22.4739 20.8597L20.8597 22.4739L15.443 17.068V16.2122L15.1505 15.9089C13.9155 16.9705 12.3122 17.6097 10.568 17.6097C6.67887 17.6097 3.52637 14.4572 3.52637 10.568C3.52637 6.67887 6.67887 3.52637 10.568 3.52637C14.4572 3.52637 17.6097 6.67887 17.6097 10.568C17.6097 12.3122 16.9705 13.9155 15.9089 15.1505L16.2122 15.443ZM5.69303 10.568C5.69303 13.2655 7.87053 15.443 10.568 15.443C13.2655 15.443 15.443 13.2655 15.443 10.568C15.443 7.87053 13.2655 5.69303 10.568 5.69303C7.87053 5.69303 5.69303 7.87053 5.69303 10.568Z"
        fill="#282928"
      />
    </svg>
  );
};
