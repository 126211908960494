import { Footer } from './components/Footer';
// import { Backdrop } from './components/Backdrop';
import { HoverContent } from './components/HoverContent';
import { Image } from './components/Image';
import { Root } from './components/Root';
import { Tones } from './components/Tones';

export default {
  Root,
  Image,
  Tones,
  // Backdrop,
  Footer,
  HoverContent,
};
