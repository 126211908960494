import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Assuming you are using react-router

const useCurrentSection = (initialState = null) => {
  const [currentSection, setCurrentSection] = useState(initialState);
  const location = useLocation();

  useEffect(() => {
    const section = location.pathname.split('/')[1];
    setCurrentSection(section);
  }, [location]);

  useEffect(() => {
    const section = window.location.pathname.split('/')[1];
    setCurrentSection(section);
  }, []);

  return currentSection;
};

export default useCurrentSection;
