import { Bookmark } from 'lucide-react';

import { cn } from '@/helpers/utils';
import { SaveToCollection } from '@/layouts/common/saveToCollection';

import { useCard } from '../../context';

export function ToneCard({ hex, code, name }) {
  const isSelected = false;

  return (
    <div className="grid w-full grid-cols-12 space-x-2">
      <div
        className="col-span-1 mt-1.5 mr-2 h-[1.125rem] w-[1.125rem] shrink-0 rounded-full"
        style={{ backgroundColor: hex }}
      />
      <div className="col-span-9 mr-8 flex shrink-0 flex-col text-[0.875rem] capitalize leading-[1.5]">
        <h3 className="font-bold">{name.replace('-', ' ')}</h3>
        <h3>Pantone TCX {code}</h3>
      </div>

      {/* <SaveToCollection id={code} type="tone" />
       */}
      <SaveToCollection id={code} type="tone" asChild>
        <button
          className={cn(
            'col-span-1 aspect-square rounded px-1 py-0.5 place-self-center border transition-all duration-300',
            isSelected
              ? 'bg-primary text-white border-primary'
              : 'bg-[#1A1A1A]/5 text-black border-[#A1A1A1]/5 hover:bg-primary hover:text-white hover:border-primary',
          )}
        >
          <Bookmark size={18} />
        </button>
      </SaveToCollection>
    </div>
  );
}
/**
 * TODO: For the first item filters panel overlaps the tones section; z-index is not working correctly.
 */

export function ToneCards() {
  const { data } = useCard();
  const tones = data.tones || [];

  return (
    <div className="z-50 mr-2 w-[16rem] flex-col justify-center gap-4 rounded bg-[#F7F7F7] p-4">
      {tones.map((tone) => (
        <ToneCard {...tone} key={tone.hex} />
      ))}
    </div>
  );
}
