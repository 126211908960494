import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
  CREATE_MARKET_CHECKOUT_SESSION,
  CREATE_MARKET_PLAN_CHECKOUT_SESSION,
  CREATE_STUDIO_CHECKOUT_SESSION,
  FINALIZE_REPORT,
} from '@/api/market/mutations';
import {
  CHECK_PRORATED_PAYMENT,
  GET_AVAILABLE_APPARELS,
  GET_FINALIZE_MARKET_APPARELS,
  GET_MARKET_PLANS,
  GET_STUDIO_PLANS,
} from '@/api/market/queries';

const queryMap = {
  marketPlans: GET_MARKET_PLANS,
  availableApparels: GET_AVAILABLE_APPARELS,
  finalizeMarketApparels: GET_FINALIZE_MARKET_APPARELS,
  studioPlans: GET_STUDIO_PLANS,
  checkProratedPayment: CHECK_PRORATED_PAYMENT,
};

const mutationMap = {
  createMarketCheckoutSession: CREATE_MARKET_CHECKOUT_SESSION,
  finalizeReport: FINALIZE_REPORT,
  createStudioCheckoutSession: CREATE_STUDIO_CHECKOUT_SESSION,
  createMarketPlanCheckoutSession: CREATE_MARKET_PLAN_CHECKOUT_SESSION,
};

export const useMarketsQuery = (queryName, options) => {
  const query = queryMap[queryName];
  if (!query) {
    throw new Error(`Query ${queryName} not found`);
  }

  return useQuery(query, options);
};

export const useMarketsLazyQuery = (queryName, options) => {
  const query = queryMap[queryName];
  if (!query) {
    throw new Error(`Query ${queryName} not found`);
  }

  return useLazyQuery(query, options);
};

export const useMarketsMutation = (mutationName, options = {}) => {
  const mutation = mutationMap[mutationName];
  if (!mutation) {
    throw new Error(`Mutation ${mutationName} not found`);
  }

  return useMutation(mutation, { ...options });
};
