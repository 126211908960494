import React from 'react';

import { useReports } from '@/hooks/report';

import { MapPinIcon, UsersIcon } from '../../icons';

export const AudienceCaption = () => {
  const { plan, audience, location } = useReports();

  return (
    <>
      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <MapPinIcon />

          <span className="pt-[4px] font-planc text-base font-semibold">
            {location?.name}
          </span>
        </div>

        <div className="flex items-center gap-2">
          <UsersIcon />
          <span className="pt-[4px] text-[14px] leading-[16px] text-[#C29B77]">
            Apparels: {audience.length} / {plan?.scope?.audience}
          </span>
        </div>
      </div>
      <h5 className="mb-[6px] text-[32px] font-semibold leading-[37px]">
        Audience
      </h5>
      <p className="font-regular mb-[18px] text-[14px] leading-[173%]  text-[#626775]">
        Define and refine your target demographics, ensuring your trend analyses
        are sharply aligned with the audiences that propel your brand forward.
      </p>
    </>
  );
};
