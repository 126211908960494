import * as Moderation from '@/pages/moderation';

import { ModerationProtected } from './types';

export const moderation = [
  {
    path: 'select-organization',
    element: (
      <ModerationProtected>
        <Moderation.SelectOrganization />
      </ModerationProtected>
    ),
  },
];
