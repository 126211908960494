import React from 'react';

import * as Pages from '@/pages/general';

import { Private } from './types';

export const collections = [
  {
    path: 'collections',
    element: (
      <Private>
        <Pages.MyCollections />
      </Private>
    ),
  },
  {
    path: 'collections/:id/:name',
    element: (
      <Private>
        <Pages.MyCollection />
      </Private>
    ),
  },
];
