import { createContext, useContext } from 'react';

const ApiCacheContext = createContext();

export function ApiCacheProvider({ children }) {
  const apiCacheMap = new Map();

  function cache(key, value) {
    apiCacheMap.set(key, value);
  }

  function get(key) {
    return apiCacheMap.get(key);
  }

  function remove(key) {
    apiCacheMap.delete(key);
  }

  return (
    <ApiCacheContext.Provider value={{ apiCacheMap, cache, get, remove }}>
      {children}
    </ApiCacheContext.Provider>
  );
}

export function useApiCache() {
  const context = useContext(ApiCacheContext);
  if (!context) {
    throw new Error('useApiCache must be used within a ApiCacheProvider');
  }

  return context;
}
