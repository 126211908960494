import { useEffect, useMemo, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import * as Tabs from '@radix-ui/react-tabs';
import { debounce } from 'lodash';
import { Search, XIcon } from 'lucide-react';

import { FormInput } from '@/components/FormInput';
import { Input } from '@/components/Input/index';
import { SelectDropdown } from '@/components/SelectDropdown';
import { TabHeading } from '@/components/TabHeading';
import { useExploreContext } from '@/contexts/ExploreContext';
import { cn } from '@/helpers/utils';
import { FEATURE_FLAGS, useFeatureFlag } from '@/hooks/useFeatureFlag';
import { SocialMediaImages } from '@/pages/general';
import { FashionWeekItems } from '@/pages/general/explore/FashionItems';

const tabs = [
  // {
  //   label: 'All',
  //   value: 'all',
  //   Component: SocialMediaImages
  // },
  {
    label: 'Fashion Weeks',
    value: 'fashion-weeks',
    Component: FashionWeekItems,
  },
  {
    label: 'Social Media',
    value: 'social-media',
    Component: SocialMediaImages,
  },
  // {
  //   label: 'AI Designs',
  //   value: 'ai-designs',
  //   Component: SocialMediaImages
  // }
];

const unwantedFilterKeys = ['audience', 'search'];

function returnPillLabel(key, filter) {
  if (key === 'market') {
    return `${filter.value.label.toLowerCase()}`;
  }

  if (key === 'personPosition') {
    return `Person Position: ${filter.value.label.toLowerCase()}`;
  }

  if (key === 'personCount') {
    return `Person Count: ${filter.value.label.toLowerCase()}`;
  }

  return (
    filter.value.label ||
    filter.value.name ||
    filter.value[0].label
  ).toLowerCase();
}

function ExploreSort() {
  const { isEnabled: exploreAdvanced } = useFeatureFlag(
    FEATURE_FLAGS.EXPLORE_ADVANCED,
  );

  const { socialMediaSort, handleSocialMediaSortOnChange, activeTab } =
    useExploreContext();

  if (!exploreAdvanced || activeTab === 'fashion-weeks') {
    return null;
  }

  return Object.entries(socialMediaSort).map(([sortKey, sort], i) => (
    <FormInput className="right-0 max-w-[8rem]" key={i}>
      <SelectDropdown
        variant="sort"
        placeholder={sort?.placeholder}
        isSearchable={false}
        options={sort?.options}
        multiselect={false}
        isClearable={true}
        value={sort?.value}
        key={sort?.key}
        className="font-normal"
        onChange={(e) => handleSocialMediaSortOnChange(e, sortKey)}
      />
    </FormInput>
  ));
}

function ExploreFilterPills() {
  const {
    pills,
    setPills,
    activeTab,
    fashionWeekFilters,
    socialMediaFilters,
    handleFashionWeekFiltersOnChange,
    handleSocialMediaFiltersOnChange,
    shouldRefetch,
    triggerRefetch,
    fashionWeekClearAll,
    socialMediaClearAll,
  } = useExploreContext();

  useEffect(() => {
    if (shouldRefetch) {
      const newPills = Object.entries(
        activeTab === 'fashion-weeks' ? fashionWeekFilters : socialMediaFilters,
      ).filter(
        ([key, pill]) => pill.value && !unwantedFilterKeys.includes(key),
      );

      setPills(newPills);
    }
  }, [shouldRefetch, activeTab, fashionWeekFilters, socialMediaFilters]);

  function handleRemovePill(key, filter, attribute) {
    const updatePills = (updatedFilters = null) => {
      setPills((prevPills) => {
        if (!updatedFilters) {
          return prevPills.filter(([pillKey]) => pillKey !== key);
        } else {
          return prevPills.map(([pillKey, pill]) =>
            pillKey === key
              ? [pillKey, { ...pill, value: updatedFilters }]
              : [pillKey, pill],
          );
        }
      });
      triggerRefetch();
    };

    if (activeTab === 'fashion-weeks') {
      handleFashionWeekFiltersOnChange(null, key);
      updatePills();
    } else {
      if (key === 'category') {
        handleSocialMediaFiltersOnChange(null, key, true);
        handleSocialMediaFiltersOnChange(null, 'attribute.custom', true);
        updatePills();
      } else if (key === 'attribute.custom') {
        const newFilters = filter.value.filter(
          (attr) => attr.value !== attribute.value,
        );

        if (newFilters.length === 0) {
          handleSocialMediaFiltersOnChange(null, key, true);
          updatePills();
        } else {
          handleSocialMediaFiltersOnChange(newFilters, key, true);
          updatePills(newFilters);
        }
      } else {
        handleSocialMediaFiltersOnChange(null, key);
        updatePills();
      }
    }
  }

  useEffect(() => {
    setPills([]);
    if (activeTab === 'fashion-weeks') {
      fashionWeekClearAll();
    } else {
      socialMediaClearAll();
    }
    triggerRefetch();
  }, [activeTab]);

  return (
    <div className="flex w-full flex-wrap items-center gap-x-4 gap-y-2 pr-4">
      {pills.length === 0 ? (
        <div className="flex shrink-0 items-center gap-2 rounded-[0.25rem] border border-night/5 bg-[#a1a1a108] px-4 py-[0.625rem] text-night">
          <span
            className={cn('text-[0.875rem] font-medium capitalize leading-5')}
          >
            {activeTab === 'fashion-weeks'
              ? 'All Fashion Weeks'
              : 'All Markets'}
          </span>
        </div>
      ) : (
        pills.map(([key, filter]) =>
          key === 'attribute.custom' ? (
            filter?.value?.map((attribute) => (
              <div
                className="mb-2 flex shrink-0 items-center gap-2 rounded-[0.25rem] border-5 border-night/5 bg-[#a1a1a108] px-4 py-[0.625rem] text-night"
                key={attribute.name}
              >
                <span
                  className={cn(
                    'text-[0.875rem] font-medium capitalize leading-5',
                    key !== 'platform' && 'mr-4 ',
                  )}
                >
                  {attribute.name} : {attribute.value}
                </span>
                <button
                  onClick={() => handleRemovePill(key, filter, attribute)}
                >
                  <XIcon size={16} />
                </button>
              </div>
            ))
          ) : (
            <div
              className="mb-2 flex shrink-0 items-center gap-2 rounded-[0.25rem] border border-night/5 bg-[#a1a1a108] px-4 py-[0.625rem] text-night"
              key={key}
            >
              {filter?.value?.hex && (
                <div
                  className="h-4 w-4 rounded-full"
                  style={{
                    background: filter?.value?.hex,
                  }}
                />
              )}
              <span
                className={cn(
                  'text-[0.875rem] font-medium capitalize leading-5',
                  key !== 'platform' && 'mr-4 ',
                )}
              >
                {returnPillLabel(key, filter)}
              </span>
              {key !== 'platform' && (
                <button onClick={() => handleRemovePill(key, filter)}>
                  <XIcon size={16} />
                </button>
              )}
            </div>
          ),
        )
      )}
    </div>
  );
}

function ExploreSocialMediaSearch() {
  const { socialMediaFilters, handleSocialMediaFiltersOnChange } =
    useExploreContext();

  const search = socialMediaFilters?.search || {};
  const [inputValue, setInputValue] = useState(search.value || '');
  const [isTyping, setIsTyping] = useState(false);

  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        setIsTyping(false);
        if (value.trim()) {
          handleSocialMediaFiltersOnChange(value, 'search');
        }
      }, 1000),
    [handleSocialMediaFiltersOnChange],
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setIsTyping(true);
    debouncedSearch(value);
  };

  const handleClearSearch = () => {
    if (isTyping) setIsTyping(false);
    setInputValue('');
    handleSocialMediaFiltersOnChange('', 'search');
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    <div className="flex min-w-[20rem] items-center gap-2 rounded-md border-5 border-[#1A1A1A] bg-white px-4 py-2 outline-none">
      <Search size={24} strokeWidth={1.5} stroke="#a1a1a1" />
      <Input
        type="text"
        placeholder={search.placeholder}
        value={inputValue}
        onChange={handleInputChange}
        className="w-full text-[0.875rem] font-medium leading-6 outline-none"
      />
      {inputValue && (
        <XIcon
          size={18}
          strokeWidth={3.5}
          className="mt-0.5 cursor-pointer text-black"
          onClick={handleClearSearch}
        />
      )}
    </div>
  );
}

export function ExploreFashionWeekSearch() {
  const {
    fashionWeekFilters,
    handleFashionWeekFiltersOnChange,
    loadFashionWeekSearchOptions,
  } = useExploreContext();

  const search = fashionWeekFilters?.search;
  const [isTyping, setIsTyping] = useState(false);
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);

  const debouncedLoadOptions = debounce((inputValue, callback) => {
    setIsOptionsLoading(true);
    loadFashionWeekSearchOptions(inputValue, (options) => {
      callback(options);
      setIsOptionsLoading(false);
    });
  }, 500);

  const handleInputChange = (selectedOption) => {
    setIsTyping(true);
    handleFashionWeekFiltersOnChange(selectedOption, 'search');
  };

  useEffect(() => {
    return () => {
      debouncedLoadOptions.cancel();
      setIsTyping(false);
    };
  }, [debouncedLoadOptions]);

  return (
    <div className="min-w-[20rem]">
      <FormInput>
        <SelectDropdown
          placeholder={search?.placeholder}
          isSearchable={search?.isSearchable}
          options={search?.options}
          multiselect={false}
          isClearable={search?.isClearable}
          value={search?.value}
          key={'search'}
          onChange={handleInputChange}
          isAsync={true}
          isCreatable={true}
          formatCreateLabel={(inputValue) => `${inputValue}`}
          cacheOptions={false}
          loadOptions={!isTyping ? debouncedLoadOptions : undefined}
          defaultOptions
          classNames={{
            control: 'min-w-[20rem]',
            valueContainer: 'min-w-[12rem]',
          }}
          allowCreateWhileLoading={true}
          createOptionPosition={'first'}
          variant="search"
          noOptionsMessage={() => ''}
          loading={isOptionsLoading}
          icon={<Search size={20} strokeWidth={1.5} stroke="#a1a1a1" />}
          components={{
            Option: ({ selectProps, innerProps, innerRef, data, ...props }) => {
              return (
                <div
                  ref={innerRef}
                  {...innerProps}
                  className={props.getClassNames(props.type, props)}
                >
                  <Highlighter
                    highlightClassName="bg-transparent"
                    unhighlightClassName="font-semibold"
                    searchWords={[selectProps.inputValue]}
                    autoEscape={true}
                    textToHighlight={data.label}
                  />
                </div>
              );
            },
          }}
        />
      </FormInput>
    </div>
  );
}

export function ExploreTabs({ className }) {
  const { activeTab, setActiveTab, shouldRefetch } = useExploreContext();
  const contentRef = useRef(null);

  useEffect(() => {
    if (shouldRefetch && contentRef.current) {
      const scrollableContent =
        contentRef.current.querySelector('.overflow-y-auto');
      if (scrollableContent) {
        scrollableContent.scrollTo(0, 0);
      }
    }
  }, [shouldRefetch]);

  return (
    <div
      className={cn('h-full w-full overflow-y-auto relative', className)}
      ref={contentRef}
    >
      <Tabs.Root
        value={activeTab}
        onValueChange={(tab) => {
          setActiveTab(tab);
        }}
        className={'relative flex h-full flex-col overflow-y-auto'}
      >
        <div className="sticky top-0 z-[400] bg-white pt-6">
          <div className="flex items-center justify-between px-[3.75rem] pb-6">
            <Tabs.List
              aria-label="tabs"
              className={'flex w-full items-center justify-between'}
            >
              <div className={'flex gap-12'}>
                {tabs.map((tab, i) => (
                  <TabHeading label={tab.label} value={tab.value} key={i} />
                ))}
              </div>
            </Tabs.List>

            {activeTab === 'social-media' ? (
              <ExploreSocialMediaSearch />
            ) : (
              <ExploreFashionWeekSearch />
            )}
          </div>

          <div className="flex w-full items-start justify-between px-[3.75rem] pb-6">
            <ExploreFilterPills />
            <ExploreSort />
          </div>
        </div>

        {tabs.map((tab, i) => (
          <Tabs.Content
            key={i}
            value={tab.value}
            className={'h-full w-full px-[3.75rem]'}
          >
            <tab.Component />
          </Tabs.Content>
        ))}
      </Tabs.Root>
    </div>
  );
}
