import React from 'react';

export const CategoryItems = ({ items }) => {
  return (
    <div className="mt-[15px] w-full">
      {items.map((item, index) => (
        <span
          className="font-regular mr-[6px] inline-flex space-x-[4px] text-[12px] text-[#626775]"
          key={index}
        >
          <span className="capitalize">{item.label}</span>
          {index !== items.length - 1 && ','}
        </span>
      ))}
    </div>
  );
};
