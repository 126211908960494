export default {
  control: {
    backgroundColor: '#a1a1a105',
    border: '1px solid #a1a1a120',
    borderRadius: '0.75rem',
    fontSize: 16,
    fontFamily: 'satoshi',
  },

  '&multiLine': {
    highlighter: {
      padding: 9,
      border: 'none',
    },
    input: {
      padding: 9,
      borderRadius: '0.75rem',
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '1px inset transparent',
      borderRadius: 20,
    },
    input: {
      padding: 1,
      border: '1px inset',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      position: 'absolute',
      top: 'auto',
      bottom: '100%',
      marginBottom: '0.5rem',
      width: '20rem',
      fontSize: '0.9rem',
      borderRadius: '0.75rem',
      padding: '0.4rem 0.2rem',
    },
    item: {
      padding: '0.2rem 0.8rem',
      '&focused': {
        backgroundColor: '#a1a1a120',
        borderRadius: '0.5rem',
      },
    },
  },
};
