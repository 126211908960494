import { useEffect, useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

import { useUser } from '@/api/authentication/hook';
import { useExploreMutation } from '@/api/explore/hooks';
import { POST } from '@/api/explore/queries';
import { Button } from '@/components';
import { AttributePill, TonePill } from '@/components/Pills';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { HoverTooltip } from '@/components/ui/tooltip';
import { useAppContext } from '@/contexts/AppContext';
import { useExploreContext } from '@/contexts/ExploreContext';
import { cn } from '@/helpers/utils';
import { Icon } from '@/icons';
import { SaveToCollection } from '@/layouts/common/saveToCollection';
import { Comments } from '@/pages/general/imagePreview/components/Comments';

import { FashionWeekItem, FashionWeekItemHeader } from './FashionWeekItem';
import { FileUploadItem, FileUploadItemHeader } from './FileUploadItem';
import { SocialMediaItem, SocialMediaItemHeader } from './SocialMediaItem';
import { StudioItem, StudioItemHeader } from './StudioItem';

dayjs.extend(utc);
dayjs.extend(relativeTime);

const headersByType = {
  fashionWeekItem: FashionWeekItemHeader,
  socialMediaItem: SocialMediaItemHeader,
  studioItem: StudioItemHeader,
  fileUploadItem: FileUploadItemHeader,
};

const itemTypeToCollectionItemTypeMap = {
  studioItem: 'studioItem',
  fashionWeekItem: 'fashionItem',
  socialMediaItem: 'post',
};

function ModerationButton({
  mutationKey,
  valueKey,
  value,
  dataKey,
  onCompleteText,
  children,
  navigate,
  location,
  isDisabled,
}) {
  const { setHasBannedApparel } = useExploreContext();
  const [mutation, { data, loading }] = useExploreMutation(mutationKey);

  if (loading) {
    return (
      <div className="animate-spin">
        <Icon name="spinner" />
      </div>
    );
  }

  return (
    <Button
      className="rounded-lg bg-primary py-2 font-bold text-white"
      disabled={isDisabled || !!data}
      onClick={() => {
        mutation({
          variables: {
            [valueKey]: value,
          },
          onCompleted: (data) => {
            data?.excludePhoto?.status && setHasBannedApparel(true);
          },
        });
        if (location.key === 'default') {
          navigate('/explore', { replace: true });
        } else {
          navigate(-1);
        }
      }}
    >
      {data?.[dataKey]?.status ? onCompleteText : children}
    </Button>
  );
}

function HeaderButtons({ isDisabled }) {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const itemIndex = urlParams.get('item');
  const pathSegments = location.pathname.split('/');
  const itemType = pathSegments.includes('socialMediaItem')
    ? 'social-media'
    : 'fashion-weeks';
  const exploreItemId = urlParams.get('exploreItem');

  const { setActiveTab, fashionWeekClearAll, socialMediaClearAll } =
    useExploreContext();
  const { type, id } = useParams();
  const { moderationEnabled } = useUser();

  function handleClose() {
    if (location.key === 'default') {
      fashionWeekClearAll();
      socialMediaClearAll();
      setActiveTab(itemType);
      navigate('/explore');
    } else {
      if (itemIndex !== null && itemIndex !== 0) {
        setActiveTab(itemType);
        navigate('/explore');
      } else {
        navigate(-1);
      }
    }
  }

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-4">
        <SaveToCollection id={id} type={itemTypeToCollectionItemTypeMap[type]}>
          {(loading) =>
            loading ? (
              <Button className="rounded-lg bg-secondary py-2 font-bold text-white">
                Save
              </Button>
            ) : (
              <Button className="rounded-lg py-2 font-bold">Save</Button>
            )
          }
        </SaveToCollection>

        <HoverTooltip tooltipContent={<p>Edit With AI Tools</p>}>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="primary"
                outline
                className="rounded-lg py-2 font-bold"
              >
                Edit
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-[12rem] bg-white">
              <DropdownMenuItem
                className="cursor-pointer rounded p-2 text-sm font-semibold text-primary hover:bg-secondary/10"
                onClick={() => {
                  const studioParams = new URLSearchParams();
                  if (id) {
                    studioParams.append('itemId', id);
                  }
                  if (type) {
                    studioParams.append('itemType', type);
                  }
                  studioParams.append('generationType', 'apparel');
                  const queryString = studioParams.toString();
                  const studioUrl = `/studio${queryString ? `?${queryString}` : ''}`;
                  navigate(studioUrl);
                }}
              >
                Use as an apparel
              </DropdownMenuItem>
              <DropdownMenuItem
                className="cursor-pointer rounded p-2 text-sm font-semibold text-primary hover:bg-secondary/10"
                onClick={() => {
                  const studioParams = new URLSearchParams();
                  if (id) {
                    studioParams.append('itemId', id);
                  }
                  if (type) {
                    studioParams.append('itemType', type);
                  }
                  studioParams.append('generationType', 'pattern');
                  const queryString = studioParams.toString();
                  const studioUrl = `/studio${queryString ? `?${queryString}` : ''}`;
                  navigate(studioUrl);
                }}
              >
                Use as a pattern
              </DropdownMenuItem>
              <DropdownMenuItem
                className="cursor-pointer rounded p-2 text-sm font-semibold text-primary hover:bg-secondary/10"
                onClick={() => {
                  const studioParams = new URLSearchParams();
                  if (id) {
                    studioParams.append('itemId', id);
                  }
                  if (type) {
                    studioParams.append('itemType', type);
                  }
                  studioParams.append('generationType', 'edit');
                  const queryString = studioParams.toString();
                  const studioUrl = `/studio${queryString ? `?${queryString}` : ''}`;
                  navigate(studioUrl);
                }}
              >
                Edit with AI Tools
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </HoverTooltip>
        {moderationEnabled && (
          <ModerationButton
            dataKey="excludePhoto"
            mutationKey="banApparel"
            onCompleteText="Banned Apparel"
            value={exploreItemId || null}
            valueKey="apparelId"
            navigate={navigate}
            location={location}
            isDisabled={isDisabled}
          >
            Ban Apparel
          </ModerationButton>
        )}
      </div>

      <RxCross1 size={42} onClick={handleClose} className="cursor-pointer" />
    </div>
  );
}

export function Wrapper({ children, imagePreview = true }) {
  const [wrapperHeight, setWrapperHeight] = useState(0);

  useEffect(() => {
    function handleSizeChange() {
      const navbarEl = document.getElementById('main-nav');
      const bodyEl = document.querySelector('body');

      setWrapperHeight(bodyEl.offsetHeight - navbarEl.offsetHeight);
    }

    handleSizeChange();

    window.addEventListener('resize', handleSizeChange);

    return () => window.removeEventListener('resize', handleSizeChange);
  }, []);

  return (
    <div
      className={cn(
        'absolute bottom-0 right-0 left-0 z-[500] grid animate-fade-in overflow-hidden bg-white',
        imagePreview ? 'grid-cols-[67%_33%]' : 'grid-cols-[33%_67%]',
      )}
      style={{
        height: `${wrapperHeight}px`,
      }}
    >
      {children}
    </div>
  );
}

export function AttributesWrapper({ children, item, loading }) {
  const [isDisabled, setIsDisabled] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const exploreItemId = urlParams.get('exploreItem');

  const { showUpdatePopup } = useAppContext();

  useEffect(() => {
    if (item && exploreItemId) {
      const foundItem = item.apparels.find(
        (apparel) => apparel.id === exploreItemId,
      );

      setIsDisabled(foundItem?.disabled);
    }
  }, [item, exploreItemId]);

  const { type } = useParams();

  if (!type) return <></>;

  const Header = headersByType[type];

  if (loading) {
    return <div>loading</div>;
  }

  return (
    <div
      className="flex flex-col gap-8 overflow-y-auto bg-white py-8 pr-8 pl-16"
      style={{
        paddingTop: showUpdatePopup ? '4rem' : '2rem',
      }}
    >
      <HeaderButtons isDisabled={isDisabled} />

      <Header item={item} />

      <div className="flex h-full flex-col gap-4 overflow-auto">{children}</div>

      <Comments itemId={item.id} />
    </div>
  );
}

export function Attributes({ item, apparels, loading }) {
  const { type } = useParams();

  if (!item || !apparels) return <></>;

  return (
    <AttributesWrapper loading={loading} item={item}>
      {apparels?.map((apparel, i) => {
        return (
          <div className="flex flex-col gap-2" key={i}>
            <h1 className="text-lg font-bold capitalize">{apparel.apparel}</h1>
            <div className="flex flex-wrap items-center gap-4">
              {apparel?.attributes?.map(
                (attribute, index) =>
                  attribute.value !== 'N/A' && (
                    <AttributePill
                      key={index}
                      label={attribute.name}
                      value={attribute.value}
                    />
                  ),
              )}
              {apparel?.tones
                .filter((tone) => tone.weight > 0.15)
                .map((tone) => (
                  <TonePill key={tone.hex} type={type} {...tone} />
                ))}
            </div>
          </div>
        );
      })}
    </AttributesWrapper>
  );
}

export function AttributesFileUpload({ item, loading }) {
  if (!item) return <></>;

  return (
    <AttributesWrapper loading={loading} item={item}>
      <div className="flex flex-col gap-2">
        <h1 className="text-lg font-bold capitalize"></h1>
        <div className="flex flex-wrap items-center gap-4">
          Upload Time {dayjs(item.createdDate).format('DD MMM YYYY')}
        </div>
      </div>
    </AttributesWrapper>
  );
}

export function ImagePreview() {
  const [post, setPost] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const itemIndex = urlParams.get('item');
  const pathSegments = location.pathname.split('/');
  const itemType = pathSegments.includes('socialMediaItem')
    ? 'social-media'
    : 'fashion-weeks';

  const { setActiveTab, fashionWeekClearAll, socialMediaClearAll } =
    useExploreContext();
  const { type, id } = useParams();
  const { posts } = useExploreContext();

  const apolloClient = useApolloClient();

  // Handle escape key press to navigate back
  useEffect(() => {
    const closePage = (e) => {
      if (e.key === 'Escape') {
        if (location.key === 'default') {
          fashionWeekClearAll();
          socialMediaClearAll();
          setActiveTab(itemType);
          navigate('/explore');
        } else {
          if (itemIndex !== null && itemIndex !== 0) {
            setActiveTab(itemType);
            navigate('/explore');
          } else {
            navigate(-1);
          }
        }
      }
    };

    window.addEventListener('keydown', closePage);
    return () => window.removeEventListener('keydown', closePage);
  }, [navigate]);

  // Fetch post either from context or via Apollo client
  useEffect(() => {
    const fetchPost = async () => {
      const foundPost = posts?.find((p) => p.post.id === id)?.post;
      if (foundPost) {
        setPost(foundPost);
      } else {
        try {
          const { data } = await apolloClient.query({
            query: POST,
            variables: { id },
          });
          setPost(data.post);
        } catch (error) {
          console.error('Failed to fetch post:', error);
        }
      }
    };

    if (id && window.location.pathname.includes('socialMediaItem')) {
      fetchPost();
    }
  }, [id, posts, apolloClient]);

  if (!post && type === 'socialMediaItem') return <></>;

  switch (type) {
    case 'fashionWeekItem':
      return <FashionWeekItem id={id} />;
    case 'socialMediaItem':
      return (
        <SocialMediaItem
          post={post.children.length === 0 ? post : post.children}
          postId={post.id}
          isParent={post.children.length === 0}
        />
      );
    case 'studioItem':
      return <StudioItem id={id} />;
    case 'fileUploadItem':
      return <FileUploadItem id={id} />;
    default:
      return <div>Not Found</div>;
  }
}
