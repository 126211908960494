/* eslint react/jsx-indent:0 */

import { forwardRef, useEffect, useRef, useState } from 'react';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { CgSpinnerAlt } from 'react-icons/cg';
import { FiEdit3 } from 'react-icons/fi';
import { ImSpinner8 } from 'react-icons/im';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { CheckIcon } from '@radix-ui/react-icons';
import * as Select from '@radix-ui/react-select';
import { styled } from '@stitches/react';

import {
  useOrganizationMutation,
  useOrganizationQuery,
} from '@/api/organization/hook';
import { Button, Modal, Text } from '@/components';
import { useToast } from '@/hooks/useToast';

// const ArrowDownIcon = () => (
//   <svg
//     width="20"
//     height="20"
//     viewBox="0 0 20 20"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M5 7.5L10 12.5L15 7.5"
//       stroke="#282928"
//       strokeWidth="2"
//       strokeLinecap="square"
//     />
//   </svg>
// )

const UserRole = ({ value, onValueChange, disabled = false }) => (
  <Select.Root value={value} onValueChange={onValueChange} disabled={disabled}>
    <SelectTrigger aria-label="Type">
      <Select.Value placeholder="User Role" />
      {!disabled && (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 7.5L10 12.5L15 7.5"
            stroke="#282928"
            strokeWidth="2"
            strokeLinecap="square"
          />
        </svg>
      )}
    </SelectTrigger>
    <Select.Portal>
      <SelectContent>
        <SelectViewport>
          <Select.Group>
            <SelectItem value="ADMIN">Admin</SelectItem>
            <SelectItem value="MEMBER">Member</SelectItem>
          </Select.Group>
        </SelectViewport>
      </SelectContent>
    </Select.Portal>
  </Select.Root>
);

const SelectTrigger = styled(Select.SelectTrigger, {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '0.5rem',
  flexShrink: 0,
  fontSize: '1rem',
  lineHeight: '1.125rem',
  weight: '600',
  color: '#282928',
  zIndex: 999,
});

const SelectContent = styled(Select.Content, {
  overflow: 'hidden',
  backgroundColor: 'white',
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
});

const SelectViewport = styled(Select.Viewport, {
  padding: 5,
});

// eslint-disable-next-line react/display-name
const SelectItem = forwardRef(({ children, ...props }, forwardedRef) => {
  return (
    <StyledItem {...props} ref={forwardedRef}>
      <Select.ItemText>{children}</Select.ItemText>
      <StyledItemIndicator>
        <CheckIcon />
      </StyledItemIndicator>
    </StyledItem>
  );
});

const StyledItem = styled(Select.Item, {
  fontSize: 16,
  lineHeight: 1,
  color: '#282928',
  display: 'flex',
  alignItems: 'center',
  height: '35px',
  padding: '0 35px 0 25px',
  position: 'relative',
  userSelect: 'none',
  '&[data-highlighted]': {
    outline: 'none',
    backgroundColor: '#C29B77',
    color: '#fff',
  },
});

const StyledItemIndicator = styled(Select.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

function Filler() {
  return Array.from({ length: 5 }).map((_, index) => (
    <>
      <div className="col-span-6 h-2 animate-pulse bg-slate-300"></div>
      <div className="col-span-4 h-2 animate-pulse bg-slate-300"></div>
      <div className="col-span-2 h-2 animate-pulse bg-slate-300"></div>
    </>
  ));
}

const statusToColor = {
  PENDING: 'text-yellow-500',
  ACCEPTED: 'text-green',
  REJECTED: 'text-red-600',
};

function MemberRow(data) {
  const status =
    data.__typename === 'User' ? 'ACCEPTED' : data.status || 'PENDING';
  const isInvitation = data.__typename === 'Invitation';

  return (
    <>
      <span className="col-span-6 truncate text-lg !leading-5">
        {data.fullName}
        <span className="ml-2 text-gray-400">{`(${data.email})`}</span>
      </span>
      <div className="col-span-2">
        <UserRole
          value={data.role}
          onValueChange={() => {}}
          disabled={isInvitation}
        />
      </div>
      <span className={`col-span-2 capitalize ${statusToColor[status]}`}>
        {status.toLowerCase()}
      </span>
      {!isInvitation && (
        <Button className="col-span-2" size="sm" outline>
          <span className="text-lg !leading-5">Remove</span>
        </Button>
      )}
    </>
  );
}

function OrganizationName({ name }) {
  const toast = useToast();

  const [isEditing, setIsEditing] = useState(false);
  const [parentRef] = useAutoAnimate();
  const inputRef = useRef(null);

  const [mutate, { loading }] = useOrganizationMutation('updateName', {
    refetchQueries: ['Organization'],
    onCompleted: () => {
      setIsEditing(false);
      toast.createToast({
        message: 'Organization name updated successfully',
        type: 'success',
        position: 'top-right',
        showCloseButton: true,
      });
    },
  });

  return (
    <div ref={parentRef} className="flex items-center gap-4">
      {isEditing ? (
        <input
          className="border-b border-black p-1 text-4xl font-semibold focus:outline-none"
          placeholder={name}
          ref={inputRef}
          disabled={loading}
        />
      ) : (
        <h5 className="truncate text-4xl font-semibold">{name}</h5>
      )}
      <div className="flex items-center gap-2">
        <div className="cursor-pointer text-3xl">
          {isEditing ? (
            <AiOutlineCheck
              onClick={() => {
                if (!inputRef.current.value) return;
                mutate({
                  variables: {
                    input: {
                      name: inputRef.current.value,
                    },
                  },
                });
              }}
            />
          ) : (
            !loading && <FiEdit3 onClick={() => setIsEditing(true)} />
          )}
        </div>
        {isEditing && !loading && (
          <AiOutlineClose
            onClick={() => setIsEditing(false)}
            className="cursor-pointer text-3xl"
          />
        )}

        {loading && (
          <div className="animate-spin">
            <CgSpinnerAlt className="text-3xl" />
          </div>
        )}
      </div>
    </div>
  );
}

// function Checkbox ({ checked, onChange }) {
//   return (
//     <div
//       className={`flex h-6 w-6 cursor-pointer items-center justify-center rounded-md border border-primary ${
//         checked ? 'border-black bg-primary' : ''
//       }`}
//       onClick={onChange}
//     >
//       {checked && <AiOutlineCheck className="text-white" />}
//     </div>
//   )
// }

// function RolesModal({ roles, onClose, isOpen }) {
//   const toast = useToast();
//   const [addNewRole, setAddNewRole] = useState(false);
//   const [newRolePayload, setNewRolePayload] = useState({
//     editOrganization: false,
//     editRoles: false,
//     giveRoles: false,
//     removeRoles: false,
//     inviteMembers: false,
//     removeMembers: false,
//     createMarket: false,
//     name: 'New Role',
//   });

//   const [rolesPayload, setRolesPayload] = useState({});

//   useEffect(() => {
//     const initialRolesPayload = roles
//       ? roles.reduce((acc, role) => {
//           acc[role.name] = { ...role.permissions, label: role.label };

//           return acc;
//         }, {})
//       : {};

//     setRolesPayload(initialRolesPayload);
//   }, [roles]);

//   function handleRoleChange(role, permission) {
//     console.log({ role, permission });

//     setRolesPayload((prev) => ({
//       ...prev,
//       [role]: {
//         ...prev[role],
//         [permission]: !prev[role][permission],
//         isUpdated: true,
//       },
//     }));
//   }

//   function handleNewRoleChange(permission) {
//     setNewRolePayload((prev) => ({
//       ...prev,
//       [permission]: !prev[permission],
//     }));
//   }

//   function handleAddRole() {
//     const name = newRolePayload.name.toUpperCase().replace(/\s/g, '_');

//     if (rolesPayload[name]) {
//       toast.createToast({
//         message: 'Role already exists',
//         type: 'error',
//         position: 'top-right',
//         showCloseButton: true,
//       });
//       return;
//     }

//     setRolesPayload((prev) => ({
//       ...prev,
//       [name]: {
//         editOrganization: newRolePayload.editOrganization,
//         editRoles: newRolePayload.editRoles,
//         giveRoles: newRolePayload.giveRoles,
//         removeRoles: newRolePayload.removeRoles,
//         inviteMembers: newRolePayload.inviteMembers,
//         removeMembers: newRolePayload.removeMembers,
//         createMarket: newRolePayload.createMarket,
//         label: newRolePayload.name,
//         name,
//         isNew: true,
//       },
//     }));

//     setNewRolePayload({
//       editOrganization: false,
//       editRoles: false,
//       giveRoles: false,
//       removeRoles: false,
//       inviteMembers: false,
//       removeMembers: false,
//       createMarket: false,
//       name: 'New Role',
//     });

//     setAddNewRole(false);
//   }

//   function handleRemoveRole(role) {
//     if (role === 'ADMIN' || Object.keys(rolesPayload).length === 1) {
//       toast.createToast({
//         message: 'Cannot remove this role',
//         type: 'error',
//         position: 'top-right',
//         showCloseButton: true,
//       });
//       return;
//     }

//     setRolesPayload((prev) => ({
//       ...prev,
//       [role]: {
//         ...prev[role],
//         isDeleted: true,
//       },
//     }));
//   }

//   return (
//     <Modal isOpen={isOpen} onClose={onClose}>
//       <div className="px-8 py-4">
//         <div className="flex flex-col">
//           <div className="flex items-center justify-between">
//             <Text weight="bold" size="2xl">
//               Organization Roles
//             </Text>

//             <Button size="sm" onClick={() => setAddNewRole((prev) => !prev)}>
//               {addNewRole ? 'Cancel' : 'Add New Role'}
//             </Button>
//           </div>

//           <Text>
//             Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim illo
//             et
//           </Text>

//           <div className="flex flex-col mt-8 gap-y-2">
//             <div className="grid grid-cols-12 gap-y-4  col-span-12 border-b-charcoal border-b pb-2 mb-2">
//               <Text className="col-span-4" weight="semibold">
//                 Roles
//               </Text>
//               <Text weight="semibold">Edit Organization</Text>
//               <Text weight="semibold">Edit Roles</Text>
//               <Text weight="semibold">Give Roles</Text>
//               <Text weight="semibold">Remove Roles</Text>
//               <Text weight="semibold">Invite Members</Text>
//               <Text weight="semibold">Remove Members</Text>
//               <Text weight="semibold">Create Market</Text>
//             </div>
//             {addNewRole && (
//               <div className="grid grid-cols-12 gap-y-4  col-span-12 border-charcoal border gap-x-2 py-2 items-center">
//                 <div className="col-span-4 px-2">
//                   <input
//                     placeholder="Enter Role Name"
//                     value={newRolePayload.name}
//                     onChange={(e) =>
//                       setNewRolePayload((prev) => {
//                         return {
//                           ...prev,
//                           name: e.target.value,
//                         };
//                       })
//                     }
//                   />
//                 </div>
//                 <Checkbox
//                   checked={newRolePayload.editOrganization}
//                   onChange={() => handleNewRoleChange('editOrganization')}
//                 />
//                 <Checkbox
//                   checked={newRolePayload.editRoles}
//                   onChange={() => handleNewRoleChange('editRoles')}
//                 />
//                 <Checkbox
//                   checked={newRolePayload.giveRoles}
//                   onChange={() => handleNewRoleChange('giveRoles')}
//                 />
//                 <Checkbox
//                   checked={newRolePayload.removeRoles}
//                   onChange={() => handleNewRoleChange('removeRoles')}
//                 />
//                 <Checkbox
//                   checked={newRolePayload.inviteMembers}
//                   onChange={() => handleNewRoleChange('inviteMembers')}
//                 />
//                 <Checkbox
//                   checked={newRolePayload.removeMembers}
//                   onChange={() => handleNewRoleChange('removeMembers')}
//                 />
//                 <Checkbox
//                   checked={newRolePayload.createMarket}
//                   onChange={() => handleNewRoleChange('createMarket')}
//                 />

//                 <div>
//                   <Button onClick={handleAddRole} size="sm">
//                     Add Role
//                   </Button>
//                 </div>
//               </div>
//             )}
//             {roles &&
//               Object.keys(rolesPayload).length > 0 &&
//               Object.keys(rolesPayload).map((roleKey) => {
//                 const role = rolesPayload[roleKey];

//                 return (
//                   <div
//                     className={`grid grid-cols-12 gap-y-4 gap-x-2 col-span-12 py-2 items-center ${
//                       role.isNew ? 'bg-green/30' : ''
//                     }`}
//                   >
//                     <Text className="col-span-4 px-2">{role.label}</Text>
//                     <Checkbox
//                       checked={role.editOrganization}
//                       onChange={() =>
//                         handleRoleChange(roleKey, 'editOrganization')
//                       }
//                     />
//                     <Checkbox
//                       onChange={() => handleRoleChange(roleKey, 'editRoles')}
//                       checked={role.editRoles}
//                     />
//                     <Checkbox
//                       checked={role.giveRoles}
//                       onChange={() => handleRoleChange(roleKey, 'giveRoles')}
//                     />
//                     <Checkbox
//                       checked={role.removeRoles}
//                       onChange={() => handleRoleChange(roleKey, 'removeRoles')}
//                     />
//                     <Checkbox
//                       checked={role.inviteMembers}
//                       onChange={() =>
//                         handleRoleChange(roleKey, 'inviteMembers')
//                       }
//                     />
//                     <Checkbox
//                       checked={role.removeMembers}
//                       onChange={() =>
//                         handleRoleChange(roleKey, 'removeMembers')
//                       }
//                     />
//                     <Checkbox
//                       checked={role.createMarket}
//                       onChange={() => handleRoleChange(roleKey, 'createMarket')}
//                     />
//                     <div>
//                       <Button
//                         onClick={() => handleRemoveRole(roleKey)}
//                         size="sm"
//                       >
//                         Remove Role
//                       </Button>
//                     </div>
//                   </div>
//                 );
//               })}
//           </div>

//           <div className="self-end flex items-center gap-2 mt-4">
//             <Button variant="secondary" outline onClick={onClose} size="sm">
//               Cancel
//             </Button>

//             <Button variant="primary" onClick={onClose} size="sm">
//               Save
//             </Button>
//           </div>
//         </div>
//       </div>
//       {/* <div className="flex flex-col gap-2">
//           <div className="flex items-center justify-between">
//             <h2 className="text-2xl font-semibold">Roles</h2>

//             <AiOutlineClose
//               className="cursor-pointer text-2xl"
//               onClick={() => setShowRolesModal(false)}
//             />
//           </div>

//           <span
//             className="cursor-pointer font-semibold underline"
//             onClick={() => setAddNewRole((prev) => !prev)}
//           >
//             Add New Role
//           </span>

//           <table className="table-auto">
//             <thead className="border-b border-black">
//               <tr>
//                 <th className="px-4 text-start">Role</th>
//                 <th className="text-start">Edit Organization</th>
//                 <th className="text-start">Edit Roles</th>
//                 <th className="text-start">Give Roles</th>
//                 <th className="text-start">Remove Roles</th>
//                 <th className="text-start">Invite Members</th>
//                 <th className="text-start">Remove Members</th>
//                 <th className="text-start">Create Market</th>
//                 <th />
//               </tr>
//             </thead>
//             <tbody className="overflow-hidden font-semibold" ref={tableRef}>
//               {addNewRole && (
//                 <tr className="bg-primaryHover text-black">
//                   <td className="py-2 px-4">
//                     <input type="text" />
//                   </td>
//                   <td className="py-2 px-4">
//                     <input type="checkbox" />
//                   </td>
//                   <td className="py-2 px-4">
//                     <input type="checkbox" />
//                   </td>
//                   <td className="py-2 px-4">
//                     <input type="checkbox" />
//                   </td>
//                   <td className="py-2 px-4">
//                     <input type="checkbox" />
//                   </td>
//                   <td className="py-2 px-4">
//                     <input type="checkbox" />
//                   </td>
//                   <td className="py-2 px-4">
//                     <input type="checkbox" />
//                   </td>
//                   <td className="py-2 px-4">
//                     <input type="checkbox" />
//                   </td>
//                   <td className="py-2 px-4">
//                     <input type="checkbox" />
//                   </td>
//                 </tr>
//               )}
//               {data?.organization?.roles.map((role, idx) => (
//                 <tr
//                   key={role.name}
//                   className={idx % 2 !== 0 && 'bg-primaryHover text-white'}
//                 >
//                   <td className="py-2 px-4">{role.label}</td>
//                   <td className="py-2 px-4">
//                     <input
//                       type="checkbox"
//                       checked={role.permissions.editOrganization}
//                     />
//                   </td>
//                   <td className="py-2 px-4">
//                     <input
//                       type="checkbox"
//                       checked={role.permissions.editRoles}
//                     />
//                   </td>
//                   <td className="py-2 px-4">
//                     <input
//                       type="checkbox"
//                       checked={role.permissions.giveRoles}
//                     />
//                   </td>
//                   <td className="py-2 px-4">
//                     <input
//                       type="checkbox"
//                       checked={role.permissions.removeRoles}
//                     />
//                   </td>
//                   <td className="py-2 px-4">
//                     <input
//                       type="checkbox"
//                       checked={role.permissions.inviteMembers}
//                     />
//                   </td>
//                   <td className="py-2 px-4">
//                     <input
//                       type="checkbox"
//                       checked={role.permissions.removeMembers}
//                     />
//                   </td>
//                   <td className="py-2 px-4">
//                     <input
//                       type="checkbox"
//                       checked={role.permissions.editMarket}
//                     />
//                   </td>
//                   <td className="py-2 px-4">
//                     <input
//                       type="checkbox"
//                       checked={role.permissions.createMarket}
//                     />
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div> */}
//     </Modal>
//   );
// }

function SendInvitationsModal({ isOpen, onClose }) {
  const { data } = useOrganizationQuery('organization');

  const remainingInviteLimit =
    data?.organization?.inviteLimit - data?.organization?.members?.length || 0;

  const [invitations, setInvitations] = useState([
    { email: '', role: 'MEMBER' },
  ]);
  const [pasted, setPasted] = useState(false);
  const [invalidEmailIndexes, setInvalidEmailIndexes] = useState([]);

  useEffect(() => {
    if (invitations.length) return;

    if (remainingInviteLimit > 0) {
      setInvitations([{ email: '', role: 'MEMBER' }]);
    }
  }, [data]);

  const toast = useToast();

  const [sendInvitations, { loading, data: invitationResponse }] =
    useOrganizationMutation('sendInvitations', {
      refetchQueries: ['Organization'],
      onCompleted: (data) => {
        toast.createToast({
          message: 'Invitations sent successfully',
          type: 'success',
          position: 'top-right',
          showCloseButton: true,
        });

        if (!data.sendInvitations.failedEmails.length > 0) {
          onClose();
        }
      },
      onError: () => {
        toast.createToast({
          message: 'Something went wrong',
          type: 'error',
          position: 'top-right',
          showCloseButton: true,
        });
      },
    });

  useEffect(() => {
    if (remainingInviteLimit === 0 && data?.organization?.members) {
      setInvitations([]);
    }
  }, [remainingInviteLimit]);

  function handleOnChange(index, key, value) {
    if (key === 'email' && pasted) {
      const emails = [...value.split(' '), value.split(',')];

      if (!emails.length > 1) {
        setPasted(false);
        return;
      }

      if (emails.length - 1 > remainingInviteLimit) {
        toast.createToast({
          message: 'Invite limit reached',
          type: 'error',
          position: 'top-right',
          showCloseButton: true,
        });
        setPasted(false);
        return;
      }

      const newInvitations = [];
      emails.forEach((email) => {
        if (!email) return;
        if (Array.isArray(email)) return;

        if (newInvitations.find((inv) => inv.email === email)) return;

        if (
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            email,
          )
        ) {
          return;
        }

        newInvitations.push({ email, role: 'MEMBER' });
      });

      setInvitations((prev) => {
        const temp = [...prev];
        const lastItem = temp[temp.length - 1];
        temp.pop();

        if (remainingInviteLimit - newInvitations.length === 0) {
          return [...temp, ...newInvitations];
        }

        return [...temp, ...newInvitations, lastItem];
      });
      setPasted(false);
      return;
    }

    if (key === 'email' && !pasted) {
      setInvitations((prev) => {
        const newInvitations = [...prev];

        newInvitations[index].email = value;

        return newInvitations;
      });
      return;
    }

    if (key === 'role') {
      setInvitations((prev) => {
        const newInvitations = [...prev];
        newInvitations[index].role = value;

        return newInvitations;
      });
    }
  }

  function handleRemoveInvitation(index) {
    if (invitations.length === 1) return;

    setInvitations((prev) => {
      const newInvitations = [...prev];
      newInvitations.splice(index, 1);

      return newInvitations;
    });
  }

  function handleAddEmail() {
    if (invitations.length >= 10000) return;
    if (invitations[invitations.length - 1].email === '') return;

    if (remainingInviteLimit === invitations.length) {
      toast.createToast({
        message: 'Invite limit reached',
        type: 'error',
        position: 'top-right',
        showCloseButton: true,
      });
      return;
    }

    setInvitations((prev) => [...prev, { email: '', role: 'MEMBER' }]);
  }

  function handleOnBlur(value, index) {
    const validEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        value,
      );

    if (!validEmail && !invalidEmailIndexes.includes(index)) {
      setInvalidEmailIndexes((prev) => [...prev, index]);
      return;
    }

    if (validEmail && invalidEmailIndexes.includes(index)) {
      setInvalidEmailIndexes((prev) => prev.filter((i) => i !== index));
    }
  }

  function handleSendInvitations() {
    if (invalidEmailIndexes.length > 0) {
      toast.createToast({
        message: 'Please enter valid email addresses',
        type: 'error',
        position: 'top-right',
        showCloseButton: true,
      });
      return;
    }

    const validInvitations = invitations.filter(
      (invitation) => invitation.email && invitation.role,
    );

    if (validInvitations.length === 0) {
      toast.createToast({
        message: 'Please enter valid email addresses',
        type: 'error',
        position: 'top-right',
        showCloseButton: true,
      });
      return;
    }

    sendInvitations({
      variables: {
        invitations: validInvitations,
      },
    });
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} fluid={false}>
      <div className="flex max-h-[80vh] flex-col gap-2 overflow-y-auto px-8 py-4 xl:max-w-2xl">
        <Text weight="bold" size="2xl">
          Invite Co-Workers
        </Text>

        {loading ? (
          <div className="flex items-center justify-between gap-4">
            <ImSpinner8 className="animate-spin text-xl" />
            <Text weight="semibold" size="lg">
              Sending invitations, please wait and don’t close the window.
            </Text>
          </div>
        ) : invitationResponse ? (
          <>
            {invitationResponse.sendInvitations.failedEmails.length > 0 && (
              <>
                <Text weight="semibold" size="lg">
                  Some of the invitations failed to send
                </Text>

                <div className="mb-2 grid grid-cols-2 gap-4 border-b border-b-charcoal">
                  <Text weight="semibold" size="lg">
                    Email
                  </Text>
                  <Text weight="semibold" size="lg">
                    Reason
                  </Text>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  {invitationResponse.sendInvitations.failedEmails.map(
                    (email) => (
                      <>
                        <Text>{email.email}</Text>
                        <Text className="capitalize">
                          {email.reason.split('_').join(' ').toLowerCase()}
                        </Text>
                      </>
                    ),
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <Text>
              To invite a co-worker, enter their email address and select a
              role. If they don’t have an account, we’ll send them an invite to
              create one.
              <br />
              <br />
              If you want to invite more than one co-worker, separate their
              email addresses with a comma or space. And paste them in the email
              field.
              <br />
              <br />
              {remainingInviteLimit > 0 && (
                <span className="text-green">
                  {remainingInviteLimit}{' '}
                  {remainingInviteLimit === 1 ? 'invite' : 'invites'} remaining
                </span>
              )}
            </Text>

            {invitations.map((invitation, index) => (
              <div className="flex items-center gap-4" key={`email_${index}`}>
                <input
                  className={`flex-1 p-2 ${
                    invalidEmailIndexes.includes(index)
                      ? 'border border-red-600'
                      : 'border border-green'
                  }`}
                  placeholder="Enter Email"
                  value={invitation.email}
                  onChange={(e) =>
                    handleOnChange(index, 'email', e.target.value)
                  }
                  onPaste={(e) => setPasted(true)}
                  onBlur={() => handleOnBlur(invitation.email, index)}
                />
                <UserRole
                  value={invitation.role}
                  onValueChange={(value) =>
                    handleOnChange(index, 'role', value)
                  }
                />
                {invitations.length > 1 && (
                  <Button
                    size="sm"
                    variant="secondary"
                    outline
                    onClick={() => handleRemoveInvitation(index)}
                  >
                    Remove
                  </Button>
                )}
              </div>
            ))}

            <Button size="sm" className="my-4" outline onClick={handleAddEmail}>
              Add More
            </Button>

            <div className="my-2 flex items-center gap-2 self-end">
              <Button variant="secondary" outline onClick={onClose} size="sm">
                Cancel
              </Button>

              <Button
                variant="primary"
                onClick={handleSendInvitations}
                size="sm"
              >
                Send
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

function LimitExceededModal({ isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} fluid={false}>
      <div className="flex flex-col gap-2 px-8 py-4 xl:max-w-2xl">
        <Text weight="bold" size="2xl">
          Limit Exceeded
        </Text>
        <div className="flex items-center justify-between"></div>

        <Text>
          Member limit for this organization has reached its maximum. Please
          contact{' '}
          <a
            className="font-semibold text-primary"
            href="mailto:customer-support@tfashion.ai"
          >
            support@tfashion.ai
          </a>{' '}
          to increase the limit.
        </Text>

        <div className="my-2 flex items-center gap-2 self-end">
          <Button variant="secondary" outline onClick={onClose} size="sm">
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export const Team = () => {
  const { data, loading } = useOrganizationQuery('organization');

  // const [showRolesModal, setShowRolesModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showLimitModal, setShowLimitModal] = useState(false);

  const exceedsLimit =
    data?.organization.members.length >= data?.organization.inviteLimit;

  const renderData = [
    ...(data?.organization.members || []),
    ...(data?.organization.invitations.filter(
      (invitation) => invitation.status !== 'ACCEPTED',
    ) || []),
  ];

  // const [tableRef] = useAutoAnimate();

  return (
    <>
      {/* <RolesModal
        roles={data?.organization.roles}
        isOpen={showRolesModal}
        onClose={() => setShowRolesModal(false)}
      /> */}
      <SendInvitationsModal
        isOpen={showInviteModal}
        onClose={() => setShowInviteModal(false)}
      />
      <LimitExceededModal
        isOpen={showLimitModal}
        onClose={() => setShowLimitModal(false)}
      />
      <div className="flex w-full flex-col">
        <h5 className="mb-[21px] flex text-[36px] font-semibold   leading-[42px]">
          Organization
        </h5>
        <p className="font-regular w-[80%] text-[16px]   leading-[18px]">
          Centralize and streamline your fashion forecasting efforts by managing
          your organization’s account. Adjust member roles, oversee
          collaborative projects, and ensure the right people have the right
          access to our platform.
        </p>

        {/* <span
          className="my-8 cursor-pointer text-xl font-semibold underline"
          onClick={() => setShowRolesModal(true)}
        >
          See and Edit Organization Roles
        </span> */}
        <div className="mt-12 flex flex-col">
          <p className="font-regular mb-4 text-base">Organization Name</p>
          <div className="mb-[60px]">
            {loading ? (
              <div className="h-6 w-full animate-pulse rounded-md bg-slate-300" />
            ) : (
              <OrganizationName name={data.organization.name} />
            )}
          </div>
          <div className="flex items-center justify-between">
            <h5 className="mb-[21px] mt-8 text-[36px] font-semibold leading-[42px]">
              Members ({data?.organization?.members?.length || 0})
            </h5>

            <Button
              size="sm"
              onClick={() =>
                exceedsLimit
                  ? setShowLimitModal(true)
                  : setShowInviteModal(true)
              }
            >
              Invite Co-Workers
            </Button>
          </div>
          <p className="font-regular w-[80%] text-[16px]   leading-[18px]">
            Expand your organizational reach within the T-Fashion platform.
            Invite co-workers to collaborate, explore, and analyze fashion
            trends together, fostering a seamless, data-driven working
            environment.
          </p>

          <div className="mt-8 flex flex-col gap-y-4">
            <div className="grid grid-cols-12 gap-4 border-b border-black pb-2 text-lg font-semibold !leading-5">
              <span className="col-span-6">Member Name</span>
              <span className="col-span-2">Role</span>
            </div>
            <div className="grid grid-cols-12 items-center gap-4 ">
              {loading || !renderData.length ? (
                <Filler />
              ) : (
                renderData.map(MemberRow)
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
