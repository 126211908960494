import React, { useEffect, useMemo, useState } from 'react';
import { Blurhash } from 'react-blurhash';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { ChevronRight, Minus, Plus } from 'lucide-react';

import { GET_STUDIO_TASK_HISTORY } from '@/api/studio/queries';
import { Slider } from '@/components/ui/slider';

import { LazyLoadTrigger } from '../../explore/components/utils';

import { SidebarHeader } from './SidebarMenu';

export const StudioGeneratedImage = ({
  image,
  variant = 'default',
  entityType,
  studioItemId,
}) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [imageWidth, setImageWidth] = useState(image?.width || 1200);
  const [imageHeight, setImageHeight] = useState(image?.height || 1080);
  const [aspectRatio, setAspectRatio] = useState('portrait');

  const typeNameToExploreType = {
    Post: 'socialMediaItem',
    StudioItem: 'studioItem',
    ExploreFashionWeekItem: 'fashionWeekItem',
  };

  useEffect(() => {
    const imageToLoad = new window.Image();
    imageToLoad.src = image?.url || '';
    imageToLoad.onload = () => {
      setLoading(false);
      setImageWidth(imageToLoad.naturalWidth);
      setImageHeight(imageToLoad.naturalHeight);
      setAspectRatio(
        imageToLoad.naturalWidth / imageToLoad.naturalHeight === 1
          ? 'square'
          : imageToLoad.naturalWidth / imageToLoad.naturalHeight > 1
            ? 'landscape'
            : 'portrait',
      );
    };
  }, [image]);

  const aspectRatioClass = {
    square: 'aspect-square',
    landscape: 'aspect-[4/3]',
    portrait: 'aspect-[3/4]',
  };

  const blur = image?.blurhash || 'L0LETl.8j[?b~qoffQazj[j[a|ay';

  const handleClick = () => {
    if (variant !== 'collection') {
      const path =
        entityType === 'STUDIO_ITEM' ? 'studioItem' : 'fileUploadItem';
      navigate(
        `/explore/image-preview/${path}/${image.studioItemId || studioItemId}`,
      );
    } else {
      navigate(
        `/explore/image-preview/${typeNameToExploreType[entityType]}/${studioItemId}`,
      );
    }
  };

  if (loading) {
    return (
      <div className="relative">
        {blur && (
          <div
            className={`relative h-full w-full overflow-hidden rounded-md bg-white ${aspectRatioClass[aspectRatio]}`}
          >
            <Blurhash
              height="100%"
              width="100%"
              hash={blur}
              resolutionX={32}
              resolutionY={32}
              punch={1}
              className={`absolute inset-0 ${aspectRatioClass[aspectRatio]}`}
            />
          </div>
        )}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="animate-pulse rounded-md bg-gray-200" />
        </div>
        <div className="absolute inset-0">
          <img
            src={image.url}
            width={imageWidth}
            height={imageHeight}
            alt=""
            className="h-full w-full object-cover"
            style={{ visibility: 'hidden' }}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={`group w-full ${variant === 'collection' ? '' : 'cursor-pointer'} overflow-hidden rounded-md transition-transform hover:shadow`}
      onClick={handleClick}
    >
      <img
        src={image.url}
        width={imageWidth}
        height={imageHeight}
        alt=""
        className="w-full object-cover transition-transform hover:rounded-md group-hover:scale-105"
        loading="lazy"
      />
    </div>
  );
};
const StudioHistory = ({ columnCount, setHistoryDate, activeModal }) => {
  const [edges, setEdges] = useState([]);
  const [noEdges, setNoEdges] = useState(false);
  const [, { data, error, loading, fetchMore, networkStatus, refetch }] =
    useLazyQuery(GET_STUDIO_TASK_HISTORY, {
      variables: {},
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data.studioTaskHistory.edges.length === 0) {
          setNoEdges(true);
        } else {
          setEdges(data.studioTaskHistory.edges);
          setHistoryDate(
            dayjs(
              data.studioTaskHistory.edges[edges.length].node.createdAt,
            ).format('MMM YYYY'),
          );
        }
      },
    });

  useEffect(() => {
    if (activeModal === 'history') {
      refetch();
    }
  }, [activeModal]);

  const { pageInfo } = data?.studioTaskHistory || {};
  const { endCursor, hasNextPage } = pageInfo || {};

  const loadingMore = networkStatus === 3 || networkStatus === 4;

  if (loading && !loadingMore) {
    return <StudioTabHistoryLoading />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="h-full px-2 lg:px-6">
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: columnCount }}>
        <Masonry gutter="0.5rem">
          {edges &&
            edges.length > 0 &&
            edges.map((item, i) =>
              item.node.images.map((image, j) => (
                <StudioGeneratedImage
                  key={`${i}_${j}`}
                  image={image}
                  variant="default"
                  entityType="STUDIO_ITEM"
                />
              )),
            )}
        </Masonry>
      </ResponsiveMasonry>
      {noEdges && (
        <div className="flex h-full items-center justify-center">
          <p className="text-center text-sm text-secondary">No items found</p>
        </div>
      )}
      {hasNextPage &&
        !loadingMore &&
        Array(10)
          .fill()
          .map((_, index) => (
            <div key={index} className="flex h-12 w-full bg-transparent">
              <LazyLoadTrigger
                fetchMore={() => {
                  fetchMore({
                    variables: {
                      cursor: endCursor,
                    },
                  });
                }}
                cursorNext={endCursor}
                loading={loading}
              />
            </div>
          ))}
    </div>
  );
};

export const StudioHistorySidebar = ({ activeModal, setActiveModal }) => {
  const [columnCount, setColumnCount] = useState(3);
  const [historyDate, setHistoryDate] = useState(dayjs().format('MMM YYYY'));

  const handleSliderChange = (value) => {
    setColumnCount(Math.max(1, Math.min(5, 6 - value[0])));
  };

  const sliderValue = useMemo(() => [6 - columnCount], [columnCount]);

  return (
    <div
      style={{
        boxShadow: '-4px 0 8px rgba(0, 0, 0, 0.05)',
        transition: 'right 2s ease',
      }}
      className="absolute top-0 right-[7rem] flex h-full w-[22.5rem] items-start justify-start border-x border-secondary/50"
    >
      <div className="flex h-full w-full flex-col items-start justify-between bg-white py-6">
        <div className="flex h-full w-full flex-col gap-4 overflow-hidden">
          <div className="flex flex-col px-2 lg:px-6">
            <SidebarHeader
              text="History"
              variant="back"
              icon={ChevronRight}
              onClick={() => setActiveModal(null)}
            />
          </div>
          <div className="flex w-full flex-row items-center justify-between px-2 lg:px-6">
            <h3 className="text-xs font-bold text-secondary">{historyDate}</h3>
            <div className="flex w-1/2 items-center justify-center gap-x-2">
              <div className="flex cursor-pointer items-center justify-center">
                <Minus
                  size={18}
                  strokeWidth={1.5}
                  onClick={() => handleSliderChange([sliderValue[0] - 1])}
                />
              </div>
              <Slider
                value={sliderValue}
                min={1}
                max={5}
                step={1}
                onValueChange={handleSliderChange}
              />
              <div className="flex cursor-pointer items-center justify-center">
                <Plus
                  size={18}
                  strokeWidth={1.5}
                  onClick={() => handleSliderChange([sliderValue[0] + 1])}
                />
              </div>
            </div>
          </div>
          <div className="flex h-full flex-col overflow-auto">
            <StudioHistory
              columnCount={columnCount}
              setHistoryDate={setHistoryDate}
              activeModal={activeModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const StudioTabHistoryLoading = () => {
  return (
    <div className="grid grid-cols-3 gap-2 px-2 lg:px-6">
      {Array.from({ length: 16 }).map((_, i) => (
        <div
          key={i}
          className="aspect-[3/4] animate-pulse rounded-md bg-gray-200"
        />
      ))}
    </div>
  );
};
