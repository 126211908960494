import React from 'react';

export const ArrowDownIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 7.5L10 12.5L15 7.5"
      stroke="#282928"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);
