import { RadioItem as RadixRadioItem } from '@radix-ui/react-dropdown-menu';
import { styled } from '@stitches/react';

import styles from '../../../styles/Checkbox.module.css';

export const RadioItemRoot = styled(RadixRadioItem, {
  all: 'unset',
  cursor: 'pointer',
  backgroundColor: 'white',
  minWidth: 12,
  height: 12,
  border: '1px solid black',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': { backgroundColor: 'rgba(194, 155, 119, 0.5)' },
  "&[data-state='checked']": {
    background: 'rgba(194, 155, 119, 1)',
  },
});

export const RadioItem = ({ label, onChange, id, item }) => {
  return (
    <div className="flex items-center">
      <RadioItemRoot
        id={id}
        onCheckedChange={() => {
          onChange && onChange(item);
        }}
      />
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};
