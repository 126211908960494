import { useMutation, useQuery } from '@apollo/client';

import {
  SET_TREND_ITEM_COVER,
  SET_TREND_ITEM_GALLERY,
} from '@/api/trends/mutations';
import {
  NOTIFICATIONS,
  SIMILAR_SNAPSHOT_ITEM,
  SIMILAR_TREND_ITEMS,
  SNAPSHOT_ITEM,
  SNAPSHOT_ITEMS,
  TREND_FABRICS,
  TREND_ITEM,
  TREND_ITEM_FORECAST,
  TREND_ITEMS,
  TREND_OVERVIEWS,
  TREND_PATTERNS,
  TRENDS_TONES,
} from '@/api/trends/queries';

const queryMap = {
  overview: TREND_OVERVIEWS,
  forecast: TREND_ITEM_FORECAST,
  trendItem: TREND_ITEM,
  trendItems: TREND_ITEMS,
  trendFabrics: TREND_FABRICS,
  trendTones: TRENDS_TONES,
  trendPatterns: TREND_PATTERNS,
  similarTrendItems: SIMILAR_TREND_ITEMS,
  notifications: NOTIFICATIONS,
  snapshotItems: SNAPSHOT_ITEMS,
  snapshotItem: SNAPSHOT_ITEM,
  similarSnapshotItem: SIMILAR_SNAPSHOT_ITEM,
};

const mutationMap = {
  setGallery: SET_TREND_ITEM_GALLERY,
  setCover: SET_TREND_ITEM_COVER,
};

export const useTrendsQuery = (queryName, options) => {
  const query = queryMap[queryName];
  if (!query) {
    throw new Error(`Query ${queryName} not found`);
  }

  const queryHook = useQuery(query, options);

  return queryHook;
};

export const useTrendsMutation = (mutationName, options) => {
  const mutation = mutationMap[mutationName];
  if (!mutation) {
    throw new Error(`Mutation ${mutationName} not found`);
  }

  const mutationHook = useMutation(mutation, options);

  return mutationHook;
};
