import { FilterDropdown } from '@/components/FilterDropdown';
import { useAppFilters } from '@/contexts/AppFiltersContext';

export function FashionCategoryFilters() {
  const { filters, handleFashionWeekCategoryOnChange } = useAppFilters();
  const { fashionWeekCategory } = filters;

  console.log({ fashionWeekCategory });

  return (
    <FilterDropdown
      label={fashionWeekCategory?.label}
      placeholder={fashionWeekCategory?.placeholder}
      isLoading={fashionWeekCategory?.loading}
      options={fashionWeekCategory?.options}
      value={fashionWeekCategory?.value}
      onChange={handleFashionWeekCategoryOnChange}
      isSearchable
      isClearable
    />
  );
}
