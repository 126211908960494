import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { useFilters } from '../../hooks/filters';

export const TrendsRedirector = ({ children }) => {
  const [searchParams] = useSearchParams();
  const { selectedAudienceId, selectedMarketId } = useFilters();

  if (
    window.location.pathname === '/trends' &&
    (!selectedAudienceId || !selectedMarketId)
  ) {
    return <Navigate to={'/overview?' + searchParams.toString()} />;
  }
  if (
    window.location.pathname === '/trends/overview' &&
    selectedAudienceId &&
    selectedMarketId
  ) {
    return (
      <Navigate
        to={
          `/trends/${selectedMarketId}/${selectedAudienceId}?` +
          searchParams.toString()
        }
      />
    );
  }
  return children;
};
