import React from 'react';

export const MapPinIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_523_4084)">
      <path
        d="M12.25 5.83203C12.25 9.91537 7 13.4154 7 13.4154C7 13.4154 1.75 9.91537 1.75 5.83203C1.75 4.43964 2.30312 3.10429 3.28769 2.11972C4.27226 1.13515 5.60761 0.582031 7 0.582031C8.39239 0.582031 9.72774 1.13515 10.7123 2.11972C11.6969 3.10429 12.25 4.43964 12.25 5.83203Z"
        stroke="#C29B77"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7.58203C7.9665 7.58203 8.75 6.79853 8.75 5.83203C8.75 4.86553 7.9665 4.08203 7 4.08203C6.0335 4.08203 5.25 4.86553 5.25 5.83203C5.25 6.79853 6.0335 7.58203 7 7.58203Z"
        stroke="#C29B77"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_523_4084">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
