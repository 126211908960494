import { gql } from '@apollo/client';

export const CREATE_MARKET_CHECKOUT_SESSION = gql`
  mutation createMarketCheckoutSession(
    $marketInputs: [CreateMarketInput]
    $successUrl: String!
    $cancelUrl: String!
    $predefinedPlan: PredefinedPlanInput
  ) {
    createMarketCheckoutSession(
      marketInputs: $marketInputs
      successUrl: $successUrl
      cancelUrl: $cancelUrl
      predefinedPlan: $predefinedPlan
    ) {
      sessionId
      url
    }
  }
`;

export const FINALIZE_REPORT = gql`
  mutation FinalizeReport($input: [FinalizeReportInput]!) {
    finalizeReport(input: $input) {
      status
    }
  }
`;

export const CREATE_STUDIO_CHECKOUT_SESSION = gql`
  mutation CreateStudioCheckoutSession(
    $planSlug: String
    $successUrl: String!
    $cancelUrl: String!
    $quantity: Int
  ) {
    createStudioPlanCheckoutSession(
      planSlug: $planSlug
      successUrl: $successUrl
      cancelUrl: $cancelUrl
      quantity: $quantity
    ) {
      sessionId
      url
    }
  }
`;

export const CREATE_MARKET_PLAN_CHECKOUT_SESSION = gql`
  mutation CreateMarketPlanCheckoutSession(
    $planSlug: String
    $successUrl: String!
    $cancelUrl: String!
  ) {
    createMarketPlanCheckoutSession(
      planSlug: $planSlug
      successUrl: $successUrl
      cancelUrl: $cancelUrl
    ) {
      sessionId
      url
    }
  }
`;
