import { useUser } from '@/api/authentication/hook';
import { PageHeader, PageHeaderTitle } from '@/components/PageHeader';

export default function Header() {
  const { user } = useUser();
  return (
    <PageHeader>
      <div className="mt-12 flex items-center justify-between">
        <PageHeaderTitle primary>
          Welcome {user?.fullName.split(' ')[0]}
        </PageHeaderTitle>
      </div>
    </PageHeader>
  );
}
