import React, { useEffect } from 'react';

import { useMarketsQuery } from '@/api/market/hook';
import { useReports } from '@/hooks/report';

import {
  SelectPlanButton,
  SelectPlanCaption,
  SelectPlanInfo,
  SelectPlanType,
} from './';

export const SelectPlan = () => {
  const { reports, addNewReport } = useReports();
  const { data } = useMarketsQuery('marketPlans');

  useEffect(() => {
    if (!reports.length) {
      addNewReport();
    }

    return () => {};
  }, [reports]);

  return (
    <div className="flex w-full flex-col px-[60px]">
      <SelectPlanCaption />
      <div className="mx-auto mb-[34px] flex max-w-[1192px] cursor-pointer gap-x-[72px] border-t border-[#A1A1A1] pt-[34px]">
        {data &&
          data.marketPlans.map((item, index) => (
            <div className="group flex max-w-[350px] flex-col" key={index}>
              <SelectPlanType item={item} />
              <SelectPlanInfo item={item} />
              <SelectPlanButton item={item} />
            </div>
          ))}
      </div>
    </div>
  );
};
