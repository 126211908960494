import React from 'react';

import { useReports } from '@/hooks/report';

export const ReportTotal = () => {
  const { reports } = useReports();

  const plans = reports?.map((item) => item.plan);
  const sum = plans?.reduce(function (sum, plan) {
    return sum + Number(plan.price);
  }, 0);

  return (
    <div className="mt-[33px] flex w-full items-center space-x-[6px] px-[39px]">
      <span className="text-[16px] font-semibold leading-[28px]">
        Total Price
      </span>
      <span className="mt-[6px] flex h-[1px] flex-1 bg-[#a1a1a166]"></span>
      <span className="text-[16px] font-semibold text-[#3CA780] ">{sum}$</span>
    </div>
  );
};
