import { gql } from '@apollo/client';

export const UPDATE_ORGANIZATION_NAME = gql`
  mutation UpdateOrganizationName($input: OrganizationUpdateInput!) {
    updateOrganization(input: $input) {
      name
    }
  }
`;
export const ACCEPT_INVITATION = gql`
  mutation AcceptInvitation($input: AcceptInvitationInput) {
    acceptInvitation(input: $input) {
      accessToken
      refreshToken
      user {
        id
        email
        fullName
      }
    }
  }
`;
export const SEND_INVITATIONS = gql`
  mutation SendInvitations($invitations: [CreateInvitationInput]!) {
    sendInvitations(invitations: $invitations) {
      invitations {
        email
        role
        status
        inviter {
          fullName
          role
          email
          createdAt
          updatedAt
        }
      }
      failedEmails {
        email
        reason
      }
    }
  }
`;

export const ASSIGN_STUDIO_LICENCES = gql`
  mutation AssignStudioLicences($members: [String], $licence: String) {
    assignStudioLicences(members: $members, licence: $licence) {
      status
    }
  }
`;
