// import { Button } from '@/components/Button'
import {
  Dialog,
  DialogContent,
  // DialogDescription,
  // DialogFooter,
  // DialogHeader,
  // DialogTitle,
  // DialogTrigger
} from '@/components/Dialog';
import { useStudioContext } from '@/contexts/StudioContext';

export function StudioImagePopup() {
  const { imagePopup, setImagePopup } = useStudioContext();
  const isOpen = imagePopup?.isOpen;
  // const data = imagePopup?.imageGenerationTask

  return (
    <div>
      <Dialog
        open={isOpen}
        defaultOpen={isOpen}
        onOpenChange={(val) => {
          setImagePopup({
            ...imagePopup,
            isOpen: val,
          });
        }}
      >
        <DialogContent className="sm:max-h-[80vh] sm:max-w-[60vw]">
          {/* <DialogHeader> */}
          {/*   <DialogTitle>Edit profile</DialogTitle> */}
          {/*   <DialogDescription> */}
          {/*     Make changes to your profile here. Click save when you're done. */}
          {/*   </DialogDescription> */}
          {/* </DialogHeader> */}
          <div className="grid gap-4 py-4">
            <div>image details</div>
            <div></div>
          </div>
          {/* <DialogFooter> */}
          {/*   <Button size={'sm'} type="submit">Save changes</Button> */}
          {/* </DialogFooter> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}
