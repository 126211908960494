import React from 'react';

import { useReports } from '@/hooks/report';

export const AudienceCreate = () => {
  const { addEmptyAudience } = useReports();
  return (
    <button
      className="flex h-[58px] w-full items-center justify-center border-2 border-primary font-semibold text-primary "
      onClick={addEmptyAudience}
    >
      Create Audience List +
    </button>
  );
};
