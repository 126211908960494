import { cn } from '@/helpers/utils';

import { useCard } from '../context';

import styles from '../index.module.css';

export function HoverContent({ children, gradientDegree = '180deg' }) {
  const { hovered } = useCard();

  const style = {
    background:
      'linear-gradient(' +
      gradientDegree +
      ', rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.52) 60.88%)',
  };

  if (gradientDegree === '45deg') {
    style.top = 0;
    style.height = '100%';
    style.zIndex = 0;
  }

  if (gradientDegree === '0deg') {
    style.top = 0;
    style.height = '100%';
    style.zIndex = 0;
    style.background = 'rgba(0, 0, 0, 0.1)';
  }

  return (
    <div
      className={cn(
        styles.hoveredContentWrapper,
        'transition-all opacity-0',
        hovered
          ? 'opacity-1 pointer-events-all'
          : 'opacity-0 pointer-events-none',
      )}
    >
      <div className={styles.backdrop} style={style} />
      {children}
    </div>
  );
}
