import { useState } from 'react';
import { CiHeart } from 'react-icons/ci';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { useMutation } from '@apollo/client';
import { Heart } from 'lucide-react';

import {
  ADD_ITEM_TO_DEFAULT,
  REMOVE_ITEM_FROM_DEFAULT,
} from '@/api/collections/mutations';
import { cn } from '@/helpers/utils';
import { useToast } from '@/hooks/useToast';

export function LikeButton({
  item,
  isLiked,
  season,
  type,
  onlyIcon = true,
  variant,
}) {
  const [liked, setLiked] = useState(isLiked);

  const toast = useToast();

  const [likeItem] = useMutation(ADD_ITEM_TO_DEFAULT, {
    onCompleted() {
      setLiked(true);
    },
    onError() {
      setLiked(false);
    },
  });

  const [dislikeItem] = useMutation(REMOVE_ITEM_FROM_DEFAULT, {
    onCompleted() {
      setLiked(false);
    },
    onError() {
      setLiked(true);
    },
  });

  function handleOnClick() {
    setLiked((prev) => !prev);

    if (liked) {
      return dislikeItem({
        variables: {
          item,
        },
        onCompleted: (data) => {
          toast.createToast({
            message: 'Item successfully removed from likes.',
            type: 'success',
            showCloseButton: true,
            position: 'top-right',
          });
        },
      });
    }

    likeItem({
      variables: {
        item,
        season: season || '0',
        type,
      },
      onCompleted: (data) => {
        toast.createToast({
          message: 'Item successfully added to likes.',
          type: 'success',
          showCloseButton: true,
          position: 'top-right',
        });
      },
    });
  }

  if (variant === 'outlined') {
    return (
      <button
        onClick={handleOnClick}
        className="group rounded-full border border-[#a1a1a1]/20 bg-[#a1a1a1]/5 p-2 transition-all hover:bg-[#a1a1a1]/50"
      >
        <CiHeart
          size={32}
          className={`transition-colors duration-200 ${liked ? 'fill-red-500 group-hover:fill-gray-500' : 'fill-gray-500 group-hover:fill-red-500'}`}
        />
      </button>
    );
  }

  if (variant === 'icon') {
    return (
      <button
        onClick={handleOnClick}
        className="rounded border border-secondary/10 bg-white p-1 text-black hover:border-primary hover:bg-white hover:text-primary"
      >
        {liked ? <Heart size={20} fill="#000" /> : <Heart size={20} />}
      </button>
    );
  }

  return (
    <button
      onClick={handleOnClick}
      className={cn(
        'flex items-center justify-center bg-white gap-2 rounded hover:bg-gray-100 transition-all',
        onlyIcon ? 'p-2' : 'py-1 px-2',
      )}
    >
      {liked ? <FaHeart size={16} color="red" /> : <FaRegHeart size={16} />}
      {!onlyIcon && !liked ? 'Like' : !onlyIcon && liked ? 'Liked' : ''}
    </button>
  );
}
