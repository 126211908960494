import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const loginFormSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email must be a valid email')
    .required('Email is required')
    .min(3, 'Email must be at 3 char long'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at 8 char long'),
});

const invitationFormSchema = Yup.object().shape({
  fullName: Yup.string()
    .required('Full name is required')
    .min(3, 'Full name must be at 3 char long'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at 8 char long'),
});

export const loginFormOptions = {
  resolver: yupResolver(loginFormSchema),
};

export const invitationFormOptions = {
  resolver: yupResolver(invitationFormSchema),
};
