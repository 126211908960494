import { gql } from '@apollo/client';

export const GET_COMMENTS_FOR_ENTITY = gql`
  query Comments($entityId: String) {
    comments(entityId: $entityId) {
      id
      comment
      createdAt
      likes
      replies {
        id
        comment
        author {
          fullName
          profileImage
        }
        likes
        parentComment
        createdAt
      }
      author {
        fullName
        profileImage
      }
    }
  }
`;
