import React from 'react';

export const ReportCaption = () => (
  <>
    <h5 className="text-[48px] font-semibold leading-[55px]">Report</h5>
    <p className="font-regular mt-[16px] text-center text-[14px] leading-[173%] text-[#626775]">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua.
    </p>
  </>
);
