import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const inviteCoWorkersFormSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email must be a valid email')
    .required('Email is required')
    .min(3, 'Email must be at 3 char long'),
});

export const inviteCoWorkersFormOptions = {
  resolver: yupResolver(inviteCoWorkersFormSchema),
};
