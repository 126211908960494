import React from 'react';

import { AttentionIcon } from '../../icons';

export const MarketExists = () => {
  return (
    <p className="mt-[24px] flex w-full items-center gap-x-[10px]">
      <span className="flex items-center">
        <AttentionIcon color="#ffae00" />
      </span>
      <span className="pt-[4px] text-[14px] font-medium text-black">
        This market already exists. Please{' '}
        <strong className="leading-[24px] text-[#ffae00] underline">
          select different location from list.
        </strong>
      </span>
    </p>
  );
};
