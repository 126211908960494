import { useState } from 'react';
import { Root as RadixCheckboxRoot } from '@radix-ui/react-checkbox';
import { styled } from '@stitches/react';

import { colorMap } from '@/constants';
import { hexToHsl } from '@/helpers/hexToHsl';
import { Icon } from '@/icons';

import styles from '../styles/Checkbox.module.css';

const CheckboxRoot = styled(RadixCheckboxRoot, {
  all: 'unset',
  cursor: 'pointer',
  backgroundColor: 'white',
  minWidth: 12,
  height: 12,
  border: '1px solid transparent',
  outline: '1px solid black',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': { backgroundColor: 'rgba(194, 155, 119, 0.5)' },
});

export const Checkbox = ({
  label,
  onChange,
  id,
  item,
  checked,
  multiselect,
  type,
  onClick: onClickProp,
}) => {
  const [checkedState, setCheckedState] = useState(checked);

  function onClick() {
    multiselect && setCheckedState((prev) => !prev);
    onChange && onChange(multiselect ? item : !checked ? item : null);
  }

  return (
    <label className={[styles.label, 'flex items-center '].join(' ')}>
      <div className="relative">
        <CheckboxRoot
          id={id}
          checked={multiselect ? checkedState : checked}
          onCheckedChange={onClick}
          onClick={onClickProp}
          style={{
            backgroundColor:
              type === 'color'
                ? colorMap[item.label]
                : checked
                  ? 'rgb(194, 155, 119)'
                  : 'white',
            outlineColor: 'black',
          }}
        />
        {type === 'color' && checked && (
          <div className="absolute top-0 left-0 h-full w-full ">
            <Icon
              name="close-x"
              color={
                item.label !== 'yellow' &&
                hexToHsl(colorMap[item.label]) <= 50 &&
                'white'
              }
            />
          </div>
        )}
      </div>

      {label}
    </label>
  );
};
