import { useState } from 'react';

import { Icon } from '@/icons';

export function CollapsableContent({ title, children }) {
  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => setCollapsed(!collapsed);

  return (
    <div className="my-4 flex flex-col gap-4">
      <div
        className="flex items-center justify-between border-b border-b-[#282828] py-2"
        onClick={toggle}
      >
        <h3 className="text-planc text-base font-semibold capitalize">
          {title}
        </h3>

        <div className={collapsed ? '' : 'rotate-180'}>
          <Icon name="chevron-down" />
        </div>
      </div>

      {!collapsed && (
        <div className="grid max-h-36 grid-cols-4 gap-2 overflow-y-auto">
          {children}
        </div>
      )}
    </div>
  );
}
