import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { CategoryIcon, MapPinIcon, UsersIcon } from '../icons';

export const CreateReportProcessInfo = () => {
  const { pathname } = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const { reports, activeReport } = useSelector((state) => state.report);
  const report =
    reports?.[reports?.findIndex((report) => report.id === activeReport)];

  useEffect(() => {
    setShowLocation(!pathname.includes('location'));
  }, [pathname]);

  useEffect(() => {
    isLoaded && setShowLocation(true);
    setIsLoaded(true);
  }, [report?.location, isLoaded]);

  return (
    report?.location?.length > 0 &&
    showLocation && (
      <div className="mb-[9px] flex w-full items-center justify-between">
        <span className="inline-flex items-center gap-x-[8px]">
          <span className="flex h-[21px] items-center">
            <MapPinIcon />
          </span>
          <span className="pt-[4px] text-[21px] leading-[21px] text-[700]  ">
            {report?.location}
          </span>
        </span>

        <span className="inline-flex items-center gap-x-[5px]">
          <span className="flex h-[12px] w-[12px] items-center">
            {pathname?.includes('audience') && report?.audience?.length > 0 && (
              <UsersIcon />
            )}
            {pathname?.includes('categories') &&
              report?.categories?.length > 0 && <CategoryIcon />}
          </span>
          <span className="pt-[4px] text-[14px] font-semibold leading-[16px] text-primary">
            {pathname?.includes('audience') && report?.audience?.length > 0 && (
              <>
                <span>Audience: </span>
                <span>
                  {report?.audience?.length}/{report?.plan?.scope.audience}
                </span>
              </>
            )}
            {pathname?.includes('categories') &&
              report?.categories?.length > 0 && (
                <>
                  <span>Category: </span>
                  <span>
                    {report?.categories?.length}/{report?.plan?.scope.category}
                  </span>
                </>
              )}
          </span>
        </span>
      </div>
    )
  );
};
