import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

import styles from './index.module.css';

dayjs.extend(relativeTime);
dayjs.extend(utc);

export function Detail({ title, shots, updatedAt }) {
  return (
    <div className={styles.detailWrapper}>
      <h3>{title}</h3>

      <div>
        <span>{shots} shots</span>
        <span>✴</span>
        <span>{dayjs.utc(updatedAt).fromNow()}</span>
      </div>
    </div>
  );
}
