import React, { useEffect, useRef, useState } from 'react';

import { useReports } from '@/hooks/report';

export const AudienceName = ({ audience }) => {
  const { updateAudience } = useReports();
  const nameRef = useRef();
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    edit && nameRef.current.focus();
  }, [edit]);

  return (
    <button className="flex h-[58px] w-full items-center justify-between border-2 border-primary px-[32px] font-semibold  text-primary">
      <input
        type="text"
        ref={nameRef}
        className="flex-1 cursor-pointer bg-white outline-none"
        defaultValue={audience?.name}
        onChange={() =>
          updateAudience({ id: audience?.id, name: nameRef.current.value })
        }
        disabled={!edit}
      />
      <span
        className="font-regular text-[12px]  text-charcoal underline"
        onClick={() => setEdit(!edit)}
      >
        {edit ? 'Save List Name' : 'Edit List Name'}
      </span>
    </button>
  );
};
