import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAutoAnimate } from '@formkit/auto-animate/react';

import { useReports } from '@/hooks/report';

import { useMarketsQuery } from '../../../../../api/market/hook';
import { ArrowDownIcon, ArrowRightIcon } from '../../icons';

import { CategoryAttention, CategoryCaption, CategoryItems } from './';

function CategoryItem({ item, setAccordion, accordion }) {
  const { apparelGroups, selectApparelGroup } = useReports();

  const isSelected = apparelGroups.find((group) => group.id === item.id);

  const [ref] = useAutoAnimate();

  return (
    <div
      className="border-[rgba(161, 161, 0.4)] flex flex-col items-center border-b py-[13px]"
      ref={ref}
    >
      <div className="flex w-full items-center">
        <div
          className="flex flex-1 cursor-pointer select-none items-center gap-x-[14px] capitalize"
          onClick={() => setAccordion(item.id)}
        >
          {accordion === item.id ? <ArrowDownIcon /> : <ArrowRightIcon />}
          <span className="pt-[4px] text-[24px] font-semibold leading-[28px]">
            {item.name}
          </span>
        </div>
        <div className="flex items-center">
          <input
            className="form-check-input float-left h-[22px] w-[22px] cursor-pointer appearance-none rounded-none border border-charcoal bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-charcoal checked:bg-primary focus:outline-none"
            type="checkbox"
            checked={isSelected}
            value=""
            onChange={() => selectApparelGroup(item)}
          />
        </div>
      </div>
      {accordion === item.id && <CategoryItems items={item.items} />}
    </div>
  );
}

export const Category = () => {
  const navigate = useNavigate();
  const { plan, apparelGroups } = useReports();
  const [accordion, setAccordion] = useState([]);

  const { data, loading } = useMarketsQuery('availableApparels');

  const scope = plan?.scope || null;

  useEffect(() => {
    if (!scope) navigate('/create-trend-report/select-a-plan');
  }, [scope]);

  const [parentRef] = useAutoAnimate();

  const handleAccordion = (value) => {
    if (accordion === value) setAccordion(null);
    else setAccordion(value);
  };

  return (
    <div className="mt-[29px] flex max-w-[573px] flex-col">
      <CategoryCaption />
      <div className="flex flex-col" ref={parentRef}>
        {loading &&
          Array.from({ length: 5 }).map((_, index) => (
            <div className="ghost my-2 h-10 w-full" key={index}></div>
          ))}
        {scope?.apparel > apparelGroups.length &&
          data?.availableApparels?.map((item) => (
            <CategoryItem
              key={item.id}
              item={item}
              setAccordion={handleAccordion}
              accordion={accordion}
            />
          ))}

        {scope?.apparel === apparelGroups.length &&
          apparelGroups.map((item) => (
            <CategoryItem
              key={item.id}
              item={item}
              setAccordion={handleAccordion}
              accordion={accordion}
            />
          ))}
        {apparelGroups?.length >= scope?.category && <CategoryAttention />}
      </div>
    </div>
  );
};
