import styles from '../index.module.css';

const positionMap = {
  left: styles.left,
  right: styles.right,
  center: styles.center,
};

export function Root({ position, index, children }) {
  const [vertical, horizontal] = position.split('-');

  const className = positionMap[horizontal];

  if (!className) throw new Error(`Invalid position prop: ${position}`);

  return (
    <div
      style={{
        [vertical]: index ? `${index * 5 + 3}rem` : '3rem',
      }}
      className={[styles.root, className].join(' ')}
    >
      {children}
    </div>
  );
}
