/* eslint-disable react/jsx-indent */

import { useEffect, useState } from 'react';
import { ImSpinner8 } from 'react-icons/im';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useParams } from 'react-router-dom';
import { NetworkStatus, useQuery } from '@apollo/client';

import { EXPLORE, EXPLORE_FASHION_ITEMS } from '@/api/explore';
import { useTrendsMutation } from '@/api/trends/hook';
import { TREND_ITEM } from '@/api/trends/queries';
import { seasons, seasonsMapping } from '@/constants';
import { useAppFilters } from '@/contexts/AppFiltersContext';
import { isBright } from '@/helpers/isBright';
import { useToast } from '@/hooks/useToast';

import { EXPLORE_FASHION_WEEK_FILTERS } from '../../../../api/explore/queries';
import { useFiltersQuery } from '../../../../api/filters/hook';
import {
  ExploreLoadingScreen,
  LazyLoadTrigger,
} from '../../explore/components/utils';

const itemTypeToFieldMapping = {
  snapshotItem: {
    color: 'ssiColor',
    pattern: 'ssiPattern',
    fabric: 'ssiFabric',
    colorTone: 'ssiColorTone',
  },
  trendItem: {
    color: 'tiColor',
    pattern: 'tiPattern',
    fabric: 'tiFabric',
    colorTone: 'tiColorTone',
  },
};

// TODO: change component name something more generic like ImageChanger
export function EditGallery({ item, onClose, gallery = true, onRefetch }) {
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [cursorNext, setCursorNext] = useState(null);
  const [currentImages, setCurrentImages] = useState({});
  const [source, setSource] = useState('apparelItem');
  const { selectedSeason, selectedAudience } = useAppFilters();

  const itemType = item.tiId ? 'trendItem' : 'snapshotItem';

  const [apparelFilter, setApparelFilter] = useState(
    item?.apparel
      ? [
          {
            value: item?.apparel?.toString(),
            items: [],
          },
        ]
      : [],
  );
  const [colorFilter, setColorFilter] = useState(
    item?.[itemTypeToFieldMapping[itemType].color]
      ? [item[itemTypeToFieldMapping[itemType].color]]
      : [],
  );

  const [seasonFilter, setSeasonFilter] = useState(selectedSeason.label);

  const toast = useToast();

  const { marketId } = useParams();

  const [fashionItemFilters, setFashionItemFilters] = useState({
    season: null,
    designer: null,
    itemType: null,
    city: null,
    apparels: [],
    colors: [],
  });

  const {
    data: fashionItemData,
    networkStatus: fashionItemNetworkStatus,
    loading: fashionItemLoading,
    fetchMore: fashionItemFetchMore,
    error: fashionItemError,
    refetch: fashionItemRefetch,
  } = useQuery(EXPLORE_FASHION_ITEMS, {
    variables: {
      filters: fashionItemFilters,
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: fashionItemFiltersData, refetch: fashionItemFiltersRefetch } =
    useQuery(EXPLORE_FASHION_WEEK_FILTERS, {
      variables: {
        filters: fashionItemFilters,
      },
    });

  useEffect(() => {
    fashionItemFiltersRefetch({
      filters: fashionItemFilters,
    });

    return () => {};
  }, [fashionItemFilters, fashionItemFiltersRefetch]);

  useEffect(() => {
    fashionItemRefetch({
      filters: fashionItemFilters,
    });

    return () => {};
  }, [fashionItemFilters, fashionItemRefetch]);

  useEffect(() => {
    if (gallery) {
      const setImages = item.featuredImages.every(
        (image) => image.exploreItemId,
      );

      if (setImages) {
        const temp = {};

        item.featuredImages.forEach((image) => {
          temp[image.exploreItemId] = image;
        });

        setCurrentImages(temp);
      }
    }

    return () => {
      setCurrentImages({});
    };
  }, [item]);

  const { data: apparelFiltersData } = useFiltersQuery('exploreApparels', {
    season: selectedSeason?.label.toLowerCase(),
    audiences: selectedAudience ? [selectedAudience.value] : null,
    market: selectedAudience?.marketId,
  });

  const { data: colorFiltersData } = useFiltersQuery('exploreColors', {
    season: selectedSeason?.label,
    audiences: selectedAudience?.value ? [selectedAudience.value] : null,
    market: marketId,
  });

  const [updateGallery, { loading }] = useTrendsMutation('setGallery', {
    refetchQueries: [TREND_ITEM],
    onCompleted: () => {
      toast.createToast({
        message: 'Gallery updated successfully',
        type: 'success',
        position: 'top-right',
      });

      onRefetch && onRefetch();

      onClose();
    },
  });

  const [updateCover, { loading: coverLoading }] = useTrendsMutation(
    'setCover',
    {
      refetchQueries: [TREND_ITEM],
      onCompleted: () => {
        toast.createToast({
          message: 'Cover updated successfully',
          type: 'success',
          position: 'top-right',
        });
        const triggerEvent = new Event('itemCoverChange');
        window.dispatchEvent(triggerEvent);

        onRefetch && onRefetch();

        onClose();
      },
    },
  );

  const { data, error, fetchMore, networkStatus, refetch } = useQuery(EXPLORE, {
    variables: {
      cursor: null,
      filters: {
        apparels: apparelFilter,
        colors: colorFilter,
        season: selectedSeason?.label.toLowerCase(),
      },
      audiences: selectedAudience?.value && [selectedAudience.value],
      market: selectedAudience?.marketId,
    },
    onCompleted: (data) => {
      data?.explore?.cursor && setCursorNext(data.explore.cursor);
      setFullyLoaded(!data.explore.hasNextPage);
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    refetch({
      cursor: null,
      filters: {
        apparels: apparelFilter,
        colors: colorFilter,
        season: seasonFilter,
      },
      audiences: selectedAudience?.value && [selectedAudience.value],
      market: selectedAudience?.marketId,
    });

    return () => {
      setFullyLoaded(false);
      setCursorNext(null);
    };
  }, [
    selectedSeason,
    selectedAudience,
    apparelFilter,
    colorFilter,
    seasonFilter,
  ]);

  useEffect(() => {
    setCurrentImages({});
  }, [source]);

  if (error || fashionItemError) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <p>{error?.message || fashionItemError?.message}</p>
      </div>
    );
  }

  if (
    (networkStatus === NetworkStatus.loading && !data?.explore) ||
    (!fashionItemData?.exploreFashionWeekItems &&
      fashionItemNetworkStatus === NetworkStatus.loading)
  ) {
    return (
      <div className="min-w-screen max-h-[80vh] w-full overflow-y-auto">
        <ExploreLoadingScreen />
      </div>
    );
  }
  const fashionItemLoadingMore = fashionItemNetworkStatus === 3;

  if (
    loading ||
    coverLoading ||
    (fashionItemLoading && !fashionItemLoadingMore)
  ) {
    return (
      <div className="min-w-screen flex max-h-[80vh] w-full items-center justify-center gap-4 overflow-y-auto py-12">
        <h1 className="text-2xl font-bold">Applying changes...</h1>
        <ImSpinner8 className="animate-spin text-4xl" />
      </div>
    );
  }

  function NoSuchResultsWereFound() {
    return (
      data?.explore.data.length === 0 && (
        <h4 className="pb-8 text-center text-lg font-semibold">
          No such results were found, please review your search criteria...
        </h4>
      )
    );
  }

  function handleImageSelect(item, source) {
    const { image } = item;
    let id = '';

    if (source === 'socialMediaItem') {
      id = item.post.id;
    } else {
      id = item.id;
    }

    if (gallery) {
      if (currentImages[id]) {
        const temp = { ...currentImages };

        delete temp[id];

        setCurrentImages(temp);

        return;
      }

      setCurrentImages((prev) => ({
        ...prev,
        [id]: {
          ...image,
          source,
        },
      }));
    } else {
      if (currentImages[id]) {
        setCurrentImages({});
        return;
      }

      setCurrentImages({
        [id]: {
          ...image,
          source,
        },
      });
    }
  }

  const { edges, pageInfo } = fashionItemData?.exploreFashionWeekItems;
  const { endCursor, hasNextPage } = pageInfo;

  const renderLazyLoad =
    networkStatus !== NetworkStatus.fetchMore &&
    data?.explore?.hasNextPage &&
    !fullyLoaded;

  function handleSave() {
    if (Object.keys(currentImages).length === 0) {
      toast.createToast({
        message: 'Please select at least one image',
        type: 'error',
        position: 'top-right',
      });
      return;
    }

    const payload = Object.entries(currentImages).map(([key, image]) => ({
      entity: {
        entityType: image.source,
        entityId: key,
      },
      url: image.urlKey,
    }));

    if (gallery) {
      updateGallery({
        variables: {
          id: item.id,
          gallery: payload,
          season: Number(selectedSeason.value),
          tiId: item.tiId,
          itemType: source,
        },
      });
    } else {
      updateCover({
        variables: {
          id: item.id,
          cover: payload[0],
          season: Number(selectedSeason.value),
          tiId: item.tiId,
          itemType: source,
        },
      });
    }
  }

  return (
    <div className="min-w-screen relative max-h-[80vh] w-full overflow-y-auto px-[3.75rem]">
      <div className="sticky top-0 left-0 z-50 flex w-full items-center justify-between gap-4 bg-white py-8">
        <div className="flex flex-col gap-2">
          <h1 className="text-lg font-bold">
            Currently editing for season{' '}
            {seasonsMapping[selectedSeason.value].label}
          </h1>

          <div className="flex w-full flex-wrap items-center gap-4  py-4">
            <label htmlFor="source" className="font-semibold">
              Source
            </label>
            <select
              id="source"
              value={source}
              onChange={(e) => setSource(e.target.value)}
            >
              <option value="apparelItem">Apparel Item</option>
              <option value="fashionItem">Fashion Item</option>
            </select>

            {source === 'apparelItem' ? (
              <>
                <span>Apparel Filter</span>
                <select
                  value={apparelFilter[0] ? apparelFilter[0].value : null}
                  onChange={(e) =>
                    setApparelFilter([
                      {
                        value: e.target.value,
                        items: [],
                      },
                    ])
                  }
                >
                  {apparelFiltersData?.exploreFilters?.apparels?.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>

                <span>Color Filter</span>
                <select
                  value={colorFilter[0] || null}
                  onChange={(e) => setColorFilter([e.target.value])}
                >
                  {colorFiltersData?.exploreFilters?.colors?.map((item) => (
                    <option key={item.value} value={item.label}>
                      {item.label}
                    </option>
                  ))}
                </select>
                <span>Season Filter</span>
                <select
                  value={seasonFilter}
                  onChange={(e) => setSeasonFilter(e.target.value)}
                >
                  {seasons.map((item) => (
                    <option
                      key={item.value}
                      value={item.label.toLowerCase()}
                      defaultChecked={item.label.toLowerCase() === seasonFilter}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </>
            ) : (
              <>
                {fashionItemFiltersData?.exploreFashionWeekItemFilters?.map(
                  (filter) =>
                    filter.name !== 'apparel' && (
                      <>
                        <label htmlFor={filter.name} className="font-semibold">
                          {filter.label}
                        </label>
                        <select
                          value={
                            filter.name === 'category'
                              ? fashionItemFilters.apparels[0]?.value
                              : filter.name === 'colors'
                                ? fashionItemFilters.colors[0]
                                : fashionItemFilters[filter.name]
                          }
                          onChange={(e) => {
                            if (filter.name === 'category') {
                              return setFashionItemFilters((prev) => ({
                                ...prev,
                                apparels:
                                  e.target.value === 'clear'
                                    ? null
                                    : [
                                        {
                                          value: e.target.value,
                                          items: [],
                                        },
                                      ],
                              }));
                            }

                            if (filter.name === 'colors') {
                              return setFashionItemFilters((prev) => ({
                                ...prev,
                                apparels:
                                  e.target.value === 'clear'
                                    ? null
                                    : [e.target.value],
                              }));
                            }

                            setFashionItemFilters((prev) => ({
                              ...prev,
                              [filter.name]:
                                e.target.value === 'clear'
                                  ? null
                                  : e.target.value,
                            }));
                          }}
                          id={filter.name}
                          className="max-w-[25%]"
                        >
                          <option value="clear">clear</option>
                          {filter.values.map((value) => (
                            <option key={value.value} value={value.value}>
                              {value.label}
                            </option>
                          ))}
                        </select>
                      </>
                    ),
                )}
              </>
            )}
          </div>
        </div>

        <div className="flex items-center gap-4">
          <button
            onClick={() => onClose()}
            disabled={loading}
            className="flex items-center justify-start bg-red-500 px-[1.125rem] py-[0.640625rem] font-bold text-white hover:bg-red-600 disabled:cursor-not-allowed disabled:opacity-50"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            disabled={loading}
            className="flex items-center justify-start bg-green px-[1.125rem] py-[0.640625rem] font-bold text-white disabled:cursor-not-allowed disabled:opacity-50"
          >
            Save
          </button>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <h1 className="text-2xl font-bold">
          {gallery ? ' Current Images' : 'Selected Image'}
        </h1>

        <div className="flex items-center gap-4 overflow-x-auto">
          {Object.entries(currentImages).map(([key, image], index) => (
            <img
              key={index}
              className="w-[200px]"
              src={image.url}
              onClick={() =>
                handleImageSelect({
                  id: key,
                })
              }
            />
          ))}
        </div>
      </div>

      <h1 className="mt-4 text-2xl font-bold">Results</h1>
      {networkStatus === NetworkStatus.refetch ? (
        <div className="flex items-center justify-center py-4">
          <ImSpinner8 className="animate-spin self-center text-4xl" />
        </div>
      ) : (
        <div className="mt-4 w-full">
          <NoSuchResultsWereFound />
          <ResponsiveMasonry
            columnsCountBreakPoints={{
              350: 2,
              900: 4,
              1200: 4,
            }}
          >
            {source === 'apparelItem' ? (
              <Masonry gutter={'1.625rem'}>
                {data?.explore.data?.map((exploreItem) => (
                  <div key={exploreItem.id}>
                    <img
                      src={exploreItem.image.url}
                      onClick={() =>
                        handleImageSelect(exploreItem, 'socialMediaItem')
                      }
                      className={
                        currentImages[exploreItem.id]
                          ? 'border-4 border-black transition-all duration-150 ease-in-out'
                          : ''
                      }
                    />

                    <div className="my-4 flex flex-col">
                      <span className="mb-2 font-semibold">
                        Apparel: {exploreItem.title}
                      </span>
                      {exploreItem.tones.slice(0, 3).map((tone) => (
                        <div
                          key={tone.hex}
                          className="flex flex-col p-2 font-semibold"
                          style={{
                            backgroundColor: tone.hex,
                            color: isBright(tone.hex) ? '#000' : '#FFF',
                          }}
                        >
                          <span>Tone: {tone.name} </span>
                          <span>Color: {tone.main}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
                {renderLazyLoad &&
                  Array(6)
                    .fill()
                    .map((_, index) => (
                      <LazyLoadTrigger
                        key={'lazyload_' + index}
                        fetchMore={fetchMore}
                        loading={networkStatus === NetworkStatus.loading}
                        cursorNext={cursorNext}
                      />
                    ))}
              </Masonry>
            ) : (
              <Masonry gutter={'1.625rem'}>
                {edges?.map(({ node: fashionItem }) => (
                  <div key={fashionItem.id}>
                    <img
                      src={fashionItem.image.url}
                      onClick={() =>
                        handleImageSelect(fashionItem, 'fashionWeekItem')
                      }
                      className={
                        currentImages[fashionItem.id]
                          ? 'border-4 border-black transition-all duration-150 ease-in-out'
                          : ''
                      }
                    />

                    <div className="my-4 flex flex-col">
                      <span className="mb-2 font-semibold">
                        Apparel: {fashionItem.apparel}
                      </span>
                      {fashionItem.tones?.slice(0, 3).map((tone) => (
                        <div
                          key={tone.hex}
                          className="flex flex-col p-2 font-semibold"
                          style={{
                            backgroundColor: tone.hex,
                            color: isBright(tone.hex) ? '#000' : '#FFF',
                          }}
                        >
                          <span>Tone: {tone.name} </span>
                          <span>Color: {tone.main}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
                {hasNextPage &&
                  !fashionItemLoadingMore &&
                  Array(6)
                    .fill()
                    .map((_, index) => (
                      <LazyLoadTrigger
                        key={'lazyload_' + index}
                        fetchMore={() => {
                          fashionItemFetchMore({
                            variables: {
                              cursor: endCursor,
                            },
                          });
                        }}
                        cursorNext={endCursor}
                        loading={fashionItemLoading}
                      />
                    ))}
              </Masonry>
            )}
          </ResponsiveMasonry>
        </div>
      )}
    </div>
  );
}
