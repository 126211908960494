import { Trigger as RadixTrigger } from '@radix-ui/react-popover';

import { usePopoverContext } from './PopoverHook';

export function PopoverTrigger({ children }) {
  const { show, hide } = usePopoverContext();

  return (
    <RadixTrigger
      onMouseEnter={() => show()}
      onMouseLeave={() => hide()}
      className="outline-none"
    >
      {children}
    </RadixTrigger>
  );
}
