import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useUser } from '@/api/authentication/hook';
import { reachMapping } from '@/constants';
import { isBright } from '@/helpers/isBright';
import { Icon } from '@/icons';

import { useTabs } from '../hook';

import styles from '../style/index.module.css';

dayjs.extend(utc);

function Image({ url, title = 'Placeholder', href }) {
  return (
    <div
      className={styles.itemImageBox}
      style={{
        background: `linear-gradient(360deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 107.51%), url(${url})`,
        backgroundSize: 'cover',
      }}
    >
      <Link to={href} className={styles.title}>
        {title}
      </Link>
      <div className={styles.copyIcon}>
        <Icon name="copy" />
      </div>
    </div>
  );
}

function Tone({ tone, href }) {
  return (
    <Link
      to={href}
      className={styles.itemToneBox}
      style={{
        backgroundColor: tone.hex,
        color: isBright(tone.hex) ? 'black' : 'white',
        zIndex: 0,
      }}
    >
      Pantone
      <br />
      {tone.code} TCX
      <div className={styles.copyIcon}>
        <Icon name="copy" color={isBright(tone.hex) ? '#000' : '#FFF'} />
      </div>
    </Link>
  );
}

function ItemStat({ children }) {
  return <div className={styles.itemStat}>{children}</div>;
}

export function Item({
  title,
  yoyGrowth: { percent },
  featuredImages,
  reach,
  optimalLaunch,
  image,
  tone,
  id,
  onEditGallery,
  onEditCover,
}) {
  const { marketId, seasonId } = useParams();
  const { selectedTab } = useTabs();

  const { moderationEnabled } = useUser();

  const url = `/trends/detail/${marketId}/${encodeURIComponent(
    title.toLowerCase().replaceAll(' ', '-'),
  )}/${id}/${seasonId}`;

  return (
    <div className={styles.itemWrapper}>
      <div className={styles.info}>
        <div className="group relative">
          {tone && selectedTab === 'Color' ? (
            <Tone tone={tone} href={url} />
          ) : (
            image && <Image {...image} title={title} href={url} />
          )}
          {moderationEnabled && !tone && (
            <div className="absolute inset-0 flex h-full w-full items-center justify-center bg-black/30 opacity-0 transition-all duration-150 ease-in-out group-hover:opacity-100">
              <button
                onClick={onEditCover}
                className="border-2 border-gray-100 px-6 py-2 text-center font-semibold text-white transition-all duration-150 ease-in-out hover:bg-gray-300/70 active:bg-gray-600/70"
              >
                Change Cover
              </button>
            </div>
          )}
        </div>

        <div className={styles.itemStats}>
          <ItemStat>
            <span>Year over Year Growth</span>
            <h5 className={percent > 0 ? '!text-green' : '!text-red-500'}>
              <span>%{percent.toFixed(0)}</span>
              <div className={percent > 0 ? styles.arrowUp : styles.arrowDown}>
                <Icon
                  name="small-arrow"
                  color={percent > 0 ? 'green' : 'red'}
                />
              </div>
            </h5>
          </ItemStat>

          <ItemStat>
            <span>Optimal Launch</span>
            <h5>{dayjs.utc(optimalLaunch).format('MMMM')}</h5>
          </ItemStat>

          <ItemStat>
            <span>Reach</span>
            <h5
              style={{
                color: reachMapping[String(reach)].color,
              }}
            >
              {reachMapping[String(reach)].text}
            </h5>
          </ItemStat>

          {moderationEnabled && (
            <button
              onClick={onEditGallery}
              className="border-2 border-charcoal px-6 py-2 text-center text-2xl font-semibold"
            >
              Edit Gallery
            </button>
          )}
        </div>
      </div>
      <div className={styles.featuredImages}>
        {featuredImages.slice(0, 4).map((item, index) => (
          <img className={styles.featuredImage} src={item.url} key={index} />
        ))}
      </div>
    </div>
  );
}
