export function EditIcon({ color }) {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7499 4.49985L23.4999 9.24985M15.5833 26.6665H28.2499M2.91659 20.3332L1.33325 26.6665L7.66658 25.0832L26.0111 6.73868C26.6047 6.14484 26.9382 5.33953 26.9382 4.49985C26.9382 3.66016 26.6047 2.85485 26.0111 2.26101L25.7388 1.98868C25.1449 1.39502 24.3396 1.06152 23.4999 1.06152C22.6602 1.06152 21.8549 1.39502 21.2611 1.98868L2.91659 20.3332Z"
        stroke={color || 'black'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
