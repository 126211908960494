import { Link } from 'react-router-dom';

import { Button } from '@/components/Button.jsx';

export const NotFound = ({ children }) => {
  return (
    <div className="flex h-screen min-h-full w-screen min-w-full items-center justify-center">
      <div className="flex flex-col items-center justify-center text-charcoal">
        <h2 className="py-2 text-2xl font-medium">Page not found</h2>
        <p className="text-lg text-gray-500">
          The page you are looking for does not exist.
        </p>

        <Link to={'/'}>
          <Button variant={'secondary'} outline className={'mt-8'}>
            Go back to home
          </Button>
        </Link>
      </div>
    </div>
  );
};
