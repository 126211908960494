import { gql } from '@apollo/client';

export const UPVOTE_APPAREL = gql`
  mutation UpvoteApparel($apparelId: String!) {
    upvoteApparel(apparelId: $apparelId) {
      status
    }
  }
`;

export const DOWNVOTE_APPAREL = gql`
  mutation DownvoteApparel($apparelId: String!) {
    downvoteApparel(apparelId: $apparelId) {
      status
    }
  }
`;

export const EXCLUDE_APPAREL = gql`
  mutation ExcludeApparel($apparelId: String!) {
    excludePhoto(apparelId: $apparelId) {
      status
    }
  }
`;

export const EXCLUDE_ACCOUNT = gql`
  mutation ExcludeAccount($influencerId: String!) {
    excludeAccount(influencerId: $influencerId) {
      status
    }
  }
`;

export const REPORT_ITEM = gql`
  mutation ReportItem(
    $itemId: String!
    $itemType: String!
    $reason: String!
    $message: String
  ) {
    reportItem(
      itemId: $itemId
      itemType: $itemType
      reason: $reason
      message: $message
    ) {
      status
    }
  }
`;
