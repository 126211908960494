import { createContext, useEffect, useState } from 'react';

import { useCollectionsMutations } from '@/api/collections/hooks';
import { useToast } from '@/hooks/useToast';

export const AddToCollectionContext = createContext();

export function AddToCollectionProvider({ children }) {
  const [collectionName, setCollectionName] = useState('');
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [mutationLoading, setMutationLoading] = useState(false);
  const [mutationDone, setMutationDone] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [tone, setTone] = useState(null);

  const toast = useToast();

  const payload = selectedCollection
    ? {
        collection: selectedCollection,
        // metadata: {
        //   market: selectedMarket,
        //   audience: selectedAudience,
        //   season: selectedSeason,
        //   apparelFilters,
        //   colorFilters,
        //   patternFilters,
        // },
      }
    : null;

  function cleanUp() {
    setSelectedCollection(null);
    setMutationLoading(false);
    setMutationDone(false);
    setItemId(null);
    setTone(null);
  }

  const [addItem] = useCollectionsMutations('addApparel', {
    onCompleted: () => {
      cleanUp();
      toast.createToast({
        message: 'Apparel added to collection successfully!',
        type: 'success',
        position: 'top-right',
      });
    },
    refetchQueries: ['Collections'],
  });

  const [addTone] = useCollectionsMutations('addTone', {
    onCompleted: () => {
      cleanUp();
      toast.createToast({
        message: 'Tone added to collection successfully!',
        type: 'success',
        position: 'top-right',
      });
    },
    refetchQueries: ['Collections'],
  });

  const [createCollection] = useCollectionsMutations('create', {
    variables: {
      name: collectionName,
    },
    onCompleted: function () {
      setMutationLoading(false);
      setMutationDone(true);
    },
    refetchQueries: ['Collections'],
  });

  function handleAddItem(collectionId) {
    if (!selectedCollection) setSelectedCollection(collectionId);

    if (mutationLoading) return;
    setMutationLoading(true);
    if (itemId) {
      addItem({
        variables: {
          metadata: payload?.metadata || null,
          collection: payload?.collection || collectionId,
          apparel: itemId,
        },
      });
    }

    if (tone) {
      addTone({
        variables: {
          metadata: payload?.metadata || null,
          collection: payload?.collection || collectionId,
          tone,
        },
      });
    }
  }

  function handleAddCollection() {
    if (collectionName === '' || !collectionName) {
      return;
    }

    setMutationLoading(true);
    createCollection();
  }

  function setCollection(id) {
    setSelectedCollection((prev) => (prev === id ? null : id));
  }

  useEffect(() => {
    if (selectedCollection) {
      handleAddItem();
    }
  }, [selectedCollection, handleAddItem]);

  return (
    <AddToCollectionContext.Provider
      value={{
        selectedCollection,
        setSelectedCollection,
        mutationLoading,
        mutationDone,
        setItemId,
        setTone,
        handleAddItem,
        handleAddCollection,
        collectionName,
        setCollection,
        setCollectionName,
      }}
    >
      {children}
    </AddToCollectionContext.Provider>
  );
}
