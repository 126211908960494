import { FormLabel } from '@/components/FormInput';
import { cn } from '@/helpers/utils';

export function ButtonSelect({ filter, filterKey, onChange, currentValue }) {
  return (
    <div className="flex flex-col">
      <FormLabel>{filter?.label}</FormLabel>
      <div className="flex flex-row gap-2">
        {filter?.options?.map((option, index) => (
          <button
            key={index}
            onClick={() => onChange(option, filterKey)}
            className={cn(
              'rounded-md border transition-all duration-300 border-charcoal text-center py-2 text-[.875rem] leading-5 w-full capitalize',
              option.value === currentValue
                ? 'border-primary text-primary bg-primary/10 font-semibold'
                : 'border-charcoal/50 text-charcoal/50',
            )}
          >
            {option.label.toLowerCase()}
          </button>
        ))}
      </div>
    </div>
  );
}
