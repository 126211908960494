import { useMemo, useState } from 'react';

import { useUserQuery } from '@/api/authentication/hook';
import { useFiltersQuery } from '@/api/filters/hook';
import { DemoAccountModal } from '@/components/DemoAccountPopup';
import { useFilters } from '@/hooks/filters';

import { Checkbox } from './Checkbox';
import Dropdown from './Dropdown';

export function TrendsPattern() {
  const { data: userData } = useUserQuery('me');

  const isDemoAccount = userData?.me?.email === 'demo@tfashion.ai';

  const [showDemoPopup, setShowDemoPopup] = useState(false);

  const { selectedPatterns, selectPattern, selectedAudience, demoPatterns } =
    useFilters('trends');

  const { data, error } = useFiltersQuery('trendFilters', {
    audiences: selectedAudience ? [selectedAudience.id] : null,
  });

  const renderData = useMemo(() => {
    if (isDemoAccount) return demoPatterns;

    return data?.trendFilters?.patterns;
  }, [data, isDemoAccount, userData]);

  return (
    <>
      <DemoAccountModal
        isOpen={showDemoPopup}
        onClose={() => setShowDemoPopup(false)}
      />

      <Dropdown.Root open={showDemoPopup ? false : undefined}>
        <Dropdown.Trigger
          label="Pattern"
          selectedOptions={
            selectedPatterns.length > 0 ? selectedPatterns : null
          }
        />
        {error ? (
          <div>Error loading patterns</div>
        ) : (
          <Dropdown.Content>
            <Dropdown.Options>
              {(renderData || []).map((pattern) => (
                <Checkbox
                  label={pattern.label}
                  item={pattern}
                  id={pattern.value}
                  checked={
                    !isDemoAccount &&
                    selectedPatterns &&
                    selectedPatterns.find(
                      (item) => item.value === pattern.value,
                    )
                  }
                  onChange={!isDemoAccount && selectPattern}
                  key={pattern.value}
                  multiselect
                  onClick={() => {
                    isDemoAccount && setShowDemoPopup(true);
                  }}
                />
              ))}
            </Dropdown.Options>
          </Dropdown.Content>
        )}
      </Dropdown.Root>
    </>
  );
}
