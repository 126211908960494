import { useQuery } from '@apollo/client';

import {
  EXPLORE_FASHION_ITEMS,
  EXPLORE_FASHION_WEEK_CITIES,
  EXPLORE_FASHION_WEEK_FILTERS,
  EXPLORE_FASHION_WEEKS,
} from './queries';

export {
  EXPLORE,
  EXPLORE_FASHION_ITEMS,
  EXPLORE_FASHION_WEEK_CITIES,
  EXPLORE_FASHION_WEEK_FILTERS,
  EXPLORE_FASHION_WEEKS,
} from './queries';

export function useExploreFashionItems({ filters, cursor, limit, ...options }) {
  return useQuery(EXPLORE_FASHION_ITEMS, {
    variables: {
      filters,
      cursor,
      limit,
    },
    ...options,
  });
}

export function useExploreFashionItemFilters({ filters, ...options }) {
  return useQuery(EXPLORE_FASHION_WEEK_FILTERS, {
    variables: {
      filters,
    },
    ...options,
  });
}

export function useExploreFashionWeeks() {
  return useQuery(EXPLORE_FASHION_WEEKS);
}

export function useExploreFashionWeekCities() {
  return useQuery(EXPLORE_FASHION_WEEK_CITIES);
}
