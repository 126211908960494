import { Link } from 'react-router-dom';

import styles from '../index.module.css';

export function Root({ children, to, skeleton }) {
  if (skeleton) {
    return (
      <div
        className={[styles.rootWrapper, 'text-transparent cursor-default'].join(
          ' ',
        )}
      >
        {children}
      </div>
    );
  }

  return (
    <Link to={to} className={styles.rootWrapper}>
      {children}
    </Link>
  );
}
