import React from 'react';
import { Navigate } from 'react-router-dom';

import { useUserQuery } from '@/api/authentication/hook';

export const ModerationProtected = ({ children }) => {
  const { data } = useUserQuery('me');

  if (!data) {
    return <Navigate to="/login" />;
  }

  const moderationEnabled =
    data?.me?.role === 'admin' ||
    data?.me?.role === 'moderator' ||
    data?.me?.role === 'annotator';

  if (!moderationEnabled) {
    return <Navigate to="/explore" />;
  }

  return children;
};
