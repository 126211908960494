import { gapMap } from '@/components';

export function FlexRow({
  gap = 'md',
  items,
  justify,
  fluid = false,
  className = '',
  onClick,
  as = 'div',
  children,
}) {
  const Comp = as;

  return (
    <Comp
      className={[
        'flex',
        items ? `items-${items}` : '',
        justify ? `justify-${justify}` : '',
        fluid ? 'w-full' : '',
        gapMap[gap],
        className,
      ].join(' ')}
      onClick={onClick}
    >
      {children}
    </Comp>
  );
}
