import { useEffect, useMemo, useState } from 'react';
import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { debounce } from 'lodash';

import { EXPLORE_FASHION_ITEMS } from '@/api/explore/queries';
import { FlexRow } from '@/components';
import { LikeButton } from '@/components/LikeButton';
import {
  returnFashionItemFilters,
  useExploreContext,
} from '@/contexts/ExploreContext';
import { FashionWeeksProvider } from '@/contexts/FashionWeeksContext';
import { SaveToCollection } from '@/layouts/common/index';

import {
  ExploreLoadingScreen,
  LazyLoadTrigger,
  NoSuchResultsWereFound,
} from './components/utils';
import { Card, More } from './components';

function FashionItems() {
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const {
    fashionWeekFilters: filters,
    activeTab,
    shouldRefetch,
    setShouldRefetch,
  } = useExploreContext();

  const [
    fetchFashionItems,
    { data, networkStatus, loading, fetchMore, error, refetch },
  ] = useLazyQuery(EXPLORE_FASHION_ITEMS, {
    variables: {
      filters: returnFashionItemFilters(filters),
      search: filters.search?.value?.value,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (newData) => {
      if (data?.exploreFashionWeekItems?.cursor === null) {
        setFullyLoaded(true);
      } else {
        setFullyLoaded(false);
      }
    },
  });

  useEffect(() => {
    if (activeTab === 'fashion-weeks') {
      fetchFashionItems();
    }
  }, [activeTab]);

  useEffect(() => {
    if (shouldRefetch) {
      refetch({
        filters: returnFashionItemFilters(filters),
      });
      setShouldRefetch(false);
    }
  }, [shouldRefetch]);

  const debouncedRefetch = useMemo(() => debounce(refetch, 300), [refetch]);

  useEffect(() => {
    const searchValue = filters?.search?.value?.value;
    if (searchValue !== null) {
      debouncedRefetch({
        filters: returnFashionItemFilters(filters),
        search: searchValue,
      });
    }
  }, [filters?.search?.value?.value]);

  const loadingMore = networkStatus === 3;

  if (loading && !loadingMore) {
    return <ExploreLoadingScreen />;
  }

  if (error) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <p>{error.message}</p>
      </div>
    );
  }

  const { edges, pageInfo } = data?.exploreFashionWeekItems || {};
  const { endCursor, hasNextPage } = pageInfo || {};

  const renderLazyLoad =
    networkStatus !== NetworkStatus.fetchMore &&
    hasNextPage &&
    fullyLoaded === false;

  if (edges?.length === 0) {
    return (
      <div>
        <NoSuchResultsWereFound />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5">
      {edges?.map(({ node: exploreItem }, index) => (
        <Card.Root
          data={exploreItem}
          key={'explore_' + index}
          moderationEnabled={false}
        >
          <Card.Image />

          <Card.HoverContent>
            <FlexRow justify="between" items="center" className="-mr-12">
              <Card.Tones />

              <FlexRow className="self-end" justify="between" items="center">
                <SaveToCollection
                  id={exploreItem.id}
                  type="fashionItem"
                  variant="explore"
                />

                <LikeButton
                  item={exploreItem.id}
                  isLiked={exploreItem.isLiked}
                  type="fashionItem"
                />

                <More />
              </FlexRow>
            </FlexRow>

            <Card.Footer
              id={exploreItem.id}
              exploreItemId={exploreItem.photoItem}
              activeTab={activeTab}
            />
          </Card.HoverContent>
        </Card.Root>
      ))}

      {loadingMore &&
        Array.from({ length: 20 }).map((_, i) => (
          <div className="ghost aspect-[3/4] w-full" key={i}></div>
        ))}

      {renderLazyLoad && (
        <div className="mb-8 h-16 w-full bg-transparent">
          <LazyLoadTrigger
            fetchMore={() => {
              fetchMore({
                variables: {
                  cursor: endCursor,
                },
              });
            }}
            cursorNext={endCursor}
            loading={loading}
          />
        </div>
      )}
    </div>
  );
}

export function FashionWeekItems() {
  return (
    <FashionWeeksProvider>
      <FashionItems />
    </FashionWeeksProvider>
  );
}
