import {
  Content as RadixContent,
  Portal,
  SubContent as RadixSubContent,
} from '@radix-ui/react-dropdown-menu';
import { styled } from '@stitches/react';

import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from '@/animations';
import { FlexColumn, Grid, Text } from '@/components';

const StyledContent = styled(RadixContent, {
  display: 'flex',
  flexDirection: 'column',
  padding: '38px 31.26px',
  zIndex: 300,
  width: '800px',
  minWidth: '300px',
  backgroundColor: 'white',
  borderTop: 'none',
  gap: '1rem',
  borderBottom: '1px solid rgba(161, 161, 161, 1)',
  borderLeft: '1px solid rgba(161, 161, 161, 1)',
  borderRight: '1px solid rgba(161, 161, 161, 1)',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

const StyledSubContent = styled(RadixSubContent, {
  display: 'flex',
  flexDirection: 'column',
  padding: '38px 31.26px',
  overflow: 'scroll',
  flex: 1,
  zIndex: 300,
  width: '800px',
  minWidth: '300px',
  backgroundColor: 'white',
  borderTop: 'none',
  gap: '1rem',
  borderBottom: '1px solid rgba(161, 161, 161, 1)',
  borderLeft: '1px solid rgba(161, 161, 161, 1)',
  borderRight: '1px solid rgba(161, 161, 161, 1)',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

export const DropdownContentTitle = ({ title, children }) => {
  return (
    <Text weight="semibold" className="border-b border-charcoal">
      {title || children}
    </Text>
  );
};

export const ContentOptions = ({ children }) => {
  return <Grid gap="sm">{children}</Grid>;
};

export const DropdownContent = ({ children, defaultContent, ...props }) => {
  const isWithTitle = children[0]?.type?.name === 'ContentTitle';

  return (
    <Portal>
      {defaultContent ? (
        <RadixContent className="z-[300] w-[800px] min-w-[300px]" {...props}>
          {children}
        </RadixContent>
      ) : (
        <StyledContent {...props}>
          {isWithTitle ? (
            <FlexColumn gap="sm">{children}</FlexColumn>
          ) : (
            children
          )}
        </StyledContent>
      )}
    </Portal>
  );
};

export const DropdownSubContent = ({ children, ...props }) => {
  return (
    <Portal>
      <StyledSubContent sideOffset={2} alignOffset={-5} {...props}>
        {children}
      </StyledSubContent>
    </Portal>
  );
};
