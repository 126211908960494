import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';

import searchIcon from '@/assets/svg/search.svg';
import { cn } from '@/helpers/utils';

const HideGroupHeading = ({ className, ...props }) => {
  return (
    <div
      className={cn('collapse-group-heading', props.getClassNames())}
      onClick={() => {
        document
          .querySelector(`#${props.id}`)
          .parentElement.parentElement.classList.toggle('collapsed-group');
      }}
    >
      <components.GroupHeading {...props} />
    </div>
  );
};

const HideGroupMenuList = (props) => {
  const isSearch = props.selectProps.inputValue.length > 0;

  const newProps = {
    ...props,
    children: Array.isArray(props.children)
      ? props.children.map((c, idx) =>
          isSearch || idx === 0
            ? c
            : {
                ...c,
                props: {
                  ...c.props,
                  className: 'collapsed-group',
                },
              },
        )
      : props.children,
  };

  return <components.MenuList {...newProps} />;
};

export const SelectDropdown = ({
  placeholder,
  options,
  classNames,
  isAsync = false,
  isCreatable = false,
  isMulti = false,
  onChange,
  icon,
  valueLabel,
  variant,
  ...props
}) => {
  const isGroup = Boolean(options ? options[0]?.options : false);
  const isLoading = props.loading;

  const SelectComponent = isAsync
    ? isCreatable
      ? AsyncCreatableSelect
      : AsyncSelect
    : isCreatable
      ? CreatableSelect
      : Select;

  return (
    <SelectComponent
      classNames={{
        control: (state) =>
          cn(
            'border-night border-5 px-4 py-2 outline-none bg-white rounded-md flex items-center gap-2',
            state.isDisabled && 'bg-gray-100 cursor-not-allowed',
            classNames?.control,
          ),
        placeholder: (state) =>
          cn(
            'text-[#A1A1A1] text-[0.875rem] flex items-center gap-2',
            (variant === 'sort' || variant === 'search') && 'font-medium',
            variant !== 'search' && state.isFocused && 'text-black',
          ),
        valueContainer: (state) =>
          cn(
            'flex flex-row flex-wrap gap-2 items-center',
            variant === 'search' && 'pl-8',
          ),
        menu: (state) =>
          cn(
            'rounded-md outline-none w-full bg-[#F7F7F7] border border-night/5 space-y-2 mt-2 p-2 !z-[999] min-w-[230px] right-0 shadow-[0px_4px_8px_0px_#00000014]',
            classNames?.menu,
            variant === 'search' && !state.selectProps.inputValue && 'hidden',
          ),
        option: (state) =>
          cn(
            'transition-all hover:bg-[#FFFDFD] border border-transparent hover:border-[#1A1A1A/5] rounded cursor-pointer px-2 py-[.625rem] text-[0.875rem] leading-5',
          ),
        group: (state) => cn('my-1'),
        groupHeading: (state) => {
          return cn(
            'px-2 py-[.625rem] text-[#1A1A1A] font-semibold text-[0.875rem] leading-5 w-full rounded mb-2 flex items-center',
            !state.isFocused && 'bg-[#FFFDFD]',
          );
        },
        noOptionsMessage: (state) => 'text-center px-4 py-2',
        singleValue: (state) => {
          const tone = state.data?.hex;

          if (variant === 'primary' && state.data?.location) {
            return cn('font-normal text-[0.875rem] leading-5');
          }

          return cn(
            tone
              ? 'w-max text-base leading-none font-normal'
              : 'font-normal text-[0.875rem] leading-5',
          );
        },
        multiValue: (state) => {
          const color = state.data?.hex;

          return cn(
            'text-charcoal px-1 py-1 rounded-full text-sm font-medium leading-none border-4 border-primary/0',
            color ? '' : 'bg-primary/50',
          );
        },
        multiValueRemove: (state) => 'cursor-pointer ml-2',
        dropdownIndicator: (state) =>
          cn(
            state.hasValue &&
              state.selectProps.id !== 'hasIndicator' &&
              '!hidden',
            variant === 'search' && '!hidden',
          ),
        clearIndicator: (state) => 'text-charcoal',
        loadingIndicator: (state) => cn(isLoading === false && '!hidden'),
      }}
      styles={{
        multiValue: (provided, state) => {
          const color = state.data?.hex;

          return {
            ...provided,
            border: `2px solid ${color}`,
          };
        },
        valueContainer: (provided, state) => {
          const isSearch = variant === 'search';

          return {
            ...provided,
            backgroundImage: isSearch && `url(${searchIcon})`,
            backgroundRepeat: isSearch && 'no-repeat',
            backgroundPosition: isSearch && 'left',
            backgroundSize: isSearch && '1.5rem',
          };
        },
      }}
      components={{
        ...(isGroup
          ? {
              GroupHeading: HideGroupHeading,
              MenuList: HideGroupMenuList,
            }
          : {}),
      }}
      placeholder={placeholder}
      options={options}
      unstyled={true}
      isMulti={isMulti}
      onChange={onChange}
      formatOptionLabel={(option, { context }) => {
        if (context === 'value' && variant === 'primary' && option.location) {
          const locationString = option.location.toString();
          if (option.label.includes(locationString)) {
            return option.label;
          } else {
            return `${locationString}, ${option.label}`;
          }
        }
        return option.label;
      }}
      icon={icon}
      {...props}
    />
  );
};
