import {
  Root as RadixDropdownRoot,
  Sub as RadixDropdownSubRoot,
} from '@radix-ui/react-dropdown-menu';

import { FlexRow } from '@/components';

const dropdownColorScheme = {
  default:
    'bg-white text-black hover:bg-primary hover:text-white transition-all duration-200',
  primary: 'bg-primary text-white',
  none: '',
};

export const DropdownRoot = ({
  children,
  colorScheme = 'default',
  open,
  onOpenChange,
  defaultOpen,
  onlyRoot,
}) => {
  if (onlyRoot) {
    return (
      <RadixDropdownRoot
        onOpenChange={(open) => {
          onOpenChange && onOpenChange(open);
        }}
        open={open !== null || open !== undefined ? open : undefined}
        defaultOpen={defaultOpen}
      >
        {children}
      </RadixDropdownRoot>
    );
  }

  return (
    <FlexRow
      fluid
      className={[
        'min-w-[200px] cursor-pointer xl:w-full 2xl:min-w-[259px]',
        dropdownColorScheme[colorScheme],
      ].join(' ')}
    >
      <RadixDropdownRoot
        onOpenChange={(open) => {
          onOpenChange && onOpenChange(open);
        }}
        open={open !== null || open !== undefined ? open : undefined}
        defaultOpen={defaultOpen}
      >
        {children}
      </RadixDropdownRoot>
    </FlexRow>
  );
};

export const DropdownSubRoot = ({
  children,
  open,
  onOpenChange,
  defaultOpen,
}) => {
  return (
    <RadixDropdownSubRoot
      onOpenChange={(open) => {
        onOpenChange && onOpenChange(open);
      }}
      open={true}
      defaultOpen={true}
    >
      {children}
    </RadixDropdownSubRoot>
  );
};
