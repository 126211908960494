import { Content as RadixContent, Portal } from '@radix-ui/react-dropdown-menu';
import { styled } from '@stitches/react';

import {
  slideDownAndFade,
  slideLeftAndFade,
  slideRightAndFade,
  slideUpAndFade,
} from '../animations';

import styles from '../../../styles/Dropdown.module.css';

const StyledContent = styled(RadixContent, {
  display: 'flex',
  flexDirection: 'column',
  padding: '38px 31.26px',
  overflow: 'scroll',
  zIndex: 300,
  width: '800px',
  minWidth: '300px',
  backgroundColor: 'white',
  borderTop: 'none',
  gap: '1rem',
  borderBottom: '1px solid rgba(161, 161, 161, 1)',
  borderLeft: '1px solid rgba(161, 161, 161, 1)',
  borderRight: '1px solid rgba(161, 161, 161, 1)',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

export const ContentTitle = ({ title, children }) => {
  return <p className={styles.contentTitle}>{title || children}</p>;
};

export const ContentOptions = ({ children }) => {
  return <div className={styles.contentOptions}>{children}</div>;
};

export const Content = ({ children, ...props }) => {
  const isWithTitle = children[0]?.type?.name === 'ContentTitle';

  return (
    <Portal>
      <StyledContent {...props}>
        {isWithTitle ? (
          <div className={styles.contentWithTitle}>{children}</div>
        ) : (
          children
        )}
      </StyledContent>
    </Portal>
  );
};
