import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at 8 char long'),
  confirmPwd: Yup.string()
    .required('Password is required')
    .oneOf([Yup.ref('password')], 'Passwords does not match'),
});

export const resetPasswordOptions = {
  resolver: yupResolver(resetPasswordSchema),
};
