import React, { useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { keyframes, styled } from '@stitches/react';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const PopoverContent = styled(Popover.Content, {
  outline: 'none',
  backgroundColor: 'rgba(88, 88, 88, 0.81)',
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  '&[data-state="open"]': {
    '&[data-side="top"]': { animationName: slideDownAndFade },
    '&[data-side="right"]': { animationName: slideLeftAndFade },
    '&[data-side="bottom"]': { animationName: slideUpAndFade },
    '&[data-side="left"]': { animationName: slideRightAndFade },
  },
});

const PopoverArrow = styled(Popover.Arrow, {
  fill: 'rgba(88, 88, 88, 0.81)',
  height: '22px',
  width: '28px',
});

const AttentionIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_523_4783)">
        <path
          d="M6.99935 12.8346C10.221 12.8346 12.8327 10.223 12.8327 7.0013C12.8327 3.77964 10.221 1.16797 6.99935 1.16797C3.77769 1.16797 1.16602 3.77964 1.16602 7.0013C1.16602 10.223 3.77769 12.8346 6.99935 12.8346Z"
          stroke="#282928"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 9.33333V7"
          stroke="#282928"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 4.66797H7.00583"
          stroke="#282928"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_523_4783">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ReportHeader = ({ plan }) => {
  const [open, setOpen] = useState(false);

  const getColor = (plan) =>
    plan.name === 'premium'
      ? 'rgba(140, 68, 15, 1)'
      : plan.name === 'standard'
        ? 'rgba(77, 68, 147, 1)'
        : plan.name === 'basic' && 'rgba(37, 68, 95, 1)';

  return (
    <div
      className="flex h-[44px] w-full items-center justify-between px-[21px]"
      style={{ background: plan?.color }}
    >
      <span className="flex flex-1 items-center space-x-[8px]">
        <span className="pt-[2px] capitalize" style={{ color: getColor(plan) }}>
          {plan?.name} Plan
        </span>
        <Popover.Root open={open}>
          <Popover.Trigger asChild>
            <span
              onMouseEnter={() => setOpen(true)}
              onMouseLeave={() => setOpen(false)}
            >
              <AttentionIcon color={plan?.color} />
            </span>
          </Popover.Trigger>
          <Popover.Portal>
            <PopoverContent sideOffset={5}>
              <div className="flex w-[478px] flex-col px-[32px] pt-[26px]">
                <div className="flex flex-col">
                  <div className="mb-[21px] flex flex-col">
                    <h5 className="mb-[25px] text-[24px] font-semibold  capitalize leading-[28px] text-white">
                      {plan.name}
                    </h5>
                    <p className="font-semi-light text-[16px] leading-[18.48px]  text-white">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Quis tempus neque, magna libero nulla imperdiet ridiculus
                      dignissim. Ut purus non tortor porttitor posuere a sed mi
                      est. Amet nec, a ut vel iaculis. Accumsan viverra
                      consequat euismod dignissim in in.
                    </p>
                  </div>
                </div>
              </div>
              <PopoverArrow />
            </PopoverContent>
          </Popover.Portal>
        </Popover.Root>
      </span>
      <span className="text-white">{plan.price}$</span>
    </div>
  );
};
