import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState: { ...initialState },
  reducers: {
    setNotification: (state, action) => {
      state.isOpen = !state.isOpen;
      state.message = action.payload;
    },
  },
});

// this is for dispatch
export const { setNotification } = notificationSlice.actions;

// this is for configureStore
export default notificationSlice.reducer;
