import { useRef } from 'react';
import { Trigger as RadixTrigger } from '@radix-ui/react-dropdown-menu';

import { Icon } from '@/icons';

export const DropdownTrigger = ({
  children,
  icon,
  iconColor,
  disabled,
  onOpenChange,
  onlyChild,
}) => {
  const triggerRef = useRef(null);

  return (
    <RadixTrigger
      ref={triggerRef}
      asChild
      className="inline-flex"
      disabled={disabled}
      onOpenChange={(open) => {
        onOpenChange && onOpenChange(open);
      }}
    >
      {onlyChild ? (
        children
      ) : (
        <button className="flex w-full max-w-full items-center justify-between border-r border-[#A1A1A1] px-8 text-[18px] font-normal text-inherit outline-none data-closed:border-b">
          {children}

          {icon || <Icon name="chevron-down" color={iconColor} />}
        </button>
      )}
    </RadixTrigger>
  );
};
