import { createContext, useContext } from 'react';

// import styles from '../index.module.css'

const Context = createContext({
  tone: null,
});

export const useHeroContext = () => useContext(Context);

export function Root({ children, tone }) {
  return (
    <Context.Provider value={{ tone }}>
      {/* <div className={[styles.rootWrapper].join(' ')}>
        {tone && (
          <div
            className="absolute z-0 min-h-[21rem] w-full"
            style={{
              backgroundColor: tone ? tone.hex : '#fff'
            }}
          ></div>
        )}
      <div className="z-10 px-16">{children}</div>
      </div> */}
    </Context.Provider>
  );
}
