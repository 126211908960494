import React from 'react';

import { useReports } from '@/hooks/report';

export const LocationList = ({ children }) => {
  return (
    <div className="absolute top-[58px] w-full border-x-2 border-b-2 border-[#808080] bg-white px-[8px] py-[14px]">
      <div className="custom-scrollbar-second flex max-h-[208px] w-full flex-col gap-y-[8px] overflow-scroll overflow-x-hidden px-[24px]">
        {children}
      </div>
    </div>
  );
};

export function LocationListItem({ name, id, onCheck }) {
  const { selectLocation, location, marketLoading } = useReports();

  return (
    <div className="flex h-[28px] items-center">
      <input
        className="form-check-input checked:bordercharcoal float-left h-[14px] w-[14px] cursor-pointer appearance-none rounded-none border border-charcoal bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:bg-primary focus:outline-none"
        type="checkbox"
        disabled={marketLoading}
        onChange={() => {
          selectLocation({
            name,
            id,
          });

          onCheck();
        }}
        checked={location?.id === id}
        id={id}
      />
      <label
        htmlFor={id}
        className="ml-[8px] cursor-pointer pt-[3px] leading-[28px]"
      >
        {name}
      </label>
    </div>
  );
}
