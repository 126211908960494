import { ItemIndicator as RadixItemIndicator } from '@radix-ui/react-dropdown-menu';
import { styled } from '@stitches/react';

import { hexToHsl } from '@/helpers/hexToHsl';
import { Icon } from '@/icons';

export const DropdownItemIndicator = styled(RadixItemIndicator, {
  all: 'unset',
  cursor: 'pointer',
  backgroundColor: 'white',
  minWidth: 12,
  height: 12,
  border: '1px solid black',
  display: 'flex',
  alignItems: 'center',
  outlineColor: 'black',
  position: 'relative',
  justifyContent: 'center',
  '&:hover': { backgroundColor: 'rgba(194, 155, 119, 0.5)' },
  "&[data-state='checked']": {
    background: 'rgba(194, 155, 119, 1)',
  },
});

const CheckedIconWrapper = styled('div', {
  display: 'none',
  '&[data-state="checked"]': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const DropdownItemIndicatorColored = ({ color, forceMount }) => {
  return (
    <DropdownItemIndicator
      style={{ backgroundColor: color }}
      forceMount={forceMount}
    >
      <CheckedIconWrapper>
        <Icon name="close-x" color={hexToHsl(color) <= 50 && 'white'} />
      </CheckedIconWrapper>
    </DropdownItemIndicator>
  );
};
