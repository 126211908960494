import React, { useState } from 'react';

export function ImageSlider({ firstImage, secondImage, className, ...props }) {
  const [sliderPosition, setSliderPosition] = useState(50);
  const [isDragging, setIsDragging] = useState(false);

  const handleMove = (event) => {
    if (!isDragging) return;

    const rect = event.currentTarget.getBoundingClientRect();
    const x = Math.max(0, Math.min(event.clientX - rect.left, rect.width));
    const percent = Math.max(0, Math.min((x / rect.width) * 100, 100));

    setSliderPosition(percent);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <div className="relative w-full rounded" onMouseUp={handleMouseUp}>
      <div
        className="relative m-auto aspect-[3/4] w-full select-none overflow-hidden rounded"
        onMouseMove={handleMove}
        onMouseDown={handleMouseDown}
      >
        <img
          alt=""
          draggable={false}
          src={firstImage}
          style={{
            minWidth: '100%',
            minHeight: '100%',
            objectFit: 'cover',
            marginLeft: '1px',
          }}
        />

        <div
          className="absolute inset-x-0 top-0 m-auto w-full select-none overflow-hidden"
          style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}
        >
          <img
            alt=""
            draggable={false}
            src={secondImage}
            style={{ minWidth: '100%', minHeight: '100%', objectFit: 'cover' }}
          />
        </div>
        <div
          className="absolute inset-y-0 w-1 cursor-ew-resize bg-white"
          style={{
            left: `calc(${sliderPosition}% - 1px)`,
          }}
        >
          <div className="absolute -left-1 top-[calc(50%-5px)] h-4 w-4 rounded-full bg-white" />
        </div>
      </div>
    </div>
  );
}
