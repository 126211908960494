import React, { useEffect } from 'react';
export const LogOut = () => {
  useEffect(() => {
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    window.location.href = '/';
  }, []);
  return <>Logging out...</>;
};
