import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

export const trendsSlice = createSlice({
  name: 'trends',
  initialState: { ...initialState },
  reducers: {
    resetTrendFields: (state) => {
      state.trendItems = [];
      state.trendTones = [];
      state.trendFabrics = [];
      state.trendPatterns = [];
    },
    setTrendItems: (state, action) => {
      state.trendItems = [...state.trendItems, ...action.payload];
    },
    setTrendTones: (state, action) => {
      state.trendTones = [...state.trendTones, ...action.payload];
    },
    setTrendPatterns: (state, action) => {
      state.trendPatterns = [...state.trendPatterns, ...action.payload];
    },
    setTrendFabrics: (state, action) => {
      state.trendFabrics = [...state.trendFabrics, ...action.payload];
    },
  },
});

// this is for dispatch
export const {
  resetTrendFields,
  setTrendItems,
  setTrendTones,
  setTrendPatterns,
  setTrendFabrics,
} = trendsSlice.actions;

// this is for configureStore
export default trendsSlice.reducer;
