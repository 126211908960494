import React from 'react';

export const LayoutTwo = ({ active }) => {
  const stroke = active ? '#C29B77' : '#A1A1A1';

  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="group-hover:stroke-primary"
        x="19"
        y="1"
        width="14"
        height="32"
        stroke={stroke}
        strokeWidth="2"
      />
      <rect
        className="group-hover:stroke-primary"
        x="1"
        y="1"
        width="14"
        height="14"
        stroke={stroke}
        strokeWidth="2"
      />
      <rect
        className="group-hover:stroke-primary"
        x="1"
        y="19"
        width="14"
        height="14"
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  );
};
