import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import c from 'classnames';

import { useUser } from '@/api/authentication/hook';
import {
  useCollectionsMutations,
  useCollectionsQuery,
} from '@/api/collections/hooks';
import { COLLECTIONS } from '@/api/collections/queries';
import {
  Button,
  DropdownContent,
  DropdownRoot,
  DropdownTrigger,
  FlexColumn,
  Text,
} from '@/components';
import { useAppContext } from '@/contexts/AppContext';
import { useToast } from '@/hooks/useToast';
import { Icon } from '@/icons';

import Filters from './components/Filters';
import LayoutSelection from './components/LayoutSelection';

import styles from './index.module.css';

const layouts = [
  {
    value: 'gallery',
    icon: 'layout-one',
  },
  {
    value: 'two-col',
    icon: 'layout-two',
  },
  {
    value: 'three-col',
    icon: 'layout-three',
  },
];

function AccessButton({ selected, label, description, onChangeAccess }) {
  return (
    <FlexColumn
      as="button"
      gap="none"
      items="start"
      className={`py-4 px-[1.125rem] ${
        selected
          ? 'bg-primaryLight'
          : 'bg-white transition-colors hover:bg-primaryLight'
      }`}
      onClick={onChangeAccess}
      disabled={selected}
    >
      <Text weight="semibold" className={selected ? 'text-primary' : ''}>
        {label}
      </Text>
      <Text className="text-start text-gray-400" size="sm">
        {description}
      </Text>
    </FlexColumn>
  );
}

export function Header() {
  const { name, id: collection } = useParams();

  const { data, refetch } = useCollectionsQuery('collection', {
    variables: {
      id: collection,
    },
  });

  const { user } = useUser();

  const canChangeAccess =
    data?.collection?.owner.id === user?.id ||
    user.organizationRole === 'ADMIN';

  const navigate = useNavigate();
  const [deleteCollection] = useCollectionsMutations('deleteCollection', {
    onCompleted: () => {
      toast.createToast({
        message: 'Collection deleted successfully',
        type: 'success',
        showCloseButton: true,
        position: 'top-right',
      });

      navigate('/collections');
    },
    refetchQueries: [
      {
        query: COLLECTIONS,
        variables: {
          cursor: null,
        },
      },
    ],
  });

  const [changeAccess] = useCollectionsMutations('changeAccess', {
    onCompleted: () => {
      toast.createToast({
        message: 'Collection access changed successfully',
        type: 'success',
        showCloseButton: true,
        position: 'top-right',
      });

      refetch({
        id: collection,
      });
    },
  });

  function handleChangeAccess(access) {
    changeAccess({
      variables: {
        collection,
        access,
      },
    });
  }

  const toast = useToast();
  const [parentRef] = useAutoAnimate();

  const { collectionsLayout, changeCollectionsLayout } = useAppContext();

  const [askDelete, setAskDelete] = useState(false);

  return (
    <div className={styles.wrapper}>
      <div className={'flex basis-5/12'}>
        <h1 className={c(styles.title)}>{name}</h1>
      </div>

      <div className={'flex basis-2/12 justify-center'}>
        <LayoutSelection.Root>
          {layouts.map((layout, index) => (
            <>
              {index !== 0 && (
                <LayoutSelection.Divider
                  key={`layout-divider-key-${layout.value}`}
                />
              )}
              <LayoutSelection.Button
                key={layout.value}
                onClick={() => changeCollectionsLayout(layout.value)}
                icon={
                  <Icon
                    name={layout.icon}
                    active={collectionsLayout === layout.value}
                  />
                }
              />
            </>
          ))}
        </LayoutSelection.Root>
      </div>

      <div className={'flex basis-5/12 justify-end gap-5'} ref={parentRef}>
        {/* <Filters.Button icon={<Icon name="arrow-down" />}> */}
        {/*   Filter By */}
        {/* </Filters.Button> */}
        {/* <Filters.Button>Edit Collection</Filters.Button> */}
        {askDelete ? (
          <div className="flex items-center gap-4">
            <h1 className="text-lg font-bold">Are you sure?</h1>

            <Filters.Button
              className="border-yellow-600"
              onClick={() => setAskDelete(false)}
            >
              Cancel
            </Filters.Button>
            <Filters.Button
              className="border-red-600"
              onClick={() =>
                deleteCollection({
                  variables: {
                    collection,
                  },
                })
              }
            >
              Yes
            </Filters.Button>
          </div>
        ) : (
          <div className="flex items-center gap-4">
            {data && canChangeAccess && (
              <DropdownRoot colorScheme="none" onlyRoot>
                <DropdownTrigger onlyChild>
                  <Button variant="secondary" outline>
                    <Text size="md" weight="medium">
                      Sharing
                    </Text>
                  </Button>
                </DropdownTrigger>
                <DropdownContent
                  defaultContent
                  align="end"
                  className="z-50 max-w-sm"
                >
                  <FlexColumn
                    gap="none"
                    className="mt-2 border-2 border-charcoal"
                  >
                    <AccessButton
                      label="Private"
                      description="Only you and organization admins can view and edit this
                        collection. Ideal for drafts or personal projects."
                      onChangeAccess={() => handleChangeAccess('private')}
                      selected={data.collection.access === 'private'}
                    />
                    <AccessButton
                      label="Viewable by Organization"
                      description="Visible to everyone in the organization but editable only by you and organization admins. Perfect for sharing information without allowing modifications."
                      onChangeAccess={() =>
                        handleChangeAccess('organization_viewable')
                      }
                      selected={
                        data.collection.access === 'organization_viewable'
                      }
                    />
                    <AccessButton
                      label="Organization Collaboration"
                      description="Both visible and editable by everyone in the organization. Suitable for collaborative projects and team contributions."
                      onChangeAccess={() => handleChangeAccess('public')}
                      selected={data.collection.access === 'public'}
                    />
                  </FlexColumn>
                </DropdownContent>
              </DropdownRoot>
            )}
            <Filters.Button onClick={() => setAskDelete(true)}>
              Delete Collection
            </Filters.Button>
          </div>
        )}
      </div>
    </div>
  );
}
