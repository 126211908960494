import { configureStore } from '@reduxjs/toolkit';

import filterReducer from '@/features/filter/filterSlice';
import notificationReducer from '@/features/notification/notificationSlice';
import reportReducer from '@/features/report/reportSlice';
import trendsReducer from '@/features/trends/trendsSlice';

export default configureStore({
  reducer: {
    filters: filterReducer,
    notifications: notificationReducer,
    report: reportReducer,
    trends: trendsReducer,
  },
});
