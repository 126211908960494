import { reachMapping } from '@/constants';

export function Icon({ value, color, level }) {
  if (level) {
    color = reachMapping[parseInt(level)].color;
    value = parseInt(level);
  }

  return (
    <svg
      width="110"
      height="110"
      viewBox="0 0 110 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_61_2599)">
        <path
          d="M68.5802 57.9228C70.1935 51.0874 65.9601 44.2384 59.1248 42.6251C52.2894 41.0118 45.4404 45.2451 43.8271 52.0805C42.2138 58.9159 46.4472 65.7649 53.2825 67.3782C60.1179 68.9914 66.9669 64.7581 68.5802 57.9228Z"
          fill={color}
        />
        <path
          d="M56.0194 80.1792C42.1341 80.1792 30.8452 68.8837 30.8452 55.005C30.8452 41.1264 42.1407 29.8242 56.0194 29.8242C69.898 29.8242 81.2002 41.1197 81.2002 55.005C81.2002 68.8903 69.9047 80.1792 56.0194 80.1792ZM56.0194 34.798C44.8832 34.798 35.819 43.8623 35.819 54.9984C35.819 66.1345 44.8832 75.1988 56.0194 75.1988C67.1555 75.1988 76.2198 66.1345 76.2198 54.9984C76.2198 43.8623 67.1555 34.798 56.0194 34.798Z"
          fill={color}
          fillOpacity={value >= 1 ? '1' : '0.15'}
        />
        <path
          d="M55.8599 89.4621C36.8614 89.4621 21.4023 74.003 21.4023 55.0045C21.4023 36.006 36.8614 20.5469 55.8599 20.5469C74.8584 20.5469 90.3175 36.006 90.3175 55.0045C90.3175 74.003 74.8584 89.4621 55.8599 89.4621ZM55.8599 23.8671C38.6876 23.8671 24.7226 37.8388 24.7226 55.0045C24.7226 72.1702 38.6942 86.1418 55.8599 86.1418C73.0257 86.1418 86.9973 72.1702 86.9973 55.0045C86.9973 37.8388 73.0257 23.8671 55.8599 23.8671Z"
          fill={color}
          fillOpacity={value >= 2 ? '1' : '0.15'}
        />
        <path
          d="M55.8333 99.1773C31.4759 99.1773 11.6606 79.3621 11.6606 55.0047C11.6606 30.6473 31.4759 10.832 55.8333 10.832C80.1907 10.832 100.006 30.6473 100.006 55.0047C100.006 79.3621 80.1907 99.1773 55.8333 99.1773ZM55.8333 12.4922C32.3923 12.4922 13.3208 31.5637 13.3208 55.0047C13.3208 78.4457 32.3923 97.5172 55.8333 97.5172C79.2743 97.5172 98.3458 78.4457 98.3458 55.0047C98.3458 31.5637 79.2743 12.4922 55.8333 12.4922Z"
          fill={color}
          fillOpacity={value >= 3 ? '1' : '0.15'}
        />
        <path
          d="M55.0033 110C24.6761 110 0 85.3305 0 55.0033C0 24.6761 24.6761 0 55.0033 0C85.3305 0 110.007 24.6761 110.007 55.0033C110.007 85.3305 85.3305 110.007 55.0033 110.007V110ZM55.0033 1.65349C25.5859 1.66013 1.66013 25.5859 1.66013 55.0033C1.66013 84.4208 25.5925 108.347 55.0033 108.347C84.4141 108.347 108.347 84.4141 108.347 55.0033C108.347 25.5925 84.4141 1.66013 55.0033 1.66013V1.65349Z"
          fill={color}
          fillOpacity={value >= 4 ? '1' : '0.1'}
        />
      </g>
      <defs>
        <clipPath id="clip0_61_2599">
          <rect width="110" height="110" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
