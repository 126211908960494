import { useToast } from '@/hooks/useToast';

import { CloseButton } from './components/CloseButton';
import { ColorBar } from './components/ColorBar';
import { Content } from './components/Content';
import { Message } from './components/Message';
import { Root } from './components/Root';

export function ToastContainer() {
  const { toasts, removeToast } = useToast();

  if (toasts.length === 0) return null;

  return (
    <>
      {toasts.map((toast, index) => (
        <Root index={index} position={toast.position} key={index}>
          <Content>
            <Message>{toast.message}</Message>
            {toast.showCloseButton && (
              <CloseButton onClick={() => removeToast(toast.id)} />
            )}
          </Content>
          <ColorBar type={toast.type} />
        </Root>
      ))}
    </>
  );
}
