import { useContext } from 'react';

import { CreateReportContext } from '@/contexts/CreateReportContext';

export function useReports() {
  const ctx = useContext(CreateReportContext);
  if (!ctx) {
    throw new Error(
      'useReports must be used within a CreateReportContext.Provider',
    );
  }

  return ctx;
}
