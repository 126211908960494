import { Root as RadixDropdownRoot } from '@radix-ui/react-dropdown-menu';

const dropdownColorScheme = {
  default: 'bg-white text-black hover:bg-gray-100 transition',
  primary: 'bg-primary text-white',
};

export const Root = ({
  children,
  colorScheme = 'default',
  open,
  onOpenChange,
  defaultOpen,
  close,
}) => {
  return (
    <div
      className={[
        'flex min-w-[200px] w-full cursor-pointer xl:w-full 2xl:min-w-[259px]',
        dropdownColorScheme[colorScheme],
      ].join(' ')}
    >
      <RadixDropdownRoot
        onOpenChange={(open) => {
          onOpenChange && onOpenChange(open);
        }}
        open={open !== null || open !== undefined ? open : undefined}
        defaultOpen={defaultOpen}
      >
        {children}
      </RadixDropdownRoot>
    </div>
  );
};
