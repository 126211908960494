import { useContext } from 'react';

import { FiltersContext } from '@/contexts/FiltersContext';

export const useFilters = (filterType = 'explore') => {
  const context = useContext(FiltersContext);

  if (!context) {
    throw new Error('useFilters must be used within a FiltersProvider');
  }

  if (!['explore', 'trends'].includes(filterType)) {
    throw new Error('useFilters must be used with a valid filterType');
  }

  return {
    ...context.common,
    ...context[filterType],
  };
};
