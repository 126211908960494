import React from 'react';
import { useForm } from 'react-hook-form';

import { invitedToOrganizationFormOptions } from './invitedToOrganizationFormOptions';

const CheckIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6663 5L7.49967 14.1667L3.33301 10"
      stroke="#3CA780"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const InvitedToOrganization = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(invitedToOrganizationFormOptions);

  const onSubmit = (data) => {};

  return (
    <div className="mx-auto flex w-full max-w-[600px] flex-col items-center justify-center">
      <h5 className="mt-[114px] text-center text-[48px]  font-semibold  leading-[55px]">
        You Invited to X Organization by John Doe
      </h5>
      <form
        className="flex w-full max-w-[404px] flex-col"
        onSubmit={handleSubmit(onSubmit)}
      >
        <p className="mt-[57px] mb-[4px] flex w-full justify-start pl-[18px]  text-[16px] font-semibold leading-[28px]">
          Full Name
        </p>
        <input
          type="text"
          name="fullName"
          className="font-regular flex h-[55px] w-full border border-charcoal px-[18px] text-[14px] leading-[16px] outline-none placeholder:text-[#D9D9D9]"
          placeholder="Write Your Full Name"
          {...register('fullName', { required: true })}
        />
        {errors.fullName?.message && (
          <p className="mt-[4px] text-[16px] font-semibold leading-[28px] text-red-500 ">
            {errors.fullName?.message}
          </p>
        )}
        <p className="mt-[16px] mb-[4px] flex w-full justify-start pl-[18px]  text-[16px] font-semibold leading-[28px]">
          E-mail
        </p>
        <div className="relative flex w-full">
          <input
            type="email"
            name="email"
            defaultValue="alexanderynoshua@tfashion.com"
            className="font-regular flex h-[55px] w-full border border-charcoal px-[18px] text-[14px] leading-[16px] outline-none placeholder:text-[#D9D9D9]"
            placeholder="Write Co-worker E-mail"
            {...register('email', { required: true })}
          />
          <span className="absolute right-[18px] top-[18px]">
            <CheckIcon />
          </span>
        </div>
        {errors.email?.message && (
          <p className="mt-[4px] text-[16px] font-semibold leading-[28px] text-red-500 ">
            {errors.email?.message}
          </p>
        )}
        <p className="mt-[16px] mb-[4px] flex w-full justify-start pl-[18px]  text-[16px] font-semibold leading-[28px]">
          Password
        </p>
        <input
          type="password"
          name="password"
          className="font-regular mb-[32px] flex h-[55px] w-full border border-charcoal px-[18px] text-[14px] leading-[16px] outline-none placeholder:text-[#D9D9D9]"
          placeholder="Write Your Password"
          {...register('password', { required: true })}
        />
        {errors.password?.message && (
          <p className="mt-[4px] text-[16px] font-semibold leading-[28px] text-red-500 ">
            {errors.password?.message}
          </p>
        )}
        <button className="btn-primary-without-hover h-[46px]">Accept</button>
      </form>
    </div>
  );
};
