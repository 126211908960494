export default {
  color: '#111',
  fontWeight: 600,
  fontSize: 16,
  fontFamily: 'satoshi',
  height: 'max-content',
  border: 'none',
  letterSpacing: -0.5,
  zIndex: 50,
  backgroundColor: '#a1a1a105',
  width: 'max-content',
  position: 'absolute',
  display: 'inline-block',
};
