import { useEffect, useRef, useState } from 'react';
import { Blurhash } from 'react-blurhash';
import { CiSearch } from 'react-icons/ci';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { Watermark } from '@/pages/general/explore/components/Card/components/Image';

export function Image({ image, children }) {
  const [loading, setLoading] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const foreignObjectRef = useRef(null);

  const { type, id } = useParams();

  const [imageWidth, setImageWidth] = useState(image.width);
  const [imageHeight, setImageHeight] = useState(image.height);

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);
  const handleMouseMove = (e) => {
    if (foreignObjectRef.current) {
      const { left, top, width, height } =
        foreignObjectRef.current.getBoundingClientRect();
      const x = ((e.clientX - left) / width) * imageWidth;
      const y = ((e.clientY - top) / height) * imageHeight;
      setMousePosition({ x, y });
    }
  };

  useEffect(() => {
    const imageToLoad = new window.Image();
    imageToLoad.src = image.url;
    imageToLoad.onload = () => {
      setLoading(false);

      setImageWidth(imageToLoad.naturalWidth);
      setImageHeight(imageToLoad.naturalHeight);
    };
  }, [image]);

  const blur = image?.blurhash || 'LKO2?U%2Tw=w]~RBVZRi};RPxuof';

  if (loading) {
    return (
      <div className="relative">
        {blur && (
          <Blurhash
            height="100%"
            width="100%"
            hash={blur}
            resolutionX={32}
            resolutionY={32}
            punch={1}
            className="absolute inset-0"
          />
        )}

        <div className="absolute inset-0 flex items-center justify-center">
          <div
            className={
              'flex h-full max-h-[80%] w-full max-w-[90%] justify-center'
            }
          >
            {imageWidth && (
              <svg
                className="z-40 border border-white shadow-xl"
                viewBox={`0 0 ${imageWidth} ${imageHeight}`}
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio={'xMidYMid meet'}
              >
                <image
                  href={image?.url}
                  width={image.width}
                  height={image.height}
                />

                <foreignObject width={imageWidth} height={imageHeight}>
                  <Blurhash
                    height="100%"
                    width="100%"
                    hash={blur}
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                    className="border border-white shadow-xl"
                  />
                </foreignObject>
              </svg>
            )}
          </div>
          {children}
        </div>
      </div>
    );
  }

  return (
    <div className="relative flex overflow-auto">
      <div className="absolute inset-0 overflow-hidden">
        <img
          className="absolute inset-0 h-full w-full origin-center scale-[1.1] blur-3xl"
          src={image?.url}
        />
      </div>

      <div className="flex h-full w-full flex-col items-center justify-center">
        <div
          className={
            'animate-fadeIn flex h-full max-h-[80%] w-full max-w-[90%] justify-center opacity-0'
          }
          style={{
            animation: 'fadeIn 0.2s ease-in forwards',
          }}
        >
          <svg
            className="z-40 border border-white shadow-xl"
            viewBox={`0 0 ${imageWidth} ${imageHeight}`}
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio={'xMidYMid meet'}
          >
            <image href={image?.url} width={imageWidth} height={imageHeight} />

            <foreignObject
              width={imageWidth}
              height={imageHeight}
              ref={foreignObjectRef}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onMouseMove={handleMouseMove}
            >
              <div className="flex h-full w-full items-end justify-between">
                <div className="mb-4 ml-4 cursor-pointer rounded-full border-2 border-white p-4 hover:bg-gray-50/10">
                  <Link
                    to={`/explore/image-preview/${type}/${id}/similar-images`}
                  >
                    <CiSearch
                      className="text-white"
                      size={48}
                      strokeWidth={0.5}
                    />
                  </Link>
                </div>

                <div className={'pointer-events-none'}>
                  {type === 'fashionWeekItem' ? <Watermark /> : <div />}
                </div>
              </div>
              {isHovering && (
                <div
                  style={{
                    position: 'absolute',
                    left: `${mousePosition.x - 100}px`,
                    top: `${mousePosition.y - 100}px`,
                    width: '30rem',
                    height: '40rem',
                    border: '1px solid white',
                    borderRadius: '10%',
                    overflow: 'hidden',
                    transform: 'translate(-10%, -10%)',
                    pointerEvents: 'none',
                    zIndex: 1000,
                  }}
                >
                  <svg
                    width="100%"
                    height="100%"
                    viewBox={`${mousePosition.x} ${mousePosition.y} 150 150`}
                    preserveAspectRatio="xMidYMid slice"
                  >
                    <image
                      href={image?.url}
                      width={imageWidth}
                      height={imageHeight}
                    />
                  </svg>
                </div>
              )}
            </foreignObject>
          </svg>
        </div>
        {children}
      </div>
    </div>
  );
}
