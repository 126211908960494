import { Line, LineChart, ResponsiveContainer } from 'recharts';

const YearOverYearGrowthChart = ({ data, isDown }) => {
  return (
    <div className="flex w-full">
      <ResponsiveContainer width="100%" height={82}>
        <LineChart height={82} data={data}>
          <Line
            type="monotone"
            dataKey="full"
            dot={false}
            strokeWidth={4}
            stroke="#EEEEEE"
          />
          <Line
            type="monotone"
            dataKey="green"
            dot={false}
            strokeWidth={4}
            stroke={isDown ? '#FF0000' : '#3CA780'}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default YearOverYearGrowthChart;
