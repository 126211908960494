export const initialState = {
  activeReport: null,
  reports: [
    {
      id: '',
      plan: {},
      location: '',
      audience: [],
      categories: [],
    },
  ],
};
