export const reachMapping = {
  0: {
    text: 'Narrow',
    color: '#EBB563',
  },
  1: {
    text: 'Small',
    color: '#EBB563',
  },
  2: {
    text: 'Medium',
    color: '#CD9063',
  },
  3: {
    text: 'Big',
    color: '#639CCD',
  },
  4: {
    text: 'Massive',
    color: '#1c9a6d',
  },
};

export const apparelMapping = [
  'bikini',
  'swimsuit',
  'dress',
  'jumpsuit',
  'pant/trouser',
  'short',
  'shirt',
  'skirt',
  'blouse',
  'bag',
  'shoe',
  'glass',
  'glove',
  'earring',
  'ring',
  'sweatshirt',
  'top',
  'coat',
  'jeans',
  't-shirt',
  'sweater',
  'hat',
  'sock',
  'sunglass',
  'tie',
  'bracelet',
  'face-covering',
  'necklace&chain',
  'hijab',
  'cardigan',
  'boxer',
  'panties',
  'bra',
  'belt',
  'scarve',
  'head-accessory',
  'wallet',
  'watch',
  'vest',
  'jacket',
];

export const trendTypeMapping = {
  0: {
    name: 'Flat',
    color: 'rgba(41, 94, 244, 0.09)',
    definition:
      'This trend type refers to a product that remains stable in terms of sales or popularity. There is little to no growth or decline in demand for the product or category over time. While flat trends may not offer opportunities for significant growth, they can be valuable for maintaining a consistent source of revenue.',
  },
  1: {
    name: 'Steady Riser',
    color: 'rgba(41, 94, 244, 0.09)',
    definition:
      'This trend type refers to a product that is experiencing slow but steady growth in popularity over time. This type of trend may be driven by factors such as shifting consumer preferences or increased visibility on online channels. While the growth may not be rapid, it can offer opportunities for you to increase your market share gradually.',
  },
  2: {
    name: 'Seasonal Riser',
    color: '#FFF0EB',
    definition:
      'This trend type refers to a product that experiences slow but steady growth in popularity that is also seasonal in nature. This type of trend may be driven by seasonal events or cultural factors that impact consumer behavior. Your brand may need to adjust its inventory and marketing strategies to capitalize on seasonal risers.',
  },
  3: {
    name: 'Rising Star',
    color: 'rgba(41, 94, 244, 0.09)',
    definition:
      'This trend type refers to a product that is experiencing rapid growth in popularity over time. This type of trend may be driven by factors such as a celebrity endorsement or a viral social media posts. Brands that are able to identify and capitalize on rising stars earlier on can experience significant growth in revenue and market share.',
  },
  4: {
    name: 'Falling Star',
    color: '#FFF0EB',
    definition:
      'This trend type refers to a product or category that is experiencing rapid decline in popularity over time. This type of trend may be driven by factors such as changing consumer preferences. Brands that are unable to respond quickly to falling stars may experience decreased revenue and market share.',
  },
  5: {
    name: 'Seasonal Decliner',
    color: '#FFF0EB',
    definition:
      'This trend type refers to a product that experiences slow but steady decline in popularity that is also seasonal in nature. This type of trend may be driven by seasonal events or cultural factors that impact consumer behavior. Brands may need to adjust their inventory and marketing strategies to minimize losses from seasonal decliners.',
  },
  6: {
    name: 'Steady Decliner',
    color: '#FFF0EB',
    definition:
      'This trend type refers to a product that is experiencing slow but steady decline in popularity over time. This type of trend may be driven by factors such as changing consumer preferences or increased competition. Brands may need to adjust their strategies to manage inventory and pricing to minimize losses from steady decliners.',
  },
};

export const seasons = [
  {
    id: 0,
    label: 'Spring',
    value: '0',
    socialWatch: true,
  },
  {
    id: 1,
    label: 'Summer',
    value: '1',
    socialWatch: true,
  },
  {
    id: 2,
    label: 'Fall',
    value: '2',
    socialWatch: true,
  },
  {
    id: 3,
    label: 'Winter',
    value: '3',
    socialWatch: true,
  },
];

export const platforms = [
  {
    id: 0,
    label: 'Instagram',
    value: 'instagram',
  },
  {
    id: 1,
    label: 'TikTok',
    value: 'tiktok',
  },
];

export const seasonsMapping = {
  0: {
    label: 'Spring',
    value: '0',
  },
  1: {
    label: 'Summer',
    value: '1',
  },
  2: {
    label: 'Fall',
    value: '2',
  },
  3: {
    label: 'Winter',
    value: '3',
  },
};

export const genderMapping = {
  1: {
    label: 'Male',
    shortLabel: 'M',
    value: '1',
  },
  2: {
    label: 'Women',
    shortLabel: 'W',
    value: '2',
  },
};

export const colorMap = {
  black: '#000000',
  silver: '#C0C0C0',
  gray: '#808080',
  white: '#FFFFFF',
  red: '#FF0000',
  pink: '#FFC0CB',
  maroon: '#800000',
  lavender: '#E6E6FA',
  purple: '#800080',
  green: '#008000',
  lime: '#00FF00',
  olive: '#808000',
  yellow: '#FFFF00',
  gold: '#FFD700',
  orange: '#FFA500',
  blue: '#0000FF',
  navy: '#000080',
  teal: '#008080',
  aqua: '#00FFFF',
  beige: '#F5F5DC',
  brown: '#964B00',
};

export const loginMessages = [
  "Great to have you back! Let's redefine fashion trends together.",
  'Log in to keep exploring sustainable, AI-powered fashion insights.',
  'Excited to see you! Continue your AI-empowered fashion journey.',
  'Your AI-powered fashion guide awaits. Log in now!',
];

export const errorMessages = {
  invalidCredentials: 'Invalid credentials',
};

export const fwSeasons = [
  { label: 'spring/summer 2017', value: 'spring-summer-2017' },
  { label: 'fall/winter 2018', value: 'fall-winter-2018' },
  { label: 'resort/2017', value: 'resort-2017' },
  { label: 'resort/2019', value: 'resort-2019' },
  { label: 'spring/summer 2018', value: 'spring-summer-2018' },
  { label: 'resort/2024', value: 'resort-2024' },
  { label: 'pre/fall 2018', value: 'pre-fall-2018' },
  { label: 'pre/fall 2020', value: 'pre-fall-2020' },
  { label: 'spring/summer 2023', value: 'spring-summer-2023' },
  { label: 'spring/summer 2022', value: 'spring-summer-2022' },
  { label: 'fall/winter 2016', value: 'fall-winter-2016' },
  { label: 'fall/winter 2022', value: 'fall-winter-2022' },
  { label: 'spring/summer 2019', value: 'spring-summer-2019' },
  { label: 'resort/2022', value: 'resort-2022' },
  { label: 'resort/2020', value: 'resort-2020' },
  { label: 'resort/2018', value: 'resort-2018' },
  { label: 'pre/fall 2022', value: 'pre-fall-2022' },
  { label: 'resort/2021', value: 'resort-2021' },
  { label: 'pre/fall 2021', value: 'pre-fall-2021' },
  { label: 'fall/winter 2017', value: 'fall-winter-2017' },
  { label: 'fall/winter 2023', value: 'fall-winter-2023' },
  { label: 'resort/2023', value: 'resort-2023' },
  { label: 'fall/winter 2021', value: 'fall-winter-2021' },
  { label: 'spring/summer 2021', value: 'spring-summer-2021' },
  { label: 'fall/winter 2019', value: 'fall-winter-2019' },
  { label: 'spring/summer 2020', value: 'spring-summer-2020' },
  { label: 'spring/summer 2024', value: 'spring-summer-2024' },
  { label: 'pre/fall 2023', value: 'pre-fall-2023' },
  { label: 'pre/fall 2019', value: 'pre-fall-2019' },
  { label: 'pre/fall 2017', value: 'pre-fall-2017' },
  { label: 'fall/winter 2020', value: 'fall-winter-2020' },
  { label: 'pre/fall 2024', value: 'pre-fall-2024' },
];

export const demoFilters = {
  patterns: [
    { label: 'plain', value: 'plain' },
    { label: 'abstract', value: 'abstract' },
    { label: 'cartoon', value: 'cartoon' },
    { label: 'letters&numbers', value: 'letters&numbers' },
    { label: 'camouflage', value: 'camouflage' },
    { label: 'check', value: 'check' },
    { label: 'dot', value: 'dot' },
    { label: 'fair-isle', value: 'fair-isle' },
    { label: 'floral', value: 'floral' },
    { label: 'geometric', value: 'geometric' },
    { label: 'paisley', value: 'paisley' },
    { label: 'stipe', value: 'stipe' },
    { label: 'houndstooth', value: 'houndstooth' },
    { label: 'herringbone', value: 'herringbone' },
    { label: 'chevron', value: 'chevron' },
    { label: 'argyle', value: 'argyle' },
    { label: 'animal-skin', value: 'animal-skin' },
    { label: 'toile-de-jouy', value: 'toile-de-jouy' },
    { label: 'tie-dye', value: 'tie-dye' },
    { label: 'letteres&numbers', value: 'letteres&numbers' },
    { label: 'stripe', value: 'stripe' },
    { label: 'plant', value: 'plant' },
    { label: 'cartoon&letters', value: 'cartoon&letters' },
  ],
  apparels: [
    { label: 'dress', value: 2 },
    { label: 'bikini', value: 0 },
    { label: 'swimsuit', value: 1 },
    { label: 'jumpsuit', value: 3 },
    { label: 'pant/trouser', value: 4 },
    { label: 'short', value: 5 },
    { label: 'shirt', value: 6 },
    { label: 'skirt', value: 7 },
    { label: 'blouse', value: 8 },
    { label: 'bag', value: 9 },
    { label: 'shoe', value: 10 },
    { label: 'glass', value: 11 },
    { label: 'glove', value: 12 },
    { label: 'earring', value: 13 },
    { label: 'ring', value: 14 },
    { label: 'sweatshirt', value: 15 },
    { label: 'top', value: 16 },
    { label: 'coat', value: 17 },
    { label: 'jeans', value: 18 },
    { label: 't-shirt', value: 19 },
    { label: 'sweater', value: 20 },
    { label: 'hat', value: 21 },
    { label: 'sock', value: 22 },
    { label: 'sunglass', value: 23 },
    { label: 'tie', value: 24 },
    { label: 'bracelet', value: 25 },
    { label: 'face-covering', value: 26 },
    { label: 'necklace&chain', value: 27 },
    { label: 'hijab', value: 28 },
    { label: 'cardigan', value: 29 },
    { label: 'boxer', value: 30 },
    { label: 'panties', value: 31 },
    { label: 'bra', value: 32 },
    { label: 'belt', value: 33 },
    { label: 'scarve', value: 34 },
    { label: 'head-accessory', value: 35 },
    { label: 'wallet', value: 36 },
    { label: 'watch', value: 37 },
    { label: 'vest', value: 38 },
    { label: 'jacket', value: 39 },
  ],
};

export const trendItemTypeMapping = {
  1: ['apparelLabelId'],
  2: ['tones.main'],
  3: ['tones.code'],
  4: ['pattern', 'attributes.value'],
  5: ['fabric', 'attributes.value'],
  6: ['apparelLabelId', 'tones.main'],
  7: ['apparelLabelId', 'tones.code'],
  8: ['apparelLabelId', 'pattern', 'attributes.value'],
  9: ['apparelLabelId', 'fabric', 'attributes.value'],
  10: ['apparelLabelId', 'attributes.name', 'attributes.value'],
  11: ['apparelLabelId', 'tones.main', 'attributes.name', 'attributes.value'],
  12: ['apparelLabelId', 'tones.code', 'attributes.name', 'attributes.value'],
  13: [
    'apparelLabelId',
    'attributes.name',
    'attributes.value',
    'pattern',
    'attributes.value',
  ],
  14: [
    'apparelLabelId',
    'attributes.name',
    'attributes.value',
    'fabric',
    'attributes.value',
  ],
  15: ['tones.main', 'pattern', 'attributes.value'],
  16: ['tones.main', 'fabric', 'attributes.value'],
  17: ['tones.code', 'pattern', 'attributes.value'],
  18: ['tones.code', 'fabric', 'attributes.value'],
  19: ['pattern', 'attributes.value', 'fabric', 'attributes.value'],
  20: ['tones.main', 'apparelLabelId', 'pattern', 'attributes.value'],
  21: ['apparelLabelId', 'tones.main', 'fabric', 'attributes.value'],
  22: [
    'apparelLabelId',
    'pattern',
    'attributes.value',
    'fabric',
    'attributes.value',
  ],
  23: [
    'apparelLabelId',
    'attributes.name',
    'attributes.value',
    'attributes.name',
    'attributes.value',
  ],
  24: [
    'tones.main',
    'apparelLabelId',
    'attributes.name',
    'attributes.value',
    'attributes.name',
    'attributes.value',
  ],
  25: [
    'tones.code',
    'apparelLabelId',
    'attributes.name',
    'attributes.value',
    'attributes.name',
    'attributes.value',
  ],
  26: [
    'apparelLabelId',
    'attributes.name',
    'attributes.value',
    'attributes.name',
    'attributes.value',
    'pattern',
    'attributes.value',
  ],
  27: [
    'apparelLabelId',
    'attributes.name',
    'attributes.value',
    'attributes.name',
    'attributes.value',
    'fabric',
    'attributes.value',
  ],
  28: [
    'apparelLabelId',
    'attributes.name',
    'attributes.value',
    'pattern',
    'attributes.value',
    'fabric',
    'attributes.value',
  ],
  29: [
    'apparelLabelId',
    'attributes.name',
    'attributes.value',
    'attributes.name',
    'attributes.value',
    'pattern',
    'attributes.value',
    'fabric',
    'attributes.value',
  ],
  30: [
    'tones.main',
    'pattern',
    'attributes.value',
    'fabric',
    'attributes.value',
  ],
  31: [
    'tones.code',
    'pattern',
    'attributes.value',
    'fabric',
    'attributes.value',
  ],
  32: [
    'apparelLabelId',
    'tones.main',
    'pattern',
    'attributes.value',
    'fabric',
    'attributes.value',
  ],
  33: ['apparelLabelId', 'tones.code', 'pattern', 'attributes.value'],
  34: ['apparelLabelId', 'tones.code', 'fabric', 'attributes.value'],
  35: [
    'apparelLabelId',
    'tones.code',
    'pattern',
    'attributes.value',
    'fabric',
    'attributes.value',
  ],
};

export const apparelList = [
  'bikini',
  'swimsuits',
  'dress',
  'jumpsuits',
  'pants/trousers',
  'shorts',
  'shirts',
  'skirts',
  'blouse',
  'bags',
  'shoes',
  'glasses',
  'gloves',
  'earrings',
  'rings',
  'sweatshirt',
  'top',
  'coat',
  'jeans',
  't-shirts',
  'sweater',
  'hats',
  'socks',
  'sunglasses',
  'ties',
  'bracelets',
  'face-covering',
  'necklace&chains',
  'hjiab',
  'cardigan',
  'boxer',
  'panties',
  'bras',
  'belts',
  'scarves',
  'head-accessories',
  'wallets',
  'watch',
  'vest',
  'jacket',
];

export const generationTypesMap = {
  sketches: 'SKETCH',
  patterns: 'PATTERN',
  designs: 'APPAREL',
};
