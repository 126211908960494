import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { X } from 'lucide-react';

import { DisplayMode, useStudioContext } from '@/contexts/StudioContext';
import { cn } from '@/helpers/utils';
import { SaveToCollection } from '@/layouts/common/index';

import { SidebarButton } from './components/SidebarMenu';
import { ImagePreview, TileImage } from './components/StudioContents';
import StudioImage from './components/StudioImage';
import { GenerationElapsed } from './components/StudioUtils';

const useImageDisplay = (generatedImages, orientation) => {
  const {
    selectedGeneratedImage,
    setSelectedGeneratedImage,
    displayMode,
    setDisplayMode,
    openTools,
    setOpenTools,
    studioTask,
    setReferenceImage,
    setReferencePattern,
  } = useStudioContext();

  useEffect(() => {
    if (studioTask?.images?.length > 0) {
      if (studioTask?.images?.length === 4) {
        setDisplayMode(DisplayMode.RESULT.TILE);
      } else {
        setDisplayMode(DisplayMode.PREVIEW);
        setOpenTools(true);
      }
    } else {
      setDisplayMode(displayMode);
    }
  }, [studioTask?.images, studioTask?.status]);

  const handleImageClick = (index) => {
    setSelectedGeneratedImage(index);
    setDisplayMode(DisplayMode.RESULT.SOLO);
  };

  const closePreview = () => {
    setDisplayMode(DisplayMode.RESULT.TILE);
    setReferenceImage(null);
    setReferencePattern(null);
    setSelectedGeneratedImage(-1);
    if (openTools === true) setOpenTools(false);
  };

  return {
    displayMode,
    setDisplayMode,
    selectedGeneratedImage,
    handleImageClick,
    closePreview,
    studioTask,
  };
};

const ImageHeader = ({
  taskStatus,
  generatedImages,
  selectedGeneratedImage,
  closePreview,
  downloadFile,
}) => {
  if (
    [
      'PREPARING',
      'PENDING',
      'GENERATING',
      'READY_FOR_GENERATION',
      'PENDING_GENERATION',
    ].includes(taskStatus)
  ) {
    return (
      <h2 className="pb-6 text-2xl font-bold leading-6 text-primary">
        Generating <GenerationElapsed />
      </h2>
    );
  }

  if (taskStatus === 'COMPLETED') {
    return selectedGeneratedImage !== -1 ? (
      <PreviewHeader
        selectedImage={generatedImages[selectedGeneratedImage]}
        onClose={closePreview}
        downloadFile={downloadFile}
      />
    ) : (
      <h2 className="pb-6 text-2xl font-bold leading-6 text-primary">Result</h2>
    );
  }

  if (taskStatus === 'FAILED') {
    return (
      <h2 className="pb-6 text-2xl font-bold leading-6 text-primary">FAILED</h2>
    );
  }

  if (taskStatus === 'LOADING') {
    return (
      <h2 className="pb-6 text-2xl font-bold leading-6 text-primary">
        Loading
      </h2>
    );
  }

  return (
    <h2 className="pb-6 text-2xl font-bold leading-6 text-primary">Result</h2>
  );
};

const ImageContent = ({
  displayMode,
  studioTask,
  generatedImages,
  orientation,
  selectedGeneratedImage,
  handleImageClick,
}) => {
  const { selectedTool, setSelectedArea, setReferenceImage } =
    useStudioContext();
  const [canZoomIn, setCanZoomIn] = useState(false);

  useEffect(() => {
    if (selectedTool === 'ZOOM_IN_IMAGE') {
      setCanZoomIn(true);
    } else {
      setCanZoomIn(false);
    }
  }, [selectedTool]);

  const debouncedSetSelectedArea = useMemo(
    () => debounce(setSelectedArea, 100),
    [setSelectedArea],
  );

  if (
    [
      'LOADING',
      'PENDING',
      'GENERATING',
      'READY_FOR_GENERATION',
      'PENDING_GENERATION',
    ].includes(studioTask?.status)
  ) {
    return <GeneratingPlaceholder />;
  }
  if (
    studioTask?.status === 'COMPLETED' &&
    displayMode === DisplayMode.RESULT.TILE
  ) {
    return (
      <TileView
        images={generatedImages}
        orientation={orientation}
        onImageClick={handleImageClick}
      />
    );
  }
  if (
    studioTask?.status === 'COMPLETED' &&
    displayMode === DisplayMode.RESULT.SOLO
  ) {
    return (
      <ImagePreview
        images={generatedImages}
        activeIndex={selectedGeneratedImage}
        setActiveIndex={handleImageClick}
      />
    );
  }
  if (
    studioTask?.status === 'COMPLETED' &&
    displayMode === DisplayMode.PREVIEW
  ) {
    return generatedImages.length > 0 ? (
      <StudioImage
        id={generatedImages[0].studioItemId}
        image={generatedImages[0]}
        selectedAreaType="square"
        isEditing={canZoomIn}
        onChangeSelectedArea={(box) => {
          debouncedSetSelectedArea(box);
          if (selectedTool === 'zoomOut') {
            debouncedSetSelectedArea({
              x: 0,
              y: 0,
              width: generatedImages[0].height * 0.2,
              height: generatedImages[0].width * 0.2,
            });
          }
        }}
        onClickHook={() => {
          setReferenceImage({
            entityType: generatedImages[0].entityType || 'STUDIO_ITEM',
            entityId:
              generatedImages[0].entityId || generatedImages[0].studioItemId,
            url: generatedImages[0].url,
          });
        }}
      />
    ) : (
      <GeneratingPlaceholder />
    );
  }
  return <GeneratingPlaceholder />;
};

const GeneratingPlaceholder = () => {
  const { orientation, createItem } = useStudioContext();

  return (
    <div className="flex min-w-[40rem] max-w-[50rem] flex-col gap-6 rounded-lg bg-primaryLight">
      <div
        className={cn('grid grid-rows-1 grid-cols-1 gap-6', {
          'grid-cols-2': createItem.pendingAreaBoxCount === 4,
          'aspect-[3/4]': orientation === 'PORTRAIT',
          'aspect-square': orientation === 'SQUARE',
          'aspect-[4/3]': orientation === 'LANDSCAPE',
        })}
      >
        {Array.from({ length: createItem.pendingAreaBoxCount }).map(
          (_, index) => (
            <div
              key={index}
              className={cn('ghost rounded', {
                'aspect-[3/4]': orientation === 'PORTRAIT',
                'aspect-square': orientation === 'SQUARE',
                'aspect-[4/3]': orientation === 'LANDSCAPE',
              })}
            ></div>
          ),
        )}
      </div>
    </div>
  );
};

const TileView = ({ images, orientation, onImageClick }) => {
  const formattedStr = orientation?.toLowerCase();
  const aspect =
    formattedStr === 'square'
      ? '[1/1]'
      : formattedStr === 'portrait'
        ? '[3/4]'
        : '[4/3]';
  return (
    <div className="grid grid-cols-2 grid-rows-2 gap-6">
      {images.map((image, index) => (
        <TileImage
          key={index}
          imageUrl={image.url}
          itemId={image.studioItemId}
          aspect={aspect}
          alt={image.studioItemId}
          onClick={() => onImageClick(index)}
        />
      ))}
    </div>
  );
};

const PreviewHeader = ({ selectedImage, onClose, downloadFile }) => {
  return (
    <div className="flex items-center justify-between">
      <h2 className="pb-6 text-2xl font-bold leading-6 text-primary">Result</h2>
      <div className="flex items-start gap-x-4 pb-4">
        <SaveToCollection
          id={selectedImage?.studioItemId}
          type="studioItem"
          variant="studio"
        />
        <SidebarButton
          text="Download"
          variant="tool"
          className="py-2 px-4 font-bold"
          onClick={() => downloadFile(selectedImage?.url, 'ai-generated-image')}
        />
        <div className="flex cursor-pointer items-center gap-x-4 hover:text-primary">
          <X size={36} onClick={onClose} strokeWidth={1.5} />
        </div>
      </div>
    </div>
  );
};

export const ImageDisplay = ({
  generatedImages,
  orientation,
  downloadFile,
}) => {
  const {
    displayMode,
    selectedGeneratedImage,
    handleImageClick,
    closePreview,
    studioTask,
  } = useImageDisplay(generatedImages, orientation);
  return (
    <div className="flex max-w-[50rem] flex-col rounded-lg border border-secondary/50 bg-primaryLight p-6">
      <ImageHeader
        displayMode={displayMode}
        taskStatus={studioTask?.status}
        generationTaskType={studioTask?.generateTaskType}
        generatedImages={generatedImages}
        selectedGeneratedImage={selectedGeneratedImage}
        closePreview={closePreview}
        downloadFile={downloadFile}
      />
      <ImageContent
        displayMode={displayMode}
        studioTask={studioTask}
        generatedImages={generatedImages}
        orientation={orientation}
        selectedGeneratedImage={selectedGeneratedImage}
        handleImageClick={handleImageClick}
      />
    </div>
  );
};
