import { createContext, useContext } from 'react';

export const PopoverContext = createContext();

export function usePopoverContext() {
  const context = useContext(PopoverContext);

  if (!context) {
    throw new Error('PopoverContext is not provided');
  }

  return context;
}
