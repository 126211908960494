import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAutoAnimate } from '@formkit/auto-animate/react';

import {
  useOrganizationMutation,
  useOrganizationQuery,
} from '@/api/organization/hook';
import { TFashion } from '@/assets/svg';
import { NotificationBox } from '@/components/NotificationBox';

import { invitationFormOptions } from './loginFormOptions';

const Navbar = () => (
  <div className="flex min-h-[90px] w-full border-b border-[#A1A1A1] bg-white px-[60px]">
    <div className="flex w-[212.8px] items-center">
      <TFashion />
    </div>
  </div>
);

export const AcceptInvitation = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const invitationToken = searchParams.get('token');

  const { data, loading, error } = useOrganizationQuery('verifyInvitation', {
    variables: { token: invitationToken },
  });

  if (!invitationToken) {
    navigate('/sign-up', { replace: true });
  }

  if (!loading && data?.verifyInvitation?.status === 'ACCEPTED') {
    navigate('/sign-up', { replace: true });
  }

  if (!loading && data?.verifyInvitation?.status === 'REJECTED') {
    navigate('/sign-up', { replace: true });
  }

  if (error) {
    navigate('/sign-up', { replace: true });
  }

  if (!loading && !data) {
    navigate('/sign-up', { replace: true });
  }

  const [
    acceptInvitation,
    { loading: acceptInvitationLoading, error: acceptInvitationError },
  ] = useOrganizationMutation('acceptInvitation', {
    onCompleted: (data) => {
      const { accessToken, refreshToken, user } = data.acceptInvitation;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('user', JSON.stringify(user));
      navigate('/overview', { replace: true });
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(invitationFormOptions);

  const onSubmit = (data) => {
    const { fullName, password } = data;
    const input = {
      fullName,
      password,
      token: invitationToken,
    };
    acceptInvitation({ variables: { input } });
  };

  const [formRef] = useAutoAnimate();

  return (
    <div className="flex h-full w-full flex-col">
      <Navbar />
      <div className="flex w-full flex-1">
        <div className="flex h-auto w-[100%] flex-col items-center justify-center lg:w-[45%]">
          <div className="flex flex-col items-center">
            <h3 className="mb-[16px] text-[48px] font-semibold leading-[55px]">
              Welcome
            </h3>
            <p
              className={`font-regular mb-[16px] text-[14px] leading-[173%] text-[#626775] ${
                loading
                  ? 'h-6 w-full animate-pulse bg-slate-400 !text-transparent'
                  : ''
              }`}
            >
              You have been invited to join{' '}
              {data?.verifyInvitation?.organization?.name} by{' '}
              {data?.verifyInvitation?.inviter?.fullName}.
            </p>
          </div>
          <form
            className="mb-[22px] flex h-auto w-[404px] flex-col gap-6"
            onSubmit={handleSubmit(onSubmit)}
            ref={formRef}
          >
            {error && (
              <NotificationBox
                color="red"
                title="Something went wrong"
                content={error.message}
              />
            )}
            {acceptInvitationError && (
              <NotificationBox
                color="red"
                title="Something went wrong"
                content={acceptInvitation.message}
              />
            )}
            {(errors.fullName?.message || errors.password?.message) && (
              <NotificationBox
                color="red"
                title="Missing fields"
                content={[
                  errors.fullName?.message || null,
                  errors.password?.message || null,
                ]}
              />
            )}
            <div className="flex flex-col gap-[14px]">
              <div className="flex w-full flex-col gap-1">
                <p className="h-[28px] px-[16px] pb-1 text-base font-semibold ">
                  Email
                </p>
                <input
                  className={`h-[47px] border border-charcoal px-4 outline-0 disabled:cursor-not-allowed disabled:bg-gray-300 ${
                    loading ? 'disabled: animate-pulse' : ''
                  }`}
                  type="email"
                  disabled
                  value={data?.verifyInvitation?.email}
                />
              </div>
              <div className="flex w-full flex-col gap-1">
                <p className="h-[28px] px-[16px] pb-1 text-base font-semibold ">
                  Full Name
                </p>
                <input
                  className="h-[47px] border border-charcoal px-4 outline-0"
                  type="fullName"
                  placeholder="Full Name"
                  {...register('fullName', { required: true })}
                />
              </div>

              <div className="flex w-full flex-col gap-[4px]">
                <p className="h-[28px] px-[16px] pb-[4px] text-base font-semibold">
                  Password
                </p>
                <input
                  className="h-[47px] border border-charcoal px-4 outline-0"
                  type="password"
                  autoComplete="on"
                  placeholder="Password"
                  {...register('password', { required: true })}
                />
              </div>
            </div>
            <button className="btn-primary-without-hover h-[46px]">
              {acceptInvitationLoading ? 'Submitting...' : 'Accept Invitation'}
            </button>
          </form>
        </div>
        <div className="hidden h-auto w-[55%] bg-[url('/src/assets/image/login.png')] bg-cover bg-no-repeat lg:block" />
      </div>
    </div>
  );
};
