export function CloseX({ color }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00021 5.58599L11.9502 0.635986L13.3652 2.05099L8.41521 7.00099L13.3652 11.951L11.9502 13.365L7.00021 8.41499L2.05021 13.365L0.637207 11.95L5.58721 6.99999L0.637207 2.04999L2.05021 0.637986L7.00021 5.58799V5.58599Z"
        fill={color || 'black'}
      />
    </svg>
  );
}
