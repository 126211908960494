import React from 'react';

export const LocationCaption = () => {
  return (
    <>
      <h5 className="mb-[6px] text-[32px] font-semibold  leading-[37px]">
        Location
      </h5>
      <p className="font-regular text-[14px] leading-[173%] text-[#626775] ">
        Navigate through global fashion dynamics by specifying locations,
        ensuring localized trend analysis and pertinent forecasting.
      </p>
    </>
  );
};
