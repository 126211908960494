import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { REGISTER_USER } from '@/api/authentication/mutations.js';
import { TFashion } from '@/assets/svg';
import { NotificationBox } from '@/components/NotificationBox';

import { signUpFormOptions } from './signUpFormOptions';

const Navbar = () => (
  <div className="flex min-h-[90px] w-full border-b border-[#A1A1A1] bg-white px-[60px]">
    <div className="flex w-[212.8px] items-center">
      <TFashion />
    </div>
  </div>
);

const Links = () => (
  <p className="font-regular text-[16px] leading-[28px] text-[#000000]">
    Do you have an account?
    <NavLink
      className="ml-1 border-b-2 border-primary font-semibold text-primary"
      to="/login"
    >
      Login
    </NavLink>
  </p>
);

export const SignUpOnboarding = () => {
  const [emailConfirmed, setEmailConfirmed] = useState(false);

  const [registerUser, { loading, error }] = useMutation(REGISTER_USER, {
    onCompleted: () => {
      setEmailConfirmed(true);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(signUpFormOptions);

  const onSubmit = (data) => {
    const { fullName, email, password, organizationName } = data;
    const input = {
      fullName,
      email,
      password,
      organizationName,
    };
    registerUser({ variables: { input } });
  };

  return (
    <div className="flex h-full w-full flex-col">
      <Navbar />
      <div className="flex w-full flex-1 flex-col items-center justify-center">
        <div className="flex flex-col items-center">
          <h3 className="mb-[16px] text-[48px] font-semibold leading-[55px]">
            Sign Up
          </h3>
        </div>
        {emailConfirmed && (
          <div className="mb-4">
            <NotificationBox
              color="primary"
              title="One more step!"
              content={[
                'We have sent you a confirmation email.',
                'Please check your inbox and follow the instructions to complete your registration.',
              ]}
            />
          </div>
        )}
        <form
          className="mb-[22px] flex h-auto w-[404px] flex-col"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-[14px]">
            <div className="flex w-full flex-col gap-[4px]">
              <p className="h-[28px] px-[16px] pb-[4px] text-[16px] font-semibold leading-[28px]">
                Full Name
              </p>
              <input
                className="h-[47px] border border-charcoal px-[16px] outline-0"
                placeholder="Write Your Full Name"
                {...register('fullName', { required: true, maxLength: 30 })}
              />
              {errors.fullName?.message && (
                <p className="mt-[4px] text-[16px] font-semibold leading-[28px] text-red-500">
                  {errors.fullName?.message}
                </p>
              )}
            </div>

            <div className="flex w-full flex-col gap-[4px]">
              <p className="h-[28px] px-[16px] pb-[4px] text-[16px] font-semibold leading-[28px] ">
                E-mail
              </p>
              <input
                className="h-[47px] border border-charcoal px-[16px] outline-0"
                type="email"
                placeholder="Write Your E-mail"
                {...register('email', { required: true })}
              />
              {errors.email?.message && (
                <p className="mt-[4px] text-[16px] font-semibold leading-[28px] text-red-500 ">
                  {errors.email?.message}
                </p>
              )}
            </div>

            <div className="flex w-full flex-col gap-[4px]">
              <p className="h-[28px] px-[16px] pb-[4px] text-[16px] font-semibold leading-[28px]">
                Password
              </p>
              <input
                className="h-[47px] border border-charcoal px-[16px] outline-0"
                type="password"
                autoComplete="on"
                placeholder="Password"
                {...register('password', { required: true })}
              />
              {errors.password?.message && (
                <p className="mt-[4px] text-[16px] font-semibold leading-[28px] text-red-500">
                  {errors.password?.message}
                </p>
              )}
            </div>

            <div className="flex w-full flex-col gap-[4px]">
              <p className="h-[28px] px-[16px] pb-[4px] text-[16px] font-semibold leading-[28px]">
                Organization Name
              </p>
              <input
                className="h-[47px] border border-charcoal px-[16px] outline-0"
                placeholder="Write Your Organization’s Name"
                {...register('organizationName', {
                  required: true,
                  maxLength: 30,
                })}
              />
              {errors.organizationName?.message && (
                <div className="mt-4">
                  <NotificationBox
                    color="red"
                    title="Missing fields"
                    content={[errors.organizationName?.message || '']}
                  />
                </div>
              )}
            </div>

            {error && (
              <div className="mt-4">
                <NotificationBox
                  color="red"
                  title="Something went wrong"
                  content={error.message}
                />
              </div>
            )}

            <div className="flex items-center justify-start">
              <input
                className="form-check-input float-left h-[14px] w-[14px] cursor-pointer appearance-none rounded-none border border-charcoal bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-charcoal checked:bg-primary focus:outline-none"
                type="checkbox"
                value=""
                id="privacy"
              />
              <label
                htmlFor="privacy"
                className="color-charcoal ml-[8px] flex cursor-pointer justify-center pt-0.5 text-[12px]"
              >
                {' '}
                Privacy policy
              </label>
            </div>

            <div className="mb-[22px] flex items-center justify-start">
              <input
                className="form-check-input float-left h-[14px] w-[14px] cursor-pointer appearance-none rounded-none border border-charcoal bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-charcoal checked:bg-primary focus:outline-none"
                type="checkbox"
                value=""
                id="terms"
              />
              <label
                htmlFor="terms"
                className="color-charcoal ml-[8px] flex cursor-pointer justify-center pt-0.5 text-[12px]"
              >
                {' '}
                Terms & conditions
              </label>
            </div>

            <button className="btn-primary-without-hover h-[46px]">
              {loading ? 'Submitting...' : 'Sign Up'}
            </button>
          </div>
        </form>
        <Links />
      </div>
    </div>
  );
};
