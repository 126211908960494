export const colorOptions = [
  { hex: '#f3ece0', label: 'Beige', value: 'beige' },
  { hex: '#efe1ce', label: 'Brown', value: 'brown' },
  { hex: '#ecead0', label: 'Green', value: 'green' },
  { hex: '#f6ebc8', label: 'Yellow', value: 'yellow' },
  { hex: '#f4f9ff', label: 'White', value: 'white' },
  { hex: '#e5d9d3', label: 'Lavender', value: 'lavender' },
  { hex: '#f3dfca', label: 'Orange', value: 'orange' },
  { hex: '#ede4df', label: 'Pink', value: 'pink' },
  { hex: '#e1e3de', label: 'Gray', value: 'gray' },
  { hex: '#e2eaeb', label: 'Blue', value: 'blue' },
  { hex: '#daf2f1', label: 'Aqua', value: 'aqua' },
  { hex: '#d3dec4', label: 'Lime', value: 'lime' },
  { hex: '#d3cca3', label: 'Olive', value: 'olive' },
  { hex: '#fbd897', label: 'Gold', value: 'gold' },
  { hex: '#d7d7c7', label: 'Silver', value: 'silver' },
  { hex: '#9eb6b8', label: 'Teal', value: 'teal' },
  { hex: '#c9a38d', label: 'Maroon', value: 'maroon' },
  { hex: '#cc7457', label: 'Red', value: 'red' },
  { hex: '#c6a4a4', label: 'Purple', value: 'purple' },
  { hex: '#829ca5', label: 'Navy', value: 'navy' },
  { hex: '#2d2c2f', label: 'Black', value: 'black' },
].sort((a, b) => a.label.localeCompare(b.label));
