import * as Tabs from '@radix-ui/react-tabs';

import { TabHeading } from '@/components/TabHeading';
import { cn } from '@/helpers/utils';

const tabs = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Uploads',
    value: 'FILE_UPLOAD',
  },
  {
    label: 'Generations',
    value: 'STUDIO_ITEM',
  },
];

export function StudioExploreTabs({ className, activeTab, setActiveTab }) {
  return (
    <div className={cn('overflow-hidden py-[0.25rem]', className)}>
      <Tabs.Root
        value={activeTab}
        onValueChange={(tab) => {
          setActiveTab(tab);
        }}
        className={'relative'}
      >
        <Tabs.List
          aria-label="tabs"
          className={'flex items-center  justify-between '}
        >
          <div className={'flex gap-2'}>
            {tabs.map((tab, i) => (
              <TabHeading
                variant="studioExplore"
                label={tab.label}
                value={tab.value}
                key={i}
              />
            ))}
          </div>
        </Tabs.List>
      </Tabs.Root>
    </div>
  );
}
