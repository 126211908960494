import { ImSpinner8 } from 'react-icons/im';

import { useMarketsMutation } from '@/api/market/hook';
import { useReports } from '@/hooks/report';

export const SelectPlanButton = ({ item }) => {
  const { plan } = useReports();

  const planSelected = plan && plan.slug === item.slug;

  const [createCheckoutSession, { loading }] = useMarketsMutation(
    'createMarketPlanCheckoutSession',
    {
      onCompleted: (data) => {
        window.location.href = data?.createMarketPlanCheckoutSession.url;
      },
    },
  );

  return (
    <button
      className="flex h-[54px] cursor-pointer items-center justify-center gap-2 border-x-2 border-b-2 border-charcoal bg-primary text-[18px] font-semibold leading-[21px] text-white group-hover:border-primary"
      onClick={() => {
        createCheckoutSession({
          variables: {
            successUrl: `${window.location.origin}/studio-licence/assign-members`,
            cancelUrl: `${window.location.origin}/create-trend-report/select-a-plan`,
            planSlug: item.slug,
          },
        });
      }}
      style={{
        background: planSelected && '#3CA780',
        borderColor: planSelected && '#3CA780',
      }}
      disabled={loading}
    >
      {loading && <ImSpinner8 className="animate-spin" />}
      {planSelected ? 'Selected' : 'Select Plan'}
    </button>
  );
};
