import React from 'react';
import { Navigate } from 'react-router-dom';

import * as Pages from '@/pages/general';

import { Private } from './types';

export const account = [
  {
    path: 'profile',
    element: <Pages.Profile />,
    children: [
      {
        index: true,
        element: <Navigate to="account-details" replace />,
      },
      {
        path: 'account-details',
        element: (
          <Private>
            <Pages.AccountDetails />
          </Private>
        ),
      },
      {
        path: 'payment',
        element: (
          <Private>
            <Pages.Payment />
          </Private>
        ),
      },
      {
        path: 'team',
        element: (
          <Private>
            <Pages.Team />
          </Private>
        ),
      },
      {
        path: 'activity',
        element: (
          <Private>
            <Pages.Activity />
          </Private>
        ),
      },
      {
        path: 'plans',
        element: (
          <Private>
            <Pages.Plans />
          </Private>
        ),
      },
    ],
  },
];
