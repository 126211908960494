import React from 'react';

import { useReports } from '@/hooks/report';

export const AudienceCreateNew = () => {
  const { addEmptyAudience } = useReports();

  return (
    <button
      className="mt-[24px] flex items-center justify-end pr-[16px] text-[14px] font-semibold underline"
      onClick={addEmptyAudience}
    >
      + Create New Audience List
    </button>
  );
};
