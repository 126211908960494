import React from 'react';

import { useReports } from '@/hooks/report';

export const AudienceSave = ({ id, audience }) => {
  const { updateAudience, deleteAudience, tempAudience } = useReports();
  return (
    <div className="mt-12 flex items-center justify-between">
      <button
        className="inline-flex border-0 text-[18px] font-semibold  leading-[31px] text-red-600 outline-none"
        onClick={() =>
          audience.empty
            ? deleteAudience(audience.id)
            : updateAudience({ edit: false, ...tempAudience })
        }
      >
        Discard Changes
      </button>
      <button
        className="inline-flex border-0 text-[18px] font-semibold  leading-[31px] text-[#3CA780] outline-none"
        onClick={() =>
          updateAudience({
            id: audience.id,
            edit: false,
            empty: false,
          })
        }
      >
        Save Changes
      </button>
    </div>
  );
};
