import React from 'react';

export const Star = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_61_3186)">
        <path
          d="M6.99984 1.16699L8.80234 4.81866L12.8332 5.40783L9.9165 8.24866L10.6048 12.262L6.99984 10.3662L3.39484 12.262L4.08317 8.24866L1.1665 5.40783L5.19734 4.81866L6.99984 1.16699Z"
          stroke="#282928"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_61_3186">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
