export function returnSocialMediaFilters(searchParams) {
  const apparels =
    searchParams
      .get('apparels')
      ?.split(',')
      .map((apparel) => ({
        value: apparel,
        items: [],
      })) || [];

  const colors = searchParams.get('colors')?.split(',');

  const toneCode = searchParams.get('tone');

  const tone = toneCode
    ? {
        code: searchParams.get('tone'),
        similarity: 5,
      }
    : null;

  const season = searchParams.get('season');

  const patterns = searchParams.get('patterns')?.split(',');

  return {
    apparels,
    colors,
    tone,
    season,
    patterns,
  };
}

export function returnFashionItemFilters(searchParams) {
  const city =
    searchParams.get('fashionWeek') === 'all'
      ? null
      : searchParams.get('fashionWeek');
  const season = searchParams.get('season');
  const designer = searchParams.get('designer');
  const category = searchParams.get('category');
  const itemType = searchParams.get('itemType');

  return {
    city,
    season,
    designer,
    category,
    itemType,
  };
}
