import uuid from 'react-uuid';
import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';

export const reportSlice = createSlice({
  name: 'report',
  initialState: { ...initialState },
  reducers: {
    resetActiveReport: (state, action) => {
      state.activeReport = action.payload ? action.payload : null;
    },
    createReport: (state, action) => {
      const { id, name, price, color, scope } = action.payload;
      state.activeReport = id;
      const report = {
        id,
        plan: { name, price, color, scope },
        location: '',
        audience: [],
        categories: [],
      };
      state.reports =
        state.reports[0].id !== '' ? [...state.reports, report] : [report];
    },
    updatePlan: (state, action) => {
      const { activeReport } = state;
      const index = state.reports.findIndex(
        (report) => report.id === activeReport,
      );
      state.reports[index].plan = { ...action.payload };
    },
    setLocation: (state, action) => {
      const { name } = action.payload;
      const { activeReport } = state;
      const index = state.reports.findIndex((item) => item.id === activeReport);
      if (state.reports[index]?.location === name) {
        state.reports[index].location = '';
      } else {
        state.reports[index].location = name;
      }
    },
    deleteAudienceList: (state, action) => {
      const index = state.reports.findIndex(
        (report) => report.id === state.activeReport,
      );
      const filteredAudienceList = state.reports[index].audience.filter(
        (item) => item.id !== action.payload,
      );
      state.reports[index].audience = [...filteredAudienceList];
    },
    createAudienceList: (state) => {
      const { activeReport } = state;
      const index = state.reports?.findIndex(
        (item) => item.id === activeReport,
      );
      const completedEdits = state.reports?.[index].audience.map((obj) => {
        return { ...obj, edit: false };
      });
      state.reports[index].audience = [...completedEdits];
      state.reports[index].audience = [
        ...state.reports[index].audience,
        {
          id: uuid(),
          name: `Audience List ${state.reports[index].audience.length + 1}`,
          gender: 'Men',
          age: [18, 25],
          edit: true,
        },
      ];
    },
    updateAudienceList: (state, action) => {
      const { activeReport } = state;
      const { newObj } = action.payload;
      const index = state.reports.findIndex((item) => item.id === activeReport);
      const newState = state.reports[index].audience.map((obj) => {
        if (obj.id === newObj.id) {
          return { ...newObj };
        }
        return obj;
      });
      state.reports[index].audience = [...newState];
    },
    setCategories: (state, action) => {
      const { activeReport } = state;
      const index = state.reports.findIndex((item) => item.id === activeReport);
      const { name } = action.payload;
      state.reports[index].categories = state.reports[index].categories.some(
        (item) => item.name === name,
      )
        ? state.reports[index].categories.filter((item) => item.name !== name)
        : [...state.reports[index].categories, { ...action.payload }];
    },
  },
});

// this is for dispatch
export const {
  resetActiveReport,
  createReport,
  updatePlan,
  setLocation,
  deleteAudienceList,
  createAudienceList,
  updateAudienceList,
  setCategories,
} = reportSlice.actions;

// this is for configureStore
export default reportSlice.reducer;
