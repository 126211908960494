import React from 'react';

import { useReports } from '@/hooks/report';

import { CategoryIcon, MapPinIcon } from '../../icons';

export const CategoryCaption = () => {
  const { plan, apparelGroups, location } = useReports();

  return (
    <>
      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <MapPinIcon />

          <span className="pt-[4px] font-planc text-base font-semibold">
            {location?.name}
          </span>
        </div>

        <div className="flex items-center gap-2">
          <CategoryIcon />
          <span className="pt-[4px] text-[14px] leading-[16px] text-[#C29B77]">
            Categories: {apparelGroups.length} / {plan?.scope?.apparel}
          </span>
        </div>
      </div>

      <h5 className="mb-[6px] text-[32px] font-semibold leading-[37px]">
        Categories
      </h5>
      <p className="font-regular mb-[18px] text-[14px] leading-[173%]  text-[#626775]">
        Segment and explore fashion trends by distinct clothing categories,
        enabling precise and relevant insights that directly inform your design
        and marketing strategies.
      </p>
    </>
  );
};
