import { createContext, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

const FashionWeeksContext = createContext();

const validFilters = [
  'city',
  'fashionWeekId',
  'season',
  'designer',
  'category',
];

export function FashionWeeksProvider({ children }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedFashionWeek = searchParams.get('fashionWeekId');
  const selectedSeason = searchParams.get('season');
  const selectedCity = searchParams.get('city');
  const selectedDesigner = searchParams.get('designer');
  const selectedCategory = searchParams.get('category');
  const latestCursor = searchParams.get('cursor');

  const defaultLimit = 50;

  function changeFilter(field, value) {
    if (!validFilters.includes(field)) {
      console.error(`Invalid filter: ${field}`);
    }

    if (!value) {
      return;
    }

    setSearchParams({
      ...searchParams,
      [field]: value,
    });
  }

  const searchFilters = {
    filter: {
      city: selectedCity || undefined,
      fashionWeekId: selectedFashionWeek || undefined,
      season: selectedSeason || undefined,
      designer: selectedDesigner || undefined,
      category: selectedCategory || undefined,
    },
    cursor: latestCursor || undefined,
    limit: defaultLimit,
  };

  return (
    <FashionWeeksContext.Provider
      value={{
        selectedFashionWeek,
        selectedSeason,
        selectedCity,
        selectedDesigner,
        selectedCategory,
        setSearchParams,
        changeFilter,
        searchFilters,
      }}
    >
      {children}
    </FashionWeeksContext.Provider>
  );
}

export function useFashionWeeks() {
  const context = useContext(FashionWeeksContext);
  if (context === undefined) {
    throw new Error(
      'useFashionWeeks must be used within a FashionWeeksProvider',
    );
  }
  return context;
}
