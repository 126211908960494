import { useState } from 'react';
import c from 'classnames';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/AlertDialog';
import { Icon } from '@/icons';

import styles from './index.module.css';

export const RemoveFromCollection = ({ show, onClick }) => {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <div
          className={c(
            styles.overlayButton,
            show ? 'opacity-1 bg-transparent hover:bg-primary' : 'opacity-0',
          )}
        >
          <Icon name="trash" />
        </div>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure to remove item?</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to remove this item from your collection?{' '}
            <br />
            You will not be able to undo this action.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={onClick} variant={'destructive'}>
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export function Overlay({ onClick, title, show = false }) {
  return (
    <>
      <div
        className={c(styles.overlayWrapper, show ? 'opacity-1' : 'opacity-0')}
      >
        {/* <div className="text-2xl font-bold text-white">{title}</div> */}
      </div>
    </>
  );
}

export function WithOverlay({ onClick, children, title, className }) {
  const [showOverlay, setShowOverlay] = useState(false);

  const handleMouseEnter = () => {
    setShowOverlay(true);
  };

  const handleMouseLeave = () => {
    setShowOverlay(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={[styles.itemCard, 'relative', className].join(' ')}
    >
      <Overlay onClick={onClick} title={title} show={showOverlay} />

      {children}
    </div>
  );
}
