import { FilterDropdown } from '@/components/FilterDropdown';
import { useAppFilters } from '@/contexts/AppFiltersContext';

export function FashionItemTypeFilter() {
  const { filters, handleTypeOnChange } = useAppFilters();
  const { type } = filters;

  return (
    <FilterDropdown
      label={type.label}
      placeholder={type.placeholder}
      options={type.options}
      value={type.value}
      isLoading={type.loading}
      onChange={handleTypeOnChange}
      iSearchable
      isClearable
      autoFocus
    />
  );
}
