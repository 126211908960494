import { gql } from '@apollo/client';

export const GENERATE_STUDIO_ITEM = gql`
  mutation GenerateStudioItem($payload: GenerateImageInput!) {
    generateStudioItem(payload: $payload) {
      id
    }
  }
`;

export const UPDATE_FILE_STATUS = gql`
  mutation Mutation($fileId: ID!, $status: STATUS!) {
    updateFileStatus(fileId: $fileId, status: $status)
  }
`;

export const NEW_PROMPT_SUGGESTION = gql`
  mutation StudioSuggestNewPrompts(
    $prompt: String!
    $generationType: STUDIO_TASK_TYPE!
  ) {
    studioSuggestNewPrompts(prompt: $prompt, generationType: $generationType) {
      prompt
      id
      suggestions {
        key
        value
      }
    }
  }
`;

export const CREATE_STUDIO_TASK = gql`
  mutation CreateNewStudioTask($payload: GenerateStudioTaskInput!) {
    createNewStudioTask(payload: $payload) {
      id
      status
      createdAt
    }
  }
`;

export const FILL_PAYLOAD_STUDIO_TASK = gql`
  mutation FillPayloadStudioTask(
    $fillPayloadStudioTaskId: ID!
    $payload: StudioTaskFillPayloadInput!
  ) {
    fillPayloadStudioTask(id: $fillPayloadStudioTaskId, payload: $payload) {
      id
      status
      createdAt
    }
  }
`;

export const PROCESS_STUDIO_TASK = gql`
  mutation ProcessStudioTask($processStudioTaskId: ID!) {
    processStudioTask(id: $processStudioTaskId) {
      id
      status
      createdAt
    }
  }
`;

export const STUDIO_BG_REMOVER = gql`
  mutation StudioBackgroundRemover($payload: BackgroundRemoverInput!) {
    studioBackgroundRemover(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_INPAINTING = gql`
  mutation StudioInpainting($payload: InpaintingInput!) {
    studioInpainting(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_UPSCALE = gql`
  mutation StudioImageUpscale($payload: UpscaleImageInput!) {
    studioImageUpscale(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_IMAGE_ENHANCE = gql`
  mutation StudioImageEnhance($payload: EnhanceImageInput!) {
    studioImageEnhance(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_CHANGE_BACKGROUND = gql`
  mutation StudioChangeBackground($payload: ChangeBackgroundInput!) {
    studioChangeBackground(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_COLOR_TRANSFER = gql`
  mutation StudioColorTransfer($payload: ColorTransferInput!) {
    studioColorTransfer(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_REALIZE_SKETCH = gql`
  mutation StudioRealizeSketch($payload: RealizeSketchInput!) {
    studioRealizeSketch(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_IMAGE_MANIPULATION = gql`
  mutation StudioImageManipulation($payload: ImageManipulationInput!) {
    studioImageManipulation(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_GENERATE_PATTERN = gql`
  mutation StudioGeneratePattern($payload: GeneratePatternInput!) {
    studioGeneratePattern(payload: $payload) {
      id
      taskId
    }
  }
`;

export const SUGGESTION_BENCHMARK = gql`
  mutation StudioSelectSuggestedPrompt($payload: SelectPromptSuggestion!) {
    studioSelectSuggestedPrompt(payload: $payload) {
      id
    }
  }
`;

export const STUDIO_IMAGE_UPSCALE = gql`
  mutation StudioImageUpscale($payload: UpscaleImageInput!) {
    studioImageUpscale(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_REMOVE_BG = gql`
  mutation StudioBackgroundRemover($payload: BackgroundRemoverInput!) {
    studioBackgroundRemover(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_ZOOM_IN = gql`
  mutation StudioImageZoomIn($payload: ZoomInImageInput!) {
    studioImageZoomIn(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_ZOOM_OUT = gql`
  mutation StudioImageZoomOut($payload: ZoomOutImageInput!) {
    studioImageZoomOut(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_CREATE_VARIANTS = gql`
  mutation StudioCreateVariants($payload: CreateVariantsInput!) {
    studioCreateVariants(payload: $payload) {
      id
      taskId
    }
  }
`;

export const STUDIO_PATTERN_TILE = gql`
  mutation StudioGeneratePatternTile($payload: GeneratePatternTileInput!) {
    studioGeneratePatternTile(payload: $payload) {
      id
      taskId
    }
  }
`;
