// /* eslint react/jsx-indent:0 */

// import { useState } from 'react'
// import Slider from '@mui/material/Slider'
// import { styled } from '@mui/material/styles'
// import { capitalize } from 'lodash'

// import { useUserQuery } from '@/api/authentication/hook'
import { useSearchParams } from 'react-router-dom';

import { useFiltersQuery } from '@/api/filters/hook';
import { FilterDropdown } from '@/components/FilterDropdown';
import { cn } from '@/helpers/utils';
import { returnSocialMediaFilters } from '@/pages/general/explore/explore-filters';
import tones from '@/tones';
// import {
//   Button,
//   DropdownCheckbox,
//   DropdownContent,
//   DropdownItemIndicator,
//   DropdownItemIndicatorColored,
//   DropdownRadioGroup,
//   DropdownRadioItem,
//   DropdownRoot,
//   // DropdownSubContent,
//   // DropdownSubRoot,
//   DropdownTrigger,
//   FlexColumn,
//   FlexRow,
//   Grid,
//   Text
// } from '@/components'
// import { DemoAccountModal } from '@/components/DemoAccountPopup'
// import { colorMap } from '@/constants'
// import { useFilters } from '@/hooks/filters'

// const formattedSelectedOptions = (selectedOptions) =>
//   selectedOptions
//     ? Array.isArray(selectedOptions)
//       ? selectedOptions
//         .map((l) => capitalize(l.label))
//         .join(', ')
//         .substring(0, 30)
//       : selectedOptions.label
//     : null

// const marks = [
//   {
//     value: 3,
//     label: '3'
//   },
//   {
//     value: 4,
//     label: '4'
//   },
//   {
//     value: 5,
//     label: '5'
//   },
//   {
//     value: 6,
//     label: '6'
//   },
//   {
//     value: 7,
//     label: '7'
//   },
//   {
//     value: 8,
//     label: '8'
//   },
//   {
//     value: 9,
//     label: '9'
//   },
//   {
//     value: 10,
//     label: '10'
//   }
// ]

// const CustomSlider = styled(Slider)(({ theme }) => ({
//   color: '#D9D9D9',
//   height: 2,
//   padding: '13px 0',
//   boxShadow: 'none',
//   '& .MuiSlider-mark': {
//     height: 12,
//     width: 12,
//     marginLeft: '-5px !important',
//     borderRadius: '100%',
//     backgroundColor: '#d8d8d8',
//     boxShadow: 'none'
//   },
//   '& .MuiSlider-markLabel': {
//     fontFamily: 'Planc !important'
//   },
//   '& .MuiSlider-markActive': {
//     backgroundColor: '#c29b77',
//     boxShadow: 'none'
//   },
//   '& .MuiSlider-thumb': {
//     height: 12,
//     width: 12,
//     backgroundColor: '#c29b77',
//     border: '#c29b77',
//     boxShadow: 'none !important',
//     '&:hover': {
//       boxShadow: 'none'
//     }
//   },
//   '& .MuiSlider-track': {
//     height: 2,
//     border: 'none',
//     backgroundColor: '#c29b77',
//     boxShadow: 'none'
//   },
//   '& .MuiSlider-rail': {
//     color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
//     opacity: theme.palette.mode === 'dark' ? undefined : 1,
//     height: 2,
//     boxShadow: 'none'
//   }
// }))

// function ToneSearch () {
//   const {
//     selectedTone,
//     setSelectedTone,
//     selectedToneSimilarity,
//     setSelectedToneSimilarity,
//     clearColors
//   } = useFilters('explore')
//   const [searchQuery, setSearchQuery] = useState('')
//   // const [showList, setShowList] = useState(false)

//   const { data } = useFiltersQuery('exploreTones', {
//     toneName: searchQuery
//   })

//   console.log({ data })

//   function handleToneSelect (code) {
//     const tone = data?.exploreFilters?.tones?.find(
//       (tone) => tone.value === code
//     )
//     setSearchQuery('')
//     clearColors()

//     if (selectedTone?.value === tone.value) {
//       setSelectedTone(null)
//       return
//     }

//     setSelectedTone(tone)
//   }

//   return (
//     <>
//       {selectedTone
//         ? (
//         <div className="flex flex-col gap-2">
//           <div className="flex items-center justify-between">
//             <div className="flex items-center gap-2">
//               <div
//                 className="h-[18px] w-[18px]"
//                 style={{
//                   backgroundColor: `${selectedTone.hex}`
//                 }}
//               />
//               <span className="font-medium capitalize">
//                 {selectedTone.label}
//               </span>
//             </div>
//             <div className="self-end">
//               <Button onClick={() => setSelectedTone(null)} size="sm">
//                 X Clear Tone
//               </Button>
//             </div>
//           </div>
//           <div className="flex items-center gap-8">
//             <span className="mt-[21px] mb-[25px] text-[18px] font-semibold leading-[21px]">
//               Similarity
//             </span>
//             <CustomSlider
//               getAriaLabel={() => 'Audience Age'}
//               value={selectedToneSimilarity}
//               marks={marks}
//               min={3}
//               max={10}
//               step={null}
//               onChange={(e, value) => setSelectedToneSimilarity(value)}
//             />
//           </div>
//         </div>
//           )
//         : (
//         <>
//           <input
//             type="text"
//             placeholder="Enter Tone Name..."
//             className="h-full w-full border border-charcoal px-4 py-2 outline-none placeholder:text-[#626775]"
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//           />
//           <DropdownRadioGroup
//             value={selectedTone?.value}
//             onChange={handleToneSelect}
//           >
//             <FlexColumn className="max-h-[24rem] overflow-y-auto" gap="sm">
//               {data?.exploreFilters?.tones?.map((item) => (
//                 <DropdownRadioItem key={item.value} value={item.value}>
//                   <FlexRow items="center" gap="sm">
//                     <DropdownItemIndicator forceMount asChild />
//                     <div
//                       className="h-[1rem] w-[1rem]"
//                       style={{ backgroundColor: item.hex }}
//                     />
//                     <Text className="capitalize" weight="medium">
//                       {`Pantone TCX-${item.value} ${item.label}`}
//                     </Text>
//                   </FlexRow>
//                 </DropdownRadioItem>
//               ))}
//             </FlexColumn>
//           </DropdownRadioGroup>
//         </>
//           )}
//     </>
//   )
// }

// export function ExploreColor () {
//   const {
//     selectedAudience,
//     selectedColors,
//     selectColor,
//     apparelFilter,
//     selectedApparels,
//     selectedTone
//   } = useFilters('explore')

//   const { data, error } = useFiltersQuery('exploreColors', {
//     audiences: selectedAudience ? [selectedAudience.id] : null,
//     filters: {
//       apparels: selectedApparels.length > 0 ? apparelFilter : null
//     }
//   })

//   function colorChecked (color) {
//     return !!selectedColors.find((c) => c.value === color.value)
//   }

//   const { data: userData } = useUserQuery('me')

//   const isDemoAccount = userData?.me?.email === 'demo@tfashion.ai'

//   const [showDemoPopup, setShowDemoPopup] = useState(false)

//   const selectedOptions =
//     selectedColors.length > 0
//       ? selectedColors
//       : selectedTone
//         ? [selectedTone]
//         : null

//   console.log({ selectedOptions })

//   return (
//     <>
//       <DemoAccountModal
//         isOpen={showDemoPopup}
//         onClose={() => setShowDemoPopup(false)}
//       />
//       <DropdownRoot open={showDemoPopup ? false : undefined}>
//         <DropdownTrigger>
//           <FlexColumn gap="sm" items="start" className="w-full">
//             <Text
//               size="lg"
//               className={
//                 selectedOptions
//                   ? 'border-b border-solid border-b-[#282828]'
//                   : ''
//               }
//             >
//               Color
//             </Text>
//             {selectedOptions
//               ? (
//               <Text className="text-gray-600">
//                 {formattedSelectedOptions(selectedOptions)}
//               </Text>
//                 )
//               : null}
//           </FlexColumn>
//         </DropdownTrigger>
//         {error
//           ? (
//           <div>Error loading colors</div>
//             )
//           : (
//           <DropdownContent sideOffset={0} align="start" alignOffset={0}>
//             {!selectedTone
//               ? (
//               <>
//                 <Grid>
//                   {data?.exploreFilters?.colors?.map((item) => (
//                     <DropdownCheckbox
//                       key={item.value}
//                       value={item.value}
//                       checked={!isDemoAccount && colorChecked(item.value)}
//                       onChange={(checked) =>
//                         !isDemoAccount && selectColor(item, checked)
//                       }
//                       onClick={() => {
//                         isDemoAccount && setShowDemoPopup(true)
//                       }}
//                     >
//                       <FlexRow
//                         items="center"
//                         gap="sm"
//                         className="cursor-pointer"
//                       >
//                         <DropdownItemIndicatorColored
//                           forceMount
//                           color={colorMap[item.label]}
//                         />
//                         <Text className="capitalize" weight="semilight">
//                           {item.label}
//                         </Text>
//                       </FlexRow>
//                     </DropdownCheckbox>
//                   ))}
//                 </Grid>

//                 <div className="flex items-center gap-4">
//                   <div className="h-px w-full bg-charcoal" />
//                   <span className="text-sm text-charcoal">OR</span>
//                   <div className="h-px w-full bg-charcoal" />
//                 </div>
//               </>
//                 )
//               : null}
//             <ToneSearch />
//           </DropdownContent>
//             )}
//       </DropdownRoot>
//     </>
//   )
// }

export function ExploreColor() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data, loading } = useFiltersQuery('exploreColors', {
    audiences: searchParams.get('audiences')
      ? [searchParams.get('audiences')]
      : null,
    filters: returnSocialMediaFilters(searchParams),
  });

  // const { data: tonesData } = useFiltersQuery('exploreTones', {
  //   toneName: searchQuery,
  // });

  function handleOnChange(color) {
    if (color.length === 0) {
      setSearchParams((prev) => {
        prev.delete('colors');
        prev.delete('tone');
        prev.delete('color');
        return prev;
      });

      return;
    }

    const isToneOption = color.find((c) => c.main);

    if (isToneOption) {
      const selectedColors = color?.map((color) => color.label);

      setSearchParams((prev) => {
        prev.set('tone', isToneOption.value);
        prev.delete('color');
        prev.set('colors', selectedColors);
        return prev;
      });

      return;
    }

    setSearchParams((prev) => {
      prev.delete('tone');
      prev.set('color', color[0].value);
      return prev;
    });

    const selectedColors = color
      ?.map((color) => color.label)
      .filter((c) => !c.similarity);

    setSearchParams((prev) => {
      prev.set('colors', selectedColors);
      return prev;
    });
  }

  const rawSelectedColors = searchParams.get('colors')?.split(',') || [];
  const selectedColors =
    data?.exploreFilters?.colors?.filter((color) =>
      rawSelectedColors.includes(color.value),
    ) || [];

  const selectedTones = tones.filter((tone) =>
    rawSelectedColors.includes(tone.label),
  );

  const options = [
    {
      label: 'Colors',
      options: data?.exploreFilters?.colors || [],
    },
    {
      label: 'Tones',
      options: tones,
    },
  ];

  return (
    <FilterDropdown
      label="Color"
      placeholder="Search for colors"
      isMulti
      value={[...selectedColors, ...selectedTones]}
      isSearchable
      options={options}
      onChange={handleOnChange}
      loading={loading}
      pageSize={20}
      formatOptionLabel={(option) => {
        return (
          <div className={'flex flex-row items-center gap-3'}>
            <div
              className={cn(
                'h-4 w-4 border-charcoal rounded-full',
                (option.value || option.main) === 'white' && 'border-2',
              )}
              style={{
                background: option.main ? option.hex : option.value,
              }}
            ></div>
            <span>
              {option.main
                ? `Pantone TCX ${option.value} ${option.label.replaceAll(
                    '-',
                    ' ',
                  )}`
                : option.label}
            </span>
          </div>
        );
      }}
    />
  );
}
