import { useQuery } from '@apollo/client';

import { UPLOAD_ITEM_IMAGE } from '@/api/explore/queries';
import { generationTypesMap } from '@/constants';

import { Image } from './components/Image';
import { AttributesSkeleton, ImageSkeleton } from './components/Skeletons';
import { AttributesFileUpload, Wrapper } from './index';

export function FileUploadItemHeader({ item }) {
  if (!item) {
    return <div>loading</div>;
  }

  return (
    <div className="flex flex-col gap-[0.675rem]">
      <h3 className="text-lg font-semibold capitalize leading-none text-primary">
        {
          Object.entries(generationTypesMap).find(
            ([key, value]) => value === item.fileEntityType,
          )[0]
        }
      </h3>
      <h3 className="text-lg font-semibold capitalize leading-none text-primary"></h3>
    </div>
  );
}

export function FileUploadItem({ id }) {
  const { data, loading, error } = useQuery(UPLOAD_ITEM_IMAGE, {
    variables: {
      uploadItemImageId: id,
    },
    skip: !id,
  });

  if (loading) {
    return (
      <Wrapper>
        <ImageSkeleton />
        <AttributesSkeleton />
      </Wrapper>
    );
  }

  if (error) {
    return (
      <Wrapper>
        <div className="flex h-full w-full items-center justify-center">
          <p>{error.message}</p>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Image image={data?.uploadItemImage.node.image} apparels={[]} />
      <AttributesFileUpload
        loading={loading}
        item={data?.uploadItemImage.node}
      />
    </Wrapper>
  );
}
