import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useReports } from '@/hooks/report';

import { AttentionIcon } from '../../icons';

export const LocationAttention = () => {
  const navigate = useNavigate();
  const { clearReportData } = useReports();

  const handleNavigate = () => {
    clearReportData();
    return navigate('/create-trend-report');
  };

  return (
    <p className="mt-[24px] flex w-full items-center gap-x-[10px]">
      <span className="flex items-center">
        <AttentionIcon />
      </span>
      <span className="pt-[4px] text-[14px] font-medium text-black">
        To add a second location, you need to{' '}
        <strong
          className="cursor-pointer leading-[24px] text-[#3CA780] underline"
          onClick={handleNavigate}
        >
          {' '}
          get a new report.
        </strong>
      </span>
    </p>
  );
};
