import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

import { useReports } from '@/hooks/report';

const marks = [
  {
    value: 13,
    label: '13',
  },
  {
    value: 18,
    label: '18',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 35,
    label: '35',
  },
  {
    value: 45,
    label: '45',
  },
  {
    value: 55,
    label: '55',
  },
  {
    value: 65,
    label: '65',
  },
];

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: '#D9D9D9',
  height: 2,
  padding: '13px 0',
  boxShadow: 'none',
  '& .MuiSlider-mark': {
    height: 12,
    width: 12,
    marginLeft: '-5px !important',
    borderRadius: '100%',
    backgroundColor: '#d8d8d8',
    boxShadow: 'none',
  },
  '& .MuiSlider-markLabel': {
    fontFamily: 'Planc Medium !important',
  },
  '& .MuiSlider-markActive': {
    backgroundColor: '#c29b77',
    boxShadow: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    backgroundColor: '#c29b77',
    border: '#c29b77',
    boxShadow: 'none !important',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  '& .MuiSlider-track': {
    height: 2,
    border: 'none',
    backgroundColor: '#c29b77',
    boxShadow: 'none',
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 2,
    boxShadow: 'none',
  },
}));

export const AudienceAge = ({ audience }) => {
  const { updateAudience } = useReports();
  const [value, setValue] = useState([]);

  const handleAgeSlider = (event, newValue) => {
    setValue(newValue);
    updateAudience({
      id: audience?.id,
      ageMin: newValue[0],
      ageMax: newValue[1],
    });
  };

  useEffect(() => {
    const ageMin = audience?.ageMin || 18;
    const ageMax = audience?.ageMax || 64;

    setValue([ageMin, ageMax]);
  }, [audience]);

  return (
    <div className="mt-[35px] flex w-full flex-col pl-[7px]">
      <span className="mt-[21px] mb-[25px] text-[18px] font-semibold leading-[21px]">
        Age
      </span>
      <CustomSlider
        getAriaLabel={() => 'Audience Age'}
        value={value}
        marks={marks}
        min={18}
        max={64}
        step={null}
        onChange={handleAgeSlider}
      />
    </div>
  );
};
