import { createContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTrendsQuery } from '@/api/trends/hook';

export const TabsContext = createContext();

export function TabsProvider({ children }) {
  const { itemId, marketId, seasonId } = useParams();

  const { data } = useTrendsQuery('trendItem', {
    variables: {
      trendItemId: itemId,
      season: seasonId,
      market: marketId,
    },
  });

  useEffect(() => {
    if (data?.trendItem?.tabs?.length === 1) {
      setSelectedTab(data.trendItem.tabs[0].name);
    }
  }, [data]);

  const availableTabs = data?.trendItem?.tabs?.map((t) => t.name) || [];

  const [selectedTab, setSelectedTab] = useState(availableTabs[0] || null);

  const renderData = useMemo(() => {
    if (!data?.trendItem?.tabs) {
      return [];
    }

    const currTab = data?.trendItem?.tabs?.find((t) => t.name === selectedTab);

    return currTab?.data || [];
  }, [selectedTab, data?.trendItem?.tabs]);

  function changeTab(tab) {
    if (!availableTabs.includes(tab)) {
      return;
    }

    setSelectedTab(tab);
  }

  return (
    <TabsContext.Provider
      value={{
        availableTabs,
        selectedTab,
        changeTab,
        renderData,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
}
