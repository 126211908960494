import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { ToastProvider } from '@/contexts/ToastContext';

export const Public = ({ children }) => {
  const [searchParams] = useSearchParams();

  const user = localStorage.getItem('user');
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const isInvitationPage = window.location.pathname.includes('invitation');
  const invitationToken = searchParams.get('token');

  if (
    isInvitationPage &&
    invitationToken &&
    localStorage.getItem('accessToken')
  ) {
    localStorage.clear();
    return <Navigate to={`/invitation?token=${invitationToken}`} />;
  }
  if (user || accessToken || refreshToken) {
    return <Navigate to="/" />;
  }
  return <ToastProvider>{children}</ToastProvider>;
};
