import React, { useRef, useState } from 'react';
import { RgbaColorPicker } from 'react-colorful';
import Highlighter from 'react-highlight-words';
import { useQuery } from '@apollo/client';
import { debounce } from 'lodash';

import { GET_SIMILAR_TONES } from '@/api/explore/queries';
import { Button } from '@/components/Button';
import { FormInput, FormLabel } from '@/components/FormInput';
import { Heading } from '@/components/Heading';
import {
  ButtonSelect,
  DropdownSelect,
  DropdownSelectItem,
} from '@/components/Select/index';
import { SelectDropdown } from '@/components/SelectDropdown';
import { Sidebar } from '@/components/Sidebar';
import { Spacing } from '@/components/Spacing';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { useExploreContext } from '@/contexts/ExploreContext';
import { cn } from '@/helpers/utils';
import { FEATURE_FLAGS, useFeatureFlag } from '@/hooks/useFeatureFlag';

function FiltersHeading() {
  const { clearAll } = useExploreContext();

  return (
    <div className="mb-4 flex items-end justify-between">
      <Heading size="lg" className="font-bold">
        Filter
      </Heading>

      <Button
        variant={'link'}
        size={'xs'}
        className={'mb-2 p-0'}
        onClick={() => clearAll()}
      >
        Clear All
      </Button>
    </div>
  );
}

export function ExploreFashionWeekSearch() {
  const {
    fashionWeekFilters,
    handleFashionWeekFiltersOnChange,
    loadFashionWeekSearchOptions,
  } = useExploreContext();

  const searchFilter = fashionWeekFilters?.search;

  const debouncedLoadOptions = debounce(loadFashionWeekSearchOptions, 1200);

  return (
    <>
      <Heading size={'xl'}>Search</Heading>
      <Spacing size={1.125} />
      <FormInput>
        <SelectDropdown
          placeholder={searchFilter?.placeholder}
          isSearchable={searchFilter?.isSearchable}
          options={searchFilter?.options}
          multiselect={false}
          isClearable={searchFilter?.isClearable}
          value={searchFilter?.value}
          key={'search'}
          onChange={(e) => handleFashionWeekFiltersOnChange(e, 'search')}
          isAsync={true}
          isCreatable={true}
          formatCreateLabel={(inputValue) => `${inputValue}`}
          cacheOptions={false}
          loadOptions={debouncedLoadOptions}
          defaultOptions
          allowCreateWhileLoading={true}
          createOptionPosition={'first'}
          noOptionsMessage={() => 'No results found'}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Option: ({ selectProps, innerProps, innerRef, data, ...props }) => {
              return (
                <div
                  ref={innerRef}
                  {...innerProps}
                  className={props.getClassNames(props.type, props)}
                >
                  <Highlighter
                    highlightClassName="bg-transparent"
                    unhighlightClassName="font-semibold"
                    searchWords={[selectProps.inputValue]}
                    autoEscape={true}
                    textToHighlight={data.label}
                  />
                </div>
              );
            },
          }}
        />
      </FormInput>
    </>
  );
}

function ToneSelect({ filter, filterKey, onChange, currentValue }) {
  const [selectedTone, setSelectedTone] = useState({
    r: 0,
    g: 0,
    b: 0,
    a: 1,
  });
  const [showTextSearch, setShowTextSearch] = useState(false);
  const [textSearch, setTextSearch] = useState('');

  const { data, loading } = useQuery(GET_SIMILAR_TONES, {
    variables: {
      tone: [selectedTone?.r, selectedTone?.g, selectedTone?.b],
      textSearch,
    },
    skip: !selectedTone && !textSearch,
  });

  const triggerRef = useRef(null);

  return (
    <AccordionItem value={'tone'}>
      <FormLabel>{filter?.label}</FormLabel>
      <AccordionTrigger
        ref={triggerRef}
        className={cn(
          'flex items-center mt-2 rounded-md border-[#1A1A1A] bg-white px-4 py-2 text-[.875rem] leading-5 outline-none font-normal border',
          filter.value ? 'text-charcoal capitalize' : 'text-[#A1A1A1]',
        )}
      >
        <div className="flex items-center gap-2">
          {filter?.value?.hex && (
            <div
              className="h-4 w-4 rounded-full"
              style={{
                background: filter?.value?.hex,
              }}
            />
          )}
          {filter?.value?.label || filter?.placeholder}
        </div>
      </AccordionTrigger>
      <AccordionContent className="mt-2 flex flex-col items-center space-y-2">
        <div
          className={cn(
            'tone-picker w-full gap-4 px-2',
            showTextSearch ? 'flex-col' : 'flex',
          )}
        >
          {showTextSearch ? (
            <div className="mb-2 flex w-full items-center gap-2 rounded border border-charcoal p-2">
              <input
                className="w-full text-[.875rem] leading-5 outline-none"
                value={textSearch}
                onChange={(e) => setTextSearch(e.target.value)}
                placeholder="Color, Pantone Code or Pantone Name"
              />
            </div>
          ) : (
            <RgbaColorPicker
              onChange={(color) => setSelectedTone(color)}
              value={selectedTone}
            />
          )}

          <div className="flex shrink-0 flex-col">
            {data && (
              <h1 className="ml-2 text-[.875rem] font-bold leading-5">
                Select A Pantone Color
              </h1>
            )}

            {loading && (
              <div className="space-y-2">
                {Array.from({ length: 3 }).map((_, i) => (
                  <div
                    key={i}
                    className="flex animate-pulse items-center gap-2 rounded border bg-gray-100 p-2"
                  >
                    <div className="h-4 w-4 rounded-full " />
                    <span
                      className={cn(
                        'text-[0.875rem] leading-5 text-transparent',
                      )}
                    >
                      Pantone TCX 00-0000
                    </span>
                  </div>
                ))}
              </div>
            )}

            {data?.exploreSimilarTones.map((tone) => (
              <div
                key={tone.code}
                className={cn(
                  'flex cursor-pointer items-center gap-2 p-2 border rounded hover:border-charcoal group transition-colors',
                  tone.code === currentValue?.code
                    ? 'border-charcoal'
                    : 'border-transparent',
                )}
                onClick={() => {
                  onChange(
                    {
                      ...tone,
                      label: `${tone.name.replace(/-/g, ' ')} - ${tone.code}`,
                    },
                    filterKey,
                  );
                  triggerRef?.current?.click();
                }}
              >
                <div
                  className="h-4 w-4 rounded-full"
                  style={{
                    background: tone.hex,
                  }}
                />
                <span
                  className={cn(
                    'text-[0.875rem] leading-5 group-hover:text-charcoal transition-colors',
                    tone.code === currentValue?.code
                      ? 'text-charcoal'
                      : 'text-[#A1A1A1]',
                  )}
                >
                  Pantone TCX {tone.code}
                </span>
              </div>
            ))}
          </div>
        </div>

        <span
          className="cursor-pointer text-xs text-primary hover:underline"
          onClick={() => setShowTextSearch(!showTextSearch)}
        >
          {showTextSearch
            ? '... or pick a color'
            : '... or search by color name'}
        </span>
      </AccordionContent>
    </AccordionItem>
  );
}

export function ExploreSocialMediaFilters() {
  const { isEnabled: exploreAdvanced } = useFeatureFlag(
    FEATURE_FLAGS.EXPLORE_ADVANCED,
  );

  const { socialMediaFilters, handleSocialMediaFiltersOnChange } =
    useExploreContext();

  if (!exploreAdvanced) {
    delete socialMediaFilters.personCount;
    delete socialMediaFilters.personPosition;
  }
  // const selectedTones = socialMediaFilters.color?.value || [];
  return (
    <div className="flex flex-col">
      <FiltersHeading />

      <DropdownSelect collapsible className="space-y-4">
        {Object.entries(socialMediaFilters).map(([filterKey, filter], i) => {
          if (filterKey === 'attribute.custom') {
            return (
              <DropdownSelectItem
                key={i}
                filter={filter}
                filterKey={filterKey}
                onChange={handleSocialMediaFiltersOnChange}
              />
            );
          }

          if (
            filterKey !== 'search' &&
            filterKey !== 'audience' &&
            filterKey !== 'tone' &&
            filterKey !== 'personCount' &&
            filterKey !== 'personPosition' &&
            (filterKey !== 'platform' ||
              socialMediaFilters.market?.value !== null)
          ) {
            return filter.button ? (
              <ButtonSelect
                key={i}
                filter={filter}
                filterKey={filterKey}
                onChange={handleSocialMediaFiltersOnChange}
                currentValue={filter?.value?.value}
              />
            ) : (
              <DropdownSelectItem
                key={i}
                filter={filter}
                filterKey={filterKey}
                onChange={handleSocialMediaFiltersOnChange}
              />
            );
          }

          return null;
        })}
        <ToneSelect
          filter={socialMediaFilters.tone}
          filterKey={'tone'}
          onChange={handleSocialMediaFiltersOnChange}
          currentValue={socialMediaFilters.tone?.value}
        />
        {exploreAdvanced && (
          <AccordionItem value="explore-advanced">
            <AccordionTrigger className="pb-0">
              <FormLabel>Advanced Filters</FormLabel>
            </AccordionTrigger>
            <AccordionContent>
              <Accordion className="space-y-4" collapsible>
                <DropdownSelect
                  filter={socialMediaFilters.personCount}
                  filterKey={'personCount'}
                  onChange={handleSocialMediaFiltersOnChange}
                  closeOnChange={false}
                />
                <DropdownSelect
                  filter={socialMediaFilters.personPosition}
                  filterKey={'personPosition'}
                  onChange={handleSocialMediaFiltersOnChange}
                  closeOnChange={false}
                />
              </Accordion>
            </AccordionContent>
          </AccordionItem>
        )}
      </DropdownSelect>
    </div>
  );
}

export function ExploreFashionWeekFilters() {
  const { fashionWeekFilters, handleFashionWeekFiltersOnChange } =
    useExploreContext();

  return (
    <div className="space-y-4">
      {/* <ExploreFashionWeekSearch /> */}

      {/* <Spacing size={1.125} /> */}

      <FiltersHeading />

      {/* <Spacing size={1.125} /> */}

      <DropdownSelect collapsible className="space-y-4">
        {Object.entries(fashionWeekFilters).map(
          ([filterKey, filter], i) =>
            filterKey !== 'search' && (
              <DropdownSelectItem
                key={i}
                filter={filter}
                filterKey={filterKey}
                onChange={handleFashionWeekFiltersOnChange}
                isClearable={true}
              />
            ),
        )}
      </DropdownSelect>
    </div>
  );
}

export function ExploreFilters({ className }) {
  const { activeTab, fashionWeekFilters, socialMediaFilters, triggerRefetch } =
    useExploreContext();

  const filters = {
    'fashion-weeks': <ExploreFashionWeekFilters />,
    'social-media': <ExploreSocialMediaFilters />,
  };

  const isAnyFilterSelected = Object.values(
    activeTab === 'fashion-weeks' ? fashionWeekFilters : socialMediaFilters,
  ).some((filter) => filter.value && filter !== 'search');

  return (
    <>
      <Sidebar
        className="no-scrollbar flex flex-col gap-y-6"
        variant={'primary'}
      >
        {filters[activeTab]}
        <Button
          className="w-full p-2 font-bold text-primaryLight disabled:bg-black/60"
          disabled={!isAnyFilterSelected}
          onClick={() => triggerRefetch()}
        >
          Apply
        </Button>
      </Sidebar>
    </>
  );
}
