import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AlertCircleIcon } from '../../icons';

export const AudienceAttention = () => {
  const navigate = useNavigate();

  return (
    <span className="mt-[18px] flex items-center gap-x-[10px]">
      <span className="flex items-center">
        <AlertCircleIcon />
      </span>
      <span className="pt-[4px] text-[14px] leading-[24px] text-black">
        To add new audience list, you need to
        <strong
          className="cursor-pointer px-1 leading-[24px] text-[#3CA780] underline"
          onClick={() => navigate('/create-trend-report')}
        >
          upgrade
        </strong>
        your plan.
      </span>
    </span>
  );
};
