import styles from '../index.module.css';

export function Desc({ children, skeleton }) {
  return (
    <span
      className={[
        styles.desc,
        skeleton ? 'bg-slate-300 animate-pulse !text-transparent' : '',
      ].join(' ')}
    >
      {children}
    </span>
  );
}
