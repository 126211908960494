import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { isBright } from '@/helpers/isBright';

import styles from '../index.module.css';

dayjs.extend(utc);

export function Value({ optimalLaunch, tone, skeleton }) {
  if (!optimalLaunch) return null;

  const date = optimalLaunch.endsWith('Z')
    ? dayjs.utc(optimalLaunch).format('MMMM')
    : dayjs.utc(Number(optimalLaunch)).format('MMMM');

  const isBrightColor = tone ? isBright(tone.hex) : false;

  const textColor = tone
    ? isBrightColor
      ? 'text-black'
      : 'text-white'
    : 'text-primary';

  return (
    <div
      className={[
        styles.text,
        textColor,
        skeleton ? 'text-transparent ghost' : '',
      ].join(' ')}
    >
      {date}
    </div>
  );
}
