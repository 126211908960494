import React from 'react';
import dayjs from 'dayjs';
import { ChevronLeft } from 'lucide-react';

import { cn } from '@/helpers/utils.js';

export const SidebarButton = ({
  icon: Icon,
  text,
  onClick,
  variant,
  active = false,
  disabled = false,
  className,
}) => {
  if (variant === 'nav') {
    return (
      <div
        className={cn(
          'flex cursor-pointer items-center gap-2 rounded border border-transparent bg-white px-4 py-2 text-left text-[1rem] font-bold leading-[1.75rem] text-charcoal hover:border-primary hover:bg-[#FAF8F8] hover:text-primary transition duration-300 ease-in-out',
          className,
        )}
        onClick={onClick}
      >
        {Icon ? <Icon size={16} /> : <></>}
        {text}
      </div>
    );
  }

  if (variant === 'generate') {
    return (
      <div
        className={cn(
          'flex cursor-pointer items-center justify-center gap-2 rounded bg-gradient-to-b from-primary/5 to-primary px-4 py-2 text-center text-[1rem] font-bold leading-[1.75rem] text-white hover:shadow transition duration-300 ease-in-out',
          disabled && 'cursor-not-allowed hover:shadow-none',
          className,
        )}
        style={{
          background: disabled
            ? 'linear-gradient(180deg, rgba(128, 128, 128, 0.05) -97%, #808080 100%)'
            : 'linear-gradient(180deg, rgba(194, 155, 119, 0.05) -97%, #C29B77 100%)',
        }}
        onClick={disabled ? undefined : onClick}
      >
        {text}
        {Icon ? <Icon size={16} /> : <></>}
      </div>
    );
  }

  if (variant === 'tool') {
    return (
      <div
        className={cn(
          'flex cursor-pointer items-center justify-center gap-2 rounded border border-secondary/10 bg-secondary/5 px-2.5 py-1.5 text-center text-sm font-bold text-black hover:border-primary hover:bg-white hover:text-primary transition duration-300 ease-in-out',
          className,
          active && 'border-primary bg-white text-primary',
        )}
        onClick={onClick}
      >
        {Icon ? <Icon size={16} /> : <></>}
        {text}
      </div>
    );
  }

  return (
    <div
      className={cn(
        'flex items-center text-sm rounded py-2 px-4 font-bold border transition duration-300 ease-in-out',
        {
          'cursor-pointer bg-primary text-white border-primary focus-visible:none focus:ring-0 hover:bg-primaryHover hover:border-primaryHover hover:text-white focus:border-primaryHover focus-visible:outline-none focus:bg-primaryHover':
            !disabled,
          'cursor-not-allowed bg-secondary/60 text-white': disabled,
        },
        className,
      )}
      onClick={disabled ? undefined : onClick}
    >
      {Icon ? <Icon size={16} /> : <></>}
      {text}
    </div>
  );
};

export const SidebarHeader = ({ icon: Icon, text, onClick, variant }) => {
  if (variant === 'back') {
    return (
      <div
        className="flex cursor-pointer items-center gap-x-2 rounded bg-white px-1 text-[1rem] font-bold capitalize leading-[1.75rem] text-charcoal"
        onClick={onClick}
      >
        {Icon ? <Icon size={18} /> : <ChevronLeft size={18} />}
        <h2>{text || 'Back'}</h2>
      </div>
    );
  }

  return (
    <div
      className="flex items-center gap-x-2 rounded bg-primary px-4 py-2 text-[1rem] font-bold leading-[1.75rem] text-white"
      onClick={onClick}
    >
      <Icon size={16} />
      <h2>{text}</h2>
    </div>
  );
};

export const SidebarTitle = ({ text, variant }) => {
  return (
    <h3 className={`text- text-sm font-bold ${variant || 'black'}`}>{text}</h3>
  );
};

export const SidebarCard = ({
  value,
  index,
  selectedId,
  onClick,
  variant = 'default',
}) => {
  if (variant === 'ghost') {
    return (
      <div
        className="ghost flex cursor-pointer items-center justify-start rounded border border-secondary/10 px-4 py-1 text-sm font-normal text-night hover:border-secondary/50 focus-visible:outline-none"
        style={{
          transition: 'all 0.2s',
          height: '3rem',
        }}
      >
        <span className="text-night">{value}</span>
      </div>
    );
  }

  if (variant === 'error') {
    return (
      <div
        className="flex cursor-pointer items-center justify-start rounded border border-red-600 bg-red-600/5 px-4 text-sm font-normal text-red-600 hover:border-red-600 hover:bg-red-600/10 focus-visible:outline-none"
        style={{
          transition: 'all 0.2s',
          height: '3rem',
        }}
        onClick={() => onClick(index, value)}
      >
        <span>{value}</span>
      </div>
    );
  }

  return (
    <div
      className={cn(
        `flex cursor-pointer items-center justify-start rounded bg-secondary/5 px-4 text-sm font-normal text-night focus-visible:outline-none py-1 transition duration-300 ease-in-out max-h-14 line-clamp-2 hover:border-primary hover:bg-[#FFFDFD]  hover:line-clamp-none hover:max-h-80`,
        selectedId === index
          ? 'border border-primary text-primary'
          : 'border border-secondary/10 hover:border-secondary/50',
      )}
      style={{
        transition: 'all 0.2s',
      }}
      onClick={() => onClick(index, value)}
    >
      <span>{value}</span>
    </div>
  );
};

export const GhostCard = () => {
  return (
    <div
      className="ghost flex cursor-pointer items-center justify-start rounded border border-secondary/10 bg-secondary/5 text-sm font-normal text-night transition duration-300 ease-in-out hover:border-secondary/50 focus-visible:outline-none"
      style={{ transition: 'all 0.2s', minHeight: '4.25rem' }}
    >
      <span className="text-night"></span>
    </div>
  );
};

export const CategoryCard = ({
  category,
  index,
  setId,
  variant,
  onCollectionClick,
}) => {
  if (!category) return <GhostCard />;

  const DefaultCard = () => {
    const { title, description, image } = category;
    return (
      <div
        className="group flex cursor-pointer items-center justify-start rounded border border-secondary/20 bg-secondary/5 text-sm font-normal text-night transition duration-300 ease-in-out hover:border hover:border-primary hover:bg-[#FFFDFD] hover:text-primary focus-visible:outline-none"
        style={{ transition: 'all 0.2s', minHeight: '4.25rem' }}
        onClick={() => setId(index)}
      >
        <div className="flex h-[4.25rem] flex-row gap-x-2">
          <div
            className="flex aspect-square h-full items-center justify-center bg-night/10"
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              zIndex: 0,
            }}
          />
          <div className="flex flex-col items-start justify-center py-2 pr-4 text-sm">
            <span className="text-sm font-bold">{title}</span>
            <div className="flex flex-row">
              <span className="text-xs text-secondary">{description}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const CollectionCard = () => {
    const { name, owner, itemsCount, updatedAt, featuredImages } = category;
    return (
      <div
        className="group flex cursor-pointer items-center justify-start rounded border border-secondary/20 bg-secondary/5 text-sm font-normal text-night transition duration-300 ease-in-out hover:border hover:border-primary hover:bg-[#FFFDFD] hover:text-primary focus-visible:outline-none"
        style={{ transition: 'all 0.2s', minHeight: '4.25rem' }}
        onClick={() => onCollectionClick(category)}
      >
        <div className="flex h-[4.25rem] flex-row gap-x-2">
          <div className="grid aspect-square h-full grid-cols-2 grid-rows-2 gap-0.5 overflow-hidden border-r p-1 group-hover:border-primary">
            {featuredImages.slice(0, 4).map((img, imgIndex) => (
              <div
                key={imgIndex}
                className="h-full w-full bg-night/10"
                style={{
                  backgroundImage: `url(${img.url})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            ))}
          </div>
          <div className="flex flex-col items-start justify-center py-2 pr-4 text-sm">
            <span className="text-lg font-bold">{name}</span>
            <div className="flex flex-row">
              <span className="text-xs text-secondary">
                {(owner?.fullName || 'Unknown') +
                  ' ✴ ' +
                  itemsCount +
                  ' shots ✴ updated ' +
                  dayjs(updatedAt).fromNow()}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return variant === 'collection' ? <CollectionCard /> : <DefaultCard />;
};
