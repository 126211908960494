import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';

import { cn } from '@/helpers/utils';

const headingVariants = cva(
  'font-medium transition focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none',
  {
    variants: {
      variant: {
        primary: 'text-primary',
        secondary: '',
      },
      size: {
        sm: 'text-[1rem]',
        md: 'text-[1.125rem]',
        lg: 'text-[1.5rem]',
        xl: 'text-[2rem] font-semibold',
      },
      block: {
        true: 'w-full',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  },
);

const Heading = React.forwardRef(
  (
    {
      className,
      variant = 'primary',
      size = 'md',
      block,
      outline,
      color = 'default',
      asChild = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'span';
    const classNames = cn(
      headingVariants({
        variant,
        size,
        block,
      }),
      className,
    );

    return (
      <Comp
        className={classNames}
        ref={ref}
        type={props.type || 'span'}
        {...props}
      >
        {props.children}
      </Comp>
    );
  },
);

Heading.displayName = 'Button';

export { Heading, headingVariants };
