import { useQuery } from '@apollo/client';

import { GET_INSPIRED_IMAGES } from '@/api/studio/queries';
import { LazyLoadTrigger } from '@/pages/general/explore/components/utils';
import {
  StudioDesignImage,
  StudioDesignImageLoading,
} from '@/pages/general/studio/components/StudioDesignTask';

export function StudioTabGetInspired({ ...props }) {
  const { data, loading, error, networkStatus, fetchMore } = useQuery(
    GET_INSPIRED_IMAGES,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    },
  );

  const loadingMore = networkStatus === 3;
  if (loading && !loadingMore) {
    return (
      <div className={'mt-4 flex w-full flex-wrap gap-4'}>
        {Array.from({ length: 16 }).map((_, i) => (
          <StudioDesignImageLoading key={i} />
        ))}
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const { edges, pageInfo } = data.getInspiredImages;

  return (
    <>
      <div className={'mt-4 flex w-full flex-wrap gap-4'}>
        {edges.map((item, i) => (
          <StudioDesignImage
            key={i}
            image={item.node.image}
            designTask={item.node}
          />
        ))}

        {loadingMore &&
          Array.from({ length: 16 }).map((_, i) => (
            <StudioDesignImageLoading key={i} />
          ))}

        {pageInfo.hasNextPage && !loadingMore && (
          <LazyLoadTrigger
            fetchMore={() => {
              fetchMore({
                variables: {
                  cursor: pageInfo.endCursor,
                },
              });
            }}
            cursorNext={pageInfo.endCursor}
            loading={loading}
          />
        )}
      </div>
    </>
  );
}
