import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import {
  CreateReportCaption,
  CreateReportNavigation,
  CreateReportProcessInfo,
  CreateReportProcessLine,
} from './components';

export const CreateReport = () => {
  const { pathname } = useLocation();
  const path = pathname.split('/')[2];
  const checkPath =
    path === 'report' || path === 'finalize' || path === 'select-a-plan';

  return (
    <div
      className="flex-w mx-auto flex flex-col items-center justify-center"
      style={{
        maxWidth: path !== 'select-a-plan' && '573px',
      }}
    >
      {!checkPath && (
        <>
          <CreateReportCaption />
          <CreateReportProcessLine />
          <CreateReportProcessInfo />
        </>
      )}
      <Outlet />
      {!checkPath && <CreateReportNavigation />}
    </div>
  );
};
