import styles from '../index.module.css';

const colorMap = {
  success: styles.success,
  error: styles.error,
  warning: styles.warning,
  info: styles.info,
};

export function ColorBar({ type }) {
  const colorClass = colorMap[type];

  if (!colorClass) throw new Error(`Invalid type prop: ${type}`);

  return <div className={[styles.colorBar, colorClass].join(' ')} />;
}
