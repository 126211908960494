export function SmallArrow({ color }) {
  return (
    <svg
      width="26"
      height="21"
      viewBox="0 0 26 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 0L25.1244 21H0.875645L13 0Z" fill={color} />
    </svg>
  );
}
