import React, { useMemo, useState } from 'react';
import { MagicWandIcon } from '@radix-ui/react-icons';

import { useUser } from '@/api/authentication/hook';
import { Input, Text } from '@/components';
import { Button } from '@/components/Button.jsx';
import { FormInput, FormLabel } from '@/components/FormInput';
import { SelectDropdown } from '@/components/SelectDropdown';
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useStudioContext } from '@/contexts/StudioContext';
import { cn } from '@/helpers/utils';
import {
  additionalInputOptions,
  colorOptions,
  conceptOptions,
  getDesignOptions,
  itemOptions,
  orientationOptions,
} from '@/pages/general/studio/components/options';

const AdditionalInputOptionLabel = ({
  option,
  activeInputs,
  setActiveInputs,
}) => {
  return (
    <div className={'flex flex-row justify-between'}>
      <FormLabel>{option.label}</FormLabel>
      <Button
        variant={'link'}
        className={'p-0 text-sm font-semibold text-red-700'}
        onClick={() => {
          setActiveInputs({
            ...activeInputs,
            [option.value]: false,
          });
        }}
      >
        Remove
      </Button>
    </div>
  );
};

export function StudioDesignForm() {
  const { payload, setPayload, handleGenerate, designTask } =
    useStudioContext();

  const { isPrivateImageEnabled } = useUser();
  const privateImageEnabled = isPrivateImageEnabled();

  const [activeInputs, setActiveInputs] = useState({
    color: false,
    location: false,
    gender: false,
  });

  const [visibility, setVisibility] = useState(
    privateImageEnabled ? 'PRIVATE' : 'PUBLIC',
  );

  const toggleVisibility = (value) => {
    if (value.trim() !== '') {
      setVisibility(value);
      setPayload({
        ...payload,
        visibility: value,
      });
    }
  };
  const designOptions = useMemo(() => {
    // Filter out already selected attribute classes/categories from the options
    return getDesignOptions(payload);
  }, [payload]);

  const handleItemChange = (value) => {
    const newDesignOptions = getDesignOptions({
      item: value,
    })
      .map((option) => option.options)
      .flat();

    const designOptions = newDesignOptions?.filter((item) => {
      return payload?.design?.some((option) => option.value === item.value);
    });

    setPayload({
      ...payload,
      item: value,
      design: designOptions,
    });
  };

  const availableAdditionalInputOptions = additionalInputOptions.filter(
    (option) => !activeInputs[option.value],
  );

  const guidedModeForm = (
    <>
      <FormInput>
        <FormLabel required={true}>Item</FormLabel>
        <SelectDropdown
          placeholder={'Search for an item like'}
          isSearchable={true}
          options={itemOptions}
          key={'name'}
          multiselect={false}
          onChange={handleItemChange}
          value={payload.item}
          autoFocus={true}
        />
      </FormInput>

      <FormInput>
        <FormLabel>Design</FormLabel>
        <SelectDropdown
          placeholder={
            payload.item
              ? `Search for design details for ${payload.item.label.toLowerCase()}`
              : 'Select an item first'
          }
          isDisabled={!payload.item}
          options={designOptions}
          isSearchable={true}
          isMulti={true}
          onChange={(value) =>
            setPayload({
              ...payload,
              design: value,
            })
          }
          value={payload.design}
          autoFocus={true}
        />
        <Text size={'sm'}>You can select multiple design details</Text>
      </FormInput>

      <FormInput>
        <FormLabel>Color</FormLabel>
        <SelectDropdown
          placeholder={'Select a color'}
          options={colorOptions}
          isSearchable={true}
          isClearable={true}
          onChange={(value) => {
            const colorful = value.find((v) => v.value === 'colorful');
            const isColorfulAlreadySelected = payload?.color?.length
              ? payload.color.find((v) => v.value === 'colorful')
              : false;

            if (colorful && !isColorfulAlreadySelected) {
              setPayload({
                ...payload,
                color: [colorful],
              });
            } else {
              setPayload({
                ...payload,
                color: value.filter((v) => v.value !== 'colorful'),
              });
            }
          }}
          value={payload.color}
          isMulti={true}
          formatOptionLabel={(option) => (
            <div className={'flex flex-row items-center gap-3'}>
              <div
                className={cn(
                  'h-4 w-4 border-charcoal rounded-full',
                  option.value === 'white' && 'border-2',
                )}
                style={{
                  background: option.hex,
                }}
              ></div>
              <span>{option.label}</span>
            </div>
          )}
        />
        <Text size={'sm'}>You can select multiple colors</Text>
      </FormInput>

      {additionalInputOptions.map((option, i) => {
        if (option.type !== 'select' || !activeInputs[option.value]) {
          return <></>;
        }

        return (
          <FormInput key={i}>
            <AdditionalInputOptionLabel
              activeInputs={activeInputs}
              setActiveInputs={setActiveInputs}
              option={option}
            />
            <SelectDropdown
              options={option.selectOptions}
              isSearchable={false}
              onChange={(value) => {
                setPayload({
                  ...payload,
                  [option.key]: value,
                });
              }}
              value={payload[option.key]}
            />
          </FormInput>
        );
      })}

      {additionalInputOptions.map((option, i) => {
        if (option.type !== 'text' || !activeInputs[option.value]) {
          return <></>;
        }

        return (
          <FormInput key={i}>
            <AdditionalInputOptionLabel
              activeInputs={activeInputs}
              setActiveInputs={setActiveInputs}
              option={option}
            />
            <Input
              type={'text'}
              bordered={true}
              block
              placeholder={option.placeholder}
              onChange={(e) => {
                setPayload({
                  ...payload,
                  [option.key]: e.target.value,
                });
              }}
            />
          </FormInput>
        );
      })}

      {availableAdditionalInputOptions.length > 0 && (
        <FormInput className={'flex flex-row bg-primary'}>
          <Button size={'sm'}>Add</Button>
          <SelectDropdown
            className={'grow'}
            options={availableAdditionalInputOptions}
            isSearchable={false}
            onChange={(value) => {
              setActiveInputs({
                ...activeInputs,
                [value.value]: true,
              });
            }}
            value={null}
            classNames={{
              control: 'border-primary',
            }}
          />
        </FormInput>
      )}

      <FormInput>
        <FormLabel>Concept</FormLabel>
        <SelectDropdown
          options={conceptOptions}
          isSearchable={false}
          onChange={(value) =>
            setPayload({
              ...payload,
              concept: value,
            })
          }
          value={payload.concept}
        />
      </FormInput>
    </>
  );

  const expertModeForm = (
    <>
      <div className={''}>
        You are currently using expert mode. T-Fashion does best effort to
        generate a design based on the prompt you provide. But it is{' '}
        <span className={'underline'}>not guaranteed</span> that the generated
        design will match the prompt. Use it at your own risk.
      </div>
      <FormInput>
        <FormLabel required={true}>Prompt</FormLabel>
        <Input
          textArea
          bordered={true}
          block
          placeholder={'Enter a prompt'}
          onChange={(e) => {
            setPayload({
              ...payload,
              positivePrompt: e.target.value,
            });
          }}
          value={payload?.positivePrompt || ''}
          rows={3}
        />
      </FormInput>

      <FormInput>
        <FormLabel required={true}>Negative Prompt</FormLabel>
        <Input
          textArea
          bordered={true}
          block
          placeholder={'Enter a negative prompt'}
          onChange={(e) => {
            setPayload({
              ...payload,
              negativePrompt: e.target.value,
            });
          }}
          value={payload?.negativePrompt || ''}
          rows={3}
        />
      </FormInput>

      {`${payload?.positivePrompt} ${payload?.negativePrompt}}`.split(' ')
        .length > 60 && (
        <div className={'bg-amber-50 p-4 font-medium text-amber-700'}>
          Your prompt is too long. <br />
          Please use less than 60 words.
        </div>
      )}
    </>
  );

  return (
    <div className="flex w-full flex-col gap-4">
      {payload?.mode === 'EXPERT' ? expertModeForm : guidedModeForm}

      <FormInput>
        <FormLabel>Orientation</FormLabel>
        <SelectDropdown
          options={orientationOptions}
          onChange={(value) =>
            setPayload({
              ...payload,
              orientation: value,
            })
          }
          value={payload.orientation}
        />
      </FormInput>

      <FormInput>
        <FormLabel>Visibility</FormLabel>
        <ToggleGroup
          type="single"
          className="flex gap-1 rounded-none bg-white p-0"
          onValueChange={(value) => toggleVisibility(value)}
        >
          <ToggleGroupItem
            value="PUBLIC"
            data-state={visibility === 'PUBLIC' && 'on'}
          >
            Public
          </ToggleGroupItem>
          {!privateImageEnabled ? (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger className="w-full">
                  <TooltipContent sideOffset={4}>
                    <Text size={'xs'}>
                      Private image generation is only available for Pro Plan.
                    </Text>
                  </TooltipContent>
                  <ToggleGroupItem
                    value="PRIVATE"
                    data-state={visibility === 'PRIVATE' && 'on'}
                    disabled={true}
                  >
                    Private
                  </ToggleGroupItem>
                </TooltipTrigger>
              </Tooltip>
            </TooltipProvider>
          ) : (
            <ToggleGroupItem
              value="PRIVATE"
              data-state={visibility === 'PRIVATE' && 'on'}
            >
              Private
            </ToggleGroupItem>
          )}
        </ToggleGroup>
      </FormInput>

      <Button
        icon={<MagicWandIcon width={'1.25rem'} height={'1.25rem'} />}
        iconPosition={'right'}
        block
        size={'sm'}
        onClick={() => {
          handleGenerate();
        }}
        className={cn('gap-2', designTask?.loading && 'animate-pulse')}
      >
        {designTask?.loading ? 'Generating...' : 'Generate'}
      </Button>

      <Button
        size={'sm'}
        variant={'link'}
        onClick={() => {
          setPayload({
            item: null,
            design: null,
            color: null,
            location: null,
          });
        }}
      >
        Reset all
      </Button>
    </div>
  );
}
