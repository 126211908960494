import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { genderMapping } from '@/constants';
import { useReports } from '@/hooks/report';

import {
  ArrowRightIcon,
  ArrowUpIcon,
  CategoryIcon,
  UsersIcon,
} from '../../icons';

export const ReportDetails = ({ plan }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const { location, apparelGroups, audience } = useReports();

  const handleNavigate = (route) => {
    navigate(`/create-trend-report/${route}`);
  };

  if (plan.alias) {
    return plan.markets.map((market) => (
      <div
        key={market.location.name}
        className="border-[rgba(161, 161, 0.4)] mt-[29px] flex w-full flex-col items-center border-b pb-[22px]"
      >
        <div
          className="flex w-full cursor-pointer items-center space-x-[19px]"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <span>{isOpen ? <ArrowUpIcon /> : <ArrowRightIcon />}</span>
          <span className="select-none pt-[4px] text-[24px] font-semibold leading-[28px]">
            Market: {market.location.name}
          </span>
        </div>
        <div
          className={`mt-[5px] w-full justify-between ${
            isOpen ? 'flex' : 'hidden'
          }`}
        >
          <div className="flex flex-col pl-[39px]">
            <span className="font-regular text-[14px] text-[#626775]">
              {market.apparelGroups.map((apparelGroup, index) => (
                <span key={index} className="capitalize">
                  {apparelGroup.name}
                  {apparelGroups.length !== index + 1 && ','}{' '}
                </span>
              ))}
            </span>
            {market.audiences.map((aud, idx) => (
              <span
                key={idx}
                className="font-regular text-[14px] text-[#626775]"
              >
                {genderMapping[aud.gender].label},{' '}
                {`${aud.ageMin} - ${aud.ageMax}`}
              </span>
            ))}
          </div>
        </div>
      </div>
    ));
  }

  return (
    <div className="border-[rgba(161, 161, 0.4)] mt-[29px] flex w-full flex-col items-center border-b pb-[22px]">
      <div
        className="flex w-full cursor-pointer items-center space-x-[19px]"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span>{isOpen ? <ArrowUpIcon /> : <ArrowRightIcon />}</span>
        <span className="select-none pt-[4px] text-[24px] font-semibold leading-[28px]">
          Market: {location?.name}
        </span>
      </div>
      <div
        className={`mt-[5px] w-full justify-between ${
          isOpen ? 'flex' : 'hidden'
        }`}
      >
        <div className="flex flex-col pl-[39px]">
          <span className="font-regular text-[14px] text-[#626775]">
            {apparelGroups.map((apparelGroup, index) => (
              <span key={index} className="capitalize">
                {apparelGroup.name}
                {apparelGroups.length !== index + 1 && ','}{' '}
              </span>
            ))}
          </span>
          {audience.map((aud, idx) => (
            <span key={idx} className="font-regular text-[14px] text-[#626775]">
              {genderMapping[aud.gender].label},{' '}
              {`${aud.ageMin} - ${aud.ageMax}`}
            </span>
          ))}
        </div>
        <div className="flex flex-col gap-y-[13px]">
          <span className="flex items-center gap-x-[5px]">
            <span>
              <CategoryIcon />
            </span>
            <span className="pt-[4px] text-[14px] leading-[16px] text-[#C29B77]">
              Category: {apparelGroups.length}/{plan?.scope?.apparel}
            </span>
            <button
              className="font-regular ml-[9px] cursor-pointer text-[12px] leading-[14px] underline"
              onClick={() => handleNavigate('categories')}
            >
              Edit
            </button>
          </span>
          <span className="flex items-center gap-x-[5px]">
            <span>
              <UsersIcon />
            </span>
            <span className="pt-[4px] text-[14px] leading-[16px] text-[#C29B77]">
              Audience: {audience.length || 0}/{plan?.scope?.audience}
            </span>
            <button
              className="font-regular ml-[9px] cursor-pointer text-[12px] leading-[14px] underline"
              onClick={() => handleNavigate('audience')}
            >
              Edit
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};
