import React from 'react';
import { useLocation } from 'react-router-dom';

export const CreateReportProcessLine = () => {
  const { pathname } = useLocation();

  const getColor = (item) =>
    pathname?.split('/')[2] === item ? '#C29B77' : '#D9D9D9';

  return (
    <div className="relative mt-[32px] mb-[85px] flex w-full max-w-[573px] items-center justify-center">
      <div className="z-10 flex w-[268px] justify-between">
        {['location', 'audience', 'category'].map((item, index) => (
          <span className="flex flex-col items-center" key={index}>
            <span
              className="flex h-[12px] w-[12px] rounded-full"
              style={{
                background: getColor(item),
              }}
            ></span>
            <span
              className="font-regular text-[12px] capitalize leading-[21px]"
              style={{
                color: getColor(item),
              }}
            >
              {item}
            </span>
          </span>
        ))}
      </div>
      <div className="absolute top-[6px] flex w-full border-b border-[#D9D9D9]" />
    </div>
  );
};
