import { useQuery } from '@apollo/client';

import { FASHION_WEEK_ITEM } from '@/api/explore/queries';
import { nameFormat } from '@/helpers/utils';

import { Image } from './components/Image';
import { AttributesSkeleton, ImageSkeleton } from './components/Skeletons';
import { Attributes, Wrapper } from './index';

export function FashionWeekItemHeader({ item }) {
  if (!item) {
    return <div>loading</div>;
  }

  const seasonSplit = item?.season.split('-');
  const year = seasonSplit[seasonSplit.length - 1];

  const getSeasonAbbreviation = () => {
    if (seasonSplit.includes('resort')) {
      return `Resort ${year}`;
    }
    if (seasonSplit.includes('pre')) {
      return `Pre-Fall ${year}`;
    }

    const season = `${seasonSplit[0][0]}${seasonSplit[1][0]}`;
    return `${season.toUpperCase()}${year.slice(2, 4)}`;
  };

  return (
    <div className="flex flex-col gap-[0.675rem]">
      <h3 className="text-lg font-semibold capitalize leading-none text-primary">
        {nameFormat(item?.city)} Fashion Week{' '}
        <span>{getSeasonAbbreviation()}</span>
      </h3>
      <h1 className="text-[2.5rem] font-semibold capitalize leading-tight">
        {item?.designer.replaceAll('-', ' ')}
      </h1>
    </div>
  );
}

export function FashionWeekItem({ id }) {
  const { data, loading, error } = useQuery(FASHION_WEEK_ITEM, {
    variables: {
      itemId: id,
    },
    skip: !id,
  });

  if (loading) {
    return (
      <Wrapper>
        <ImageSkeleton />
        <AttributesSkeleton />
      </Wrapper>
    );
  }

  if (error) {
    return (
      <Wrapper>
        <div className="flex h-full w-full items-center justify-center">
          <p>{error.message}</p>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Image
        image={data?.fashionWeekItem.photo.image}
        apparels={data?.fashionWeekItem.apparels}
      />
      <Attributes
        loading={loading}
        item={data?.fashionWeekItem.photo}
        apparels={data?.fashionWeekItem.apparels}
      />
    </Wrapper>
  );
}
