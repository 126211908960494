import React from 'react';

import * as Pages from '@/pages/general';

import { Private } from './types';

export const explore = [
  {
    path: 'explore',
    element: (
      <Private>
        <Pages.Explore />
      </Private>
    ),
    children: [
      {
        path: 'image-preview/:type/:id',
        element: (
          <Private>
            <Pages.ImagePreview />
          </Private>
        ),
      },
      {
        path: 'image-preview/:type/:id/similar-images',
        element: (
          <Private>
            <Pages.ExploreSimilarImages />
          </Private>
        ),
      },
    ],
  },
];
