import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useReports } from '@/hooks/report';

const ArrowLeftIcon = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.0021 0.984375L3.00315 6.37926L2.37566 6.54332L2.37566 6.73722L14 6.73722L14 8.24361L2.37566 8.24361L2.37566 8.4375L2.96537 8.60156L8.02308 13.9964L7.03463 15L-8.64339e-07 7.49148L7.03253 8.30863e-08L8.0021 0.984375Z"
      fill="#626775"
    />
  </svg>
);

const ArrowRightIcon = ({ color }) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.9979 14.0156L10.9969 8.62074L11.6243 8.45668L11.6243 8.26278L-2.94493e-07 8.26278L-3.60339e-07 6.75639L11.6243 6.75639L11.6243 6.5625L11.0346 6.39844L5.97691 1.00355L6.96537 6.49208e-07L14 7.50852L6.96747 15L5.9979 14.0156Z"
      fill={color.length > 0 ? color : '#626775'}
    />
  </svg>
);

export const CreateReportNavigation = () => {
  const { pathname } = useLocation();
  const steps = [
    'select-a-plan',
    'location',
    'audience',
    'categories',
    'report',
  ];
  const { reports, activeReport } = useSelector((state) => state.report);
  const path = pathname?.split('/')[2];

  const checkNavigate = () => {
    const report =
      reports?.[reports.findIndex((item) => item.id === activeReport)];
    if (path === 'audience') {
      return (
        report?.audience?.length > 0 &&
        !report?.audience.find((item) => item.edit === true)
      );
    } else {
      return steps.includes(path) && report?.[path]?.length > 0;
    }
  };

  const { nextStep, prevStep, checkNextStep, checkPrevStep } = useReports();

  return (
    <div className="mt-[24px] mb-[40px] flex w-full items-center justify-between">
      <button
        className="flex cursor-pointer items-center justify-center gap-x-[6px] text-[#626775]"
        onClick={prevStep}
        disabled={!checkPrevStep()}
      >
        <ArrowLeftIcon />
        <span className="pt-[2px] font-semibold">Back</span>
      </button>
      <button
        className={[
          'flex items-center justify-center gap-x-[6px] text-[#626775]',
          checkNextStep() ? 'cursor-pointer' : 'cursor-not-allowed',
        ].join(' ')}
        style={{
          color: checkNavigate() ? '#3CA780' : '#626775',
        }}
        disabled={!checkNextStep()}
        onClick={nextStep}
      >
        <span className="pt-[2px] font-semibold">Next</span>
        <ArrowRightIcon color={checkNextStep() && '#3CA780'} />
      </button>
    </div>
  );
};
