import { useSearchParams } from 'react-router-dom';

import { useFiltersQuery } from '@/api/filters/hook';
import { FilterDropdown } from '@/components/FilterDropdown';
// import { DemoAccountModal } from '@/components/DemoAccountPopup'
// import { useFilters } from '@/hooks/filters'

// import { FilterDropdownRoot, FiltersCheckboxDropdown } from './FiltersDropdown'

// export function ExploreCategory () {
//   const {
//     selectedSeasonLabel,
//     selectedSeason,
//     selectedApparels,
//     selectApparel,
//     selectedApparelItems,
//     // selectApparelItem,
//     clearApparels,
//     selectedAudience,
//     selectedMarket,
//     demoApparels
//   } = useFilters('explore')
//   const [searchTerm, setSearchTerm] = useState('')
//   const [showDemoPopup, setShowDemoPopup] = useState(false)

//   const { data: userData } = useUserQuery('me')

//   const isDemoAccount = userData?.me?.email === 'demo@tfashion.ai'

//   const { data, error, refetch } = useFiltersQuery('exploreApparels', {
//     season: selectedSeasonLabel(),
//     audiences: selectedAudience ? [selectedAudience.id] : null,
//     market: selectedMarket
//       ? !['demo_market_1', 'demo_market_2'].includes(selectedMarket.id)
//           ? selectedMarket.id
//           : null
//       : null
//   })

//   useEffect(() => {
//     refetch({
//       season: selectedSeasonLabel(),
//       audiences: selectedAudience ? [selectedAudience.id] : null,
//       market: selectedMarket
//         ? !['demo_market_1', 'demo_market_2'].includes(selectedMarket.id)
//             ? selectedMarket.id
//             : null
//         : null
//     })
//   }, [selectedSeason, selectedAudience])

//   const searchResult = useMemo(() => {
//     if (searchTerm) {
//       if (isDemoAccount) {
//         return demoApparels.filter((item) =>
//           item.label.toLowerCase().includes(searchTerm.toLowerCase())
//         )
//       }

//       return data?.exploreFilters?.apparels?.filter((item) =>
//         item.label.toLowerCase().includes(searchTerm.toLowerCase())
//       )
//     } else {
//       if (isDemoAccount) return demoApparels

//       return data?.exploreFilters?.apparels
//     }
//   }, [searchTerm, data])

//   let selectedOptions = null

//   if (selectedApparels.length > 0) {
//     selectedOptions = selectedApparels
//   }

//   if (selectedApparelItems.length > 0) {
//     selectedOptions = selectedApparelItems
//   }

//   function isApparelSelected (apparel) {
//     if (!isDemoAccount || apparel.label === 'dress' || !isDemoAccount) {
//       return selectedApparels?.find((i) => i.id === apparel.id)
//     }

//     return false
//   }

//   function handleSelectApparel (apparel) {
//     if (isDemoAccount && apparel.label !== 'dress') {
//       return
//     }

//     selectApparel(apparel)
//   }

//   return (
//     <>
//       <DemoAccountModal
//         isOpen={showDemoPopup}
//         onClose={() => setShowDemoPopup(false)}
//       />
//       <FilterDropdownRoot
//         open={showDemoPopup ? false : undefined}
//         label={error ? 'Error loading categories' : 'Categories'}
//         selectedOptions={selectedOptions}
//       >
//         {!error
//           ? (
//             <FiltersCheckboxDropdown
//             searchBox
//             searchBoxOnChange={(value) => setSearchTerm(value)}
//             searchBoxOnClearAll={() => {
//               setSearchTerm('')
//               clearApparels()
//             }}
//             searchBoxValue={searchTerm}
//             searchBoxPlaceholder="Search Categories"
//             checkedFn={isApparelSelected}
//             filters={searchResult}
//             onClick={(apparel) => {
//               if (isDemoAccount && apparel.label !== 'dress') {
//                 setShowDemoPopup(true)
//               }
//             }}
//             onSelect={handleSelectApparel}
//           />
//             )
//           : null}
//       </FilterDropdownRoot>
//     </>
//   )
// }

export function ExploreCategory() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data, loading } = useFiltersQuery('exploreApparels', {
    season: searchParams.get('season'),
    audiences: searchParams.get('audiences')
      ? [searchParams.get('audiences')]
      : null,
    market: searchParams.get('market')
      ? !['demo_market_1', 'demo_market_2'].includes(searchParams.get('market'))
        ? searchParams.get('market')
        : null
      : null,
  });

  const selectedApparels = searchParams.get('apparels')?.split(',') || [];

  function handleOnChange(apparel) {
    if (apparel.length === 0) {
      setSearchParams((prev) => {
        prev.delete('apparels');
        return prev;
      });

      return;
    }

    const selectedApparels = apparel?.map((apparel) => apparel.id);

    setSearchParams((prev) => {
      prev.set('apparels', selectedApparels);
      return prev;
    });
  }

  const options = [
    {
      label: 'Categories',
      options: data?.exploreFilters?.apparels || [],
    },
  ];

  return (
    <FilterDropdown
      label="Category"
      placeholder="Search for categories"
      isLoading={loading}
      isMulti
      isSearchable
      options={options}
      selectedOptions={selectedApparels}
      onChange={handleOnChange}
    />
  );
}
