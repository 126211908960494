import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@radix-ui/react-tooltip';
import { startCase } from 'lodash';
import numeral from 'numeral';

import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table';
import { lastArrowStyle, ReverseArrow } from '@/components/ui/tooltip';
import { apparelMapping } from '@/constants/index';
import { cn } from '@/helpers/utils';
import tones from '@/tones.json';

const toneSkeletonBgMapping = {
  1: 'bg-[#F5F5F5]',
  2: 'bg-[#E5E5E5]',
  3: 'bg-[#D9D9D9]',
  4: 'bg-[#C9C9C9]',
  5: 'bg-[#B9B9B9]',
  6: 'bg-[#A9A9A9]',
};

function ToneSkeleton({ bg }) {
  const color = toneSkeletonBgMapping[bg];

  return (
    <div className={['w-1/6 h-8 h-max-8 animate-pulse', color].join(' ')}></div>
  );
}

export const ToneBar = ({
  tone,
  hex,
  metric,
  images,
  isLast,
  variant = 'default',
  tab = 'tone',
}) => {
  if (tone === undefined || hex === undefined) {
    return (
      <div className="mb-8">
        <h3 className="mb-4 text-lg font-bold leading-5 text-black">
          Color Tones
        </h3>
        <div className="flex h-8 w-full flex-row items-start justify-center">
          {Array.from({ length: 6 }, (_, i) => (
            <ToneSkeleton key={i} bg={i + 1} />
          ))}
        </div>
      </div>
    );
  }

  if (variant !== 'default') {
    return (
      <div
        className={cn('group flex flex-1 flex-col items-center w-full')}
        style={{ flex: `${Number(metric).toFixed(0)}%` }}
      >
        <div
          className={cn(
            'mb-[0.625rem] flex h-[0.625rem] w-full items-center justify-center font-semibold text-5xl leading-[0rem]  text-center transition text-transparent select-none ',
          )}
          style={{ background: `${hex}` }}
        >
          <Tooltip side="top" align="center" delayDuration={0}>
            <TooltipTrigger className="h-full w-full">i</TooltipTrigger>
            <TooltipContent sideOffset={4}>
              <div className="flex flex-col items-start justify-center rounded-xl bg-white shadow-md">
                <div className="flex flex-row items-start justify-center px-4 py-2">
                  <div className="flex flex-row items-center justify-between gap-x-8">
                    <div className="flex flex-row items-center gap-x-1">
                      <span
                        className="h-2.5 w-2.5 rounded-full"
                        style={{ background: hex }}
                      ></span>
                      {tab === 'tone' && (
                        <h3 className="text-xs font-semibold text-black">
                          Pantone{' '}
                          <span style={{ color: hex }}>{tone.code}</span> TCX
                        </h3>
                      )}
                      {tab === 'pattern' && (
                        <h3 className="text-xs font-semibold text-black">
                          {startCase(tone.ssiPattern)}
                        </h3>
                      )}
                      {tab === 'color' && (
                        <h3 className="text-xs font-semibold text-black">
                          {startCase(tone.ssiColor)}
                        </h3>
                      )}
                      {tab === 'fabric' && (
                        <h3 className="text-xs font-semibold text-black">
                          {startCase(tone.ssiFabric)}
                        </h3>
                      )}
                      {tab === 'attribute' && (
                        <h3 className="text-xs font-semibold text-black">
                          {`${startCase(tone.attributeKeyA || tone.attributeKeyB)} - 
                          ${startCase(
                            tone.attributeValueA || tone.attributeValueB,
                          )}`}
                        </h3>
                      )}
                      {tab === 'apparel' && (
                        <h3 className="text-xs font-semibold text-black">
                          {startCase(apparelMapping[tone.apparel])}
                        </h3>
                      )}
                    </div>
                    <div className="flex">
                      <span className="text-xs font-semibold text-black">
                        {numeral(metric).format('0.0a').toUpperCase()}%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </TooltipContent>
          </Tooltip>
        </div>
      </div>
    );
  }

  return (
    <div
      className={cn('group flex flex-1 flex-col items-center w-full')}
      style={{ flex: `${Number(metric).toFixed(0)}%` }}
    >
      <div
        className={cn(
          'mb-[0.625rem] flex h-[2rem] w-full items-center justify-center font-semibold text-5xl leading-[0rem]  text-center transition text-transparent select-none ',
        )}
        style={{ background: `${hex}` }}
      >
        <Tooltip side="top" align="center" delayDuration={0}>
          <TooltipTrigger className="h-full w-full">2</TooltipTrigger>
          <TooltipContent sideOffset={-8}>
            <div className="flex flex-col items-start justify-center rounded-xl bg-white shadow-md">
              {images?.length > 0 && (
                <div className="flex w-full flex-row items-start justify-center gap-x-0">
                  {images.slice(0, 3).map((image, i) => (
                    <div
                      style={{
                        backgroundImage: `url(${image.url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        aspectRatio: '18 / 25',
                        width: '100%',
                      }}
                      className={`h-full bg-secondary ${i === 0 ? 'rounded-tl-xl' : ''} ${i === 2 ? 'rounded-tr-xl' : ''}`}
                      key={i}
                    />
                  ))}
                </div>
              )}
              <div className="flex flex-row items-start justify-center px-4 py-2">
                <div className="flex flex-col items-start justify-center">
                  <h3 className="mb-0.5 text-base font-semibold leading-4 text-black">
                    Pantone <span style={{ color: hex }}>{tone.code}</span> TCX
                  </h3>
                  <h3 className="mb-[0.625rem] text-base font-semibold leading-4">
                    {startCase(tones.find((t) => t.hex === hex)?.label)}
                  </h3>
                  {metric !== undefined && (
                    <p className="text-start text-sm font-medium text-black">
                      Visibility
                      <span
                        className="leading-[0.94rem text-[0.6875rem] font-medium"
                        style={{ color: metric > 0 ? '#1C9A6D' : '#EA3323' }}
                      >
                        {' '}
                        {metric}%
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>
            {isLast ? (
              <ReverseArrow className={lastArrowStyle()} />
            ) : (
              <ReverseArrow />
            )}
          </TooltipContent>
        </Tooltip>
      </div>
    </div>
  );
};

export const DetailTones = ({ tones, variant = 'default' }) => {
  if (!tones || tones.length === 0 || tones === null || tones.tone === null) {
    return <></>;
  }

  const tonesList =
    tones.find((tab) => tab.name === 'Tone')?.data.slice(0, 5) || [];

  if (tonesList.find((tone) => tone.tone === null)) {
    return <></>;
  }

  const areTonesSame = tonesList.every(
    (tone, i, arr) => tone.tone.hex === arr[0].tone.hex,
  );

  if (areTonesSame || tonesList.length === 1) {
    return <></>;
  }

  const percentCalculator = (yoyGrowth, totalTones) => {
    const totalGrowth = totalTones.reduce(
      (sum, tone) =>
        sum + (tone.yoyGrowth?.percent || tone.lastWeekQualityEngagement),
      0,
    );
    const percent = (yoyGrowth / totalGrowth) * 100;
    const res = variant === 'default' ? percent.toFixed(2) : percent.toFixed(0);
    return res;
  };

  return (
    <div className="flex w-full flex-col gap-y-8">
      <div className={cn('flex w-full items-start')}>
        <div className="flex w-full flex-col">
          <h3 className="mb-4 text-lg font-bold leading-6 text-black">
            Color Tones
          </h3>
          <div className="flex w-full flex-col items-start justify-center">
            <div className="flex w-full flex-row items-start justify-center">
              {tonesList.length > 0 &&
                tonesList.map((item, index) => (
                  <ToneBar
                    hex={item.tone?.hex}
                    tone={item.tone}
                    metric={percentCalculator(
                      item.yoyGrowth?.percent || item.lastWeekQualityEngagement,
                      tonesList,
                    )}
                    images={item.featuredImages}
                    isLast={index === tonesList.length - 1}
                    key={index}
                    variant={'default'}
                  />
                ))}
            </div>
            <Table className="w-full">
              <TableBody>
                {tonesList.map((item, index) => (
                  <TableRow key={index} className="border-none">
                    <TableCell className="w-3/4 px-1 py-0">
                      <div className="flex flex-row items-center justify-start">
                        <div
                          className="h-2.5 w-2.5 rounded-full"
                          style={{ background: item.tone.hex }}
                        ></div>
                        <p className="ml-2 py-1.5 text-sm font-medium text-black">
                          Pantone {item.tone.code} TCX
                        </p>
                      </div>
                    </TableCell>
                    <TableCell className="w-1/4 px-2 py-0 text-right font-medium">
                      {percentCalculator(
                        item.yoyGrowth?.percent ||
                          item.lastWeekQualityEngagement,
                        tonesList,
                      ) + '%'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};
