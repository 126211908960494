import { useRef } from 'react';
import { Trigger as RadixTrigger } from '@radix-ui/react-dropdown-menu';
import { capitalize } from 'lodash';

import { Icon } from '@/icons';

import styles from '../../../styles/Dropdown.module.css';

export const Trigger = ({
  label,
  icon,
  iconColor,
  selectedOptions,
  disabled,
  open,
  onOpenChange,
}) => {
  const triggerRef = useRef(null);

  const formattedSelectedOptions = selectedOptions
    ? Array.isArray(selectedOptions)
      ? selectedOptions
          .map((l) => capitalize(l.label))
          .join(', ')
          .substring(0, 30)
      : selectedOptions.label
    : null;

  return (
    <RadixTrigger
      ref={triggerRef}
      asChild
      className="inline-flex"
      disabled={disabled}
      onOpenChange={(open) => {
        onOpenChange && onOpenChange(open);
      }}
    >
      <button className={styles.dropdownTriggerButton}>
        <div
          className={[
            styles.dropdownTriggerLabelWrapper,
            selectedOptions ? styles.dropdownTriggerLabelWrapperSelected : '',
          ].join(' ')}
        >
          <span
            className={[
              styles.dropdownTriggerLabel,
              selectedOptions ? styles.dropdownTriggerLabelSelected : '',
            ].join(' ')}
          >
            {label}
          </span>

          {formattedSelectedOptions && (
            <span className={styles.dropdownTriggerSelectedOptions}>
              {formattedSelectedOptions}
            </span>
          )}
        </div>

        {icon || <Icon name="chevron-down" color={iconColor} />}
      </button>
    </RadixTrigger>
  );
};
