import React from 'react';

export const ArrowUpIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 12.5L10 7.5L5 12.5"
      stroke={color || '#282928'}
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);
