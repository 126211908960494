import { useMutation, useQuery } from '@apollo/client';

import {
  DOWNVOTE_APPAREL,
  EXCLUDE_ACCOUNT,
  EXCLUDE_APPAREL,
  REPORT_ITEM,
  UPVOTE_APPAREL,
} from './mutations';
import { EXPLORE } from './queries';

const queryMap = {
  explore: EXPLORE,
};

const mutationMap = {
  upvote: UPVOTE_APPAREL,
  downvote: DOWNVOTE_APPAREL,
  banAccount: EXCLUDE_ACCOUNT,
  banApparel: EXCLUDE_APPAREL,
  reportItem: REPORT_ITEM,
};

export function useExploreQuery(queryName, options) {
  const query = queryMap[queryName];

  if (!query) {
    throw new Error(`Invalid query name: ${queryName}`);
  }

  return useQuery(query, options);
}

export function useExploreMutation(mutationName, options) {
  const mutation = mutationMap[mutationName];

  if (!mutation) {
    throw new Error(`Invalid mutation name: ${mutationName}`);
  }

  return useMutation(mutation, options);
}
