import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useAppContext } from '@/contexts/AppContext';
import { Filters, Navbar } from '@/layouts/common';

export const Layout = () => {
  const location = useLocation();
  const { showUpdatePopup } = useAppContext();
  let isFiltersVisible =
    (location.pathname.includes('/overview') ||
      location.pathname.includes('/trends/detail') ||
      location.pathname.includes('/collections') ||
      location.pathname.includes('/profile') ||
      location.pathname.includes('/invite-co-workers') ||
      location.pathname.includes('/invited-to-organization') ||
      location.pathname.includes('/pricing') ||
      location.pathname.includes('/create-trend-report') ||
      location.pathname.includes('/trends') ||
      location.pathname.includes('/studio')) === false;

  if (isFiltersVisible && location.pathname.startsWith('/trends')) {
    isFiltersVisible = true;
  } else {
    isFiltersVisible = false;
  }

  return (
    <div
      className="flex h-full w-full flex-col"
      style={{
        paddingTop: isFiltersVisible
          ? '11.1875rem'
          : showUpdatePopup
            ? '8rem'
            : '5.875rem',
      }}
    >
      <Navbar />
      {isFiltersVisible && <Filters />}
      <Outlet />
    </div>
  );
};
