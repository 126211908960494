import { gql } from '@apollo/client';

export const CREATE_COMMENT = gql`
  mutation CreateComment($input: CreateCommentInput) {
    createComment(input: $input) {
      status
    }
  }
`;

export const REPLY_COMMENT = gql`
  mutation ReplyComment($parentComment: String, $input: CreateCommentInput) {
    replyComment(parentComment: $parentComment, input: $input) {
      status
    }
  }
`;

export const LIKE_COMMENT = gql`
  mutation LikeComment($commentId: String, $parentComment: String) {
    likeComment(commentId: $commentId, parentComment: $parentComment) {
      status
    }
  }
`;

export const DISLIKE_COMMENT = gql`
  mutation DislikeComment($commentId: String, $parentComment: String) {
    dislikeComment(commentId: $commentId, parentComment: $parentComment) {
      status
    }
  }
`;
