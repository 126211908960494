import React from 'react';

export const ArrowDown = ({ color = '#000' }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.3125 8.60493L8.50568 15.7767L8.72443 16.6769H8.98295V0H10.9915V16.6769H11.25L11.4688 15.8309L18.6619 8.57482L20 9.99291L9.98864 20.0852L0 9.99593L1.3125 8.60493Z"
        fill={color}
        className="transition-all"
      />
    </svg>
  );
};
