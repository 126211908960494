import { useCallback, useEffect, useMemo, useState } from 'react';
import { Blurhash } from 'react-blurhash';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';

import { useExploreContext } from '@/contexts/ExploreContext';

import { useCard } from '../context';

function Wrapper({ width, height, className, children }) {
  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        position: 'relative',
        maxWidth: '100%',
      }}
      className={className || ''}
    >
      {children}
    </div>
  );
}

export function Watermark() {
  const location = useLocation();
  return (
    <svg
      viewBox="0 0 1024 181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`absolute ${location.pathname.includes('image-preview') ? '-bottom-1 -right-2' : 'bottom-0 right-0'} w-full`}
      style={{
        height: 'auto',
        width: location.pathname.includes('image-preview') ? '130%' : '110%',
      }}
    >
      <path
        d="M867 90.5C867 55.4299 895.93 27 931 27H932C967.07 27 997.202 55.4299 997.202 90.5C997.202 125.57 967.57 154 932.5 154H932C896.93 154 867 125.57 867 90.5Z"
        fill="white"
      />
      <path
        d="M983.727 72.2872C985.842 77.974 987 84.1104 987 90.5092C987 120.051 962.422 144 932.104 144C909.501 144 890.095 130.684 881.681 111.67L910.33 85.1534L939.863 114.55L983.727 72.2872ZM931.774 37C950.977 37 967.859 46.5752 977.638 61.0523L939.653 97.4174L910.354 68.4383L877.698 98.4681C877.243 95.7188 877 92.9033 877 90.0297C877 60.7424 901.523 37 931.774 37Z"
        fill="black"
      />
    </svg>
  );
}

export function Image() {
  const [loading, setLoading] = useState(true);
  const { socialMediaImageVersion } = useExploreContext();

  const { data, containerWidth, setContainerHeight, isCarousel, hovered } =
    useCard();

  const setContainerHeightDebounced = useCallback(
    debounce((height) => setContainerHeight(height), 300),
    [],
  );

  const getImageUrl = () => {
    if (data.__typename === 'ExploreFashionWeekItem') {
      return data.image.url;
    }

    return socialMediaImageVersion === 'instance'
      ? data.image.url
      : data.image.url.replace(/instance-\d+/, 'full');
  };
  const url = getImageUrl() + '&width=600';

  const getImageDimensions = () => {
    const isFashionWeekImage = data.__typename === 'ExploreFashionWeekItem';
    return isFashionWeekImage
      ? {
          width: 1024,
          height: 1536,
        }
      : {
          width: data?.post?.image?.width || data.image.width,
          height: data?.post?.image?.height || data.image.height,
        };
  };

  const [imageWidth, setImageWidth] = useState(getImageDimensions().width);
  const [imageHeight, setImageHeight] = useState(getImageDimensions().height);

  useEffect(() => {
    const imageToLoad = new window.Image();
    imageToLoad.src = url;
    imageToLoad.onload = () => {
      setImageWidth(imageToLoad.naturalWidth);
      setImageHeight(imageToLoad.naturalHeight);
      setLoading(false);
    };

    return () => setLoading(false);
  }, [url]);

  const height = useMemo(() => {
    if (data.__typename === 'ExploreItem' || data.__typename === 'Post') {
      setContainerHeightDebounced((containerWidth / 9) * 16);
      return (containerWidth / 9) * 16;
    }

    setContainerHeightDebounced((containerWidth / imageWidth) * imageHeight);
    return (containerWidth / imageWidth) * imageHeight;
  }, [containerWidth, imageWidth, imageHeight, setContainerHeightDebounced]);

  return (
    <Wrapper width={containerWidth} height={height}>
      {loading && data.image.blurhash ? (
        <Blurhash
          hash={
            socialMediaImageVersion === 'instance'
              ? data.image.blurhash
              : data?.post?.image?.blurhash || data.image.blurhash
          }
          width={containerWidth}
          height={height}
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      ) : (
        <div
          className={`absolute z-10 h-full w-full overflow-hidden rounded-md`}
          style={{ animation: 'soft-fade-in 0.2s ease-in' }}
        >
          {isCarousel && !hovered && (
            <div className="absolute top-px right-px z-10 p-2">
              <svg
                id="Layer_1"
                height="20"
                viewBox="0 0 24 24"
                width="20"
                fill="#fffffe"
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
              >
                <path d="m15 20h-10a5.006 5.006 0 0 1 -5-5v-10a5.006 5.006 0 0 1 5-5h10a5.006 5.006 0 0 1 5 5v10a5.006 5.006 0 0 1 -5 5zm9-1v-13a1 1 0 0 0 -2 0v13a3 3 0 0 1 -3 3h-13a1 1 0 0 0 0 2h13a5.006 5.006 0 0 0 5-5z" />
              </svg>
            </div>
          )}
          <div
            className="absolute h-full w-full scale-110 bg-cover blur-md"
            style={{ backgroundImage: `url(${url})` }}
          />
          <div
            className="h-full w-full scale-100 rounded-lg bg-contain bg-center bg-no-repeat transition-all duration-500 ease-in-out group-hover:scale-[1.35]"
            style={{
              backgroundImage: `url(${url})`,
            }}
          />
          {data.__typename === 'ExploreFashionWeekItem' && <Watermark />}
        </div>
      )}
    </Wrapper>
  );
}
