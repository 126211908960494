import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Email is required')
    .min(3, 'Email must be at 3 char long'),
});

export const forgotPasswordOptions = {
  resolver: yupResolver(forgotPasswordSchema),
};
