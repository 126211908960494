import { fontSizeMap, fontWeightMap } from '@/components';

export function Text({
  size = 'md',
  weight = 'normal',
  className = '',
  as = 'p',
  children,
  ...props
}) {
  const Comp = as;

  return (
    <Comp
      className={[
        'font-planc',
        fontSizeMap[size],
        fontWeightMap[weight],
        className,
      ].join(' ')}
      {...props}
    >
      {children}
    </Comp>
  );
}
