import React from 'react';

import { cn } from '@/helpers/utils';

export function Container({ children, className, mainPage = false, ...props }) {
  return (
    <div
      className={cn(
        'px-[3.75rem]',
        mainPage ? 'py-[2rem]' : 'container mx-auto',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}
