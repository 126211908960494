export const initialState = {
  selectedApparels: [],
  // Selected Locations
  selectedLocations: [],
  // Selected Audience
  selectedAudiences: [],
  // Selected Seasons
  selectedSeasons: ['0'],
  // Selected Categories
  selectedCategories: [],
  // Selected Colors
  selectedColors: [],
  // Selected Patterns
  selectedPatterns: [],
};
