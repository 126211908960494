import { useQuery } from '@apollo/client';

import { GET_STUDIO_TASK_HISTORY } from '@/api/studio/queries';
import { LazyLoadTrigger } from '@/pages/general/explore/components/utils';
import {
  StudioDesignTask,
  StudioDesignTaskLoading,
} from '@/pages/general/studio/components/StudioDesignTask';

export function StudioTabHistory({ ...props }) {
  const { data, error, loading, networkStatus, fetchMore } = useQuery(
    GET_STUDIO_TASK_HISTORY,
    {
      variables: {},
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    },
  );

  const loadingMore = networkStatus === 3;
  if (loading && !loadingMore) {
    return <StudioTabHistoryLoading />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className={'mt-4 flex flex-col gap-8'}>
        {data.studioTaskHistory.edges.map((item, i) => (
          <StudioDesignTask designTask={item.node} key={i} />
        ))}
      </div>

      {loadingMore && (
        <div className={'mt-4 flex flex-col gap-8'}>
          {Array.from({ length: 2 }).map((_, i) => (
            <StudioDesignTaskLoading key={i} />
          ))}
        </div>
      )}

      {data.studioTaskHistory.pageInfo.hasNextPage && !loadingMore && (
        <LazyLoadTrigger
          fetchMore={() => {
            fetchMore({
              variables: {
                cursor: data.studioTaskHistory.pageInfo.endCursor,
              },
            });
          }}
          cursorNext={data.studioTaskHistory.pageInfo.endCursor}
          loading={loading}
        />
      )}
    </>
  );
}

export function StudioTabHistoryLoading() {
  return (
    <div className={'mt-4 flex flex-col gap-8'}>
      {Array.from({ length: 5 }).map((_, i) => (
        <StudioDesignTaskLoading key={i} orientation={'portrait'} />
      ))}
    </div>
  );
}
