import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { MoveDown } from 'lucide-react';

import { useDemoAccount } from '@/api/authentication/hook';
import { ME } from '@/api/authentication/queries';
import { useFiltersQuery } from '@/api/filters/hook';
import { useTrendsQuery } from '@/api/trends/hook';
import { Container } from '@/components';
import { DemoAccountModal } from '@/components/DemoAccountPopup';
import { getInitials } from '@/helpers/utils';
import { useFilters } from '@/hooks/filters';
import { FEATURE_FLAGS, useFeatureFlag } from '@/hooks/useFeatureFlag';

import { Header } from './components';

function OverviewLoader() {
  return (
    <div className="flex flex-row items-start justify-between gap-x-6">
      <div className="flex w-2/3 flex-col items-start justify-start">
        <h3 className="mb-4 text-head font-bold text-black">Markets</h3>
        {Array.from({ length: 3 }).map((_, index) => (
          <div key={index} className="flex w-full flex-col gap-y-6">
            {Array.from({ length: 2 }).map((_, index) => (
              <div
                key={index}
                className="flex w-full flex-row items-start justify-start gap-x-10 rounded-xl
border border-[#1a1a1a] border-opacity-10 bg-white py-6 px-8 shadow-sm"
              >
                <div className="flex w-1/2 flex-col items-start justify-start gap-y-9">
                  <div className="flex flex-col">
                    <h2 className="ghost text-left text-head font-bold text-transparent">
                      Japan
                    </h2>
                    <p className="ghost text-left text-head font-bold text-transparent">
                      M 25-45
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <p className="line ghost text-left text-base font-normal italic text-transparent">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nulla nec purus feugiat, molestie ipsum et, eleifend nunc.
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Nulla nec purus feugiat, molestie ipsum et, eleifend nunc.
                    </p>
                  </div>
                </div>
                <div className="flex w-1/2 flex-col items-start justify-center gap-y-6">
                  <div className="flex w-full flex-col gap-y-6">
                    {Array.from({ length: 3 }).map((_, index) => (
                      <div
                        key={index}
                        className="flex flex-row justify-between rounded-xl"
                      >
                        <div className="flex w-max flex-col items-start justify-center">
                          <div className="ghost text-lg font-medium leading-[1.375rem] text-transparent">
                            Denim Jacket
                          </div>
                          <p className="ghost text-sm font-normal leading-4 text-transparent">
                            Year over Year Growth
                          </p>
                        </div>
                        <div className="ghost flex aspect-[2.15] w-1/2 gap-[0.05rem] rounded-xl [&>*]:w-1/3">
                          {Array.from({ length: 3 }).map((_, index) => (
                            <div
                              key={index}
                              style={{
                                aspectRatio: '18/25',
                                width: '100%',
                                borderRadius:
                                  index === 0
                                    ? '1rem 0 0 1rem'
                                    : index === 2
                                      ? '0 1rem 1rem 0'
                                      : 'none',
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="flex w-1/3 flex-col items-start justify-start gap-y-4">
        <h3 className="text-head font-bold text-black">Activity</h3>
        {Array.from({ length: 2 }).map((_, index) => (
          <div
            key={index}
            className="flex w-full flex-col items-start justify-start gap-x-0.5 rounded-xl
border border-[#1a1a1a] border-opacity-10 bg-white p-3 shadow-sm"
          >
            <div className="flex w-full flex-col items-start justify-start gap-y-9">
              <div className="flex flex-row items-start gap-x-4">
                <div
                  className="ghost rounded-full"
                  style={{
                    aspectRatio: '1/1',
                    height: '3rem',
                  }}
                />
                <div className="flex flex-col items-start justify-center">
                  <p className="line ghost w-full text-left text-sm font-normal text-transparent">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla nec purus feugiat, molestie ipsum et, eleifend nunc.
                  </p>
                </div>
                <div
                  className="ghost w-2/5 rounded-xl"
                  style={{
                    aspectRatio: '7/9',
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const MarketCardItems = ({ trendItem }) => {
  if (!trendItem) {
    return (
      <div className="flex w-full flex-col gap-y-6">
        <div className="flex flex-row justify-between rounded-xl">
          <div className="flex w-max flex-col items-start justify-center">
            <div className="ghost text-lg font-medium leading-[1.375rem] text-transparent">
              Denim Jacket
            </div>
            <p className="ghost text-sm font-normal leading-4 text-transparent">
              Year over Year Growth
            </p>
          </div>
          <div className="ghost flex aspect-[2.15] w-1/2 gap-[0.05rem] rounded-xl [&>*]:w-1/3">
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                style={{
                  aspectRatio: '18/25',
                  height: '4rem',
                  borderRadius:
                    index === 0
                      ? '1rem 0 0 1rem'
                      : index === 2
                        ? '0 1rem 1rem 0'
                        : 'none',
                }}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  // if (error) {
  //   return <div>Unexpected error {error.message}</div>;
  // }

  return (
    <div className="flex flex-row justify-between rounded-xl">
      <div className="flex w-max flex-col items-start justify-center">
        <div className="text-lg font-medium leading-[1.375rem] text-black">
          {trendItem?.title}
        </div>
        <p className="text-sm font-normal leading-4 text-black">
          Year over Year Growth{' '}
          <span
            style={
              trendItem?.yoyGrowth > 0 ? { color: '#1C9A6D' } : { color: 'red' }
            }
          >
            {trendItem?.yoyGrowth > 0 ? '+' : ''}
            {trendItem?.yoyGrowth?.toFixed(0)}%
          </span>
        </p>
      </div>
      <div className="flex aspect-[2.15] w-1/2 gap-[0.05rem] rounded-xl bg-white [&>*]:w-1/3">
        {trendItem?.featuredImages?.slice(0, 3).map((image, index) => (
          <MarketCardImages key={index} i={index} imageInfo={image} />
        ))}
      </div>
    </div>
  );
};

const MarketCardImages = ({ i, imageInfo }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${imageInfo?.url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: '#F7F7F7',
        aspectRatio: '18/25',
        width: '100%',
        borderRadius:
          i === 0
            ? '0.5rem 0 0 0.5rem'
            : i === 2
              ? '0 0.5rem 0.5rem 0'
              : 'none',
      }}
    />
  );
};

function MarketCard({
  marketId,
  audience,
  location,
  handleAudienceSelect,
  featuredItems,
}) {
  return (
    // TODO: set time frame to what default is set to
    <Link
      to={`/trends/snapshots/${marketId}/${audience.audienceId}?timeframe=lastWeek`}
      onClick={() => handleAudienceSelect({ id: marketId }, audience)}
      className="mb-6 block w-full rounded"
    >
      <div
        className="flex w-full cursor-pointer flex-row items-start
              gap-x-10 rounded-xl border border-charcoal/10 bg-white py-6 px-8 shadow-sm"
      >
        <div className="flex w-1/2 flex-col items-start justify-start gap-y-9">
          <div className="flex flex-col">
            <h2 className="text-left text-head font-bold text-primary">
              {location}
            </h2>
            <p className="text-left text-head font-bold capitalize text-black">
              {audience.name
                ? audience.name
                : `${Number(audience.gender) === 2 ? 'Women' : 'Men'} ${audience.ageMin}-${audience.ageMax}`}
            </p>
          </div>
          <div className="flex flex-col">
            <p className="line text-left text-base font-normal italic text-black">
              Explore the latest trends and insights to inspire your next
              design. Dive into the data-driven world of fashion and harness the
              power of analytics to create groundbreaking apparel that resonates
              with your target audience.
            </p>
          </div>
        </div>
        <div className="flex w-1/2 flex-col items-start justify-center gap-y-6">
          <MarketTrendItems featuredItems={featuredItems} />
        </div>
      </div>
    </Link>
  );
}

function returnActivityMessage(activityItem) {
  if (activityItem.notificationType === 'REPORT_RESOLVED') {
    return 'Reported item has been resolved.';
  }

  return activityItem.payload.comment?.comment;
}

function ActivityFeed({ activityItem, show, image }) {
  const { data: meData } = useQuery(ME);

  const navigate = useNavigate();

  const activityType = activityItem.notificationType;
  const activityBy = activityItem.source.fullName;
  const initialsName = getInitials(activityItem.source.fullName);

  if (activityItem.seen && !show) {
    return null;
  }

  const handleClick = () => {
    if (activityItem.source.fullName === 'SYSTEM') {
      return;
    }

    const entityType = activityItem.payload.entityType;
    const entityId = activityItem.payload.entityId;
    navigate(`/explore/image-preview/${entityType}/${entityId}`);
  };

  return (
    <div
      className="mb-6 flex w-full cursor-pointer flex-row items-start gap-x-0.5 rounded-xl border border-[#1a1a1a]/10 bg-white p-3 shadow-sm hover:border-charcoal/30"
      style={
        activityType === 'LIKE'
          ? { background: '#ff333305' }
          : { background: '#28292805' }
      }
      onClick={handleClick}
    >
      <div className="flex w-full flex-col items-start justify-start gap-y-9">
        <div className="flex w-full flex-row items-start gap-x-4">
          <div
            className="rounded-full shadow-sm"
            style={{
              // backgroundImage: `url(${activityItem.source.profilePicture ? activityItem.source.profilePicture : ''})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              aspectRatio: '1/1',
              height: '2.5rem',
              border: '1px solid rgba(161, 161, 161, 0.2)',
            }}
          >
            <div className="flex h-full w-full items-center justify-center text-lg font-medium text-gray-400">
              {initialsName}
            </div>
          </div>
          <div className="flex w-full flex-col items-start justify-center">
            <p className="line w-full text-left text-sm font-bold capitalize text-black">
              {activityBy.toLowerCase()}
              {', '}
              <span className="text-sm font-medium text-black">
                {meData ? ` @${meData.me.fullName.split(' ')[0]}` : ''}
              </span>
            </p>

            <span className="text-sm font-normal text-black">
              {returnActivityMessage(activityItem)}
            </span>
          </div>
          {image !== undefined && image !== null && (
            <div
              className="w-1/4 rounded-xl shadow-sm"
              style={{
                backgroundImage: `url(${image.url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                aspectRatio: '7/9',
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function MarketTrendItems({ featuredItems }) {
  return (
    <div className="flex w-full flex-col gap-y-6">
      {featuredItems.map((item, index) => (
        <MarketCardItems key={index} trendItem={item} />
      ))}
    </div>
  );
}

export const Overview = () => {
  const [showDemoPopup, setShowDemoPopup] = useState(false);
  const [showSeenNotifications, setShowSeenNotifications] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [activityCursor, setActivityCursor] = useState(null);

  const navigate = useNavigate();

  const { isEnabled: isTrendsPageEnabled } = useFeatureFlag(
    FEATURE_FLAGS.TRENDS,
  );

  const { setSelectedMarket, setSelectedAudience } = useFilters();
  const { data, loading, error } = useTrendsQuery('overview');
  const {
    data: marketsData,
    loading: marketsLoading,
    error: marketsError,
  } = useFiltersQuery('markets');
  const { isDemoAccount } = useDemoAccount();

  const { loading: loadActivity, fetchMore: fetchMoreActivity } =
    useTrendsQuery('notifications', {
      variables: {
        cursor: null,
      },
      onCompleted: (data) => {
        setActivityCursor(data.notifications.cursor);
        setNotificationList(data.notifications.data);
      },
    });

  if (isTrendsPageEnabled !== undefined && isTrendsPageEnabled === false) {
    return (
      <div className="flex h-full w-full items-start justify-center pt-40">
        <div className="h-max w-2/5 rounded-lg border border-secondary/30 bg-secondary/5 p-2 px-6 text-center shadow-sm">
          <h1 className="text-xl font-bold leading-8 text-black">
            Trends feature is not yet active for your organization.
          </h1>
          In the meantime, you may want to try our{' '}
          <span
            className="cursor-pointer font-semibold text-primary hover:underline hover:underline-offset-4"
            onClick={() => navigate('/explore')}
          >
            Explore
          </span>{' '}
          and{' '}
          <span
            className="cursor-pointer font-semibold text-primary hover:underline hover:underline-offset-4"
            onClick={() => navigate('/studio/home')}
          >
            Studio
          </span>{' '}
          features by clicking the respective links.
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex h-full items-center justify-center">
        <span>Unexpected error {error.message}</span>
      </div>
    );
  }

  if (marketsError) {
    return (
      <div className="flex h-full items-center justify-center">
        <span>Unexpected error {marketsError.message}</span>
      </div>
    );
  }
  // gender 1 is male, 2 is female

  function handleAudienceSelect(market, audience) {
    if (
      isDemoAccount &&
      ['demo_market_1', 'demo_market_2'].includes(market.id)
    ) {
      setShowDemoPopup(true);
      return;
    }

    const selectedMarket = marketsData.markets.find(
      (item) => item.id === market.id,
    );

    if (!selectedMarket) return;

    setSelectedMarket(selectedMarket);

    const audienceKey = `${audience.id}`;

    const selectedAudience = selectedMarket.audiences.find(
      (item) => `${item.id}` === audienceKey,
    );

    let audienceId = selectedAudience?.audienceId;

    if (!audienceId) {
      audienceId = selectedAudience?.groups[0]?.audienceId;
    }

    if (!audienceId) return;

    setSelectedAudience({
      ...selectedAudience,
      id: audienceId,
      value: audienceId,
    });

    // navigate(`/trends/insights/${selectedMarket.id}/${audienceId}`);
  }

  const renderData = () => {
    if (isDemoAccount && data) {
      return [
        {
          id: 'demo_market_1',
          location: {
            name: 'Japan',
            countryCode: 'JP',
          },
          audiences: [
            {
              highlight: 'Denim Jacket',
              audience: {
                id: 'demo_audience_1',
                ageMax: 45,
                ageMin: 25,
                gender: 1,
              },
              analyzedImages: 30000000,
              yoyGrowth: 56,
            },
          ],
          apparels: [
            {
              label: 'dress',
              value: 2,
            },
            {
              label: 'pant/trouser',
              value: 4,
            },
            {
              label: 't-shirt',
              value: 19,
            },
          ],
        },
        {
          id: 'demo_market_2',
          location: {
            name: 'Paris',
            countryCode: 'FR',
          },
          audiences: [
            {
              audience: {
                id: 'demo_audience_2',
                ageMax: 35,
                ageMin: 18,
                gender: 2,
              },
              highlight: 'Denim Jacket',
              analyzedImages: 30000000,
              yoyGrowth: 62,
            },
          ],
          apparels: [
            {
              label: 'dress',
              value: 2,
            },
            {
              label: 'pant/trouser',
              value: 4,
            },
            {
              label: 't-shirt',
              value: 19,
            },
          ],
        },
      ];
    }

    return data?.trendsOverview || [];
  };

  const audienceList = renderData().flatMap((market) =>
    market.audiences.map((audience) => ({
      ...audience,
      marketId: market.id,
      location: market.location,
      apparels: market.apparels,
      featuredItems: market.featuredItems,
    })),
  );

  const sortedAudienceList = audienceList.sort((a, b) => {
    const aHasSocialwatch = a.audience.features?.socialwatch === true;
    const bHasSocialwatch = b.audience.features?.socialwatch === true;

    if (aHasSocialwatch && !bHasSocialwatch) return -1;
    if (!aHasSocialwatch && bHasSocialwatch) return 1;
    return 0;
  });

  return (
    <>
      <DemoAccountModal
        isOpen={showDemoPopup}
        onClose={() => setShowDemoPopup(false)}
      />
      <Container>
        <Header />
        <div className="mt-[3rem] grid grid-cols-1 gap-6">
          {loading || marketsLoading ? (
            <OverviewLoader />
          ) : (
            <div className="flex flex-row items-start justify-between gap-x-6">
              <div className="flex w-2/3 flex-col items-start justify-start">
                <h3 className="mb-4 text-head font-bold text-black">Markets</h3>
                {sortedAudienceList.map((item, index) => (
                  <MarketCard
                    key={index}
                    marketId={item.marketId}
                    audience={item.audience}
                    location={
                      item.location.name + ', ' + item.location.countryCode
                    }
                    handleAudienceSelect={handleAudienceSelect}
                    featuredItems={item.featuredItems}
                  />
                ))}
              </div>
              <div className="flex w-1/3 flex-col items-start justify-start">
                <div className="mb-4 flex w-full flex-row items-end justify-between">
                  <h3 className="text-head font-bold text-black">Activity</h3>
                  {notificationList.find((item) => item.seen === false) && (
                    <button
                      className="w-full text-right text-base text-primary underline underline-offset-4"
                      onClick={() => {
                        setNotificationList(
                          notificationList.map((item) => ({
                            ...item,
                            seen: true,
                          })),
                        );
                      }}
                    >
                      Clear all
                    </button>
                  )}
                </div>
                {loadActivity ? (
                  <></>
                ) : (
                  <>
                    {notificationList.length !== 0 ? (
                      notificationList
                        ?.filter((item) => showSeenNotifications || !item.seen)
                        .map((item, index) => (
                          <ActivityFeed
                            key={index}
                            activityItem={item}
                            show={showSeenNotifications}
                            image={item.image}
                          />
                        ))
                    ) : (
                      <div className="w-full rounded-lg border border-secondary/30 bg-secondary/5 p-2 text-center shadow-sm">
                        No notifications found
                      </div>
                    )}
                    {!showSeenNotifications &&
                      notificationList?.some((item) => item.seen === true) && (
                        <button
                          className="w-full rounded-md text-left text-base text-primary"
                          onClick={() => setShowSeenNotifications(true)}
                        >
                          Load seen notifications (
                          {notificationList.filter((item) => item.seen).length})
                        </button>
                      )}
                    <div className="flex w-full flex-row items-start justify-between">
                      {showSeenNotifications && (
                        <button
                          className="mb-16 w-full rounded-md text-left text-base text-primary"
                          onClick={() => {
                            setShowSeenNotifications(false);
                            if (notificationList.length > 10) {
                              setActivityCursor(null);
                              fetchMoreActivity({
                                variables: {
                                  cursor: activityCursor,
                                },
                              }).then((res) => {
                                setNotificationList([
                                  ...res.data.notifications.data,
                                ]);
                              });
                            }
                          }}
                        >
                          Hide seen notifications
                        </button>
                      )}
                      {showSeenNotifications && activityCursor !== null && (
                        <button
                          className="flex w-full flex-row items-start justify-end p-0 text-base underline underline-offset-4"
                          onClick={() => {
                            fetchMoreActivity({
                              variables: {
                                cursor: activityCursor,
                              },
                            }).then((res) => {
                              setNotificationList([
                                ...notificationList,
                                ...res.data.notifications.data,
                              ]);
                              setActivityCursor(res.data.notifications.cursor);
                            });
                          }}
                        >
                          Load More
                          <span>
                            <MoveDown strokeWidth={1} />
                          </span>
                        </button>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};
