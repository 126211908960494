import { useRouteError } from 'react-router-dom';

import { TFashion } from '@/assets/svg';

export function RootErrorBoundary({ children }) {
  const error = useRouteError();
  const networkRequests = window.performance.getEntriesByType('resource');

  return (
    <div className={'p-4'}>
      <div className={'container mx-auto my-6 rounded border p-6 shadow-lg '}>
        <h1 className={'mb-2 text-2xl font-medium'}>
          It seems there&apos;s been an error – our apologies for the
          inconvenience.
        </h1>
        <div className={'flex flex-col gap-1 font-medium text-gray-600 '}>
          <p>
            Rest assured, we&apos;re actively working to ensure this
            doesn&apos;t happen again.
          </p>
          <p>
            In the meantime, please try refreshing the page or returning to the
            previous page to continue.
          </p>
          <p>
            If the issue persists, we&apos;re here to help! Reach out to us
            at&nbsp;
            <a
              className={'text-blue-500 underline'}
              href="mailto:support@tfashion.ai"
            >
              support@tfashion.ai
            </a>{' '}
            and include the details below along with a screenshot of this
            message.
          </p>
          <p className={'mt-4'}>
            Thank you for your patience and assistance in resolving this issue.{' '}
          </p>
        </div>

        <h2 className={'mt-6 mb-2 text-xl font-semibold'}>Client Details</h2>
        <pre className={'pre code rounded border bg-gray-50 p-4'}>
          URL: {window.location.href} <br />
          UA: {navigator.userAgent} <br />
          VIEW {window.screen.width}x{window.screen.height}@
          {window.devicePixelRatio} ({window.innerWidth}x{window.innerHeight}@
          {window.devicePixelRatio}) <br />
          {navigator.platform} {navigator.language} CPU
          {navigator.hardwareConcurrency} GPU{window.screen.pixelDepth} MEM
          {navigator.deviceMemory} <br />
          CD{window.screen.colorDepth} C{navigator.cookieEnabled ? '1' : '0'} L
          {window.localStorage.length} S{window.sessionStorage.length} <br />
          TZ {Intl.DateTimeFormat().resolvedOptions().timeZone} <br />
          DATE {new Date().toLocaleString()} - {new Date().toISOString()} <br />
        </pre>

        <h2 className={'mt-6 mb-2 text-xl font-semibold'}>Error details</h2>
        <pre className={'pre code rounded border bg-gray-50 p-4'}>
          {error?.stack}
          <br />
          {error?.name}: {error?.message}
          <br />
          {error?.status}
          {error?.statusCode}
          {error?.statusText}
          <br />
          {networkRequests?.map((item, i) => (
            <div key={i}>
              {item?.responseStatus} {item?.name} {item?.initiatorType}{' '}
              {item?.deliveryType} T {item?.duration} {item?.startTime}{' '}
              {item?.fetchStart} S {item?.transferSize} {item?.encodedBodySize}{' '}
              {item?.decodedBodySize} <br />
            </div>
          ))}
        </pre>
      </div>
      <div className={'flex w-full justify-center pb-10 pt-4'}>
        <TFashion className={'w-48 opacity-20'} />
      </div>
    </div>
  );
}
