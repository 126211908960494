import React from 'react';

export const CategoryIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 1.5H1.5V5H5V1.5Z"
        stroke="#C29B77"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 1.5H7V5H10.5V1.5Z"
        stroke="#C29B77"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 7H7V10.5H10.5V7Z"
        stroke="#C29B77"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 7H1.5V10.5H5V7Z"
        stroke="#C29B77"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
