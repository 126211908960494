import c from 'classnames';

const colorMap = {
  black: 'bg-black',
  red: 'bg-[#D74D4D]',
  green: 'bg-[#3CA780]',
  primary: 'bg-primary',
};

export function NotificationBox({ icon, fluid, color, title, content }) {
  const className = c(
    'px-6 py-4 text-white',
    icon ? 'flex items-center gap-7' : 'flex flex-col items-start gap-[6px]',
    fluid ? 'w-full' : 'w-[400px]',
    colorMap[color],
  );

  const isContentArray = Array.isArray(content);
  const contentToRender = isContentArray ? (
    <div className="my-0 flex flex-col gap-2">
      {content.map(
        (ctx, idx) =>
          ctx && (
            <span
              key={idx}
              className="font-regular font-planc text-base !leading-4"
            >
              • {ctx}
            </span>
          ),
      )}
    </div>
  ) : (
    <p className="font-regular font-planc text-base !leading-4">{content}</p>
  );

  return (
    <div className={className}>
      {icon && <div className="flex items-center justify-center">{icon}</div>}
      <div className={!icon && 'flex flex-col gap-[6px]'}>
        <h3 className="font-planc text-lg font-semibold !leading-5">{title}</h3>
        {contentToRender}
      </div>
    </div>
  );
}
