import React from 'react';

import * as Pages from '@/pages/general';

import { Private } from './types';

export const invite = [
  {
    path: 'invite-co-workers',
    element: (
      <Private>
        <Pages.InviteCoWorkers />
      </Private>
    ),
  },
  {
    path: 'invited-to-organization',
    element: (
      <Private>
        <Pages.InvitedToOrganization />
      </Private>
    ),
  },
];
