import {
  RadioGroup as RadixRadioGroup,
  RadioItem as RadixRadioItem,
} from '@radix-ui/react-dropdown-menu';
import { styled } from '@stitches/react';

export const RadioItemRoot = styled(RadixRadioItem, {
  all: 'unset',
  cursor: 'pointer',
  backgroundColor: 'white',
  minWidth: 12,
  height: 12,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  outline: 'none',
});

export const DropdownRadioGroup = ({ children, value, onChange }) => {
  return (
    <RadixRadioGroup
      value={value}
      onValueChange={(value) => {
        onChange && onChange(value);
      }}
      className="outline-none"
    >
      {children}
    </RadixRadioGroup>
  );
};

export const DropdownRadioItem = ({ children, value, onChange, id }) => {
  return (
    <RadixRadioItem
      id={id}
      onCheckedChange={() => {
        onChange && onChange(value);
      }}
      value={value}
      className="cursor-pointer outline-none"
    >
      {children}
    </RadixRadioItem>
  );
};
