import { useFiltersQuery } from '@/api/filters/hook';
import { useAppFilters } from '@/contexts/AppFiltersContext';
import { useFilters } from '@/hooks/filters';

import {
  DesignerFilter,
  ExploreCategory,
  ExploreClearAll,
  ExploreColor,
  ExplorePattern,
  FashionCategoryFilters,
  FashionItemTypeFilter,
  Markets,
  Season,
  TrendsCategory,
  TrendsClearAll,
  TrendsColor,
  TrendsPattern,
} from './components';

const locationToFilterMap = {
  explore: {
    category: ExploreCategory,
    pattern: ExplorePattern,
    color: ExploreColor,
    clearAll: ExploreClearAll,
  },
  trends: {
    category: TrendsCategory,
    pattern: TrendsPattern,
    color: TrendsColor,
    clearAll: TrendsClearAll,
  },
};

export const Filters = () => {
  const { mode } = useAppFilters();
  const location = window.location.pathname.split('/')[1];

  const Pattern = locationToFilterMap[location]?.pattern || ExplorePattern;

  const { data } = useFiltersQuery('markets');

  const { selectedMarketId } = useFilters();

  if (
    window.location.pathname.includes('overview') ||
    window.location.pathname.includes('detail')
  ) {
    return <></>;
  }

  const renderPattern =
    data && selectedMarketId
      ? data.markets.some((market) =>
          market.plan.scope.permissions.includes('PATTERN'),
        )
      : false;

  const modeToFilters = {
    trends: (
      <>
        <Markets />
        <Season />
        <TrendsCategory />
        <TrendsColor />
        {renderPattern && <TrendsPattern />}
        <TrendsClearAll />
      </>
    ),
    'explore-trends': (
      <>
        <Markets />
        <Season />
        <TrendsCategory />
        <TrendsColor />
        {renderPattern && <ExplorePattern />}
        <ExploreClearAll />
      </>
    ),
    'explore-fashion-week': (
      <>
        <Markets />
        <Season />
        <DesignerFilter />
        <FashionItemTypeFilter />
        <FashionCategoryFilters />
        {renderPattern && <Pattern />}
        <ExploreClearAll />
      </>
    ),
  };

  return (
    <div className="fixed top-[5.875rem] z-[700] grid min-h-[5.3125rem] w-screen min-w-full auto-cols-auto grid-flow-col flex-nowrap border-[#A1A1A1] bg-white">
      {modeToFilters[mode]}
    </div>
  );
};
