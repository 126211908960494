import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Triangle } from 'lucide-react';
import numeral from 'numeral';
import {
  CartesianGrid,
  // Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  YAxis,
} from 'recharts';

import { useTrendsContext } from '@/contexts/TrendsContext';

dayjs.extend(utc);

const CustomTooltip = ({ active, payload }) => {
  const { activeChart } = useTrendsContext();

  if (active && payload && payload.length) {
    const lessThanZero = payload[0]?.payload?.yoyGrowth < 0;
    const growthValue = payload[0]?.payload?.yoyGrowth;

    const trendType = payload[0]?.payload.trendType;

    return (
      <div className="flex flex-col items-center justify-start gap-1 rounded bg-white/80 px-4 py-2 outline-none drop-shadow-sm">
        <span className="border-b border-[#a1a1a140] pb-1 text-start text-xs font-semibold">
          {payload[0].payload.data}{' '}
          {Math.ceil(dayjs(payload[0].payload.date).date() / 7)}
          {Math.ceil(dayjs(payload[0].payload.date).date() / 7) === 1
            ? 'st'
            : Math.ceil(dayjs(payload[0].payload.date).date() / 7) === 2
              ? 'nd'
              : Math.ceil(dayjs(payload[0].payload.date).date() / 7) === 3
                ? 'rd'
                : 'th'}{' '}
          week, {dayjs(payload[0].payload.date).year()}
        </span>
        <div className="flex items-center justify-center gap-x-1">
          <span className="text-xs font-normal">
            {activeChart === 'visibility' ? 'Visibility: ' : 'Engagement: '}
          </span>
          <span
            className={`text-xs font-medium ${lessThanZero ? 'text-[#EA3323]' : 'text-[#1C9A6D]'}`}
          >
            {activeChart !== 'visibility'
              ? numeral(growthValue).format('0.0a')
              : numeral(growthValue * 100).format('0.0a') + '%'}
          </span>
          {trendType === 'UP' && (
            <Triangle
              size={14}
              // fill={lessThanZero ? '#EA3323' : '#1C9A6D'}
              // style={
              //   lessThanZero
              //     ? { transform: 'rotate(180deg)', borderColor: '#EA3323' }
              //     : { borderColor: '#1C9A6D' }
              // }
              fill={'#1C9A6D'}
              style={{ borderColor: '#1C9A6D' }}
              strokeWidth={0}
            />
          )}
        </div>
      </div>
    );
  }
  return null;
};

// const CustomizedLegend = ({ external }) => {
//   return (
//     <div className="ml-6 -mt-2 flex w-full flex-row items-center justify-between gap-x-2 pr-4 text-center leading-3">
//       <span className="text-[0.7rem] font-normal text-charcoal">
//         0-20 Very Poor
//       </span>
//       <span className="text-[0.7rem] font-normal text-charcoal">
//         20-40 Poor
//       </span>
//       <span className="text-[0.7rem] font-normal text-charcoal">
//         40-60 Average
//       </span>
//       <span className="text-[0.7rem] font-normal text-charcoal">
//         60-80 Good
//       </span>
//       <span className="text-[0.7rem] font-normal text-charcoal">
//         80-100 Excellent
//       </span>
//     </div>
//   );
// };

export const ForecastLineChart = ({ data }) => {
  return (
    <div
      className="absolute bottom-[1.6rem] flex w-full"
      style={{
        paddingLeft: `calc(${100 / 24}% - 2px)`,
        paddingRight: `calc(${100 / 24}% - 2px)`,
        width: (data.length / 12) * 100 + '%',
        overflow: 'visible',
      }}
    >
      <ResponsiveContainer height={120} width={'100%'}>
        <LineChart
          key="trends-line-chart"
          data={data}
          height={120}
          margin={{
            top: 0,
            right: 2,
            left: 2,
            bottom: 0,
          }}
        >
          <Tooltip
            isAnimationActive={false}
            wrapperStyle={{ outline: 'none' }}
            content={<CustomTooltip />}
            allowEscapeViewBox={{ y: -400 }}
            offset={-100}
            cursor={{
              stroke: '#1a1a1a',
              strokeWidth: 1,
              strokeDasharray: '2 4',
            }}
          />
          <Line
            key="trends-line-chart-line"
            type="monotone"
            dataKey="value"
            strokeWidth={2}
            stroke="#1a1a1a"
            dot={{ r: 1 }}
            activeDot={{ r: 2 }}
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export const SnapshotLineChart = ({ data }) => {
  const formattedData = data.map((item) => ({
    data: dayjs(item.date).format('MMM'),
    value: item.yoyGrowth === null ? 0 : item.yoyGrowth,
    date: item.date,
    yoyGrowth: item.yoyGrowth === null ? 0 : item.yoyGrowth,
    trendType: item.trendType === null ? 'FLAT' : item.trendType,
  }));

  return (
    <div
      className="absolute flex w-full rounded-lg bg-[#a1a1a110] pt-4"
      style={{
        paddingLeft: `calc(${100 / 24}%)`,
        paddingRight: `calc(${100 / 24}%)`,
        width: (data.length / 12) * 100 + '%',
        overflow: 'visible',
      }}
    >
      <ResponsiveContainer className="mb-10" height={180} width="100%">
        <LineChart
          key="trends-line-chart"
          data={formattedData}
          height={180}
          margin={{ top: 5, right: 5, left: -40, bottom: 5 }}
        >
          <CartesianGrid stroke="#D0D0D040" vertical={false} syncWithTicks />
          {/* <Legend
            verticalAlign="top"
            height={40}
            content={<CustomizedLegend external />}
          /> */}
          <YAxis
            axisLine={false}
            tick={{ fill: '#a1a1a105', fontSize: 10 }}
            dx={8}
            padding={{ top: 5, bottom: 5 }}
            scale={'linear'}
            type="number"
            tickMargin={7}
            domain={[
              Math.min(...data.map((item) => item.yoyGrowth)),
              Math.max(...data.map((item) => item.yoyGrowth)) * 1.1,
            ]}
            tickLine={false}
          />
          <Tooltip
            isAnimationActive={false}
            wrapperStyle={{ outline: 'none' }}
            content={<CustomTooltip />}
            allowEscapeViewBox={{ y: -400 }}
            offset={-100}
            cursor={{
              stroke: '#1a1a1a',
              strokeWidth: 1,
              strokeDasharray: '2 4',
            }}
          />
          <Line
            key="trends-line-chart-line"
            type="monotone"
            dataKey="value"
            strokeWidth={2}
            stroke="#1a1a1a"
            dot={{ r: 1 }}
            activeDot={{ r: 2 }}
            isAnimationActive={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
