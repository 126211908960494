import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { GET_MARKETS } from '@/api/filters/queries';
import {
  TREND_ITEMS,
  TREND_OVERVIEWS,
  TRENDS_TONES,
} from '@/api/trends/queries';

import { REMOVE_SELECTED_ORGANIZATION, SELECT_ORGANIZATION } from './mutations';
import { ME } from './queries';

const queryMap = {
  me: ME,
};

const mutationMap = {
  selectOrganization: SELECT_ORGANIZATION,
  removeSelectedOrganization: REMOVE_SELECTED_ORGANIZATION,
};

export function useUserQuery(queryName, options) {
  const query = queryMap[queryName];

  if (!query) {
    throw new Error(`Invalid query name: ${queryName}`);
  }

  return useQuery(query, options);
}

export function useUserMutation(mutationName, options) {
  const mutation = mutationMap[mutationName];

  if (!mutation) {
    throw new Error(`Invalid mutation name: ${mutationName}`);
  }

  return useMutation(mutation, {
    ...options,
    refetchQueries: [
      ME,
      GET_MARKETS,
      TREND_OVERVIEWS,
      TREND_ITEMS,
      TRENDS_TONES,
    ],
  });
}

export function useUser() {
  const navigate = useNavigate();
  const { data, loading, error } = useQuery(ME);

  useEffect(() => {
    if (data?.me && data.me.isEmailConfirmed !== true) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      navigate('/login?verifyEmail=false');
    }
  }, [data, navigate]);

  const moderationEnabled = ['admin', 'moderator', 'annotator'].includes(
    data?.me?.role || '',
  );

  const isAnnotator = data?.me?.role === 'annotator';

  const isPrivateImageEnabled = () => {
    const canPrivateImage =
      data?.me?.currentStudioPlan?.slug !== 'FREE_STUDIO_PLAN';
    if (moderationEnabled) {
      return true;
    } else if (!moderationEnabled && canPrivateImage !== undefined) {
      return canPrivateImage;
    }
    return false;
  };

  return {
    user: data?.me,
    loading,
    error,
    moderationEnabled,
    isPrivateImageEnabled,
    isAnnotator,
  };
}

export function useDemoAccount() {
  const { data } = useQuery(ME);

  const demoAccounts = ['demo@tfashion.ai'];
  const isDemoAccount = demoAccounts.includes(data?.me?.email);

  return {
    isDemoAccount,
  };
}
