export function Close() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4463 0.572701C24.2708 0.396914 24.0625 0.25745 23.8331 0.162295C23.6037 0.067139 23.3578 0.0181589 23.1094 0.0181589C22.8611 0.0181589 22.6152 0.067139 22.3858 0.162295C22.1564 0.25745 21.948 0.396914 21.7726 0.572701L12.5 9.82631L3.22743 0.553738C3.05187 0.378182 2.84345 0.238922 2.61408 0.143912C2.3847 0.0489008 2.13886 1.84979e-09 1.89058 0C1.64231 -1.84979e-09 1.39646 0.0489008 1.16709 0.143912C0.937711 0.238922 0.729295 0.378182 0.553738 0.553738C0.378182 0.729295 0.238922 0.937711 0.143912 1.16709C0.0489008 1.39646 -1.84979e-09 1.64231 0 1.89058C1.84979e-09 2.13886 0.0489008 2.3847 0.143912 2.61408C0.238922 2.84345 0.378182 3.05187 0.553738 3.22743L9.82631 12.5L0.553738 21.7726C0.378182 21.9481 0.238922 22.1565 0.143912 22.3859C0.0489008 22.6153 0 22.8611 0 23.1094C0 23.3577 0.0489008 23.6035 0.143912 23.8329C0.238922 24.0623 0.378182 24.2707 0.553738 24.4463C0.729295 24.6218 0.937711 24.7611 1.16709 24.8561C1.39646 24.9511 1.64231 25 1.89058 25C2.13886 25 2.3847 24.9511 2.61408 24.8561C2.84345 24.7611 3.05187 24.6218 3.22743 24.4463L12.5 15.1737L21.7726 24.4463C21.9481 24.6218 22.1565 24.7611 22.3859 24.8561C22.6153 24.9511 22.8611 25 23.1094 25C23.3577 25 23.6035 24.9511 23.8329 24.8561C24.0623 24.7611 24.2707 24.6218 24.4463 24.4463C24.6218 24.2707 24.7611 24.0623 24.8561 23.8329C24.9511 23.6035 25 23.3577 25 23.1094C25 22.8611 24.9511 22.6153 24.8561 22.3859C24.7611 22.1565 24.6218 21.9481 24.4463 21.7726L15.1737 12.5L24.4463 3.22743C25.1668 2.50686 25.1668 1.29327 24.4463 0.572701Z"
        fill="black"
      />
    </svg>
  );
}
