import { cva } from 'class-variance-authority';

import { cn } from '@/helpers/utils';

const sidebarVariants = cva('h-full w-full max-w-md min-w-[20rem] ', {
  variants: {
    variant: {
      default: '',
      primary: 'overflow-scroll border-r-2 py-8 px-[3.75rem]',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export function Sidebar({ className, variant, children, ...props }) {
  const classNames = cn(
    sidebarVariants({
      variant,
    }),
    className,
  );

  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  );
}
