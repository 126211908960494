import { useState } from 'react';

import { useDemoAccount } from '@/api/authentication/hook';
import { useFiltersQuery } from '@/api/filters/hook';
import { DemoAccountModal } from '@/components/DemoAccountPopup';
import { useFilters } from '@/hooks/filters';

import { Checkbox } from './Checkbox';
import Dropdown from './Dropdown';

export function ExplorePattern() {
  const { isDemoAccount } = useDemoAccount();

  const [showDemoPopup, setShowDemoPopup] = useState(false);

  const { selectedApparels, selectedPatterns, selectPattern, demoPatterns } =
    useFilters('explore');

  const apparels =
    selectedApparels.length > 0 ? selectedApparels.map((a) => a.value) : null;

  const { data, error } = useFiltersQuery('explorePatterns', {
    apparels,
  });

  return (
    <>
      <DemoAccountModal
        isOpen={showDemoPopup}
        onClose={() => setShowDemoPopup(false)}
      />
      <Dropdown.Root open={showDemoPopup ? false : undefined}>
        <Dropdown.Trigger
          label="Pattern"
          selectedOptions={
            selectedPatterns.length > 0 ? selectedPatterns : null
          }
        />
        {error ? (
          <div>Error loading patterns</div>
        ) : (
          <Dropdown.Content>
            {!apparels && !isDemoAccount && (
              <Dropdown.ContentTitle>
                Select apparel to see patterns
              </Dropdown.ContentTitle>
            )}
            <Dropdown.Options>
              {(isDemoAccount
                ? demoPatterns
                : data?.exploreFilters?.patterns || []
              ).map((pattern) => (
                <Checkbox
                  label={pattern.label}
                  item={pattern}
                  id={pattern.value}
                  checked={
                    selectedPatterns &&
                    !isDemoAccount &&
                    selectedPatterns.find(
                      (item) => item.value === pattern.value,
                    )
                  }
                  onChange={!isDemoAccount && selectPattern}
                  key={pattern.value}
                  multiselect
                  onClick={() => {
                    isDemoAccount && setShowDemoPopup(true);
                  }}
                />
              ))}
            </Dropdown.Options>
          </Dropdown.Content>
        )}
      </Dropdown.Root>
    </>
  );
}
