import React from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { CheckIcon } from '@radix-ui/react-icons';
import * as Select from '@radix-ui/react-select';
import { styled } from '@stitches/react';

import { ME } from '@/api/authentication';

import { accountFormOptions } from './accountFormOptions';

const AccountType = () => (
  <Select.Root>
    <SelectTrigger aria-label="Type">
      <Select.Value placeholder="Account Type" />
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 7.5L10 12.5L15 7.5"
          stroke="#282928"
          strokeWidth="2"
          strokeLinecap="square"
        />
      </svg>
    </SelectTrigger>
    <Select.Portal>
      <SelectContent>
        <SelectViewport>
          <Select.Group>
            <SelectItem value="basic">Basic</SelectItem>
            <SelectItem value="business">Business</SelectItem>
            <SelectItem value="exclusive">Exclusive</SelectItem>
          </Select.Group>
        </SelectViewport>
      </SelectContent>
    </Select.Portal>
  </Select.Root>
);

const SelectTrigger = styled(Select.SelectTrigger, {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 18px',
  fontSize: '16px',
  lineHeight: '18px',
  weight: '600',
  border: '1px solid #282928',
  height: '58px',
  color: '#282928',
});

const SelectContent = styled(Select.Content, {
  overflow: 'hidden',
  backgroundColor: 'white',
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
});

const SelectViewport = styled(Select.Viewport, {
  padding: 5,
});

// eslint-disable-next-line react/display-name
const SelectItem = React.forwardRef(({ children, ...props }, forwardedRef) => {
  return (
    <StyledItem {...props} ref={forwardedRef}>
      <Select.ItemText>{children}</Select.ItemText>
      <StyledItemIndicator>
        <CheckIcon />
      </StyledItemIndicator>
    </StyledItem>
  );
});

const StyledItem = styled(Select.Item, {
  fontSize: 16,
  lineHeight: 1,
  color: '#282928',
  display: 'flex',
  alignItems: 'center',
  height: '35px',
  padding: '0 35px 0 25px',
  position: 'relative',
  userSelect: 'none',
  '&[data-highlighted]': {
    outline: 'none',
    backgroundColor: '#C29B77',
    color: '#fff',
  },
});

const StyledItemIndicator = styled(Select.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const AccountDetails = () => {
  const { data } = useQuery(ME);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(accountFormOptions);

  const onSubmit = (data) => {};

  return (
    <div className="flex w-full flex-col">
      <h5 className="mb-[21px] flex text-[36px] font-semibold leading-[42px]">
        Account Details
      </h5>
      <p className="font-regular w-[80%] text-[16px]   leading-[18px]">
        Manage your T-Fashion account with ease, ensuring your profile details
        are up to date and secure.
      </p>
      <form className="flex h-auto flex-col" onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-[47px] flex flex-col gap-y-[32px]">
          <div className="flex space-x-[68px]">
            <div className="flex w-full flex-col">
              <span className="mb-[4px] pl-[18px] text-[16px] font-semibold leading-[28px] ">
                Full Name
              </span>
              <input
                type="text"
                name="fullName"
                defaultValue={data?.me?.fullName}
                className="h-[58px] border border-charcoal px-[18px] outline-none"
                placeholder="Full Name"
                {...register('fullName', { required: true })}
              />
              {errors.fullName?.message && (
                <p className="mt-[4px] text-[16px] font-semibold leading-[28px] text-red-500 ">
                  {errors.fullName?.message}
                </p>
              )}
            </div>
            <div className="flex w-full flex-col">
              <span className="mb-[4px] flex justify-between pl-[18px] text-[16px] font-semibold leading-[28px]">
                Password
                <small className="cursor-pointer text-[12px] font-medium leading-[21px] underline">
                  Change Password
                </small>
              </span>
              <input
                type="password"
                name="password"
                autoComplete="on"
                className="h-[58px] border border-charcoal px-[18px] outline-none"
                placeholder="Password"
                {...register('password', { required: true })}
              />
              {errors.password?.message && (
                <p className="mt-[4px] text-[16px] font-semibold leading-[28px] text-red-500 ">
                  {errors.password?.message}
                </p>
              )}
            </div>
          </div>
          <div className="flex space-x-[68px]">
            <div className="flex w-full flex-col">
              <span className="mb-[4px] pl-[18px] text-[16px] font-semibold leading-[28px] ">
                E-mail
              </span>
              <input
                defaultValue={data?.me?.email}
                type="email"
                name="email"
                className="h-[58px] border border-charcoal px-[18px] outline-none"
                placeholder="E-mail"
                {...register('email', { required: true })}
              />
              {errors.email?.message && (
                <p className="mt-[4px] text-[16px] font-semibold leading-[28px] text-red-500 ">
                  {errors.email?.message}
                </p>
              )}
            </div>
            <div className="flex w-full flex-col">
              <span className="mb-[4px] pl-[18px] text-[16px] font-semibold leading-[28px] ">
                Account Type
              </span>
              <AccountType />
              {/* {errors.accountType?.message && (
                <p className="text-red-500 font-semibold text-[16px] leading-[28px] mt-[4px] ">
                  {errors.accountType?.message}
                </p>
              )} */}
            </div>
          </div>
        </div>
        <button className="mt-[62px] h-[50px] w-[168px] bg-[#3CA780] px-[20px] pt-[2px] text-white">
          Save Changes
        </button>
      </form>
    </div>
  );
};
