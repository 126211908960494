import { useCard } from '../../context';

export function Circles() {
  const { data } = useCard();

  const tones = data.tones?.slice(0, 4) || [];

  return (
    <div className="group/tones relative h-6 w-full">
      {tones.map((tone, index) => (
        <div
          key={index}
          className="absolute top-0 h-6 w-6 rounded-full border border-white group-hover/tones:border-2 group-hover/tones:border-white"
          style={{
            backgroundColor: tone.hex,
            zIndex: 10 - index,
            left: index * 10,
          }}
        />
      ))}
    </div>
  );
}
