import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const signUpFormSchema = Yup.object().shape({
  fullName: Yup.string()
    .required('Fullname is required')
    .min(3, 'Fullname must be at 3 char long'),
  email: Yup.string()
    .email()
    .required('Email is required')
    .min(3, 'Email must be at 3 char long'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at 8 char long'),
  organizationName: Yup.string()
    .required('Organization name is required')
    .min(5, 'Organization name must be at 5 char long'),
  // confirmPwd: Yup.string()
  //   .required("Password is required")
  //   .oneOf([Yup.ref("password")], "Passwords does not match"),
});

export const signUpFormOptions = {
  resolver: yupResolver(signUpFormSchema),
};
