import { useState } from 'react';
import { ImSpinner8 } from 'react-icons/im';
import { useNavigate } from 'react-router-dom';

import { useUserMutation } from '@/api/authentication/hook';
import { useOrganizationQuery } from '@/api/organization/hook';
import { Modal } from '@/components/Modal';
import { genderMapping } from '@/constants';

export function SelectOrganization() {
  const navigate = useNavigate();

  function handleSelectOrgDone(data) {
    if (data?.selectOrganization?.accessToken) {
      localStorage.setItem(
        'accessToken',
        data?.selectOrganization?.accessToken,
      );
      localStorage.setItem(
        'refreshToken',
        data?.selectOrganization?.refreshToken,
      );

      navigate('/explore');

      window.location.reload();
    }
  }

  const { data } = useOrganizationQuery('organizations');
  const [selectOrganization, { loading: selectLoading }] = useUserMutation(
    'selectOrganization',
    {
      onCompleted: handleSelectOrgDone,
    },
  );

  const [selectedOrg, setSelectedOrg] = useState(null);
  const [showModal, setShowModal] = useState(false);

  function handleSelectOrg(orgId) {
    if (orgId === selectedOrg) {
      setSelectedOrg(null);
      setShowModal(false);
      return;
    }

    setSelectedOrg(orgId);
    setShowModal(true);
  }

  const selectedOrganization = data?.organizations.find(
    (org) => org.id === selectedOrg,
  );

  return (
    <>
      <Modal
        isOpen={showModal}
        onClose={() => handleSelectOrg(selectedOrg)}
        title="Select Organization"
      >
        <div className="flex flex-col gap-2 px-8 py-4">
          <h1 className="text-xl font-bold">
            Selected Organization: {selectedOrganization?.name}
          </h1>

          <h1 className="text-lg font-semibold">Markets</h1>

          <div className="flex flex-wrap items-center gap-4">
            {selectedOrganization?.markets.map((market) => (
              <div className="flex flex-col gap-2" key={market.id}>
                <h1 className="font-semibold">{market.location.name}</h1>

                <div className="ml-4 flex flex-col gap-4">
                  {market.audiences.map((audience) => (
                    <div
                      className="flex items-center gap-2 font-medium"
                      key={audience.id}
                    >
                      <h1>
                        {audience.ageMin} - {audience.ageMax},
                      </h1>
                      <h1>{genderMapping[audience.gender].label}</h1>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="mt-8 flex items-center gap-4 self-end font-semibold">
            <button
              disabled={selectLoading}
              className="bg-red-500 px-4 py-2 text-white"
              onClick={() => handleSelectOrg(selectedOrg)}
            >
              {selectLoading ? (
                <ImSpinner8 className="animate-spin" />
              ) : (
                'Cancel'
              )}
            </button>
            <button
              className="bg-green px-4 py-2 text-white"
              disabled={selectLoading}
              onClick={() =>
                selectOrganization({
                  variables: {
                    organizationId: selectedOrg,
                  },
                })
              }
            >
              {selectLoading ? (
                <ImSpinner8 className="animate-spin" />
              ) : (
                'Select Organization'
              )}
            </button>
          </div>
        </div>
      </Modal>
      <div className="grid grid-cols-2 gap-4 px-8 py-4 md:grid-cols-4 lg:grid-cols-6">
        {data?.organizations.map((organization) => (
          <div
            className="flex cursor-pointer flex-col gap-2 rounded-xl border border-gray-300"
            onClick={() => handleSelectOrg(organization.id)}
            key={organization.id}
          >
            {organization.logo ? (
              <img src={organization.logo} className="aspect-square w-full" />
            ) : (
              <div className="flex aspect-square w-full items-center justify-center bg-gray-300 text-2xl font-bold text-black">
                <span className="opacity-30">NO LOGO</span>
              </div>
            )}
            <div className="flex flex-col gap-2 px-4 py-2">
              <h1 className="text-lg font-medium">{organization.name}</h1>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
