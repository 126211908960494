import { useEffect } from 'react';
import dayjs from 'dayjs';
import { X } from 'lucide-react';

import { useStudioContext } from '@/contexts/StudioContext';

import { GenerateButton } from './components/GenerateButton';
import { SidebarButton } from './components/SidebarMenu';

const StudioToolsGrid = ({ isPattern }) => {
  const {
    selectedTool,
    setSelectedTool,
    studioToolsConfig,
    updateCreateItemDetails,
  } = useStudioContext();

  const apparelTools = [
    'CREATE_VARIANTS',
    'UPSCALE_IMAGE',
    'ENHANCE_IMAGE',
    'ZOOM_IN_IMAGE',
    'IMAGE_BACKGROUND_REMOVER',
    'IMAGE_CHANGE_BACKGROUND',
  ];

  const patternTools = [
    'PATTERN_TILE',
    'UPSCALE_IMAGE',
    'ENHANCE_IMAGE',
    'ZOOM_IN_IMAGE',
  ];

  return (
    <div className="grid grid-cols-2 gap-x-4 gap-y-6">
      {(isPattern ? patternTools : apparelTools).map((tool, index) => (
        <SidebarButton
          key={index}
          text={studioToolsConfig[tool].buttonText}
          icon={studioToolsConfig[tool].icon}
          variant="tool"
          active={selectedTool === tool}
          onClick={() => {
            setSelectedTool(tool);
            updateCreateItemDetails(tool);
          }}
        />
      ))}
    </div>
  );
};

export const StudioTools = () => {
  const {
    setOpenTools,
    studioTaskData,
    setReferenceImage,
    setReferencePattern,
    createItem,
    refSoloItemType,
  } = useStudioContext();

  useEffect(() => {
    if (location.pathname.includes('home')) {
      setOpenTools(false);
    }
  }, [location.pathname]);

  return (
    <div
      style={{
        boxShadow: '-4px 0 8px rgba(0, 0, 0, 0.05)',
        transition: 'right 2s ease',
      }}
      className="z-100 absolute top-0 right-[7rem] flex h-full w-[28rem] items-start justify-start border-x border-secondary/50"
    >
      <div className="flex h-full w-full flex-col items-start gap-6 bg-white p-6">
        <div className="flex w-full flex-row items-center justify-between gap-2">
          {/* {import.meta.env.VITE_APP_ENV === 'development' && (
            <div className="flex gap-4">
              <SidebarButton
                text="Save"
                className="border bg-primary py-2 px-4 font-bold text-white after:border-primary"
                onClick={() => console.log('Saved')}
              />
              <SidebarButton
                text="Download"
                className="py-2 px-4 font-bold"
                onClick={() => console.log('Downloaded')}
              />
            </div>
          )} */}
          <div className="flex gap-4">
            {createItem.type !== null &&
              !['apparel', 'pattern', 'sketch'].includes(createItem.type) && (
                <GenerateButton variant="tool" />
              )}
          </div>
          <div className="flex cursor-pointer items-center gap-x-4 hover:text-primary">
            <X
              size={36}
              onClick={() => {
                setOpenTools(false);
                setReferenceImage(null);
                setReferencePattern(null);
              }}
              strokeWidth={1.5}
            />
          </div>
        </div>
        <div className="flex w-full flex-col gap-4 pt-2">
          <h2 className="text-2xl font-bold leading-6 text-night">
            {studioTaskData?.studioTask?.generateTaskType ===
              'GENERATE_PATTERN' || refSoloItemType === 'PATTERN'
              ? 'Pattern Tools'
              : 'Design Tools'}
          </h2>
        </div>
        <StudioToolsGrid
          isPattern={
            ['GENERATE_PATTERN', 'PATTERN_TILE'].includes(
              studioTaskData?.studioTask?.generateTaskType,
            ) || refSoloItemType === 'PATTERN'
          }
        />
        {studioTaskData?.studioTask?.payload?.positivePrompt && (
          <div className="flex w-full flex-col gap-2">
            <h3 className="text-lg font-bold leading-8 text-night">Prompt</h3>
            <p className="text-sm text-night">
              {studioTaskData?.studioTask?.payload.positivePrompt}
            </p>
          </div>
        )}
        <div className="flex w-full flex-col gap-2">
          <p className="text-xs font-bold text-secondary">
            {dayjs(studioTaskData?.studioTask?.createdAt).format('D MMMM YYYY')}
          </p>
        </div>
        {studioTaskData?.studioTask?.payload?.orientation && (
          <div className="flex w-full flex-col gap-2">
            <h3 className="text-lg font-bold leading-8 text-night">Size</h3>
            <p className="text-sm font-bold text-secondary">
              {studioTaskData?.studioTask?.payload.orientation.label}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
