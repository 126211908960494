import { Button } from '@/components/Button.jsx';
import { Heading } from '@/components/Heading';
import { Sidebar } from '@/components/Sidebar';
import { Spacing } from '@/components/Spacing';
import { useStudioContext } from '@/contexts/StudioContext';
import { FEATURE_FLAGS, useFeatureFlag } from '@/hooks/useFeatureFlag';
import { StudioDesignForm } from '@/pages/general/studio/components/StudioDesignForm';
// import {
//   StudioImagePopup
// } from '@/pages/general/studio/components/StudioImagePopup'

function DesignHeader() {
  const { isEnabled: isStudioExpertModeEnabled } = useFeatureFlag(
    FEATURE_FLAGS.STUDIO_EXPERT_MODE,
  );
  const { payload, setPayload } = useStudioContext();

  const currentMode = payload?.mode;

  return (
    <div className="flex w-full items-end justify-between">
      <Heading size={'xl'}>Design</Heading>

      {isStudioExpertModeEnabled && (
        <Button
          variant={'link'}
          size={'xs'}
          className={'mb-2 p-0'}
          onClick={() => {
            setPayload({
              ...payload,
              mode: currentMode === 'GUIDED' ? 'EXPERT' : 'GUIDED',
            });
          }}
        >
          Switch to {currentMode === 'EXPERT' ? 'guided' : 'expert'} mode
        </Button>
      )}
    </div>
  );
}

export function StudioDesignArea({ className }) {
  return (
    <Sidebar variant={'primary'}>
      <DesignHeader />
      <Spacing size={1.125} />
      <StudioDesignForm />
    </Sidebar>
  );
}
