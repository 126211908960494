import React from 'react';

export const Plus = ({ color = '#499D7F' }) => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.44379 0V4.56732H11V6.42138H6.44379V11H4.53452V6.42138H0V4.56732H4.53452V0H6.44379Z"
        fill={color}
      />
    </svg>
  );
};
