import { useUserQuery } from '@/api/authentication/hook';
import { useExploreMutation } from '@/api/explore/hooks';
import { Button } from '@/components/Button';
import { Icon } from '@/icons';

import styles from '../index.module.css';

function ModerationButton({
  mutationKey,
  valueKey,
  value,
  dataKey,
  onCompleteText,
  children,
}) {
  const [mutation, { data, loading }] = useExploreMutation(mutationKey);

  return (
    <Button
      variant={'primary'}
      size={'sm'}
      disabled={!!data}
      onClick={() =>
        mutation({
          variables: {
            [valueKey]: value,
          },
        })
      }
    >
      {loading && (
        <div className="animate-spin">
          <Icon name="spinner" />
        </div>
      )}

      {data?.[dataKey]?.status ? onCompleteText : children}
    </Button>
  );
}

export function Moderation({ apparelId }) {
  const { data } = useUserQuery('me');

  if (!data) return null;

  return (
    <div className={styles.moderationWrapper}>
      <ModerationButton
        dataKey="upvoteApparel"
        mutationKey="upvote"
        onCompleteText="Upvoted"
        value={apparelId}
        valueKey="apparelId"
      >
        Upvote
      </ModerationButton>

      <ModerationButton
        dataKey="downvoteApparel"
        mutationKey="downvote"
        onCompleteText="Downvoted"
        value={apparelId}
        valueKey="apparelId"
      >
        Downvote
      </ModerationButton>

      <ModerationButton
        dataKey="excludePhoto"
        mutationKey="banApparel"
        onCompleteText="Banned Apparel"
        value={apparelId}
        valueKey="apparelId"
      >
        Ban Apparel
      </ModerationButton>

      <ModerationButton
        dataKey="excludeAccount"
        mutationKey="banAccount"
        onCompleteText="Banned Account"
        value={apparelId}
        valueKey="apparelId"
      >
        Ban Account
      </ModerationButton>
    </div>
  );
}
