import React from 'react';

export const Remove = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8735 13.1654C11.6753 13.9764 10.2164 14.4257 8.71005 14.4475C3.80366 14.4475 1 9.4684 1 9.4684C1.87186 8.02563 3.08111 6.76511 4.54663 5.7714M7.23814 4.63865C7.7206 4.53837 8.21455 4.48824 8.71005 4.48927C13.6165 4.48927 16.4201 9.4684 16.4201 9.4684C15.9946 10.1752 15.4872 10.8406 14.9061 11.4538M10.196 10.7879C10.0035 10.9713 9.77134 11.1185 9.51341 11.2205C9.25547 11.3226 8.97703 11.3774 8.6947 11.3819C8.41236 11.3863 8.13191 11.3402 7.87009 11.2462C7.60826 11.1523 7.37041 11.0126 7.17074 10.8353C6.97107 10.658 6.81366 10.4468 6.7079 10.2143C6.60214 9.98177 6.55021 9.73274 6.55519 9.48203C6.56017 9.23133 6.62197 8.98408 6.7369 8.75504C6.85182 8.526 7.01752 8.31987 7.22412 8.14893"
        stroke="#282928"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M1.58398 2L16.6499 16"
        stroke="#282928"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};
