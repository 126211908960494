import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
  FASHION_WEEK_ITEM,
  GET_STUDIO_ITEM,
  SOCIAL_MEDIA_ITEM,
  UPLOAD_ITEM_IMAGE,
} from '@/api/studio/queries';
import { DisplayMode, useStudioContext } from '@/contexts/StudioContext';
import { downloadFile } from '@/helpers/utils';

import { AsideBar, Collections } from './components/AsidebarMenu';
import { StudioExploreSideBar } from './components/StudioExploreSideBar';
import { StudioHistorySidebar } from './components/StudioHistorySidebar';
import { ImageDisplay } from './StudioGeneration';
import { StudioHome } from './StudioHome';
import { StudioLeftSidebar } from './StudioLeftSidebar';
import { StudioTools } from './StudioTools';

export const StudioLayout = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const {
    activeModal,
    setActiveModal,
    updateCreateItemDetails,
    setGeneratedImages,
    studioTaskData,
    orientation,
    openTools,
    setSelectedGeneratedImage,
    setReferencePattern,
    setReferenceImage,
    setDisplayMode,
    setReferenceSketch,
    setRefSoloItemType,
    studioTask,
    setStudioTask,
    similarImagesData,
    similarImagesLoading,
    similarImagesError,
  } = useStudioContext();

  const isHome = location.pathname.includes('home');
  const searchParams = new URLSearchParams(location.search);
  const isEditing =
    searchParams.get('isEditing') === 'true' ||
    searchParams.has('generationType');

  useEffect(() => {
    setGeneratedImages([]);
    setDisplayMode(DisplayMode.PENDING);
    setSelectedGeneratedImage(-1);
    setReferencePattern(null);
    setReferenceImage(null);
  }, [isEditing]);

  useEffect(() => {
    if (searchParams.has('isGenerating')) {
      setGeneratedImages([]);
    }
  }, [searchParams.has('isGenerating')]);

  const validParams = [
    'isEditing',
    'taskId',
    'itemId',
    'itemType',
    'isGenerating',
  ];

  const areRequiredParamsMissing = validParams.some((param) =>
    searchParams.has(param),
  );

  useEffect(() => {
    if (!areRequiredParamsMissing) {
      navigate('/studio/home');
    }
  }, [areRequiredParamsMissing]);

  const { loading: fashionWeekItemLoading } = useQuery(FASHION_WEEK_ITEM, {
    variables: {
      itemId: searchParams.get('itemId'),
    },
    skip:
      searchParams.get('itemType') !== 'fashionWeekItem' ||
      !searchParams.has('itemId'),
    onCompleted: (data) => {
      setReferenceImage({
        ...data.fashionWeekItem.photo.image,
        studioItemId: data.fashionWeekItem.photo.id,
        entityType: 'FASHION_WEEK_ITEM',
        entityId: data.fashionWeekItem.photo.id,
      });

      setStudioTask({
        ...studioTask,
        status: 'COMPLETED',
        images: [
          {
            ...data.fashionWeekItem.photo.image,
            studioItemId: data.fashionWeekItem.photo.id,
            entityType: 'FASHION_WEEK_ITEM',
            entityId: data.fashionWeekItem.photo.id,
          },
        ],
      });

      setSelectedGeneratedImage(-1);
      setRefSoloItemType('APPAREL');
      setDisplayMode(DisplayMode.PREVIEW);
    },
  });

  const { loading: socialMediaItemLoading } = useQuery(SOCIAL_MEDIA_ITEM, {
    variables: {
      postId: searchParams.get('itemId'),
    },
    skip:
      searchParams.get('itemType') !== 'socialMediaItem' ||
      !searchParams.has('itemId'),
    onCompleted: (data) => {
      setStudioTask({
        ...studioTask,
        status: 'COMPLETED',
        images: [
          {
            ...data.post.image,
            studioItemId: data.post.id,
            entityType: 'SOCIAL_MEDIA_ITEM',
            entityId: data.post.id,
          },
        ],
      });

      setReferenceImage({
        entityType: 'SOCIAL_MEDIA_ITEM',
        entityId: data.post.id,
        url: data.post.image.url,
      });

      setSelectedGeneratedImage(-1);
      setRefSoloItemType('APPAREL');
      setDisplayMode(DisplayMode.PREVIEW);
    },
  });

  const { loading: uploadItemImageLoading } = useQuery(UPLOAD_ITEM_IMAGE, {
    variables: {
      uploadItemImageId: searchParams.get('itemId'),
    },
    skip:
      searchParams.get('itemType') !== 'fileUploadItem' ||
      !searchParams.has('itemId'),
    onCompleted: (data) => {
      setStudioTask({
        ...studioTask,
        status: 'COMPLETED',
        images: [
          {
            ...data.uploadItemImage.node.image,
            width: data.uploadItemImage.node.image.width
              ? data.uploadItemImage.node.image.width
              : 950,
            height: data.uploadItemImage.node.image.height
              ? data.uploadItemImage.node.image.height
              : 1150,
            studioItemId: data.uploadItemImage.node.id,
            entityType: 'FILE_UPLOAD',
            entityId: data.uploadItemImage.node.id,
          },
        ],
      });
      setSelectedGeneratedImage(-1);
      setDisplayMode(DisplayMode.PREVIEW);

      if (data.uploadItemImage.node.fileEntityType === 'PATTERN') {
        setReferencePattern({
          ...data.uploadItemImage.node.image,
          studioItemId: data.uploadItemImage.node.id,
          entityType: 'FILE_UPLOAD',
          entityId: data.uploadItemImage.node.id,
        });
        setRefSoloItemType('PATTERN');
      } else if (data.uploadItemImage.node.fileEntityType === 'APPAREL') {
        setReferenceImage({
          ...data.uploadItemImage.node.image,
          studioItemId: data.uploadItemImage.node.id,
          entityType: 'FILE_UPLOAD',
          entityId: data.uploadItemImage.node.id,
        });
        setRefSoloItemType('APPAREL');
      } else if (data.uploadItemImage.node.fileEntityType === 'SKETCH') {
        setReferenceSketch({
          ...data.uploadItemImage.node.image,
          studioItemId: data.uploadItemImage.node.id,
          entityType: 'FILE_UPLOAD',
          entityId: data.uploadItemImage.node.id,
        });
        setRefSoloItemType('SKETCH');
      }
    },
  });

  const { loading: studioItemLoading } = useQuery(GET_STUDIO_ITEM, {
    variables: {
      id: searchParams.get('itemId'),
    },
    skip:
      searchParams.get('itemType') !== 'studioItem' ||
      !searchParams.has('itemId'),
    onCompleted: (data) => {
      setStudioTask({
        ...studioTask,
        status: 'COMPLETED',
        images: [
          {
            ...data.studioItem.node.image,
            width: data.studioItem.node.image.width,
            height: data.studioItem.node.image.height,
            studioItemId: data.studioItem.node.id,
            entityType: 'STUDIO_ITEM',
            entityId: data.studioItem.node.id,
          },
        ],
      });

      setSelectedGeneratedImage(-1);
      setDisplayMode(DisplayMode.PREVIEW);

      if (
        [
          'GENERATE_APPAREL',
          'IMAGE_BACKGROUND_REMOVER',
          'ZOOM_IN_IMAGE',
          'CREATE_VARIANTS',
        ].includes(data.studioItem.node.generateTaskType)
      ) {
        setReferenceImage({
          ...data.studioItem.node.image,
          studioItemId: data.studioItem.node.id,
          entityType: 'STUDIO_ITEM',
          entityId: data.studioItem.node.id,
        });
        setRefSoloItemType('APPAREL');
      } else if (
        ['GENERATE_PATTERN', 'PATTERN_TILE'].includes(
          data.studioItem.node.generateTaskType,
        )
      ) {
        setReferencePattern({
          ...data.studioItem.node.image,
          studioItemId: data.studioItem.node.id,
          entityType: 'STUDIO_ITEM',
          entityId: data.studioItem.node.id,
        });
        setRefSoloItemType('PATTERN');
      } else if (data.studioItem.node.generateTaskType === 'realize_sketch') {
        setReferenceSketch({
          ...data.studioItem.node.image,
          studioItemId: data.studioItem.node.id,
          entityType: 'STUDIO_ITEM',
          entityId: data.studioItem.node.id,
        });
        setReferenceImage({
          ...data.studioItem.node.image,
          studioItemId: data.studioItem.node.id,
          entityType: 'STUDIO_ITEM',
          entityId: data.studioItem.node.id,
        });
        setRefSoloItemType('SKETCH');
      }
    },
  });

  // useEffect(() => {
  //   console.log(1);
  //   fashionWeekItem();
  //   socialMediaItem();
  //   uploadItemImage();
  //   studioItem();
  //   /* if (searchParams.has('itemType')) {
  //     setCreateItem({ ...createItem, pendingAreaBoxCount: 1 });
  //     setStudioTask({ status: 'LOADING', images: [] });
  //   }

  //   if (
  //     searchParams.has('itemType') &&
  //     searchParams.get('itemType') === 'fashionWeekItem' &&
  //     searchParams.has('itemId')
  //   ) {
  //     fashionWeekItem();
  //   } else if (
  //     searchParams.has('itemType') &&
  //     searchParams.get('itemType') === 'socialMediaItem' &&
  //     searchParams.has('itemId')
  //   ) {
  //     socialMediaItem();
  //   } else if (
  //     searchParams.has('itemType') &&
  //     searchParams.get('itemType') === 'fileUploadItem' &&
  //     searchParams.has('itemId')
  //   ) {
  //     uploadItemImage();
  //   } else if (
  //     searchParams.has('itemType') &&
  //     searchParams.get('itemType') === 'studioItem' &&
  //     searchParams.has('itemId')
  //   ) {
  //     console.log('get studioItem');
  //     studioItem();
  //   } */
  // }, [searchParams.has('itemType')]);

  return (
    <div className="grid h-full grid-cols-[1fr,4.5fr] items-start justify-start">
      {/* Sidebar */}
      <StudioLeftSidebar />
      {/* Complement of Sidebar */}
      <div className="relative grid h-full grid-cols-[1fr,7rem] overflow-hidden">
        {/* Main Section */}
        <div className="flex flex-col overflow-auto border-r border-secondary/50 bg-[#FAF8F8] p-6">
          {/* Home Page */}
          {isHome && <StudioHome handleChange={updateCreateItemDetails} />}
          {/* Create an Apparel Page */}
          {!isHome && (
            <div
              className={`relative ${openTools ? 'flex w-3/5 flex-col' : 'flex h-full flex-col md:grid md:grid-cols-[1fr,0.8fr]'} items-start justify-start`}
            >
              <ImageDisplay
                generatedImages={
                  studioTask?.images?.length > 0 ? studioTask?.images : []
                }
                orientation={orientation}
                downloadFile={downloadFile}
              />

              {similarImagesData?.studioSimilarImages?.length > 0 ? (
                <div
                  className="flex max-h-[calc(120vh-1rem)] w-full flex-col gap-y-6 overflow-y-auto p-6"
                  id="more-ideas"
                >
                  <div>
                    <h2
                      className="text-2xl font-bold leading-6 text-primary"
                      inert=""
                    >
                      More ideas to explore
                    </h2>
                  </div>
                  <div
                    className={`grid ${openTools ? 'grid-cols-4' : 'grid-cols-3 sm:grid-cols-4 md:grid-cols-3'} mb-20 gap-6`}
                  >
                    {(similarImagesLoading ||
                      socialMediaItemLoading ||
                      uploadItemImageLoading ||
                      fashionWeekItemLoading ||
                      studioItemLoading) &&
                      Array.from({ length: 16 }).map((_, index) => (
                        <div
                          key={index}
                          className="ghost aspect-[3/4] rounded"
                        ></div>
                      ))}
                    {similarImagesData?.studioSimilarImages?.length > 0 &&
                      similarImagesData?.studioSimilarImages?.map(
                        (data, index) => {
                          const startIndex = data.node.image.url.indexOf('/v3');
                          const endIndex =
                            data.node.image.url.indexOf('.jpg') + 4;
                          const extractedPath = data.node.image.url.slice(
                            startIndex,
                            endIndex,
                          );
                          const newUrl = `https://assets.tfashion.ai${extractedPath}?format=auto`;
                          return (
                            <div
                              key={index}
                              className="aspect-[3/4] cursor-pointer rounded transition-transform hover:scale-105 hover:shadow"
                              onClick={() =>
                                navigate(
                                  `/studio/v1/image-preview/studioItem/${data.node.id}`,
                                )
                              }
                            >
                              <img
                                src={
                                  import.meta.env.VITE_APP_ENV === 'development'
                                    ? newUrl
                                    : data.node.image.url
                                }
                                onError={(e) => {
                                  if (e.target.src !== data.node.image.url) {
                                    e.target.src = data.node.image.url;
                                  }
                                  e.target.onerror = null;
                                }}
                                alt={data.node.image.studioItemId}
                                className="h-full w-full rounded object-cover"
                              />
                            </div>
                          );
                        },
                      )}
                    {similarImagesError && (
                      <div className="w-60 text-base font-semibold text-black">
                        More ideas failed to load
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                !(
                  studioTaskData?.studioTask?.generateTaskType ===
                    'IMAGE_BACKGROUND_REMOVER' ||
                  searchParams.has('generationType')
                ) && (
                  <div
                    className="flex w-full flex-col gap-y-6 p-6"
                    id="loading-more-ideas"
                  >
                    <div>
                      <h2
                        className="text-2xl font-bold leading-6 text-primary"
                        inert=""
                      >
                        More ideas to explore
                      </h2>
                    </div>
                    <div
                      className={`grid ${openTools ? 'grid-cols-4' : 'grid-cols-3 sm:grid-cols-4 md:grid-cols-3'} mb-20 gap-6`}
                    >
                      {Array.from({ length: 14 }).map((_, index) => (
                        <div
                          key={index}
                          className="ghost aspect-[3/4] rounded"
                        ></div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          )}
          {activeModal === null && openTools && <StudioTools />}
        </div>
        {/* AsideBar */}
        <AsideBar activeModal={activeModal} setActiveModal={setActiveModal} />
        {/* Collection Modal */}
        {activeModal === 'collections' && (
          <Collections setActiveModal={setActiveModal} />
        )}
        {activeModal === 'history' && (
          <StudioHistorySidebar
            activeModal={activeModal}
            setActiveModal={setActiveModal}
          />
        )}
        {activeModal === 'designs' && (
          <StudioExploreSideBar
            activeModal={activeModal}
            setActiveModal={setActiveModal}
          />
        )}
        {activeModal === 'patterns' && (
          <StudioExploreSideBar
            activeModal={activeModal}
            setActiveModal={setActiveModal}
          />
        )}
        {activeModal === 'sketches' && (
          <StudioExploreSideBar
            activeModal={activeModal}
            setActiveModal={setActiveModal}
          />
        )}
      </div>
    </div>
  );
};
