import attributes from '@/data/attributes.json';
import categories from '@/data/categories.json';

export const apparelGroups = [
  { id: 1, name: 'Tops', items: [6, 8, 19, 16] },
  { id: 2, name: 'Bottoms', items: [4, 5, 7, 18] },
  { id: 3, name: 'Dresses & Jumpsuits', items: [2, 3] },
  { id: 4, name: 'Outerwear', items: [17, 39, 38] },
  { id: 5, name: 'Swimwear & Beachwear', items: [0, 1] },
  { id: 6, name: 'Intimates & Lingerie', items: [30, 31, 32] },
  { id: 7, name: 'Sweaters & Cardigans', items: [15, 20, 29] },
  { id: 8, name: 'Footwear', items: [10, 22] },
  {
    id: 9,
    name: 'Accessories',
    items: [9, 36, 33, 21, 34, 35, 23, 24, 37, 26, 12, 11],
  },
  { id: 10, name: 'Jewelry', items: [13, 14, 25, 27] },
  { id: 11, name: 'Conservative Wear', items: [28] },
];

const itemOptionsGrouped = categories.all
  .map((item) => ({
    label: item.name,
    value: item.key,
    id: item.id,
  }))
  .reduce((acc, item) => {
    const group = apparelGroups.find((group) => group.items.includes(item.id));
    delete item.id;

    if (acc[group.id]) {
      acc[group.id].push(item);
    } else {
      acc[group.id] = [item];
    }

    return acc;
  }, {});

export const itemOptions = Object.keys(itemOptionsGrouped)
  .map((key) => {
    const group = apparelGroups.find((group) => group.id === parseInt(key));

    return {
      label: group.name,
      options: itemOptionsGrouped[key].sort((a, b) =>
        a.label.localeCompare(b.label),
      ),
      group,
    };
  })
  .sort((a, b) => a.group.id - b.group.id)
  .map((group) => ({
    label: group.label,
    options: group.options,
  }));

// Filter out already selected attribute classes/categories from the options
export const getDesignOptions = (payload) => {
  return attributes
    .find((a) => a.value === payload.item?.value)
    ?.attributes.filter((attribute) => {
      return (
        payload?.design?.find(
          (design) => design.attributeClass === attribute.value,
        ) === undefined
      );
    })
    .filter(
      (attribute) =>
        !attribute.label.startsWith('Concepts') &&
        !attribute.label.startsWith('Gender'),
    )
    .sort((a, b) => a.label.localeCompare(b.label))
    .map((option) => ({
      label: option.label,
      value: option.value,
      attributeClass: option.attributeClass,
      options: option.options.sort((a, b) => a.label.localeCompare(b.label)),
    }));
};

export const conceptOptions = [
  { label: 'Default', value: 'default' },
  { label: 'E-commerce', value: 'ecommerce' },
  { label: 'Fashion week', value: 'fashion-week' },
  { label: 'Only item', value: 'only-item' },
];

export const orientationOptions = [
  { label: 'Portrait (3:4)', value: 'portrait' },
  { label: 'Landscape (4:3)', value: 'landscape' },
  { label: 'Square (1:1)', value: 'square' },
];

export const colorOptions = [
  { hex: '#f3ece0', label: 'Beige', value: 'beige' },
  { hex: '#efe1ce', label: 'Brown', value: 'brown' },
  { hex: '#ecead0', label: 'Green', value: 'green' },
  { hex: '#f6ebc8', label: 'Yellow', value: 'yellow' },
  { hex: '#f4f9ff', label: 'White', value: 'white' },
  { hex: '#e5d9d3', label: 'Lavender', value: 'lavender' },
  { hex: '#f3dfca', label: 'Orange', value: 'orange' },
  { hex: '#ede4df', label: 'Pink', value: 'pink' },
  { hex: '#e1e3de', label: 'Gray', value: 'gray' },
  { hex: '#e2eaeb', label: 'Blue', value: 'blue' },
  { hex: '#daf2f1', label: 'Aqua', value: 'aqua' },
  { hex: '#d3dec4', label: 'Lime', value: 'lime' },
  { hex: '#d3cca3', label: 'Olive', value: 'olive' },
  { hex: '#fbd897', label: 'Gold', value: 'gold' },
  { hex: '#d7d7c7', label: 'Silver', value: 'silver' },
  { hex: '#9eb6b8', label: 'Teal', value: 'teal' },
  { hex: '#c9a38d', label: 'Maroon', value: 'maroon' },
  { hex: '#cc7457', label: 'Red', value: 'red' },
  { hex: '#c6a4a4', label: 'Purple', value: 'purple' },
  { hex: '#829ca5', label: 'Navy', value: 'navy' },
  { hex: '#2d2c2f', label: 'Black', value: 'black' },
  {
    hex: 'conic-gradient(red, orange, yellow, green, blue)',
    label: 'Colorful',
    value: 'colorful',
  },
].sort((a, b) => a.label.localeCompare(b.label));

export const genderOptions = [
  { label: 'Men', value: 'men' },
  { label: 'Women', value: 'women' },
];

export const additionalInputOptions = [
  // { label: 'Color', value: 'color', key: 'color' },
  {
    label: 'Gender',
    value: 'gender',
    key: 'gender',
    type: 'select',
    selectOptions: genderOptions,
  },
  {
    label: 'Scene',
    value: 'location',
    key: 'location',
    type: 'text',
    placeholder: 'e.g. Paris or coffee shop',
  },
];

export const ADDITIONAL_INPUT_OPTIONS = {
  COLOR: additionalInputOptions[0],
  LOCATION: additionalInputOptions[1],
  BACKGROUND: additionalInputOptions[2],
};
