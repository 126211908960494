import { createContext, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { genderMapping, seasons } from '@/constants';

export const FiltersContext = createContext();

const demoFilters = {
  patterns: [
    {
      label: 'plain',
      value: 'plain',
    },
    {
      label: 'abstract',
      value: 'abstract',
    },
    {
      label: 'cartoon',
      value: 'cartoon',
    },
    {
      label: 'letters&numbers',
      value: 'letters&numbers',
    },
    {
      label: 'camouflage',
      value: 'camouflage',
    },
    {
      label: 'check',
      value: 'check',
    },
    {
      label: 'dot',
      value: 'dot',
    },
    {
      label: 'fair-isle',
      value: 'fair-isle',
    },
    {
      label: 'floral',
      value: 'floral',
    },
    {
      label: 'geometric',
      value: 'geometric',
    },
    {
      label: 'paisley',
      value: 'paisley',
    },
    {
      label: 'stipe',
      value: 'stipe',
    },
    {
      label: 'houndstooth',
      value: 'houndstooth',
    },
    {
      label: 'herringbone',
      value: 'herringbone',
    },
    {
      label: 'chevron',
      value: 'chevron',
    },
    {
      label: 'argyle',
      value: 'argyle',
    },
    {
      label: 'animal-skin',
      value: 'animal-skin',
    },
    {
      label: 'toile-de-jouy',
      value: 'toile-de-jouy',
    },
    {
      label: 'tie-dye',
      value: 'tie-dye',
    },
    {
      label: 'letteres&numbers',
      value: 'letteres&numbers',
    },
    {
      label: 'stripe',
      value: 'stripe',
    },
    {
      label: 'plant',
      value: 'plant',
    },
    {
      label: 'cartoon&letters',
      value: 'cartoon&letters',
    },
  ],
  apparels: [
    {
      label: 'dress',
      value: 2,
    },
    {
      label: 'bikini',
      value: 0,
    },
    {
      label: 'swimsuit',
      value: 1,
    },
    {
      label: 'jumpsuit',
      value: 3,
    },
    {
      label: 'pant/trouser',
      value: 4,
    },
    {
      label: 'short',
      value: 5,
    },
    {
      label: 'shirt',
      value: 6,
    },
    {
      label: 'skirt',
      value: 7,
    },
    {
      label: 'blouse',
      value: 8,
    },
    {
      label: 'bag',
      value: 9,
    },
    {
      label: 'shoe',
      value: 10,
    },
    {
      label: 'glass',
      value: 11,
    },
    {
      label: 'glove',
      value: 12,
    },
    {
      label: 'earring',
      value: 13,
    },
    {
      label: 'ring',
      value: 14,
    },
    {
      label: 'sweatshirt',
      value: 15,
    },
    {
      label: 'top',
      value: 16,
    },
    {
      label: 'coat',
      value: 17,
    },
    {
      label: 'jeans',
      value: 18,
    },
    {
      label: 't-shirt',
      value: 19,
    },
    {
      label: 'sweater',
      value: 20,
    },
    {
      label: 'hat',
      value: 21,
    },
    {
      label: 'sock',
      value: 22,
    },
    {
      label: 'sunglass',
      value: 23,
    },
    {
      label: 'tie',
      value: 24,
    },
    {
      label: 'bracelet',
      value: 25,
    },
    {
      label: 'face-covering',
      value: 26,
    },
    {
      label: 'necklace&chain',
      value: 27,
    },
    {
      label: 'hijab',
      value: 28,
    },
    {
      label: 'cardigan',
      value: 29,
    },
    {
      label: 'boxer',
      value: 30,
    },
    {
      label: 'panties',
      value: 31,
    },
    {
      label: 'bra',
      value: 32,
    },
    {
      label: 'belt',
      value: 33,
    },
    {
      label: 'scarve',
      value: 34,
    },
    {
      label: 'head-accessory',
      value: 35,
    },
    {
      label: 'wallet',
      value: 36,
    },
    {
      label: 'watch',
      value: 37,
    },
    {
      label: 'vest',
      value: 38,
    },
    {
      label: 'jacket',
      value: 39,
    },
  ],
};

export const FiltersProvider = ({ children }) => {
  const [searchParams] = useSearchParams();
  const { marketId } = useParams();

  const queryParams = searchParams.get('payload')
    ? JSON.parse(window.atob(searchParams.get('payload')))
    : {};

  const [selectedAudience, setSelectedAudience] = useState(
    queryParams.audience || null,
  );
  const [selectedMarket, setSelectedMarket] = useState(
    queryParams.market || null,
  );
  const [selectedSeason, setSelectedSeason] = useState(
    queryParams.season || '0',
  );

  const [selectedExploreTone, setSelectedExploreTone] = useState(
    queryParams.exploreTone || null,
  );

  const [selectedExploreToneSimilarity, setSelectedExploreToneSimilarity] =
    useState(Number(queryParams.exploreToneSimilarity) || 3);

  const [selectedApparels, setSelectedApparels] = useState(
    queryParams.apparels ? queryParams.apparels : [],
  );
  const [selectedExploreApparels, setSelectedExploreApparels] = useState(
    queryParams.exploreApparels ? queryParams.exploreApparels : [],
  );

  const [selectedExploreApparelItems, setSelectedExploreApparelItems] =
    useState(
      queryParams.exploreApparelItems ? queryParams.exploreApparelItems : [],
    );

  const [selectedExploreColors, setSelectedExploreColors] = useState(
    queryParams.exploreColors ? queryParams.exploreColors : [],
  );

  const [selectedExplorePatterns, setSelectedExplorePatterns] = useState(
    queryParams.explorePatterns ? queryParams.explorePatterns : [],
  );

  const [selectedColors, setSelectedColors] = useState(
    queryParams.colors ? queryParams.colors : [],
  );

  const [selectedPatterns, setSelectedPatterns] = useState(
    queryParams.patterns ? queryParams.patterns : [],
  );

  const [isApparelFilterOpen, setIsApparelFilterOpen] = useState(false);
  const [isColorFilterOpen, setIsColorFilterOpen] = useState(false);

  const marketLabel = () => {
    if (!selectedMarket) return 'All Markets';

    const {
      location: { name },
    } = selectedMarket;
    if (!selectedAudience) return name;

    const { ageMin, ageMax, gender } = selectedAudience;

    return `${name}, ${genderMapping[gender].shortLabel} ${ageMin}-${ageMax}`;
  };

  const selectedMarketId = selectedMarket?.id || marketId;
  const selectedAudienceId = selectedAudience?.id;

  const exploreApparelFilters = selectedExploreApparelItems.length
    ? selectedExploreApparelItems.map((apparelItem) => ({
        value: apparelItem.apparel,
        items: apparelItem.items,
      }))
    : selectedExploreApparels.length
      ? selectedExploreApparels.map((apparel) => ({
          value: apparel.value,
          items: [],
        }))
      : null;

  // useEffect(() => {
  //   const updatedQueryParams = {
  //     ...queryParams,
  //     audience: selectedAudience,
  //     market: selectedMarket,
  //     season: selectedSeason,
  //     apparels: selectedApparels,
  //     exploreApparels: selectedExploreApparels,
  //     exploreApparelItems: selectedExploreApparelItems,
  //     exploreColors: selectedExploreColors,
  //     explorePatterns: selectedExplorePatterns,
  //     colors: selectedColors,
  //     patterns: selectedPatterns
  //   }

  //   // const payload = window.btoa(JSON.stringify(updatedQueryParams))

  //   // setSearchParams({ payload })
  //   // localStorage.setItem('filtersContext', payload)
  // }, [
  //   selectedAudience,
  //   selectedMarket,
  //   selectedSeason,
  //   selectedApparels,
  //   selectedExploreApparels,
  //   selectedExploreApparelItems,
  //   selectedExploreColors,
  //   selectedExplorePatterns,
  //   selectedColors,
  //   selectedPatterns
  // ])

  function selectExploreApparel(apparel) {
    const exists = selectedExploreApparels.find(
      (a) => a.label === apparel.label,
    );

    if (exists) {
      setSelectedExploreApparels((prev) =>
        prev.filter((a) => a.label !== apparel.label),
      );

      setSelectedExploreApparelItems((prev) =>
        prev.filter((item) => item.apparel !== apparel.value),
      );
    } else {
      setSelectedExploreApparels((prev) => [...prev, apparel]);
    }
  }

  function selectExploreApparelItem(apparelItem) {
    const exists = selectedExploreApparelItems.find(
      (item) =>
        item.apparel === apparelItem.apparel &&
        item.label === apparelItem.label,
    );

    if (exists) {
      setSelectedExploreApparelItems(
        selectedExploreApparelItems.filter(
          (item) => item.id !== apparelItem.id,
        ),
      );
    } else {
      setSelectedExploreApparelItems((prev) => [
        ...prev,
        {
          label: apparelItem.label,
          apparel: apparelItem.apparel,
          id: apparelItem.id,
          items: apparelItem.items.map((i) => ({
            type: i.type,
            value: i.value,
          })),
        },
      ]);
    }
  }

  function clearExploreApparel() {
    setSelectedExploreApparels([]);
    setSelectedExploreApparelItems([]);
  }

  function selectExploreColor(color) {
    setSelectedExploreTone(null);
    setSelectedExploreToneSimilarity(3);

    const checked = selectedExploreColors.find((c) => c.value === color.value);

    if (checked) {
      setSelectedExploreColors((prev) =>
        prev.filter((c) => c.value !== color.value),
      );
    } else {
      setSelectedExploreColors((prev) => [...prev, color]);
    }
  }

  function clearExploreColors() {
    setSelectedExploreColors([]);
  }

  function selectExplorePattern(pattern) {
    const isExists = selectedExplorePatterns.find(
      (p) => p.value === pattern.value,
    );

    if (isExists) {
      setSelectedExplorePatterns((prev) =>
        prev.filter((p) => p.value !== pattern.value),
      );
    } else {
      setSelectedExplorePatterns((prev) => [...prev, pattern]);
    }
  }

  function selectTrendsApparel(item) {
    const exists = selectedApparels.find((a) => a.value === item.value);

    if (exists) {
      setSelectedApparels((prev) => prev.filter((a) => a.value !== item.value));
    } else {
      setSelectedApparels((prev) => [...prev, item]);
    }
  }

  function selectedSeasonLabel() {
    const season = seasons.find((s) => s.value === selectedSeason);

    if (!season) return null;

    return season.label.toLowerCase();
  }

  function selectTrendPattern(pattern) {
    const exists = selectedPatterns.find((p) => p.value === pattern.value);

    if (exists) {
      setSelectedPatterns((prev) =>
        prev.filter((p) => p.value !== pattern.value),
      );
    } else {
      setSelectedPatterns((prev) => [...prev, pattern]);
    }
  }

  function selectTrendColor(color) {
    const exists = selectedColors.find((c) => c.label === color.label);

    if (exists) {
      setSelectedColors((prev) => prev.filter((c) => c.label !== color.label));
    } else {
      setSelectedColors((prev) => [...prev, color]);
    }
  }

  const exploreColorFilter = selectedExploreColors.length
    ? selectedExploreColors.map((c) => c.label)
    : null;

  const explorePatternFilter = selectedExplorePatterns.length
    ? selectedExplorePatterns.map((p) => p.value)
    : null;

  return (
    <FiltersContext.Provider
      value={{
        common: {
          selectedAudience,
          setSelectedAudience,
          selectedSeason,
          setSelectedSeason,
          selectedSeasonLabel,
          selectedMarket,
          setSelectedMarket,
          seasons,
          marketLabel: marketLabel(),
          selectedMarketId,
          selectedAudienceId,
          demoApparels: demoFilters.apparels,
          demoPatterns: demoFilters.patterns,
        },
        explore: {
          selectedApparels: selectedExploreApparels,
          selectApparel: selectExploreApparel,
          selectedApparelItems: selectedExploreApparelItems,
          selectApparelItem: selectExploreApparelItem,
          clearApparels: clearExploreApparel,
          selectedColors: selectedExploreColors,
          selectColor: selectExploreColor,
          clearColors: clearExploreColors,
          selectedPatterns: selectedExplorePatterns,
          selectPattern: selectExplorePattern,
          apparelFilter: exploreApparelFilters,
          colorFilter: exploreColorFilter,
          patternFilter: explorePatternFilter,
          selectedTone: selectedExploreTone,
          setSelectedTone: setSelectedExploreTone,
          selectedToneSimilarity: selectedExploreToneSimilarity,
          setSelectedToneSimilarity: setSelectedExploreToneSimilarity,
          clearAllFilters: () => {
            clearExploreApparel();
            clearExploreColors();
            setSelectedExplorePatterns([]);
            setSelectedSeason('0');
          },
        },
        trends: {
          selectedApparels,
          setSelectedApparels,
          selectedColors,
          setSelectedColors,
          selectedPatterns,
          setSelectedPatterns,
          selectColor: selectTrendColor,
          selectPattern: selectTrendPattern,
          selectApparel: selectTrendsApparel,
          clearApparels: () => setSelectedApparels([]),
          clearAllFilters: () => {
            setSelectedApparels([]);
            setSelectedColors([]);
            setSelectedPatterns([]);
            setSelectedSeason('0');
          },
          isApparelFilterOpen,
          setIsApparelFilterOpen,
          isColorFilterOpen,
          setIsColorFilterOpen,
        },
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

/**
 * example data
 * selectedMarket: {
 *  name: 'United States',
 *  countryCode: 'US',
 *  id: '5f9e1b5b0b5d9b0017b0b1b2',
 * },
 * selectedAudience: {
 *  id: '5f9e1b5b0b5d9b0017b0b1b2',
 *  gender: '1
 *  ageMin: 18,
 *  ageMax: 24,
 * }
 */
