import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { usePostHog } from 'posthog-js/react';

import { ME } from '@/api/authentication';
import { TFashion } from '@/assets/svg';

export function AuthMiddleware({ children }) {
  const [me, { loading, data }] = useLazyQuery(ME);
  const posthog = usePostHog();

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      me();
    } else {
      posthog.reset();
    }
  }, [me]);

  useEffect(() => {
    if (data?.me) {
      posthog.identify(data.me.id, {
        email: data.me.email,
      });
    }
  }, [data]);

  if (loading) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <TFashion />
      </div>
    );
  }

  return <>{children}</>;
}
